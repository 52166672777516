import { RescaledRectangleComponent } from '../../Geometries/Rectangle';
import ScaleUtils from '../../ScaleUtils';
import { SchematicReferenceConstants } from '../../SchematicReferenceConstants';
export const CimentacaoComponent = ({
  width,
  topo,
  base,
  scaleUtils,
  schematicReferenceConstants
}: {
  width: number;
  topo: number;
  base: number;
  scaleUtils: ScaleUtils;
  schematicReferenceConstants: SchematicReferenceConstants;
}) => {
  const referencePosition = {
    x: schematicReferenceConstants.middlePosition,
    y: schematicReferenceConstants.TopoDoPoco + topo
  };
  const color = '#3B5064';

  return (
    <RescaledRectangleComponent
      width={width}
      height={base - topo}
      referencePosition={referencePosition}
      color={color}
      lineColor={color}
      scaleUtils={scaleUtils}
    />
  );
};
