import { workflowPhasesEnum } from '@/constants/enums/phases';
import { getIssueRedmine } from '@/services/utils.services';
import { add, addHours, format, parse, parseISO } from 'date-fns';
import { Column } from 'react-table';
import { DateType } from 'react-tailwindcss-datepicker';

export type StatusChange = {
  author: string;
  date: string;
  created_on: string;
  status: {
    old_value: number;
    new_value: number;
  };
};

export const numberFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const numberFormatterCustom = (options: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat('pt-BR', options);

function hexToRgb(hex: string) {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

function rgbToHex(r: number, g: number, b: number) {
  return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
}

function interpolateColor(color1: string, color2: string, factor: number) {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * factor);
  const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * factor);
  const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * factor);

  return rgbToHex(r, g, b);
}

function calculateMeanRgb(colors: string[]) {
  let totalR = 0;
  let totalG = 0;
  let totalB = 0;

  for (const color of colors) {
    const { r, g, b } = hexToRgb(color);
    totalR += r;
    totalG += g;
    totalB += b;
  }

  const meanR = Math.round(totalR / colors.length);
  const meanG = Math.round(totalG / colors.length);
  const meanB = Math.round(totalB / colors.length);

  return rgbToHex(meanR, meanG, meanB);
}

export function interpolateColors(colors: string[], steps: number) {
  const result = [];

  for (let i = 0; i < steps - 1; i++) {
    const factor = i / (steps - 1);
    const color = interpolateColor(colors[0], colors[1], factor);
    result.push(color);
  }

  result.push(colors[colors.length - 1]);

  const meanColor = calculateMeanRgb(result);
  return meanColor;
}

export const splitAndJoinString = (str: string, size?: number): string => {
  if (!str) return '';
  const splitStrings = str.split('\n');
  const joinedStrings = splitStrings.map(splitStr => {
    const splitChunks = splitStr.match(
      new RegExp(`.{1,${size || 40}}\\S*`, 'g')
    );
    if (splitChunks) {
      return splitChunks.join('\n');
    }
    return splitStr;
  });
  return joinedStrings.join('\n');
};

export const sumTimes = (time1?: string, time2?: string) => {
  const parsedTime1 = parse(time1 ?? '00:00', 'HH:mm', new Date());
  const parsedTime2 = parse(time2 ?? '00:00', 'HH:mm', new Date());

  const summedTime = add(parsedTime1, {
    hours: parsedTime2.getHours(),
    minutes: parsedTime2.getMinutes()
  });

  return format(summedTime, 'HH:mm');
};

export const subTimes = (time1?: string, time2?: string) => {
  const parsedTime1 = parse(time1 || '00:00', 'HH:mm', new Date());
  const parsedTime2 = parse(time2 || '00:00', 'HH:mm', new Date());

  const subbedTime = add(parsedTime1, {
    hours: -parsedTime2.getHours(),
    minutes: -parsedTime2.getMinutes()
  });

  return format(subbedTime, 'HH:mm');
};

export const sumMultipleTimes = (times: string[]) => {
  let totalTime = parse('00:00', 'HH:mm', new Date());

  times.forEach(time => {
    const parsedTime = parse(time ?? '00:00', 'HH:mm', new Date());
    totalTime = add(totalTime, {
      hours: parsedTime.getHours(),
      minutes: parsedTime.getMinutes()
    });
  });

  return format(totalTime, 'HH:mm');
};

export const getStatusChangeById = async (id: string) => {
  const data = await getIssueRedmine(id);

  const allStatusChange = data
    ? changeStatusFromJournal(data.issue?.journals || [])
    : null;

  return allStatusChange;
};

export const changeStatusFromJournal = (data: any[] = []) =>
  data
    .filter(
      (journal: any) =>
        journal.details.findIndex(
          (detail: any) => detail.name == 'status_id'
        ) !== -1
    )
    .map((it: any) => {
      const status = it.details.find(
        (detail: any) => detail.name == 'status_id'
      );

      return {
        author: it.user.name
          ?.split(' ')
          ?.filter((it: string) => !!it)
          ?.map((word: string) => {
            return (
              word?.[0]?.toUpperCase() +
                word?.substring?.(1)?.toLocaleLowerCase() || ''
            );
          })
          .join(' '),
        date: format(it.created_on, 'dd/MM/yyyy HH:mm'),
        created_on: it.created_on,
        status: {
          old_value: +status.old_value,
          new_value: +status.new_value
        }
      } as StatusChange;
    })
    .sort((a: any, b: any) => b.created_on?.localeCompare(a?.created_on));

export const getAssignedChangeById = async (id: string) => {
  const data = await getIssueRedmine(id);

  const allAssignedChange = data
    ? changeAssignedFromJournal(data.issue?.journals || [])
    : null;

  return allAssignedChange;
};

const changeAssignedFromJournal = (data: any[] = []) =>
  data
    .filter(
      (journal: any) =>
        journal.details.findIndex(
          (detail: any) => detail.name == 'assigned_to_id'
        ) !== -1
    )
    .map((it: any) => {
      const assigned = it.details.find(
        (detail: any) => detail.name == 'assigned_to_id'
      );

      return {
        author: it.user.name
          ?.split(' ')
          ?.filter((it: string) => !!it)
          ?.map((word: string) => {
            return (
              word?.[0]?.toUpperCase() +
                word?.substring?.(1)?.toLocaleLowerCase() || ''
            );
          })
          .join(' '),
        date: format(it.created_on, 'dd/MM/yyyy HH:mm'),
        created_on: it.created_on,
        status: {
          old_value: +assigned.old_value,
          new_value: +assigned.new_value
        }
      } as StatusChange;
    })
    .sort((a: any, b: any) => b.created_on?.localeCompare(a?.created_on));

export const mapperArrayToAcessor = (
  arr: { rows: string[][] },
  column: Column[]
) => {
  if (!arr) return [];
  const order = column.reduce((acc: string[], curr: any) => {
    if (curr.columns) {
      return [...acc, ...curr.columns.map((it: any) => it.accessor)];
    }
    return [...acc, curr.accessor];
  }, []);

  const arrFormated = arr.rows.map(it => Object.values(it));

  return arrFormated.map((rows, idx) => {
    const newObj: { [key: string]: string | boolean | undefined } = {};
    order.forEach((it, idx) => {
      newObj[it] = rows[idx] !== '' ? rows[idx] : undefined;
    });

    if (idx >= arrFormated.length - 3) {
      newObj.isSummary = true;
    }

    return newObj;
  });
};

export const joinOr = (values: string[], defaultValue: string) => {
  return values.length > 0 ? values.join(', ') : defaultValue;
};

export const formatDateTimeISO = (isoDateString: string | undefined) => {
  if (!isoDateString) return '';

  const parsedDate = parseISO(isoDateString);
  const formattedDate = format(parsedDate, 'dd/MM/yyyy HH:mm');

  return formattedDate;
};

export const formatDateTime = (dateStr: string | undefined) => {
  if (!dateStr) return '';

  try {
    if (dateStr.includes('/')) {
      const data = parse(dateStr, 'dd/MM/yyyy HH:mm', new Date());
      return format(addHours(data, 3), 'dd/MM/yyyy HH:mm');
    }

    if (dateStr.includes('-')) {
      const data = parse(dateStr, 'yyyy-MM-dd HH:mm', new Date());
      return format(addHours(data, 3), 'dd/MM/yyyy HH:mm');
    }
  } catch (error) {
    console.error(error);
  }

  return '';
};

export const formatDate = (isoDateString: string) => {
  if (!isoDateString) return '';

  const parsedDate = parseISO(isoDateString);
  const formattedDate = format(parsedDate, 'dd/MM/yyyy');

  return formattedDate;
};

export const redmineFormatDate = (isoDateStr?: DateType) => {
  if (!isoDateStr) return undefined;
  return format(isoDateStr, 'yyyy-MM-dd');
};

export function extractYear(date: string) {
  return date.split('-')[0];
}

export const numberFormatter2 = (min = 0, max = 2) => {
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: min,
    maximumFractionDigits: max
  });
};

export const formatStringToNumber = (value: string | number): number => {
  if (typeof value === 'number') return value;

  const normalizedValue = value.replace(',', '.');
  return parseFloat(normalizedValue);
};

export const formatDateWithoutTimezone = (isoDateStr: string) => {
  const parsedDate = parseISO(isoDateStr);
  const date = new Date(isoDateStr);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export function pascalCaseToPhrase(input: string): string {
  return input.split(/(?=[A-Z])/).join(' ');
}

export function getBadgeColor(status: number) {
  if (
    status === workflowPhasesEnum.ValidacaoProducao ||
    status === workflowPhasesEnum.UploadBoletim ||
    status === workflowPhasesEnum.ValidacaoCPROD
  ) {
    return 'bg-[#FFE8D7] text-[#EC7100]';
  } else if (
    status === workflowPhasesEnum.RevisaoBoletim ||
    status === workflowPhasesEnum.PreenchimentoBoletim
  ) {
    return 'bg-red/25 text-red';
  } else if (status === workflowPhasesEnum.ValidacaoProducaoOnHold) {
    return 'bg-[#FFD800]/20 text-[#a39f32]';
  }
}

export function getBadgeText(status: workflowPhasesEnum) {
  if (
    status === workflowPhasesEnum.ValidacaoProducao ||
    status === workflowPhasesEnum.UploadBoletim ||
    status === workflowPhasesEnum.ValidacaoCPROD
  ) {
    return 'Em Validação';
  } else if (
    status === workflowPhasesEnum.RevisaoBoletim ||
    status === workflowPhasesEnum.PreenchimentoBoletim
  ) {
    return 'Em Preenchimento';
  } else if (status === workflowPhasesEnum.ValidacaoProducaoOnHold) {
    return 'Em Espera';
  }
}

export const formatDateYearMonthDay = (isoDateString: string) => {
  const parsedDate = parseISO(isoDateString);
  const formattedDate = format(parsedDate, 'yyyy-MM-dd');

  return formattedDate;
};

