import Checkbox from '@/components/atoms/Checkbox';
import Modal from '@/components/atoms/Modal';
import { PageWrapper } from '@/components/atoms/PageWrapper';
import { useRef, useState } from 'react';
import { ControlledSelect } from './ControlledSelect/ControlledSelect';
import { Activity } from './Enums/Activity.enum';
import {
  BastoesOperation,
  RondaOperation,
  SonologOperation
} from './Enums/Operation.enum';
import MudancaCorBastaoForm from './Forms/Bastoes/MudancaCorBastao/MudancaCorBastaoForm';
import { CreateMudancaCorBastaoFormData } from './Forms/Bastoes/MudancaCorBastao/useCreateMudancaCorBastaoForm';
import MudancaFrequenciaBastaoForm from './Forms/Bastoes/MudancaFrequenciaBastao/MudancaFrequenciaBastaoForm';
import { CreateMudancaFrequenciaBastaoFormData } from './Forms/Bastoes/MudancaFrequenciaBastao/useCreateMudancaFrequenciaBastaoForm';
import TesteImplementacaoBastaoForm from './Forms/Bastoes/TesteImplementacaoBastaoForm/TesteImplementacaoBastaoForm';
import { CreateTesteImplementacaoBastaoFormData } from './Forms/Bastoes/TesteImplementacaoBastaoForm/useCreateTesteImplementacaoBastaoForm';
import FrequencyInformationForm from './Forms/LayerForms/FrequencyInformationForm/FrequencyInformationForm';
import SingleDateForm from './Forms/LayerForms/FrequencyInformationForm/SingleDateForm';
import { CreateFrequencyInformationFormData } from './Forms/LayerForms/FrequencyInformationForm/useCreateFrequencyInformationForm';
import { CreateSingleDateFormData } from './Forms/LayerForms/FrequencyInformationForm/useCreateSingleDateForm';
import GeneralInformationForm from './Forms/LayerForms/GeneralInformationForm/GeneralInformationForm';
import { CreateGeneralInformationFormData } from './Forms/LayerForms/GeneralInformationForm/useCreateGeneralInformationForm';
import AlteracaoBeanForm from './Forms/Ronda/AlteracaoBeanForm/AlteracaoBeanForm';
import { CreateAlteracaoBeanFormData } from './Forms/Ronda/AlteracaoBeanForm/useCreateAlteracaoBeanForm';
import AlteracaoCursoUBForm from './Forms/Ronda/AlteracaoCursoUB/AlteracaoCursoUBForm';
import { CreateAlteracaoCursoUBFormData } from './Forms/Ronda/AlteracaoCursoUB/useCreateAlteracaoCursoUBForm';
import ColetaAmostrasCavaloteForm from './Forms/Ronda/ColetaAmostrasCavaloteForm/ColetaAmostrasCavaloteForm';
import { CreateColetaAmostrasCavaloteFormData } from './Forms/Ronda/ColetaAmostrasCavaloteForm/useCreateColetaAmostrasCavaloteForm';
import ColetaPressoesForm from './Forms/Ronda/ColetaPressoesForm/ColetaPressoesForm';
import { CreateColetaPressoesFormData } from './Forms/Ronda/ColetaPressoesForm/useCreateColetaPressoesForm';
import InstalacaoPlacaIdentificadoraForm from './Forms/Ronda/InstalacaoPlacaIdentificadoraForm/InstalacaoPlacaIdentificadoraForm';
import { CreateInstalacaoPlacaIdentificadoraFormData } from './Forms/Ronda/InstalacaoPlacaIdentificadoraForm/useCreateInstalacaoPlacaIdentificadoraForm';
import IsolamentoPositivoForm from './Forms/Ronda/IsolamentoPositivoForm/IsolamentoPositivoForm';
import { CreateIsolamentoPositivoFormData } from './Forms/Ronda/IsolamentoPositivoForm/useCreateIsolamentoPositivoForm';
import OtimizacaoBombasForm from './Forms/Ronda/OtimizacaoBombas/OtimizacaoBombasForm';
import { CreateOtimizacaoBombasFormData } from './Forms/Ronda/OtimizacaoBombas/useCreateOtimizacaoBombasForm';
import TesteEstanqueidadeForm from './Forms/Ronda/TesteEstanqueidadeForm/TesteEstanqueidadeForm';
import { CreateTesteEstanqueidadeFormData } from './Forms/Ronda/TesteEstanqueidadeForm/useCreateTesteEstanqueidadeForm';
import SonologForm from './Forms/Sonolog/SonologForm';
import { CreateSonologFormData } from './Forms/Sonolog/useCreateSonologForm';
import getLayersFormType from './getLayersFormType';
import { headerOptions } from './headerOptions';
import Input from './Input/Input';
import useCreateMainFormHeader from './useCreateMainFormHeader';
import useFormsPage from './useFormsPage';

export interface FormRef<T = any> {
  validate: () => Promise<boolean>;
  formValues: T;
}

export const FormsPage = () => {
  const { anyDataNeeded } = useFormsPage();

  const { methods, schema, useLoadSavedData } = useCreateMainFormHeader();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    trigger,
    setValue
  } = methods;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { infoFormType } = getLayersFormType(
    watch('activity')?.value,
    watch('operation')?.map(operation => operation.value) ?? [],
    watch('solicitation_type')?.value as 'change_of_frequency' | 'single_date'
  );

  const generalInformationRef =
    useRef<FormRef<CreateGeneralInformationFormData>>(null);
  const frequencyRef =
    useRef<FormRef<CreateFrequencyInformationFormData>>(null);
  const singleDateRef = useRef<FormRef<CreateSingleDateFormData>>(null);
  const coletaCavaloteRef =
    useRef<FormRef<CreateColetaAmostrasCavaloteFormData>>(null);
  const coletaPressoesRef = useRef<FormRef<CreateColetaPressoesFormData>>(null);
  const alteracaoBeanRef = useRef<FormRef<CreateAlteracaoBeanFormData>>(null);
  const isolamentoPositivoRef =
    useRef<FormRef<CreateIsolamentoPositivoFormData>>(null);
  const testeEstanqueidadeRef =
    useRef<FormRef<CreateTesteEstanqueidadeFormData>>(null);
  const instalacaoPlacaIdentificadoraRef =
    useRef<FormRef<CreateInstalacaoPlacaIdentificadoraFormData>>(null);
  const alteracaoCursoUBRef =
    useRef<FormRef<CreateAlteracaoCursoUBFormData>>(null);
  const otimizacaoBombasRef =
    useRef<FormRef<CreateOtimizacaoBombasFormData>>(null);
  const testeImplementacaoBastaoRef =
    useRef<FormRef<CreateTesteImplementacaoBastaoFormData>>(null);
  const mudancaCorBastaoRef =
    useRef<FormRef<CreateMudancaCorBastaoFormData>>(null);
  const mudancaFrequenciaBastaoRef =
    useRef<FormRef<CreateMudancaFrequenciaBastaoFormData>>(null);
  const sonologRef = useRef<FormRef<CreateSonologFormData>>(null);

  const handleValidateForms = async () => {
    const formsValidation: boolean[] = [];

    try {
      // ? Form validation
      await trigger().then(isValid => {
        formsValidation.push(isValid);
      });

      await generalInformationRef.current
        ?.validate()
        .then(isValid => formsValidation.push(isValid));

      if (infoFormType === 1) {
        await frequencyRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (infoFormType === 2) {
        await singleDateRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation =>
            operation.value === RondaOperation.COLETA_AMOSTRAS_CAVALOTE
        )
      ) {
        await coletaCavaloteRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation => operation.value === RondaOperation.COLETA_PRESSOES
        )
      ) {
        await coletaPressoesRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation =>
            operation.value === RondaOperation.ALTERACAO_ABERTURA_VALVULA_BEAN
        )
      ) {
        await alteracaoBeanRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation => operation.value === RondaOperation.ISOLAMENTO_POSITIVO
        )
      ) {
        await isolamentoPositivoRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation =>
            operation.value === RondaOperation.TESTE_ESTANQUEIDADE_LINHA
        )
      ) {
        await testeEstanqueidadeRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation =>
            operation.value === RondaOperation.INSTALACAO_PLACA_IDENTIFICADORA
        )
      ) {
        await instalacaoPlacaIdentificadoraRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation => operation.value === RondaOperation.ALTERACAO_CURSO_UB
        )
      ) {
        await alteracaoCursoUBRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation => operation.value === RondaOperation.OTIMIZACAO_BOMBAS
        )
      ) {
        await otimizacaoBombasRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation =>
            operation.value === BastoesOperation.TESTE_IMPLEMENTACAO_BASTOES
        )
      ) {
        await testeImplementacaoBastaoRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation => operation.value === BastoesOperation.MUDANCA_COR_BASTAO
        )
      ) {
        await mudancaCorBastaoRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation =>
            operation.value === BastoesOperation.MUDANCA_FREQUENCIA_BASTAO
        )
      ) {
        await mudancaFrequenciaBastaoRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }
      if (
        watch('operation')?.some(
          operation => operation.value === SonologOperation.SONOLOG
        )
      ) {
        await sonologRef.current
          ?.validate()
          .then(isValid => formsValidation.push(isValid));
      }

      if (formsValidation.every(isValid => isValid)) {
        alert('Todos os formulários são válidos!');
      } else {
        console.log('Algum formulário é inválido!');
      }
    } catch (error) {
      console.error('Erro na validação:', error);
    }
  };

  console.log(watch());

  return (
    <PageWrapper>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        className="flex flex-col p-5 h-fit max-h-[90vh] overflow-y-auto max-w-[90vw] lg:max-w-[70vw]"
      >
        <h2 className="font-bold text-xl text-left text-primary mb-4">
          Adição de nova atividade
        </h2>
        <div className="flex flex-col gap-8">
          <section className="flex flex-col gap-6 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <ControlledSelect
                label="Tipo de solicitação"
                options={[
                  {
                    label: 'Alteração de frequência temporária ou premissada',
                    value: 'change_of_frequency'
                  },
                  { label: 'Solicitação para data única', value: 'single_date' }
                ]}
                control={control}
                name="solicitation_type"
                required
                errorMessage={errors.solicitation_type?.message}
              />
              <div className="grid grid-cols-2 gap-4 mt-5">
                <Checkbox
                  {...register('is_multiple_operations')}
                  label="Solicitar mais de uma operação"
                  name="is_multiple_operations"
                  onChange={async () => {
                    setValue('operation', []);
                  }}
                />
                <Checkbox
                  {...register('is_committee_action')}
                  label="Ação de comitê"
                  name="is_committee_action"
                  tooltip="Texto a ser definido"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <ControlledSelect
                label="Atividade"
                options={headerOptions}
                control={control}
                name="activity"
                required
                errorMessage={errors.activity?.message}
              />

              <ControlledSelect
                label="Operação"
                options={
                  headerOptions
                    .find(option => option?.value === watch('activity')?.value)
                    ?.operations.map(operation => ({
                      label: operation.label,
                      value: operation?.value
                    })) ?? []
                }
                control={control}
                name="operation"
                required
                errorMessage={errors.operation?.message}
                isMulti
              />
            </div>
          </section>

          {/* General Form */}
          <GeneralInformationForm
            ref={generalInformationRef}
            isMandatory={true}
            isOilwellFieldMulti={
              watch('activity')?.value === Activity.RONDA ||
              watch('activity')?.value === Activity.BASTOES ||
              watch('activity')?.value === Activity.SONOLOG
            }
          />

          {/* Frequency Form */}
          {infoFormType === 1 ? (
            <FrequencyInformationForm ref={frequencyRef} isMandatory={true} />
          ) : infoFormType === 2 ? (
            <SingleDateForm ref={singleDateRef} isMandatory={true} />
          ) : null}

          {/* Operation Form */}
          {watch('operation')?.some(
            operation =>
              operation.value === RondaOperation.COLETA_AMOSTRAS_CAVALOTE
          ) && (
              <ColetaAmostrasCavaloteForm
                ref={coletaCavaloteRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation => operation.value === RondaOperation.COLETA_PRESSOES
          ) && (
              <ColetaPressoesForm ref={coletaPressoesRef} isMandatory={true} />
            )}

          {watch('operation')?.some(
            operation =>
              operation.value === RondaOperation.ALTERACAO_ABERTURA_VALVULA_BEAN
          ) && <AlteracaoBeanForm ref={alteracaoBeanRef} isMandatory={true} />}

          {watch('operation')?.some(
            operation => operation.value === RondaOperation.ISOLAMENTO_POSITIVO
          ) && (
              <IsolamentoPositivoForm
                ref={isolamentoPositivoRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation =>
              operation.value === RondaOperation.TESTE_ESTANQUEIDADE_LINHA
          ) && (
              <TesteEstanqueidadeForm
                ref={testeEstanqueidadeRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation =>
              operation.value === RondaOperation.INSTALACAO_PLACA_IDENTIFICADORA
          ) && (
              <InstalacaoPlacaIdentificadoraForm
                ref={instalacaoPlacaIdentificadoraRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation => operation.value === RondaOperation.ALTERACAO_CURSO_UB
          ) && (
              <AlteracaoCursoUBForm
                ref={alteracaoCursoUBRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation => operation.value === RondaOperation.OTIMIZACAO_BOMBAS
          ) && (
              <OtimizacaoBombasForm
                ref={otimizacaoBombasRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation =>
              operation.value === BastoesOperation.TESTE_IMPLEMENTACAO_BASTOES
          ) && (
              <TesteImplementacaoBastaoForm
                ref={testeImplementacaoBastaoRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation => operation.value === BastoesOperation.MUDANCA_COR_BASTAO
          ) && (
              <MudancaCorBastaoForm
                ref={mudancaCorBastaoRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation =>
              operation.value === BastoesOperation.MUDANCA_FREQUENCIA_BASTAO
          ) && (
              <MudancaFrequenciaBastaoForm
                ref={mudancaFrequenciaBastaoRef}
                isMandatory={true}
              />
            )}

          {watch('operation')?.some(
            operation => operation.value === SonologOperation.SONOLOG
          ) && <SonologForm ref={sonologRef} isMandatory={true} />}

          <Input
            label="Observações Gerais"
            {...register('general_observations')}
            variant="textarea"
            rows={3}
          />
        </div>

        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-md w-fix mt-10 ml-auto"
          onClick={handleValidateForms}
        >
          Enviar formulário
        </button>
      </Modal>

      <button
        className="bg-blue-500 text-white py-2 px-4 rounded-md w-fit ml-auto"
        onClick={() => setIsModalOpen(true)}
      >
        Nova Solicitação
      </button>
    </PageWrapper>
  );
};
