import {
  getAllOilwellsWithoutFilter,
  getAllZones,
  getConjugateLines,
  getConstants,
  getCromaFormula,
  getLocations,
  getOilwellField,
  getOilwellSatellites,
  getOilwellStatus,
  getTags
} from '../../services/oilwells.services';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

interface useMdmUtilsProps {
  status?: boolean;
  satellites?: boolean;
  fields?: boolean;
  chromaFormula?: boolean;
  constants?: boolean;
  tags?: boolean;
  location?: boolean;
  conjugateLine?: boolean;
  zones?: boolean;
  oilwells?: boolean;
  health?: boolean;
}

export const useMdmUtils = (props: useMdmUtilsProps) => {
  const { data: status } = useQuery(['status-adm'], getOilwellStatus, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7, // 7 minutos
    enabled: props.status || false,
    onError: () => {
      toast.error('Erro ao buscar status');
    }
  });

  const { data: satellites, isLoading: satellitesisLoading } = useQuery(
    ['satellite-adm'],
    getOilwellSatellites,
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7, // 7 minutos
      enabled: props.satellites || false,
      onError: () => {
        toast.error('Erro ao buscar satelites');
      }
    }
  );

  const { data: fields } = useQuery(['oilfields-adm'], getOilwellField, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7, // 7 minutos
    enabled: props.fields || false,
    onError: () => {
      toast.error('Erro ao buscar campos');
    }
  });

  const { data: chromaFormula } = useQuery(
    ['cromaFormula-adm'],
    getCromaFormula,
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7, // 7 minutos
      enabled: props.chromaFormula || false,
      onError: () => {
        toast.error('Erro ao buscar cromatografias');
      }
    }
  );

  const { data: constants } = useQuery(['constants-adm'], getConstants, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7, // 7 minutos
    enabled: props.constants || false,
    onError: () => {
      toast.error('Erro ao buscar constantes');
    }
  });

  const { data: tags, isLoading: tagsIsLoading } = useQuery(
    ['tags-adm'],
    () => getTags(0, 500),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7, // 7 minutos
      enabled: props.tags || false,
      onError: () => {
        toast.error('Erro ao buscar tags');
      }
    }
  );

  const { data: location } = useQuery(['location-adm'], () => getLocations(), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7, // 7 minutos
    enabled: props.location || false,
    onError: () => {
      toast.error('Erro ao buscar locação');
    }
  });

  const { data: conjugateLine } = useQuery(
    ['conjugate-line-adm'],
    () => getConjugateLines(),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7, // 7 minutos
      enabled: props.conjugateLine || false,
      onError: () => {
        toast.error('Erro ao buscar linha conjugada');
      }
    }
  );

  const { data: zones } = useQuery(['zones-adm'], () => getAllZones(), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7, // 7 minutos
    enabled: props.zones || false,
    onError: () => {
      toast.error('Erro ao buscar linha conjugada');
    }
  });

  const { data: oilwells } = useQuery(
    ['oilwell-adms'],
    () => getAllOilwellsWithoutFilter(),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7, // 7 minutos
      enabled: props.oilwells || false,
      onError: () => {
        toast.error('Erro ao buscar linha conjugada');
      }
    }
  );

  const data = {
    status: status || [],
    satellites: satellites || [],
    satellitesisLoading: satellitesisLoading,
    chromaFormula: chromaFormula || [],
    constants: constants || [],
    fields: fields || [],
    tags: tags || [],
    location: location || [],
    conjugateLine: conjugateLine || [],
    zones: zones || [],
    oilwells: oilwells || [],
    tagsIsLoading
  };

  return data;
};
