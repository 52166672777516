export enum RondaOperation {
  COLETA_AMOSTRAS_CAVALOTE = 'coleta_amostras_cavalote',
  MEDICAO_VAZAO_INJECAO = 'medicao_vazao_injecao',
  COLETA_PRESSOES = 'coleta_pressoes',
  COLETA_TEMPERATURA = 'coleta_temperatura',
  QUEBRA_VALVULA_BEAN = 'quebra_valvula_bean',
  ALTERACAO_ABERTURA_VALVULA_BEAN = 'alteracao_abertura_valvula_bean',
  CONFIRMAR_ABERTURA_VALVULA_BEAN = 'confirmar_abertura_valvula_bean',
  AVALIAR_INTEGRIDADE_VALVULA_BEAN = 'avaliar_integridade_valvula_bean',
  COLETA_PARAMETROS_BOMBA = 'coleta_parametros_bomba',
  COLETA_LOG_VSD = 'coleta_log_vsd',
  INSTALACAO_MANOMETRO = 'instalacao_manometro',
  TESTE_DHSV = 'teste_dhsv',
  TESTE_ANC = 'teste_anc',
  TESTE_SURGENCIA = 'teste_surgencia',
  ATUALIZACAO_PRONTUARIO = 'atualizacao_prontuario',
  ISOLAMENTO_POSITIVO = 'isolamento_positivo',
  TESTE_ESTANQUEIDADE_LINHA = 'teste_estanqueidade_linha',
  COLETA_DADOS_PDG = 'coleta_dados_pdg',
  MONITORAMENTO_DHSV = 'monitoramento_dhsv',
  INSTALACAO_PLACA_IDENTIFICADORA = 'instalacao_placa_identificadora',
  OTIMIZACAO_BOMBAS = 'otimizacao_bombas',
  ALINHAMENTO_BALANCEIO_UB = 'alinhamento_balanceio_ub',
  ALTERACAO_CURSO_UB = 'alteracao_curso_ub',
  PARAMETRIZACAO_VSD = 'parametrizacao_vsd'
}

export enum BastoesOperation {
  TESTE_IMPLEMENTACAO_BASTOES = 'teste_implementacao_bastoes',
  ENCERRAR_CURSO_BASTAO = 'encerrar_curso_bastao',
  MUDANCA_COR_BASTAO = 'mudanca_cor_bastao',
  MUDANCA_FREQUENCIA_BASTAO = 'mudanca_frequencia_bastao'
}

export enum SonologOperation {
  SONOLOG = 'sonolog'
}
