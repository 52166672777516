import { TextBold, Textlg } from '../atoms/Texts';
import { WhiteCard } from '../atoms/WhiteCard';

export const TextLongComponent = ({
  title,
  content
}: {
  title: string;
  content: string;
}) => {
  return (
    <WhiteCard>
      <div className="min-h-[100px] flex gap-2">
        <div className="w-1/4 p-2">
          <TextBold color="text-primary">{title}</TextBold>
        </div>
        <div className="w-3/4 p-2">
          <Textlg color="text-dark">{content}</Textlg>
        </div>
      </div>
    </WhiteCard>
  );
};
