export const STATUS_MAP: Record<string, string> = {
  '1': 'Produzindo',
  '2': 'Fechado',
  '3': 'Abandonado permanentemente',
  '4': 'Injetando',
  '5': 'Produzindo e injetando',
  '6': 'Abandonado temporariamente com monitoramento',
  '7': 'Abandonado temporariamente sem monitoramento',
  '8': 'Arrasado',
  '9': 'Retirando gás natural estocado',
  '10': 'Injetando para estocagem',
  '11': 'Operando para captação de água',
  '12': 'Operando para descarte',
  '13': 'Devolvido',
  '14': 'Equipado aguardando início de operação',
  '15': 'Em observação',
  '16': 'Em perfuração',
  '17': 'Em avaliação',
  '18': 'Em completação',
  '19': 'Em intervenção',
  '20': 'Cedido para captação de água'
} as const;
