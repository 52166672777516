import { ControlledSelect } from '@/pages/FormsPage/ControlledSelect/ControlledSelect';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateColetaAmostrasCavaloteForm, {
  CreateColetaAmostrasCavaloteFormData
} from './useCreateColetaAmostrasCavaloteForm';

interface ColetaAmostrasCavaloteFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const ColetaAmostrasCavaloteForm = forwardRef<
  FormRef<CreateColetaAmostrasCavaloteFormData>,
  ColetaAmostrasCavaloteFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { schema, methods, useLoadSavedData } =
    useCreateColetaAmostrasCavaloteForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    control,
    setValue
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('coleta amostras cavalote Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <ControlledSelect
          name="analysis_type"
          label="Tipo de Análise"
          control={control}
          options={[
            { label: 'Interna', value: 'internal' },
            { label: 'Externa', value: 'external' }
          ]}
          disabled={!isEditable}
          errorMessage={errors.analysis_type?.message}
          required
        />
        <ControlledSelect
          name="analysis"
          label="Análise"
          control={control}
          options={[
            { label: 'Apenas BSW', value: 'bsw' },
            { label: 'Grau API e densidade', value: 'api_and_density' },
            {
              label: 'Análise físico-química completa',
              value: 'complete_physical_chemical_analysis'
            }
          ]}
          disabled={!isEditable}
          errorMessage={errors.analysis?.message}
          required
        />
      </div>
    </FormWrapper>
  );
});

export default ColetaAmostrasCavaloteForm;
