import React, { forwardRef } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  required?: boolean;
  isError?: boolean;
  disabled?: boolean;
  className?: string; // Allow custom class overrides
  tooltip?: string;
}

const Checkbox: React.FC<CheckboxProps> = forwardRef<
  HTMLInputElement,
  CheckboxProps
>(
  (
    { label, required, isError, disabled, className, tooltip, ...rest },
    ref
  ) => {
    return (
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          className="scale-125"
          disabled={disabled}
          {...rest}
          ref={ref}
          id={rest.name}
        />
        <div className="flex items-center gap-1">
          {label && (
            <label
              htmlFor={rest.name}
              className={twMerge(`text-slate-700`, className)}
            >
              {label}
            </label>
          )}
          {tooltip && (
            <>
              <div data-tooltip-id={`tooltip-${rest.name}`}>
                <FaCircleInfo className="text-sm text-primary ml-1" />
              </div>
              <ReactTooltip
                id={`tooltip-${rest.name}`}
                place="top"
                variant="light"
                style={{
                  maxWidth: '300px',
                  textAlign: 'left',
                  backgroundColor: '#fffff',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.3)'
                }}
                content={tooltip}
                opacity={1}
              />
            </>
          )}
        </div>
        {required && <span className="text-red-500 ml-2">*</span>}
      </div>
    );
  }
);

export default Checkbox;
