import { useState } from 'react';

interface TooltipCellProps {
  value: string;
  className?: string;
}

const TooltipCell = ({ value, className = '' }: TooltipCellProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative">
      <span
        className={className}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {value}
      </span>
      {showTooltip && value && (
        <div className="absolute z-50 p-2 text-xs bg-[#f1f1f1] text-black rounded-lg whitespace-normal min-w-[200px] max-w-[300px] -top-8 left-1/2 transform -translate-x-1/2">
          {value}
        </div>
      )}
    </div>
  );
};

export default TooltipCell;
