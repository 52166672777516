import { DefaultCell } from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import { Column } from 'react-table';

const schematicOilWellSurfaceCols: Column[] = [
  {
    Header: () => (
      <Header
        secondary
        text={'Equipamentos'}
        id="schematicOilWellSurfaceEquipamentos"
        className={`pl-3 rounded-r-none rounded-b-none justify-start`}
      />
    ),
    accessor: 'equipamento',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Descrição'}
        id="schematicOilWellSurfaceDescricao"
        className={`pl-3 rounded-b-none rounded-l-none justify-start`}
      />
    ),
    accessor: 'descricao',
    Cell: DefaultCell,
    width: '50%'
  }
];

export default schematicOilWellSurfaceCols;
