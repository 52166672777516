import Button from '@/components/atoms/Button';
import DropdownButton from '@/components/atoms/DropdownButton';
import EmptyMessage from '@/components/atoms/EmptyMessage';
import SelectInputUncontrolled from '@/components/atoms/SelectInputUncontrolled';
import Spinner from '@/components/atoms/Spinner';
import { useParams } from '@/hooks/Common/useParams';
import {
  approveSchematicOilwell,
  fetchWellHandoverOilwell,
  getOilwellVersionsAsync,
  getSchematicOilwell,
  getWellHandoverOilwells,
  newVersionName,
  WellHandoverResponse,
  WellHandoverVersionList
} from '@/services/wellhandover.services';
import { useAccount } from '@azure/msal-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams as useUrlParams } from 'react-router-dom';
import Async from 'react-select/async';
import SchematicOilWell from './SchematicOilWell';
import ModalApprove from './surface/modalApprove';
import { Valves } from './Valves';

const SCHEMATIC_OILWELL_CACHE_KEY = 'schematic-oilwell';
const VERSIONS_OILWELL_CACHE_KEY = 'schematic-versions-oilwell';

const WellHandover = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useAccount();

  const { oilwell = '' } = useUrlParams();
  const { params } = useParams();
  const [activeTab, setActiveTab] = useState<Tab>('schematic');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasPermissionToApprove =
    user?.idTokenClaims?.roles?.includes('Portal.SuperAdmin') ||
    user?.idTokenClaims?.roles?.includes('Portal.ReviewersWellHandover') ||
    false;

  const { data: versions = {}, isLoading: isLoadingVersions } = useQuery(
    [VERSIONS_OILWELL_CACHE_KEY, oilwell],
    () => getOilwellVersionsAsync(oilwell, hasPermissionToApprove),
    {
      enabled: oilwell != '',
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60
    }
  );

  useEffect(() => {
    if (!schematic || (schematic === '' && Object.keys(versions).length > 0)) {
      const defaultVersion = getDefaultVersionName(
        versions,
        hasPermissionToApprove
      );
      if (defaultVersion) {
        navigate(`/home/well-handover/${oilwell}?schematic=${defaultVersion}`);
      }
    }
  }, [versions]);

  const schematic = params.get('schematic') ?? '';
  const versionId = versions[schematic]?.id;
  const isApproved = versions[schematic]?.approved ?? true;

  const onChangeOilwell = (selected: any) => {
    navigate(`/home/well-handover/${selected.value}`);
  };

  const onChangeVersion = (selected: any) => {
    navigate(`/home/well-handover/${oilwell}?schematic=${selected.label}`);
  };

  const { data, isLoading } = useQuery(
    [SCHEMATIC_OILWELL_CACHE_KEY, oilwell, versionId],
    () =>
      getSchematicOilwell(
        oilwell,
        versionId,
        isApproved,
        hasPermissionToApprove
      ),
    {
      enabled: oilwell != '',
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60
    }
  );

  const isLoadingAndEnabled = isLoading && oilwell != '' && isLoadingVersions;

  const handleApprove = async () => {
    await approveSchematicOilwell(versionId, hasPermissionToApprove);
    setIsModalOpen(false);
    queryClient.invalidateQueries([VERSIONS_OILWELL_CACHE_KEY, oilwell]);
    navigate(`/home/well-handover/${oilwell}`);
  };

  return (
    <>
      <div className="px-4 pb-1 pt-3 bg-white z-[11]">
        <h4 className="text-primary font-bold text-lg">Well Handover</h4>
      </div>

      <div className="flex w-full px-4 pt-2 pb-8 bg-white z-[11] justify-between">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
          <div className="flex flex-col gap-1 me-3 w-[10%] min-w-48">
            <h3 className="text-sm font-bold text-slate-600">Poço</h3>

            <Async
              onChange={onChangeOilwell}
              loadOptions={getWellHandoverOilwells}
              isMulti={false}
              value={{ label: oilwell, value: oilwell }}
              cacheOptions
              defaultOptions
              menuPosition="fixed"
            />
          </div>

          <div className="flex flex-col gap-1 min-w-24">
            <h3 className="text-sm font-bold text-slate-600">Versão</h3>
            <SelectInputUncontrolled
              key={`select-version-${oilwell}`}
              isMulti={false}
              placeholder="Selecione a versão"
              value={{ label: schematic, value: versionId }}
              options={Object.values(versions).map(v => ({
                value: v.id.toString(),
                label: v.title
              }))}
              onChange={onChangeVersion}
              className="h-full"
            />
          </div>
        </div>

        {hasPermissionToApprove && (
          <div className="flex flex-row justify-center items-center space-x-3 mt-5">
            <Button
              onClick={() => fetchWellHandoverOilwell(oilwell)}
              className="w-fit h-8 text-xs ml-auto bg-[#E4E8FB] font-bold text-primary hover:bg-primary hover:text-white"
              title="Procurar nova versão"
            />
            <Button
              title="Detalhes da aprovação"
              className="text-primary font-bold text-xs px-5 h-8 bg-white hover:bg-btn-light-2 transition-all duration-200 border border-primary rounded-full"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        )}
      </div>

      <span className="flex border-gray-200 border-[1px] border-[#DFDFDF]"></span>

      <div className="flex flex-row w-full px-4 py-1 bg-white flex-wrap">
        <DropdownButton
          buttonText="Esquemáticos"
          activeTab={activeTab}
          setActiveTab={tab => setActiveTab(tab as Tab)}
          links={[
            {
              label: 'Esquemático do poço',
              value: 'schematic'
            }
            // {
            //   label: 'Esquemático CSB',
            //   value: '#'
            // }
          ]}
          nameId="schematic-menu-option"
          menuClassName="shadow-light"
          buttonClassName={`
            w-fit 
            hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            text-primary
            text-sm
            font-bold
            px-5 py-2`}
          iconClassName={'text-primary'}
          linkClassName={`
            flex 
            items-center
            gap-6 py-2 px-3 mb-1
            hover:bg-btn-light rounded-md group 
            transition-all font-bold`}
        />

        {/* <DropdownButton
          buttonText="Dados Estruturais"
          activeTab={activeTab}
          setActiveTab={tab => setActiveTab(tab as Tab)}
          links={[
            // {
            //   label: 'Geologia e reservatório',
            //   value: `#`
            // },
            {
              label: 'Válvulas instaladas',
              value: 'valves'
            }
            // {
            //   label: 'Envelope seguro de alteração',
            //   value: '#'
            // }
          ]}
          nameId="schematic-menu-option"
          menuClassName="shadow-light"
          buttonClassName={`
            w-fit 
            hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            text-primary
            text-sm
            font-bold
            px-5 py-2`}
          iconClassName={'text-primary'}
          linkClassName={`
            flex 
            items-center
            gap-6 py-2 px-3 mb-1
            hover:bg-btn-light rounded-md group 
            transition-all font-bold`}
        /> */}

        {/* <Button
          title={'Avaliações Periódicas'}
          className={twMerge(
            `flex items-center justify-center gap-2
            rounded-3xl 
            bg-transparent hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            px-5 py-2`
          )}
          classNameSpan="text-primary text-sm font-bold"
        /> */}

        {/* <DropdownButton
            buttonText="CSB"
            links={[
              {
                label: 'Elementos CSBs',
                url: `#`
              },
              {
                label: 'Histórico de Verificação CSB',
                url: '#'
              },
              {
                label: 'Gerenciamento de CSB',
                url: '#'
              }
            ]}
            nameId="schematic-menu-option"
            buttonClassName={`
              w-fit 
              hover:bg-btn-light-2 hover:text-primary-dark transition-all 
              transition-all duration-200
              text-primary
              text-sm
              font-bold
              px-5 py-2`}
            iconClassName={'text-primary'}
            menuClassName="shadow-light"
            linkClassName={`
              flex 
              items-center
              gap-6 py-2 px-3 mb-1
              hover:bg-btn-light rounded-md group 
              transition-all font-bold`}
          /> */}

        {/* <Button
          title={'Avaliação global do poço'}
          className={twMerge(
            `flex items-center justify-center gap-2
            rounded-3xl 
            bg-transparent hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            px-5 py-2`
          )}
          classNameSpan="text-primary text-sm font-bold"
        /> */}
      </div>

      <section className="w-full h-[full] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full ">
        {isLoadingAndEnabled ? (
          <div className="flex justify-center items-center h-96">
            <Spinner size={32} />
          </div>
        ) : data && data !== null ? (
          <TabSelect
            activeTab={activeTab}
            selectedOilwell={oilwell}
            schematic={data}
          />
        ) : (
          <EmptyMessage
            message="Não há esquemático para este poço"
            className="h-96"
          />
        )}
      </section>

      <ModalApprove
        oilwellName={oilwell}
        versionId={versionId}
        approved={isApproved}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        handleApprove={handleApprove}
      />
    </>
  );
};

type Tab = 'schematic' | 'valves';

const TabSelect = ({
  activeTab,
  selectedOilwell,
  schematic
}: {
  activeTab: Tab;
  selectedOilwell: string;
  schematic: WellHandoverResponse;
}) => {
  return (
    <>
      {activeTab === 'schematic' ? (
        <SchematicOilWell
          oilwell={selectedOilwell as string}
          mockSchematic={schematic}
          schematicUniqueKey={schematic.updatedAt}
        />
      ) : activeTab === 'valves' ? (
        <Valves mockValvulas={schematic.jsonData.parte_superior} />
      ) : (
        <div>Não há esquemático para este poço</div>
      )}
    </>
  );
};

const getDefaultVersionName = (
  versions: WellHandoverVersionList,
  hasPermissionToApprove: boolean
) => {
  if (
    hasPermissionToApprove &&
    Object.keys(versions).includes(newVersionName)
  ) {
    return newVersionName;
  }

  return Object.values(versions)
    .filter(v => v.requestDate)
    .sort((a, b) => {
      const dateA = new Date(a.requestDate as string);
      const dateB = new Date(b.requestDate as string);
      return dateB.getTime() - dateA.getTime();
    })[0]?.title;
};

export default WellHandover;
