import { ControlledSelect } from '@/pages/FormsPage/ControlledSelect/ControlledSelect';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateColetaPressoesForm, {
  CreateColetaPressoesFormData
} from './useCreateColetaPressoesForm';

interface ColetaPressoesFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const ColetaPressoesForm = forwardRef<
  FormRef<CreateColetaPressoesFormData>,
  ColetaPressoesFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { schema, methods, useLoadSavedData } = useCreateColetaPressoesForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    control,
    setValue
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('coleta pressoes Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="flex gap-4">
        <ControlledSelect
          name="pressures_to_collect"
          label="Pressões a serem coletadas"
          control={control}
          options={[
            { label: 'Pressão de cabeça', value: 'head_pressure' },
            { label: 'Pressão de linha', value: 'line_pressure' },
            { label: 'Pressão do anular A', value: 'annular_a_pressure' },
            { label: 'Pressão do anular B', value: 'annular_b_pressure' },
            { label: 'Pressão do anular C', value: 'annular_c_pressure' }
          ]}
          disabled={!isEditable}
          errorMessage={errors.pressures_to_collect?.message}
          isMulti
          required
        />
      </div>
    </FormWrapper>
  );
});

export default ColetaPressoesForm;
