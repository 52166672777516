import { Text } from 'pixi.js';

export class TextItem {
  item: Text;
  height: number;
  margin: number;

  constructor(item: Text, height: number, margin: number) {
    this.item = item;
    this.height = height;
    this.margin = margin;
  }

  getTop(): number {
    return this.height - this.margin;
  }

  getBottom(): number {
    return this.height + this.item.height + this.margin;
  }
}

export function recalculateTextHeights(
  elements: TextItem[],
  space: number
): TextItem[] {
  const sortedElements = [...elements].sort((a, b) => b.height - a.height);

  removeCollisions(sortedElements, space);

  if (sortedElements.length > 0) {
    const lastElement = sortedElements[sortedElements.length - 1];
    const bottomValue = lastElement.getBottom();

    if (bottomValue <= 0) {
      moveDown(sortedElements, Math.abs(bottomValue) + space, space);
    }
  }

  return sortedElements;
}

function removeCollisions(elements: TextItem[], space: number): void {
  if (elements.length === 0) return;

  for (let i = 0; i < elements.length - 1; i++) {
    const newHeight = Math.min(
      elements[i + 1].height,
      elements[i].getTop() -
        elements[i + 1].item.height -
        elements[i + 1].margin -
        space
    );

    if (newHeight > 0) {
      elements[i + 1].height = newHeight;
    } else {
      elements[i + 1].height = elements[i + 1].margin;

      const elementsToMove = elements.slice(0, i + 1).reverse();
      moveDown(
        elementsToMove,
        elements[i + 1].item.height + space + 2 * elements[i + 1].margin,
        space
      );
    }
  }
}

function moveDown(elements: TextItem[], value: number, space: number): void {
  if (elements.length === 0) return;

  elements[0].height += value;

  for (let i = 0; i < elements.length - 1; i++) {
    if (elements[i].getBottom() + space > elements[i + 1].getTop()) {
      elements[i + 1].height += value;
    } else {
      break;
    }
  }
}
