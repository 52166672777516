import Inputmask from 'inputmask';
import { forwardRef, useEffect, useRef } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  required?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  tooltip?: string;
  variant?: 'input' | 'textarea';
  rows?: number;
  mask?: string;
}

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      required,
      errorMessage,
      isLoading,
      tooltip,
      variant = 'input',
      rows = 3,
      mask,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (variant === 'input' && mask && inputRef.current) {
        const maskInstance = Inputmask({
          mask,
          placeholder: mask.replace(/9/g, '_')
        }).mask(inputRef.current);

        return () => {
          maskInstance.remove();
        };
      }
    }, [mask, variant]);

    const sharedClassNames = twMerge(
      `w-full px-3 rounded-md border`,
      errorMessage ? 'border-[#FF4646]' : 'border-[#D8D8D8]',
      'focus:outline-none',
      errorMessage
        ? 'focus:border-[#FF4646] focus:ring-1 focus:ring-[#FF4646]'
        : 'focus:border-[#193CB9] focus:ring-1 focus:ring-[#193CB9]',
      'disabled:bg-[#f1f1f1] disabled:text-[#777777]',
      rest.className ?? ''
    );

    return (
      <div className="flex flex-col w-full gap-1">
        <div className="flex items-start gap-1">
          {label && (
            <label className="font-medium text-sm text-primary">
              {label}
              {required && <span className="text-red ml-1">*</span>}
            </label>
          )}
          {tooltip && (
            <>
              <div data-tooltip-id={`tooltip-${rest.name}`}>
                <FaCircleInfo className="text-sm text-primary ml-1 mt-1" />
              </div>
              <ReactTooltip
                id={`tooltip-${rest.name}`}
                place="top"
                variant="light"
                style={{
                  maxWidth: '300px',
                  textAlign: 'left',
                  backgroundColor: '#fffff',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.3)'
                }}
                content={tooltip}
                opacity={1}
              />
            </>
          )}
        </div>
        <div className="relative">
          {variant === 'input' ? (
            <input
              {...rest}
              ref={element => {
                inputRef.current = element;
                if (typeof ref === 'function') {
                  ref(element);
                } else if (ref) {
                  ref.current = element;
                }
              }}
              disabled={isLoading || rest.disabled}
              className={twMerge(sharedClassNames, 'h-[40px]')}
            />
          ) : (
            <textarea
              {...(rest as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
              ref={ref as React.RefObject<HTMLTextAreaElement>}
              rows={rows}
              disabled={isLoading || rest.disabled}
              className={twMerge(
                sharedClassNames,
                'min-h-[80px] py-2 resize-y'
              )}
              style={{
                resize: 'none'
              }}
            />
          )}
          {isLoading && (
            <div className="absolute right-3 top-1/2 -translate-y-1/2">
              <div className="animate-spin rounded-full h-4 w-4 border-2 border-gray-lighter border-t-transparent" />
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="mt-1 text-sm text-red">{errorMessage}</p>
        )}
      </div>
    );
  }
);

export default Input;
