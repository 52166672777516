import { useEffect, useState } from 'react';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import Button from '../atoms/Button';
import { Label } from '../atoms/Label';
import SelectInput from '../atoms/Select';

export type Filter = {
  key: string;
  name: string;
  values: {
    value: any;
    label: string;
  }[];
  asyncFn?: any;
  type?: string;
};

type OilwellCheckbox = {
  nome: string;
  checked: boolean;
};

interface OilwellFiltersCardProps {
  name: string;
  filters: Filter[];
  fetchOilwells: (data: FieldValues) => Promise<string[]>;
  onOilwellCheckboxChange: (checkedOilwells: string[]) => void;
}

const OilwellFiltersCard = ({
  filters,
  fetchOilwells,
  onOilwellCheckboxChange
}: OilwellFiltersCardProps) => {
  const methods = useForm();
  const { setValue, control } = methods;

  const [oilwellCheckbox, setOilwells] = useState<OilwellCheckbox[]>([]);

  const watchedOilwells = useWatch({
    control,
    name: 'oilwell',
    defaultValue: []
  });

  const watchedFilters = filters.reduce(
    (acc, filter) => {
      // Observa o valor atual do campo correspondente ao filtro
      const fieldValue = useWatch({
        control,
        name: filter.key, // Nome do campo no react-hook-form
        defaultValue: [] // Valor padrão (array vazio)
      });

      // Converte o valor para o formato esperado pelo SelectInput
      const value = fieldValue.map((item: string) => ({
        value: item,
        label: item
      }));

      acc[filter.key] = value;
      return acc;
    },
    {} as Record<string, { value: string; label: string }[]>
  );

  useEffect(() => {
    const updatedOilwells = oilwellCheckbox.map(oilwell => ({
      ...oilwell,
      checked: watchedOilwells.includes(oilwell.nome)
    }));
    setOilwells(updatedOilwells);
  }, [watchedOilwells]);

  useEffect(() => {
    if (watchedOilwells) {
      onOilwellCheckboxChange(watchedOilwells);
    }
  }, [watchedOilwells, onOilwellCheckboxChange]);

  const handleSetAllOilwells = (checked: boolean) => () => {
    const allOilwells = oilwellCheckbox.map(oilwell => oilwell.nome);

    setValue('oilwell', checked ? allOilwells : []);
    setOilwells(
      allOilwells.map(oilwell => ({ nome: oilwell, checked: checked }))
    );
  };

  const handleResetFilters = () => {
    filters.forEach(filter => {
      // Reset value of the field
      setValue(filter.key, []);
    });

    handleSubmit();
  };

  const handleSubmit = methods.handleSubmit(async data => {
    // Manter os poços que já estavam selecionados, mas não permitir duplicatas
    const oilwellsToKeep = watchedOilwells;
    const oilwells = await fetchOilwells(data);

    let newOilwellsOptions = oilwellsToKeep.concat(oilwells);

    // Unique
    newOilwellsOptions = Array.from(new Set(newOilwellsOptions));

    setOilwells(createOilwellCheckboxes(newOilwellsOptions));
  });

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-1 bg-white rounded-lg p-4 shadow">
        <Label
          id="Opções de Filtragem"
          className="text-primary"
          name="Opções de Filtragem"
          noBorder
        />

        <div className="grid grid-rows-1 grid-cols-6 items-center gap-x-2">
          {filters.map(filter => (
            <div key={filter.key}>
              <div className="pl-2 text-sm font-medium text-slate-800/50">
                {filter.name}
              </div>
              <SelectInput
                onChange={e =>
                  setValue(
                    filter.key,
                    e.map(item => item.value)
                  )
                }
                options={filter.values}
                value={watchedFilters[filter.key]}
                asyncFn={filter.asyncFn}
                secondary={true}
              />
            </div>
          ))}

          <div className="h-full flex flex-col items-end">
            <div className="flex w-full justify-center mt-2">
              <a
                className="underline text-primary hover:cursor-pointer"
                onClick={handleResetFilters}
              >
                Limpar filtros
              </a>
            </div>

            <Button
              title="Filtrar"
              onClick={handleSubmit}
              className="h-8 w-full"
            />
          </div>
        </div>

        <Label
          id="Opções de Poço"
          className="text-primary"
          name="Opções de Poço"
          noBorder
        />

        <div className="flex flex-col gap-2">
          <SelectInput
            onChange={e =>
              setValue(
                'oilwell',
                e.map(item => item.value)
              )
            }
            options={oilwellCheckbox.map(oilwell => ({
              value: oilwell.nome,
              label: oilwell.nome
            }))}
            value={watchedOilwells.map((oilwell: any) => ({
              value: oilwell,
              label: oilwell
            }))}
            secondary={true}
          />
          <div className="flex flex-row gap-2">
            <div className="text-sm font-medium text-slate-800">
              {oilwellCheckbox.length} poços identificados
            </div>
            <Button
              title="Selecionar Todos"
              onClick={handleSetAllOilwells(true)}
              className="text-sm font-medium h-6"
            />
            <button
              className="text-sm text-primary hover:text-gray-800 transition-colors"
              onClick={handleSetAllOilwells(false)}
            >
              Desmarcar Tudo
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const createOilwellCheckboxes = (oilwells: string[]): OilwellCheckbox[] => {
  if (!oilwells) return [];

  return oilwells.map(oilwell => ({
    nome: oilwell,
    checked: false
  }));
};

export default OilwellFiltersCard;
