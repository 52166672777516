import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateAlteracaoCursoUBForm, {
  CreateAlteracaoCursoUBFormData
} from './useCreateAlteracaoCursoUBForm';

interface AlteracaoCursoUBFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const AlteracaoCursoUBForm = forwardRef<
  FormRef<CreateAlteracaoCursoUBFormData>,
  AlteracaoCursoUBFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { methods } = useCreateAlteracaoCursoUBForm();

  const {
    register,
    formState: { errors },
    watch,
    trigger
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      console.log('alteracao curso ub Form is validating');
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('alteracao curso ub Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Input
          label="Curso atual (in)"
          {...register('current_course')}
          disabled={!isEditable}
          errorMessage={errors.current_course?.message}
          required
          type="number"
        />
        <Input
          label="Curso solicitado (in)"
          {...register('requested_course')}
          disabled={!isEditable}
          errorMessage={errors.requested_course?.message}
          required
          type="number"
        />
      </div>
    </FormWrapper>
  );
});

export default AlteracaoCursoUBForm;
