import { optionSchema } from '@/pages/FormsPage/optionSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z
  .object({
    is_multiple_operations: z.boolean(),
    is_committee_action: z.boolean(),
    solicitation_type: optionSchema(true),
    activity: optionSchema(true),
    operation: z.array(optionSchema(true)).min(1, 'Este campo é obrigatório'),
    general_observations: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.is_multiple_operations && data.operation?.length > 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['operation'],
        message:
          'Este campo não é múltiplo até que a opção "Solicitar mais de uma operação" seja marcada'
      });
    }
  });

export type CreateMainFormHeaderFormData = z.infer<typeof schema>;

const useCreateMainFormHeader = () => {
  const methods = useForm<CreateMainFormHeaderFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    if (data) {
      setValue('is_committee_action', data?.is_committee_action);
      setValue('is_multiple_operations', data?.is_multiple_operations);
      setValue('solicitation_type', data?.solicitation_type);
      setValue('activity', data?.activity);
      setValue('operation', data?.operation);
      setValue('general_observations', data?.general_observations);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateMainFormHeader;
