import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';
import TablePrintPDF from './TablePrintPDF';

interface HeaderPrintPDFProps {
  data: any;
  title: string;
  tailwindStyle: any;
}

export const HeaderPrintPDF: React.FC<HeaderPrintPDFProps> = props => {
  const commonHeaderStyle = `flex flex-1 p-2 text-[9px]`;

  const dataWithHeader = {
    header: [{
      poco: { value: "Poço", style: props.tailwindStyle(commonHeaderStyle) },
      campo: { value: "Campo", style: props.tailwindStyle(`${commonHeaderStyle} min-w-[50px]`) },
      let_down: { value: "Let Down", style: props.tailwindStyle(commonHeaderStyle) },
      dataAprovacao: { value: "Data de Aprovação", style: props.tailwindStyle(commonHeaderStyle) },
    }],
    body: props.data.map((equip: any) => ({
      poco: { value: equip.poco, style: props.tailwindStyle("flex flex-1 p-2 text-[9px] bg-[#EDF0FA]") },
      campo: { value: equip.campo, style: props.tailwindStyle("flex flex-1 p-2 text-[9px] bg-[#EDF0FA] min-w-[50px]") },
      let_down: { value: equip.let_down, style: props.tailwindStyle("flex flex-1 p-2 text-[9px] bg-[#EDF0FA]") },
      dataAprovacao: { value: equip.dataAprovacao, style: props.tailwindStyle("flex flex-1 p-2 text-[9px] bg-[#EDF0FA]") },
    }))
  }

  return (
    <>
      <View style={props.tailwindStyle("flex flex-row px-5 items-center justify-between print:bg-[#EDF0FA] max-h-[80px] rounded")}>
        <Text style={props.tailwindStyle("font-bold text-sm")}>
          {props.title}
        </Text>
        <Image style={props.tailwindStyle("w-[80px]")} src="/origem.png" />
      </View>

      <View style={props.tailwindStyle("border border-slate-300 rounded mt-2")}>
        <TablePrintPDF
          data={dataWithHeader}
          tailwindStyle={props.tailwindStyle}
        />
      </View>
    </>
  );
};

export default HeaderPrintPDF;
