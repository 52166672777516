import React from 'react';
import { ParteSuperior } from '../interface';
import { AnularesKeysTypes } from '../schematicUtils';
import { LineComponents } from './SchematicLineComponent';
import { SchematicOilWellCanvaValveElements } from './SchematicOilWellCanvaValveElements';
import { SchematicValvesConector } from './SchematicValvesConector';

export const SchematicOilWellAnularesElements: React.FC<any> = (props: {
  parteSuperior: ParteSuperior;
  anulares: any;
  topHeight: number;
  hoverTooltip: any;
  setShowTooltip: any;
  wellDiameter: number;
  oneRevestiment: number;
  distanceBetweenValvLayers: number;
  conectorHeightBig: number;
}) => {
  return (
    <>
      {props.anulares.map((letter: any, index: number) => (
        <React.Fragment key={`anulares-elements-${index}`}>
          <SchematicValvesConector
            size={props.wellDiameter + 2 * props.oneRevestiment * index}
            y={
              -props.topHeight +
              (props.conectorHeightBig +
                props.distanceBetweenValvLayers * index +
                1) -
              3
            }
            height={props.conectorHeightBig}
          />

          {props.parteSuperior.anulares[letter as AnularesKeysTypes].normal && (
            <SchematicOilWellCanvaValveElements
              centerDistance={
                props.wellDiameter / 2 + props.oneRevestiment * index
              }
              defaultY={
                -props.topHeight +
                (props.conectorHeightBig +
                  props.distanceBetweenValvLayers * index +
                  1) -
                7
              }
              elements={
                props.parteSuperior.anulares[letter as AnularesKeysTypes].normal
              }
              hoverTooltip={props.hoverTooltip}
              setShowTooltip={props.setShowTooltip}
              oposta={false}
              textHover={`Válvula de Acesso Anular '${letter}'`}
              hoverTooltipSize={200}
              hoverTextPosition={
                props.wellDiameter / 2 + props.oneRevestiment * index + 25
              }
            />
          )}

          {props.parteSuperior.anulares[letter as AnularesKeysTypes].oposta && (
            <SchematicOilWellCanvaValveElements
              centerDistance={
                props.wellDiameter / 2 + props.oneRevestiment * index
              }
              defaultY={
                -props.topHeight +
                (props.conectorHeightBig +
                  props.distanceBetweenValvLayers * index +
                  1) -
                7
              }
              elements={
                props.parteSuperior.anulares[letter as AnularesKeysTypes].oposta
              }
              hoverTooltip={props.hoverTooltip}
              setShowTooltip={props.setShowTooltip}
              oposta={true}
              textHover={`Válvula de Acesso Anular '${letter}' Oposta`}
              hoverTooltipSize={260}
              hoverTextPosition={
                props.wellDiameter / 2 + props.oneRevestiment * index + 10
              }
            />
          )}

          <LineComponents
            size={props.wellDiameter + 2 * props.oneRevestiment * index}
            y={
              -props.topHeight +
              (props.conectorHeightBig +
                props.distanceBetweenValvLayers * index +
                1)
            }
          />
        </React.Fragment>
      ))}
    </>
  );
};
