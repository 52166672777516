import useFiltersCardImage from '@/assets/icons/useFiltersCard.png';
import AlertTogglePanel from '@/components/atoms/AlertTogglePanel';
import Button from '@/components/atoms/Button';
import H1 from '@/components/atoms/H1';
import { Label } from '@/components/atoms/Label';
import OilwellFiltersCard, {
  Filter
} from '@/components/molecules/OilwellFiltersCard';
import {
  elevationMethodValues,
  oilwellFluidType,
  oilwellStation
} from '@/constants/filters/AlertFilters';
import {
  getSateliteAsync,
  getStatusAnpAsync
} from '@/constants/filters/OilWellsTable';
import useAlertConfig from '@/hooks/Alerts/useAlertConfig';
import { useQueryClient } from '@tanstack/react-query';
import { createContext, useState } from 'react';
import {
  AlertConfigChangeDTO,
  AlertConfigDTO,
  AlertSection,
  alertSectionLabelResource,
  AlertSectionValues,
  AlertVariablesDTO
} from '../types';
import { AlertTables } from './AlertTables';
import SubmitSummaryModal from './SubmitSummaryModal';
import { formatStringToNumber } from '@/utils/utils';
import { OilwellToElevationMethodMap } from '@/hooks/Alerts/OilwellToElevationMethodMap';

const CARD_FILTERS_NAME = 'card-filters-alert-variables';

export const ALERT_OILWELL_VARIABLES_QUERY_KEY = 'alertOilwellVariables';

const CARD_FILTER_SELECT_SCHEMA: Filter[] = [
  {
    key: 'fluid',
    name: 'Fluido',
    values: oilwellFluidType
  },
  {
    key: 'statusAnp',
    name: 'Status ANP',
    values: [],
    asyncFn: getStatusAnpAsync
  },
  {
    key: 'field',
    name: 'Campo',
    values: oilwellStation
  },
  {
    key: 'elevationMethod',
    name: 'Método de Elevação',
    values: elevationMethodValues
  },
  {
    key: 'satelite',
    name: 'Satélite',
    values: [],
    asyncFn: getSateliteAsync
  }
];

export const AlertVariablesContext = createContext<{
  defaultValues: AlertVariablesDTO;
  editedValues: AlertVariablesDTO;
  setEditedValues: (editedValues: AlertVariablesDTO) => void;
  setDefaultValues: (defaultValues: AlertVariablesDTO) => void;
}>({
  defaultValues: {},
  editedValues: {},
  setEditedValues: () => {},
  setDefaultValues: () => {}
});

const AlertVariablesTab = ({}: {}) => {
  const { fetchOilwells, submitAlertVariables, hasPermissionToEdit } =
    useAlertConfig();

  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [oilwells, setOilwells] = useState<string[]>([]);
  const [editedValues, setEditedValues] = useState<AlertVariablesDTO>({});
  const [defaultValues, setDefaultValues] = useState<AlertVariablesDTO>({});
  const [changes, setChanges] = useState<AlertConfigChangeDTO[]>([]);
  const clearChanges = () => {
    setEditedValues({});
    setChanges([]);
    oilwells.forEach(oilwell => {
      Object.values(AlertSectionValues).forEach(section => {
        queryClient.invalidateQueries([
          ALERT_OILWELL_VARIABLES_QUERY_KEY,
          oilwell,
          section
        ]);
      });
    });
  };

  const handleShowModal = () => {
    const diff = getAlertVariablesChanges(defaultValues, editedValues);
    setChanges(diff);
    setShowModal(true);
  };

  const handleSave = async () => {
    await submitAlertVariables(changes);
    setShowModal(false);
    clearChanges();
  };

  return (
    <AlertVariablesContext.Provider
      value={{
        defaultValues,
        editedValues,
        setEditedValues,
        setDefaultValues
      }}
    >
      <H1>Parametrização por Poços</H1>
      <div className="flex flex-col gap-2 p-4">
        <OilwellFiltersCard
          name={CARD_FILTERS_NAME}
          filters={CARD_FILTER_SELECT_SCHEMA}
          fetchOilwells={fetchOilwells}
          onOilwellCheckboxChange={setOilwells}
        />

        {oilwells.length > 0 ? (
          <OilwellsSection oilwells={oilwells} />
        ) : (
          <div className="flex flex-col gap-2 p-4">
            <img
              src={useFiltersCardImage}
              alt="Use filters card"
              className="mx-auto"
            />
          </div>
        )}
      </div>

      <div className="flex justify-end items-start gap-x-6">
        <button
          className="text-primary hover:text-gray-800 transition-colors"
          onClick={clearChanges}
        >
          Cancelar
        </button>
        <Button
          disabled={
            !hasPermissionToEdit || Object.keys(editedValues).length === 0
          }
          title="Salvar"
          className="w-32 h-6 text-sm"
          onClick={handleShowModal}
        />
      </div>

      {showModal && (
        <SubmitSummaryModal
          changes={changes}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={handleSave}
          alertTypeLabelResource={alertSectionLabelResource}
        />
      )}
    </AlertVariablesContext.Provider>
  );
};

const getAlertVariablesChanges = (
  defaultValues: AlertVariablesDTO,
  editedValues: AlertVariablesDTO
) => {
  const newChanges: AlertConfigChangeDTO[] = [];

  for (const oilwell in editedValues) {
    const sections = editedValues[oilwell];

    for (const section in sections) {
      const editedValuesByAlertType = groupByAlertType(
        editedValues[oilwell][section as AlertSection]
      );

      const defaultValuesByAlertType = groupByAlertType(
        defaultValues[oilwell][section as AlertSection]
      );
      for (const alertType in editedValuesByAlertType) {
        const editedAlertConfig = editedValuesByAlertType[alertType];
        const defaultAlertConfig = defaultValuesByAlertType[alertType];

        (['red', 'green'] as const).forEach(color => {
          // Cast to string to avoid comparing numbers with strings
          const new_value = formatStringToNumber(editedAlertConfig[color]);
          const old_value = formatStringToNumber(defaultAlertConfig[color]);

          if (new_value === old_value) {
            return;
          }

          const criticality = color === 'green' ? 3 : 2;

          newChanges.push({
            oilwell: oilwell,
            alert_type: alertType,
            criticality,
            old_value: old_value,
            new_value: new_value
          });
        });
      }
    }
  }
  return newChanges;
};

const groupByAlertType = (alertConfigs: AlertConfigDTO[]) => {
  return alertConfigs.reduce(
    (acc, alertConfig) => {
      acc[alertConfig.alert_type] = alertConfig;
      return acc;
    },
    {} as Record<string, AlertConfigDTO>
  );
};

const OilwellsSection = ({ oilwells }: { oilwells: string[] }) => {
  return (
    <>
      <Label
        id={1}
        className="text-primary border-b border-[#c1c1c1] pt-3"
        name="Alteração de Variáveis"
        noBorder
      />

      {oilwells.map(oilwell => (
        <div key={oilwell} className="flex flex-col gap-2">
          <Label
            id={oilwell}
            className="text-primary border-b border-[#c1c1c1] pt-3"
            name={oilwell}
            noBorder
          />
          {AlertSectionValues.map(section => {
            if (section === 'elevation') {
              const elev_method = OilwellToElevationMethodMap.get(oilwell);
              if (!['BCS', 'BM', 'BCP'].includes(elev_method)) {
                return null;
              }
            }

            return (
              <AlertTogglePanel
                key={`${oilwell}.${section}`}
                oilwell={oilwell}
                section={section}
                buttonClassName="py-4 bg-white rounded-lg hover:bg-white hover:ring-1 hover:ring-persian-blue-500"
                titleComponent={_ => (
                  <div className="flex items-center gap-2">
                    <span>{alertSectionLabelResource[section]}</span>
                  </div>
                )}
                conditionalChildren={isOpen => (
                  <AlertTables
                    oilwell={oilwell}
                    section={section}
                    enabled={isOpen}
                  />
                )}
              />
            );
          })}
        </div>
      ))}
    </>
  );
};

export default AlertVariablesTab;
