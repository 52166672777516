export default class ScaleUtils {
  readonly topoDoPoco: number;
  readonly scaleMultiplier: number;

  constructor(topoDoPoco: number, scaleMultiplier: number) {
    this.topoDoPoco = topoDoPoco;
    this.scaleMultiplier = scaleMultiplier;
  }

  yTranslationByScale = (y: number): number => {
    return this.topoDoPoco + (y - this.topoDoPoco) * this.scaleMultiplier;
  };

  rescaleHeight = (height: number): number => {
    return height * this.scaleMultiplier;
  };
}
