import { Issue, RedmineIssuesResponse } from '@/types/redmine';
import { grid2array } from '@/utils/customFields';

export namespace XML039Schema {
  export enum XML039CfIds {
    Data_do_Relatorio = 4103,
    Codigo = 4099,
    Campo = 7,
    Tipo_de_Notificacao = 4089,
    Instalacao = 4090,
    Codigo_da_Instalacao = 4100,
    Bacia = 4101,
    Estado = 4098,
    Tag_do_Ponto_de_Medicao = 4091,
    Tipo_de_Medicao = 4102,
    Equipamento = 4093,
    Tipo_de_Fluido = 4126,
    Data_de_Ocorrencia = 4104,
    Data_de_Retorno_a_Normalidade = 4106,
    Data_de_Deteccao = 4105,
    Responsavel_pela_Deteccao = 4095,
    Tipo_de_Falha = 4094,
    Descricao_do_Evento = 4109,
    Acoes_Tomadas = 4110,
    Metodologia_de_Estimativa = 4111,
    Elaborador = 4096,
    Aprovador = 4097,
    Numero_de_Serie = 4108,
    Previsao_de_retorno_a_normalidade = 4107,
    Notificacao_Relacionada = 4092,
    Grid_Correcao_de_Volume = 4124,
    Grid_Correcao_de_BSW = 4123,
    Grid_Correcao_de_Calibracao = 4125
  }

  export const getAllCfIds = (): number[] => {
    return Array.from(
      new Set([
        ...Object.values(XML039CfIds)
          .filter(value => typeof value === 'number')
          .map(it => Number(it))
      ])
    );
  };

  export const getCFValue = (
    issue: RedmineIssuesResponse,
    cf_id: XML039CfIds
  ) => {
    return (
      issue.issues?.[0]?.custom_fields
        ?.find(cf => cf.id === cf_id)
        ?.value?.toString() ?? ''
    );
  };

  export const getCFGrid = (
    issue: RedmineIssuesResponse,
    cf_id: XML039CfIds
  ) => {
    const gridValue = getCFValue(issue, cf_id);
    try {
      return grid2array(gridValue);
    } catch (error) {
      console.error(`Erro ao analisar o grid ${cf_id}:`, error);
      return {};
    }
  };

  export const getField = (
    issue: RedmineIssuesResponse,
    fieldName: keyof Issue
  ) => {
    return issue.issues?.[0]?.issue?.[fieldName];
  };
}
