import Button from '@/components/atoms/Button';
import EmptyMessage from '@/components/atoms/EmptyMessage';
import { PageWrapper } from '@/components/atoms/PageWrapper';
import { PrintStyle } from '@/components/atoms/PrintStyle';
import Spinner from '@/components/atoms/Spinner';
import PageNavigation from '@/components/molecules/PageNavigation';
import Table from '@/components/organisms/Table';
import schematicValvesCols from '@/constants/tableColumns/valves/schematicValvesCols';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaFileExport } from 'react-icons/fa6';
import { useReactToPrint } from 'react-to-print';
import { ParteSuperior } from './interface';

interface ValvesProps {
  mockValvulas: ParteSuperior;
}

const countNonNullValves = (valves: ParteSuperior): number => {
  let count = 0;

  Object.keys(valves.anulares).forEach(key => {
    const anular = valves.anulares[key as keyof typeof valves.anulares];
    ['normal', 'oposta'].forEach(position => {
      if (anular[position as keyof typeof anular]) {
        count++;
      }
    });
  });

  valves.valvula_mestra.forEach(valve => {
    ['longa', 'curta'].forEach(type => {
      if (valve[type as keyof typeof valve]) {
        count++;
      }
    });
  });

  valves.valvulas_pistoneio.forEach(valve => {
    ['longa', 'curta'].forEach(type => {
      if (valve[type as keyof typeof valve]) {
        count++;
      }
    });
  });

  valves.valvulas_laterais_producao.forEach(valve => {
    ['normal', 'oposta'].forEach(position => {
      if (valve[position as keyof typeof valve]) {
        count++;
      }
    });
  });

  return count;
};

export const Valves: React.FC<ValvesProps> = ({ mockValvulas }) => {
  const [isLoading] = useState(false);
  const componentRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [valves] = useState<any>({
    data: mockValvulas.valvula_mestra,
    total: countNonNullValves(mockValvulas)
  });

  const [headerData, setHeaderData] = useState<any>([
    {
      poco: '',
      campo: '',
      letDown: '',
      dataAprovacao: ''
    }
  ]);
  const perPage = 30;
  const methods = useForm();

  const onSubmit = async (data: any) => {
    console.log(data);
  };

  // Print
  const styles = `
    @media only print {
      @page { 
        size: A4 !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      .print-element {
        padding: 3rem !important;
      }

      .header-page-print {
        display: flex;
        flex-direction: column;
      }
  `;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const handlePDF = () => {
    handlePrint();
  };

  //TODO: change to real data
  const handleHeaderData = () => {
    setHeaderData([
      {
        poco: 'Poco de Teste',
        campo: 'Campo de Teste',
        let_down: 'Let Down de Teste',
        dataAprovacao: '14/02/2025'
      }
    ]);
  };

  useEffect(() => {
    handleHeaderData();
  }, []);
  // End Print

  return (
    <PageWrapper className="bg-background min-w-[375px]">
      <PrintStyle customStyle={styles} />
      <div className="w-full flex justify-between">
        <h1 className="text-primary font-bold text-xl mb-4">Válvulas</h1>

        <div className="flex space-x-4">
          <Button
            title="PDF"
            className={`
                                    h-8 w-20
                                    flex justify-center items-center
                                    bg-transparent text-primary 
                                    text-sm font-bold
                                    hover:text-white 
                                    border rounded-full
                                    disabled:cursor-not-allowed
                                    disabled:bg-gray-200`}
            onClick={handlePDF}
            icon={<FaFileExport size={16} />}
          />
        </div>
      </div>
      <div className="bg-white rounded-[10px] py-6 px-6 h-full mt-4">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div ref={componentRef} className="print-element">
              <h2 className="text-primary font-bold text-lg mb-4">
                Válvulas instaladas
              </h2>

              <div className="h-full max-h-[calc(100vh-320px)] pb-6 overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                {isLoading ? (
                  <Spinner size={36} />
                ) : !valves.data.length ? (
                  <EmptyMessage message="Nenhum dado foi encontrado" />
                ) : (
                  <Table
                    key="valves"
                    columns={schematicValvesCols}
                    headerClassName="sticky top-0 z-10 bg-white p-0 text-nowrap"
                    data={valves.data}
                  />
                )}
              </div>
            </div>
            {!!valves.data && (
              <PageNavigation
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                className="mt-3"
                totalPages={Math.ceil(valves.total / perPage)}
                perPage={valves.total > perPage ? perPage : valves.total}
              />
            )}

            <div className="w-full flex justify-end">
              <Button title="Salvar" type="submit" />
            </div>
          </form>
        </FormProvider>
      </div>
    </PageWrapper>
  );
};
