import { Stacked } from '../atoms/Composite';
import { Textlg } from '../atoms/Texts';

export const StackedText = ({
  label,
  value
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <Stacked>
      <Textlg color="text-gray2/70">{label}</Textlg>
      <Textlg color="text-dark">{value}</Textlg>
    </Stacked>
  );
};
