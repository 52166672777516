import { Stacked } from '../atoms/Composite';
import { TextBold, Textsm } from '../atoms/Texts';

export const StackedWhite = ({
  label,
  value
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <Stacked>
      <Textsm color="text-primary">{label}</Textsm>
      <TextBold color="text-primary">{value}</TextBold>
    </Stacked>
  );
};
