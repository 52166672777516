import { twMerge } from 'tailwind-merge';

export const Textsm = ({
  color,
  children
}: {
  color: string;
  children: React.ReactNode;
}) => {
  return <span className={twMerge('text-sm', color)}>{children}</span>;
};

export const TextBold = ({
  color,
  children
}: {
  color: string;
  children: React.ReactNode;
}) => {
  return (
    <span className={twMerge('text-lg font-bold', color)}>{children}</span>
  );
};

export const TextsmBold = ({
  color,
  children
}: {
  color: string;
  children: React.ReactNode;
}) => {
  return (
    <span className={twMerge('text-sm font-bold', color)}>{children}</span>
  );
};

export const Textlg = ({
  color,
  children
}: {
  color: string;
  children: React.ReactNode;
}) => {
  return <span className={twMerge('text-lg', color)}>{children}</span>;
};
