import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { optionSchema } from '../../../optionSchema';

const schema = z.object({
  analysis_type: optionSchema(true),
  analysis: optionSchema(true)
});

export type CreateColetaAmostrasCavaloteFormData = z.infer<typeof schema>;

const useCreateColetaAmostrasCavaloteForm = () => {
  const methods = useForm<CreateColetaAmostrasCavaloteFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    console.log('load saved data');
    if (data) {
      console.log('data received');
      setValue('analysis_type', data.analysis_type);
      setValue('analysis', data.analysis);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateColetaAmostrasCavaloteForm;
