import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  reason: z.string().min(1, { message: 'Este campo é obrigatório' })
});

export type CreateInstalacaoPlacaIdentificadoraFormData = z.infer<
  typeof schema
>;

const useCreateInstalacaoPlacaIdentificadoraForm = () => {
  const methods = useForm<CreateInstalacaoPlacaIdentificadoraFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    console.log('load saved data');
    if (data) {
      console.log('data received');
      setValue('reason', data?.reason);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateInstalacaoPlacaIdentificadoraForm;
