import { Sprite } from '@pixi/react';
import React from 'react';
import {
  DepthsColunasDeProducao,
  peixe,
  VirtualElement
} from '../schematicUtils';

const SchematicOilWellPeixes: React.FC<any> = (props: {
  subsurfaceEquipments: VirtualElement[];
  hasTwoColumns: boolean;
  wellDiameter: number;
  scaleMultiplier: number;
  topoDoPoco: number;
  maxDepthsColunaDeProducao: DepthsColunasDeProducao;
}) => {
  const xPositionLonga = props.hasTwoColumns
    ? props.wellDiameter / 3.5
    : props.wellDiameter / 7;
  const xPositionCurta = props.hasTwoColumns
    ? -props.wellDiameter / 8
    : props.wellDiameter / 7;

  const topLeftAnchor = { x: 1, y: 0 };

  const peixeInternoColunaDeProducaoLonga = props.subsurfaceEquipments.find(
    x =>
      x.tipo === 'peixe' &&
      (x.originalElement.coluna_de_producao.toLowerCase() === 'longa' ||
        x.originalElement.coluna_de_producao.toLowerCase() === 'única') &&
      x.topo <= props.maxDepthsColunaDeProducao.depthLonga
  );
  const peixeInternoColunaDeProducaoCurta = props.subsurfaceEquipments.find(
    x =>
      x.tipo === 'peixe' &&
      x.originalElement.coluna_de_producao === 'curta' &&
      x.topo <= props.maxDepthsColunaDeProducao.depthCurta
  );

  const peixeExternoColunaDeProducaoLonga = props.subsurfaceEquipments.find(
    x =>
      x.tipo === 'peixe' &&
      (x.originalElement.coluna_de_producao.toLowerCase() === 'longa' ||
        x.originalElement.coluna_de_producao.toLowerCase() === 'única') &&
      x.topo > props.maxDepthsColunaDeProducao.depthLonga
  );
  const peixeExternoColunaDeProducaoCurta = props.subsurfaceEquipments.find(
    x =>
      x.tipo === 'peixe' &&
      x.originalElement.coluna_de_producao === 'curta' &&
      x.topo > props.maxDepthsColunaDeProducao.depthCurta
  );

  const peixesList = [
    peixeInternoColunaDeProducaoLonga,
    peixeInternoColunaDeProducaoCurta,
    peixeExternoColunaDeProducaoLonga,
    peixeExternoColunaDeProducaoCurta
  ];

  return (
    <>
      {peixesList
        .filter(x => x)
        .map((line: any, index: number) => {
          return (
            <React.Fragment key={`peixe-${index}`}>
              <Sprite
                scale={1}
                anchor={topLeftAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLonga
                    : xPositionCurta
                }
                y={line.topo}
                image={peixe.image}
              />
            </React.Fragment>
          );
        })}
    </>
  );
};

export default SchematicOilWellPeixes;
