
const getLayersFormType = (activity: string, operations: string[], solicitation_type: 'change_of_frequency' | 'single_date') => {
  if (solicitation_type === 'change_of_frequency') {
    return { infoFormType: 1 };
  }
  if (solicitation_type === 'single_date') {
    return { infoFormType: 2 };
  }

  // Ronda
  // if (activity === Activity.RONDA) {
  //   if (operations.includes(RondaOperation.COLETA_AMOSTRAS_CAVALOTE)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.MEDICAO_VAZAO_INJECAO)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_PRESSOES)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_TEMPERATURA)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.QUEBRA_VALVULA_BEAN)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ALTERACAO_ABERTURA_VALVULA_BEAN)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.CONFIRMAR_ABERTURA_VALVULA_BEAN)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.AVALIAR_INTEGRIDADE_VALVULA_BEAN)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_PARAMETROS_BOMBA)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_LOG_VSD)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.INSTALACAO_MANOMETRO)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.TESTE_DHSV)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.TESTE_ANC)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.TESTE_SURGENCIA)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ATUALIZACAO_PRONTUARIO)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ISOLAMENTO_POSITIVO)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.TESTE_ESTANQUEIDADE_LINHA)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_DADOS_PDG)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.MONITORAMENTO_DHSV)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.INSTALACAO_PLACA_IDENTIFICADORA)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.OTIMIZACAO_BOMBAS)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ALINHAMENTO_BALANCEIO_UB)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ALTERACAO_CURSO_UB)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.PARAMETRIZACAO_VSD)) {
  //     return { infoFormType: 2 };
  //   }
  // }

  // Ronda
  // if (activity === Activity.RONDA) {
  //   if (operations.includes(RondaOperation.COLETA_AMOSTRAS_CAVALOTE)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.MEDICAO_VAZAO_INJECAO)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_PRESSOES)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_TEMPERATURA)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.QUEBRA_VALVULA_BEAN)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ALTERACAO_ABERTURA_VALVULA_BEAN)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.CONFIRMAR_ABERTURA_VALVULA_BEAN)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.AVALIAR_INTEGRIDADE_VALVULA_BEAN)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_PARAMETROS_BOMBA)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_LOG_VSD)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(RondaOperation.INSTALACAO_MANOMETRO)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.TESTE_DHSV)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.TESTE_ANC)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.TESTE_SURGENCIA)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ATUALIZACAO_PRONTUARIO)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ISOLAMENTO_POSITIVO)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.TESTE_ESTANQUEIDADE_LINHA)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.COLETA_DADOS_PDG)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.MONITORAMENTO_DHSV)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.INSTALACAO_PLACA_IDENTIFICADORA)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.OTIMIZACAO_BOMBAS)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ALINHAMENTO_BALANCEIO_UB)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.ALTERACAO_CURSO_UB)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(RondaOperation.PARAMETRIZACAO_VSD)) {
  //     return { infoFormType: 2 };
  //   }
  // }

  // Bastoes
  // if (activity === Activity.BASTOES) {
  //   if (operations.includes(BastoesOperation.TESTE_IMPLEMENTACAO_BASTOES)) {
  //     return { infoFormType: 1 };
  //   }
  //   if (operations.includes(BastoesOperation.ENCERRAR_CURSO_BASTAO)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(BastoesOperation.MUDANCA_COR_BASTAO)) {
  //     return { infoFormType: 2 };
  //   }
  //   if (operations.includes(BastoesOperation.MUDANCA_FREQUENCIA_BASTAO)) {
  //     return { infoFormType: 1 };
  //   }
  // }
  return { infoFormType: 0 };
};

export default getLayersFormType;
