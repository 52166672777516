import SquareButton from '@/components/atoms/SquareButton';
import { BiPencil } from 'react-icons/bi';
import { BsTrash3Fill } from 'react-icons/bs';

export function CreateActionsCell(
  handleNavigateToUpdateElement: (original: any) => void,
  handleDeleteElement: (original: any) => void
) {
  return ({ value, row: { original, index }, className }: any) => {
    return (
      <div className="flex gap-2">
        <SquareButton
          className="bg-red hover:bg-red/75"
          icon={<BsTrash3Fill color="white" size={16} />}
          onClick={() => handleDeleteElement(original)}
        />

        <SquareButton
          icon={<BiPencil color="white" size={16} />}
          onClick={() => handleNavigateToUpdateElement(original)}
        />
      </div>
    );
  };
}
