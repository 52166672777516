import { queryClient } from '@/App';
import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import SelectInput from '@/components/atoms/SelectInput';
import Spinner from '@/components/atoms/Spinner';
import MdmLayout from '@/components/templates/MdmLayout';
import { useMdmUtils } from '@/hooks/Common/MdmUtils';
import {
  createOrUpdateFacility,
  FacilityItem,
  getOneFacility
} from '@/services/apiMdm';
import { getAllOilFields } from '@/services/oilwells.services';
import { mapperOptions, mapperOptionsTextConstants } from '@/utils/mdm';
import { useQuery } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

type FacilityItemForm = {
  id: number;
  code: string;
  name: string;
  hub: string;
  basin: string;
  state: string;
  oilFieldIds: { value: number; label: string }[];
  active: string;
};

const CreateFacility = () => {
  const navigate = useNavigate();

  const handleSubmit = async (data: FacilityItemForm) => {
    try {
      const oilFieldIds = data.oilFieldIds.map((item: any) =>
        Number(item.value)
      );
      const active = data?.active?.toString() === 'true';
      await createOrUpdateFacility({ ...data, oilFieldIds, active });
      await queryClient.invalidateQueries({
        queryKey: ['facilities']
      });

      toast.success('Instalação criada com sucesso');
      navigate('/facilities');
    } catch (error) {
      console.error(error);
      toast.error('Erro ao criar instalação');
    }
  };

  return (
    <MdmLayout title="Nova Instalação">
      <FacilityForm onSubmit={handleSubmit} />
    </MdmLayout>
  );
};

export const UpdateFacility = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: facility, isLoading } = useQuery({
    queryKey: ['facility-update', id],
    queryFn: async () => {
      try {
        return await getOneFacility(Number(id));
      } catch (error) {
        console.error(error);
        toast.error('Erro ao carregar instalação');
        navigate('/facilities');
        throw error;
      }
    },
    enabled: !!id
  });

  const handleSubmit = async (data: FacilityItemForm) => {
    try {
      const oilFieldIds = data.oilFieldIds.map((item: any) =>
        Number(item.value)
      );
      const active = data?.active?.toString() === 'true';
      await createOrUpdateFacility({ ...data, oilFieldIds, active });
      await queryClient.invalidateQueries({
        queryKey: ['facility-update', id]
      });
      await queryClient.invalidateQueries({
        queryKey: ['facilities']
      });
      toast.success('Instalação atualizada com sucesso');
      navigate('/facilities');
    } catch (error) {
      console.error(error);
      toast.error('Erro ao atualizar instalação');
    }
  };

  return (
    <MdmLayout title="Editar Instalação">
      {isLoading ? (
        <Spinner />
      ) : (
        <FacilityForm onSubmit={handleSubmit} defaultValue={facility} />
      )}
    </MdmLayout>
  );
};

type FacilityFormProps = {
  onSubmit: SubmitHandler<FacilityItemForm>;
  defaultValue?: FacilityItem & { oilFields: any[] };
};

const FacilityForm: React.FC<FacilityFormProps> = ({
  onSubmit,
  defaultValue
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      id: defaultValue?.id ?? 0,
      code: defaultValue?.code ?? '',
      name: defaultValue?.name ?? '',
      hub: defaultValue?.hub ?? '',
      basin: defaultValue?.basin ?? '',
      state: defaultValue?.state ?? '',
      oilFieldIds:
        defaultValue?.oilFields?.map((item: any) => ({
          value: item.id,
          label: item.name
        })) ?? [],
      active: defaultValue?.active?.toString() ?? 'true'
    }
  });

  const { constants } = useMdmUtils({
    constants: true
  });

  const { data: oilfields = [] } = useQuery({
    queryKey: ['oilfields'],
    queryFn: async () => {
      const oilfields = await getAllOilFields();
      return oilfields?.map((it: any) => ({
        value: it.id,
        label: it.name
      }));
    }
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-2 items-center justify-center w-3/4"
    >
      <div className="space-y-4 w-52">
        <Input
          label="Código"
          required
          {...register('code', { required: true })}
        />

        <Input
          label="Nome"
          required
          {...register('name', { required: true })}
        />

        <SelectInput
          label="Polo"
          control={control}
          placeholder="Selecione o Polo"
          name="hub"
          required
          options={mapperOptions(constants?.OilFieldHub)}
        />

        <SelectInput
          label="Campos"
          control={control}
          placeholder="Selecione os campos"
          name="oilFieldIds"
          options={oilfields}
          isMulti={true}
          required
        />

        <SelectInput
          label="Bacia"
          control={control}
          placeholder="Selecione a bacia"
          name="basin"
          options={mapperOptionsTextConstants(constants?.Bacin)}
          required
        />

        <SelectInput
          label="Estado"
          control={control}
          placeholder="Selecione o estado"
          name="state"
          options={mapperOptions(constants?.BrazilianStates)}
          required
        />

        <SelectInput
          label="Status"
          placeholder="Status"
          control={control}
          name={'active'}
          options={[
            { value: 'true', label: 'Ativo' },
            { value: 'false', label: 'Inativo' }
          ]}
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Salvar"
          disabled={!isValid && !errors}
        />
      </div>
    </form>
  );
};

export default CreateFacility;
