import { Sprite } from '@pixi/react';
import linhaInternaAzulChart from '@/assets/schematic/linha-interna-azul.svg';

export const LineComponents: React.FC<{ size: number; y: number }> = ({
  size,
  y
}) => {
  const center = 0;
  const positionLeft = center + size / 2;
  const positionRight = center - size / 2;

  return (
    <>
      <Sprite
        scale={1}
        anchor={{ x: 1, y: 1 }}
        x={positionLeft}
        y={y}
        height={10}
        image={linhaInternaAzulChart}
      />
      <Sprite
        scale={1}
        anchor={{ x: 0, y: 1 }}
        x={positionRight}
        y={y}
        height={10}
        image={linhaInternaAzulChart}
      />
    </>
  );
};
