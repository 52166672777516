import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { twMerge } from 'tailwind-merge';

interface TableProps {
  columns: Column[];
  data: object[];
  setData?: (data: any) => void;
  action?: (id: any) => void;
  headerClassName?: string;
  bodyClassName?: string;
  editable?: boolean;
  hasBorder?: boolean;
  handleScroll?: (event: Event) => boolean;
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  setData,
  action,
  headerClassName,
  bodyClassName,
  editable,
  hasBorder,
  handleScroll
}) => {
  if (!data) {
    return <div>No data available</div>;
  }

  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<object>({
      columns: memoizedColumns,
      data: memoizedData
    });

  return (
    <table
      className="
        w-full 
        text-base font-normal 
        border-collapse border-spacing-0 
        rounded-b-none rounded-lg 
        table-auto
      "
      {...getTableProps()}
    >
      <thead className="">
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } =
            headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...restHeaderGroupProps} className="">
              {headerGroup.headers.map((column, index) => {
                const { key, ...restColumn } = column.getHeaderProps({
                  style: {
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth
                  }
                });

                return (
                  <th
                    className={twMerge(
                      `p-0 px-1 border-collapse group`,
                      headerClassName ?? '',
                      index === 0
                        ? 'rounded-tl-lg'
                        : index === headerGroup.headers.length - 1
                          ? 'rounded-tr-lg'
                          : '',
                      hasBorder ? 'py-0 pb-0.5 px-px' : ''
                    )}
                    key={key}
                    {...restColumn}
                  >
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <tr
              key={key}
              {...restRowProps}
              className={twMerge('group', hasBorder ? '' : 'even:bg-[#F1F3FA]')}
            >
              {row.cells.map(cell => {
                const { key, ...restCellProps } = cell.getCellProps({
                  style: {
                    minWidth: cell.column.minWidth,
                    width: cell.column.width,
                    maxWidth: cell.column.maxWidth
                  }
                });
                return (
                  <td
                    className={twMerge(
                      `h-10 p-0 px-4 
                      border-collapse 
                      left-0 
                      `,
                      bodyClassName,
                      hasBorder
                        ? 'border border-slate-300'
                        : `bg-white group-odd:bg-[#F1F3FA] 
                          [&:has(.summary)]:bg-persian-blue-100 
                          [&:has(.summary)]:group-odd:bg-persian-blue-100`
                    )}
                    key={key}
                    {...restCellProps}
                  >
                    {cell.render('Cell', {
                      action: action ?? {},
                      editable,
                      handleScroll,
                      data,
                      setData
                    })}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
