import { Sprite } from '@pixi/react';
import React from 'react';
import {
  blankingPlug,
  bpv,
  dhsv,
  mandrilComVgl,
  mandrilSemVgl,
  nipple,
  slidingSleeveAberta,
  slidingSleeveFechada,
  standingValve,
  tampaoDb,
  VirtualElement
} from '../schematicUtils';

const SchematicOilWellSubsurfaceEquipment: React.FC<any> = (props: {
  subsurfaceEquipments: VirtualElement[];
  hasTwoColumns: boolean;
  quartePacker: number;
  topoDoPoco: number;
  hoverTooltip: any;
  setShowTooltip: any;
}) => {
  const xPositionLongaMandril = props.hasTwoColumns
    ? 9.5 * props.quartePacker
    : 4.5 * props.quartePacker;
  const xPositionCurtaMandril = props.hasTwoColumns
    ? -0.5 * props.quartePacker
    : -4.5 * props.quartePacker;

  const xPositionLongaDhsv = props.hasTwoColumns ? 5 * props.quartePacker : 0;
  const xPositionCurtaDhsv = props.hasTwoColumns ? -2 * props.quartePacker : 0;

  const xPositionLongaSlidingSleeve = props.hasTwoColumns
    ? 5 * props.quartePacker
    : 0;
  const xPositionCurtaSlidingSleeve = props.hasTwoColumns
    ? -5 * props.quartePacker
    : 0;

  const xPositionLongaStandingValve = props.hasTwoColumns
    ? 5 * props.quartePacker
    : 0;
  const xPositionCurtaStandingValve = props.hasTwoColumns
    ? -5 * props.quartePacker
    : 0;

  const xPositionLongaBpv = props.hasTwoColumns ? 5 * props.quartePacker : 0;
  const xPositionCurtaBpv = props.hasTwoColumns ? -5 * props.quartePacker : 0;

  const xPositionLongaBlankingPlug = props.hasTwoColumns
    ? 5 * props.quartePacker
    : 0;
  const xPositionCurtaBlankingPlug = props.hasTwoColumns
    ? -5 * props.quartePacker
    : 0;

  const xPositionLongaNipple = props.hasTwoColumns ? 5 * props.quartePacker : 0;

  const xPositionCurtaNipple = props.hasTwoColumns
    ? -5 * props.quartePacker
    : 0;

  const topMiddleAnchor = { x: 0.5, y: 0 };
  const topLeftAnchor = { x: 1, y: 0 };

  const subsurfaceEquipmentHoverText = (tipo: string, profundidade: number) => {
    return `${tipo} - ${profundidade} m`;
  };

  return (
    <>
      {props.subsurfaceEquipments.map((line: VirtualElement, index: number) => {
        return (
          <React.Fragment key={`subsurface-equipment-${index}`}>
            {line.tipo === 'mandril-sem-vgl' && (
              <>
                <Sprite
                  scale={{
                    x: 1,
                    y: 1
                  }}
                  anchor={topLeftAnchor}
                  x={
                    line.originalElement.coluna_de_producao.toLowerCase() ===
                      'longa' ||
                    line.originalElement.coluna_de_producao.toLowerCase() ===
                      'única'
                      ? xPositionLongaMandril
                      : xPositionCurtaMandril
                  }
                  y={line.topo}
                  image={mandrilSemVgl.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x:
                          (line.originalElement.coluna_de_producao.toLowerCase() ===
                            'longa' ||
                          line.originalElement.coluna_de_producao.toLowerCase() ===
                            'única'
                            ? xPositionLongaMandril
                            : xPositionCurtaMandril) - 5,
                        y: line.topo + 50
                      },
                      {
                        x:
                          line.originalElement.coluna_de_producao.toLowerCase() ===
                            'longa' ||
                          line.originalElement.coluna_de_producao.toLowerCase() ===
                            'única'
                            ? xPositionLongaMandril
                            : xPositionCurtaMandril,
                        y: line.topo + 50
                      },
                      subsurfaceEquipmentHoverText(
                        line.originalElement.tipo,
                        line.originalElement.profundidade
                      ),
                      { width: 250, height: 70 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
              </>
            )}

            {line.tipo === 'mandril-com-vgl' && (
              <>
                <Sprite
                  scale={{
                    x: 1,
                    y: 1
                  }}
                  anchor={topLeftAnchor}
                  x={
                    line.originalElement.coluna_de_producao.toLowerCase() ===
                      'longa' ||
                    line.originalElement.coluna_de_producao.toLowerCase() ===
                      'única'
                      ? xPositionLongaMandril
                      : xPositionCurtaMandril
                  }
                  y={line.topo}
                  image={mandrilComVgl.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x:
                          (line.originalElement.coluna_de_producao.toLowerCase() ===
                            'longa' ||
                          line.originalElement.coluna_de_producao.toLowerCase() ===
                            'única'
                            ? xPositionLongaMandril
                            : xPositionCurtaMandril) - 5,
                        y: line.topo + 50
                      },
                      {
                        x:
                          line.originalElement.coluna_de_producao.toLowerCase() ===
                            'longa' ||
                          line.originalElement.coluna_de_producao.toLowerCase() ===
                            'única'
                            ? xPositionLongaMandril
                            : xPositionCurtaMandril,
                        y: line.topo + 50
                      },
                      subsurfaceEquipmentHoverText(
                        line.originalElement.tipo,
                        line.originalElement.profundidade
                      ),
                      { width: 250, height: 70 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
              </>
            )}

            {line.tipo === 'dhsv' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLongaDhsv
                    : xPositionCurtaDhsv
                }
                y={line.topo}
                image={dhsv.image}
                eventMode={'static'}
                mouseover={() => {
                  props.hoverTooltip(
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaDhsv
                          : xPositionCurtaDhsv,
                      y: line.topo + 50
                    },
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaDhsv
                          : xPositionCurtaDhsv,
                      y: line.topo + 50
                    },
                    subsurfaceEquipmentHoverText(
                      line.originalElement.tipo,
                      line.originalElement.profundidade
                    ),
                    { width: 150, height: 70 }
                  );
                }}
                mouseout={() => props.setShowTooltip(false)}
              />
            )}

            {line.tipo === 'sliding-sleeve-aberta' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLongaSlidingSleeve
                    : xPositionCurtaSlidingSleeve
                }
                y={line.topo}
                image={slidingSleeveAberta.image}
                eventMode={'static'}
                mouseover={() => {
                  props.hoverTooltip(
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaSlidingSleeve
                          : xPositionCurtaSlidingSleeve,
                      y: line.topo + 55
                    },
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaSlidingSleeve
                          : xPositionCurtaSlidingSleeve,
                      y: line.topo + 55
                    },
                    subsurfaceEquipmentHoverText(
                      line.originalElement.tipo,
                      line.originalElement.profundidade
                    ),
                    { width: 250, height: 70 }
                  );
                }}
                mouseout={() => props.setShowTooltip(false)}
              />
            )}

            {line.tipo === 'sliding-sleeve-fechada' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLongaSlidingSleeve
                    : xPositionCurtaSlidingSleeve
                }
                y={line.topo}
                image={slidingSleeveFechada.image}
                eventMode={'static'}
                mouseover={() => {
                  props.hoverTooltip(
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaSlidingSleeve
                          : xPositionCurtaSlidingSleeve,
                      y: line.topo + 55
                    },
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaSlidingSleeve
                          : xPositionCurtaSlidingSleeve,
                      y: line.topo + 55
                    },
                    subsurfaceEquipmentHoverText(
                      line.originalElement.tipo,
                      line.originalElement.profundidade
                    ),
                    { width: 250, height: 70 }
                  );
                }}
                mouseout={() => props.setShowTooltip(false)}
              />
            )}

            {line.tipo.toLowerCase() === 'standing-valve' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLongaStandingValve
                    : xPositionCurtaStandingValve
                }
                y={line.topo}
                image={standingValve.image}
                eventMode={'static'}
                mouseover={() => {
                  props.hoverTooltip(
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaStandingValve
                          : xPositionCurtaStandingValve,
                      y: line.topo + 55
                    },
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaStandingValve
                          : xPositionCurtaStandingValve,
                      y: line.topo + 55
                    },
                    subsurfaceEquipmentHoverText(
                      line.originalElement.tipo,
                      line.originalElement.profundidade
                    ),
                    { width: 200, height: 70 }
                  );
                }}
                mouseout={() => props.setShowTooltip(false)}
              />
            )}

            {line.tipo === 'bpv' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLongaBpv
                    : xPositionCurtaBpv
                }
                y={line.topo}
                image={bpv.image}
                eventMode={'static'}
                mouseover={() => {
                  props.hoverTooltip(
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaBpv
                          : xPositionCurtaBpv,
                      y: line.topo + 55
                    },
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaBpv
                          : xPositionCurtaBpv,
                      y: line.topo + 55
                    },
                    subsurfaceEquipmentHoverText(
                      line.originalElement.tipo,
                      line.originalElement.profundidade
                    ),
                    { width: 160, height: 70 }
                  );
                }}
                mouseout={() => props.setShowTooltip(false)}
              />
            )}

            {line.tipo === 'tampao-db' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLongaBpv
                    : xPositionCurtaBpv
                }
                y={line.topo}
                image={tampaoDb.image}
                eventMode={'static'}
                mouseover={() => {
                  props.hoverTooltip(
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaBpv
                          : xPositionCurtaBpv,
                      y: line.topo + 55
                    },
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaBpv
                          : xPositionCurtaBpv,
                      y: line.topo + 55
                    },
                    subsurfaceEquipmentHoverText(
                      line.originalElement.tipo,
                      line.originalElement.profundidade
                    ),
                    { width: 160, height: 70 }
                  );
                }}
                mouseout={() => props.setShowTooltip(false)}
              />
            )}

            {line.tipo === 'blanking-plug' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLongaBlankingPlug
                    : xPositionCurtaBlankingPlug
                }
                y={line.topo}
                image={blankingPlug.image}
                eventMode={'static'}
                mouseover={() => {
                  props.hoverTooltip(
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaBpv
                          : xPositionCurtaBpv,
                      y: line.topo + 55
                    },
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaBpv
                          : xPositionCurtaBpv,
                      y: line.topo + 55
                    },
                    subsurfaceEquipmentHoverText(
                      line.originalElement.tipo,
                      line.originalElement.profundidade
                    ),
                    { width: 180, height: 70 }
                  );
                }}
                mouseout={() => props.setShowTooltip(false)}
              />
            )}

            {line.tipo === 'nipple' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLongaNipple
                    : xPositionCurtaNipple
                }
                y={line.topo}
                image={nipple.image}
                eventMode={'static'}
                mouseover={() => {
                  props.hoverTooltip(
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaNipple
                          : xPositionCurtaNipple,
                      y: line.topo + 55
                    },
                    {
                      x:
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'longa' ||
                        line.originalElement.coluna_de_producao.toLowerCase() ===
                          'única'
                          ? xPositionLongaNipple
                          : xPositionCurtaNipple,
                      y: line.topo + 55
                    },
                    subsurfaceEquipmentHoverText(
                      line.originalElement.tipo,
                      line.originalElement.profundidade
                    ),
                    { width: 150, height: 70 }
                  );
                }}
                mouseout={() => props.setShowTooltip(false)}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SchematicOilWellSubsurfaceEquipment;
