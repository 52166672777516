interface InfoItemProps {
  label: string;
  value: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => {
  return (
    <div className="flex flex-col">
      <span className="text-xs text-gray-lighter font-medium">{label}</span>
      <p className="text-xs text-gray-dark font-medium mt-2">{value}</p>
    </div>
  );
};

interface InfoGridProps {
  items: InfoItemProps[];
}

const InfoGrid: React.FC<InfoGridProps> = ({ items }) => {
  return (
    <div className="grid grid-cols-4 gap-4 py-2">
      {items.map((item, index) => (
        <InfoItem key={index} label={item.label} value={item.value} />
      ))}
    </div>
  );
};

export default InfoGrid;
