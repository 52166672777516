import { DefaultCell } from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import { Column } from 'react-table';

const schematicOilWellSubSurfaceCols: Column[] = [
  {
    Header: () => (
      <Header
        secondary
        text={'Qtd.'}
        id="schematicOilWellQtd"
        className={`pl-3 rounded-r-none rounded-b-none justify-start`}
      />
    ),
    accessor: 'quantidade',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Descricao'}
        id="schematicOilWellTipoEquipamento"
        className={`pl-3 rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'descricao',
    Cell: DefaultCell,
    width: '40%'
  },
  {
    Header: () => (
      <Header
        secondary
        text={'OD (in)'}
        id="schematicOilWellOd"
        className={`pl-3 rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'diametro_externo_txt',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'ID (in)'}
        id="schematicOilWellId"
        className={`pl-3 rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'diametro_interno',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Fabricante'}
        id="schematicOilWellFabricante"
        className={`pl-3 rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'fabricante',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Prof.(m)'}
        id="schematicOilWellProf"
        className={`pl-3 rounded-b-none rounded-l-none justify-start`}
      />
    ),
    accessor: 'profundidade',
    Cell: DefaultCell
  }
];

export default schematicOilWellSubSurfaceCols;
