import { ControlledSelect } from '@/pages/FormsPage/ControlledSelect/ControlledSelect';
import { forwardRef, useImperativeHandle } from 'react';
import { ControlledDatePicker } from '../../ControlledDatePicker/ControlledDatePicker';
import type { FormRef } from '../../index';
import FormWrapper from '../FormWrapper/FormWrapper';
import useCreateSonologForm, {
  CreateSonologFormData
} from './useCreateSonologForm';

interface SonologFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const SonologForm = forwardRef<
  FormRef<CreateSonologFormData>,
  SonologFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { schema, methods, useLoadSavedData } = useCreateSonologForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue,
    control
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('alteracao bean Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <ControlledSelect
          name="type"
          options={[
            { label: 'Nível', value: 'level' },
            {
              label: 'Nível e Carta dinâmica',
              value: 'level_and_dynamic_chart'
            },
            { label: 'NA', value: 'na' }
          ]}
          label="Tipo"
          control={control}
          required
          errorMessage={errors.type?.message}
        />
        <ControlledSelect
          name="level_type"
          options={[
            { label: 'Estático', value: 'static' },
            { label: 'Dinâmico', value: 'dynamic' },
            { label: 'NA', value: 'na' }
          ]}
          label="Tipo de nível"
          control={control}
          required
          errorMessage={errors.level_type?.message}
        />
        <ControlledSelect
          name="measurement_location"
          options={[
            { label: 'Anular', value: 'annular' },
            { label: 'Coluna', value: 'column' },
            { label: 'Anular e Coluna', value: 'annular_and_column' },
            { label: 'NA', value: 'na' }
          ]}
          label="Local de medição"
          control={control}
          required
          errorMessage={errors.measurement_location?.message}
        />
        <ControlledDatePicker
          name="anp_deadline"
          control={control}
          label="Prazo ANP"
          error={errors.anp_deadline?.message}
          disabled={!isEditable}
        />
      </div>
    </FormWrapper>
  );
});

export default SonologForm;
