import { ControlledSelect } from '@/pages/FormsPage/ControlledSelect/ControlledSelect';
import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { OpeningType } from '../AlteracaoBeanForm/OpeningType.enum';
import useCreateOtimizacaoBombasForm, {
  CreateOtimizacaoBombasFormData
} from './useCreateOtimizacaoBombasForm';

interface OtimizacaoBombasFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const OtimizacaoBombasForm = forwardRef<
  FormRef<CreateOtimizacaoBombasFormData>,
  OtimizacaoBombasFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { methods } = useCreateOtimizacaoBombasForm();

  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setValue,
    control
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('coleta pressoes Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  // TODO requisição para buscar os dados do poço
  const isLoading = false;
  useEffect(() => {
    setValue('current_frequency', 12);
  }, []);

  console.log(watch('expected_impact_on_gross_production'));
  console.log(errors);

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <Input
          label="Frequência atual"
          {...register('current_frequency')}
          disabled
          errorMessage={errors.current_frequency?.message}
          required
          isLoading={isLoading}
        />
        <Input
          label="Frequência solicitada"
          {...register('requested_frequency')}
          disabled={!isEditable}
          errorMessage={errors.requested_frequency?.message}
          required
          type="number"
        />
        <ControlledSelect
          name="unit_of_measure"
          label="Unidade de medida"
          control={control}
          options={[
            { label: 'RPM', value: 'RPM' },
            { label: 'Hz', value: 'Hz' }
          ]}
          disabled={!isEditable}
          errorMessage={errors.unit_of_measure?.message}
          required
        />

        <ControlledSelect
          name="is_impact_on_gross_production"
          label="Impacto resulta em perda na produção bruta esperada?"
          control={control}
          options={[
            { label: 'Sim', value: 'yes' },
            { label: 'Não', value: 'no' }
          ]}
          disabled={!isEditable}
          errorMessage={errors.is_impact_on_gross_production?.message}
          required
        />

        <Input
          label="Impacto na produção bruta esperado (m³/d)"
          {...register('expected_impact_on_gross_production')}
          disabled={!isEditable}
          errorMessage={errors.expected_impact_on_gross_production?.message}
          type="number"
          tooltip="Em caso de perda iniciar com menos (-) na frente"
          required={watch('is_impact_on_gross_production')?.value === 'yes'}
        />

        <ControlledSelect
          name="bastao_type_change"
          label="Mudança do tipo de bastão"
          control={control}
          options={[
            { label: 'Sim', value: 'yes' },
            { label: 'Não', value: 'no' },
            { label: 'NA', value: 'na' }
          ]}
          disabled={!isEditable}
          errorMessage={errors.bastao_type_change?.message}
          required
        />

        <ControlledSelect
          name="opening_type"
          options={[
            { label: 'Rampa', value: OpeningType.RAMPA },
            { label: 'Direto', value: OpeningType.DIRETO }
          ]}
          label="Tipo de abertura"
          control={control}
          required
          errorMessage={errors.opening_type?.message}
        />

        {watch('opening_type')?.value === OpeningType.RAMPA && (
          <Input
            label="Detalhamento da sequência operacional"
            tooltip="Se tipo de abertura for em rampa, detalhe a sequência"
            {...register('operational_sequence_details')}
            errorMessage={errors.operational_sequence_details?.message}
            disabled={!isEditable}
            required
            variant="textarea"
            rows={3}
          />
        )}
      </div>
    </FormWrapper>
  );
});

export default OtimizacaoBombasForm;
