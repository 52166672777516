import axios from 'axios';
import { getUserTokenToApiRequest } from './msal.services';

const api = axios.create({
  baseURL: import.meta.env.VITE_MASTERDATA_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

api.interceptors.request.use(async (config: any) => {
  return getUserTokenToApiRequest(config);
});

export type FacilityItem = {
  id: number;
  code: string;
  name: string;
  hub: string;
  basin: string;
  state: string;
  oilFieldIds: number[];
  active: boolean;
};

type FacilityResponse = {
  items: FacilityItem[];
  meta: {
    total: number;
    skip: number;
    take: number;
  };
};

export type EquipmentItem = {
  id: number;
  code: string;
  serialNumber: string;
  type: string;
  measurementTankId?: number;
  gasMeterId?: number;
  active?: boolean;
};

type EquipmentResponse = {
  items: (EquipmentItem & { measuringTank: any; gasMeter: any })[];
  meta: {
    total: number;
    skip: number;
    take: number;
  };
};

export const getAllFacilities = async (
  skip: number,
  take: number,
  search: string
) => {
  const response = await api.get<FacilityResponse>('/facility', {
    params: {
      search,
      skip,
      take
    }
  });
  return response.data;
};

export const getOneFacility = async (id: number) => {
  const response = await api.get<FacilityItem & { oilFields: any[] }>(
    `/facility/${id}`
  );

  if (response.data && response.data.oilFields) {
    response.data.oilFieldIds = response.data.oilFields.map(
      (oilField: any) => oilField.id
    );
  }
  return response.data;
};

export const createOrUpdateFacility = async (facility: FacilityItem) => {
  const response = await api.post('/facility', facility);
  return response.data;
};

export const deleteFacility = async (id: number) => {
  const response = await api.delete(`/facility/${id}`);
  return response.data;
};

export const getAllEquipments = async (
  skip: number,
  take: number,
  search?: string,
  name?: string,
  tags?: string[],
  active?: boolean
) => {
  const response = await api.get<EquipmentResponse>('/equipment', {
    params: {
      search,
      name,
      tags,
      active,
      skip,
      take
    }
  });

  response.data.items = response?.data?.items?.map(item => {
    return {
      ...item,
      measurementSystem: item.measuringTank
        ? {
            id: item.measuringTank.id,
            type: 'tank',
            name: item.measuringTank.name
          }
        : { id: item.gasMeter?.id, type: 'gasMeter', name: item.gasMeter?.name }
    };
  });

  return response.data;
};

export const getOneEquipment = async (id: number) => {
  const response = await api.get<
    EquipmentItem & { measuringTank: any; gasMeter: any }
  >(`/equipment/${id}`);
  return response.data;
};

export const createOrUpdateEquipment = async (equipment: EquipmentItem) => {
  const response = await api.post('/equipment', equipment);
  return response.data;
};

export const deleteEquipment = async (id: number) => {
  const response = await api.delete(`/equipment/${id}`);
  return response.data;
};

export default api;
