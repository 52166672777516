import { Stacked } from '../atoms/Composite';
import { TextBold, Textlg } from '../atoms/Texts';
import { WhiteCard } from '../atoms/WhiteCard';

export const FooterCard = ({
  title,
  content
}: {
  title: string;
  content: string;
}) => {
  return (
    <WhiteCard>
      <Stacked>
        <Textlg color="text-gray2/70">{title}</Textlg>
        <TextBold color="text-dark">{content}</TextBold>
      </Stacked>
    </WhiteCard>
  );
};
