import { Label } from '@/components/atoms/Label';
import Modal from '@/components/atoms/Modal';
import Spinner from '@/components/atoms/Spinner';
import CustomStyleCell from '@/components/atoms/table/CustomStyleCell';
import {
  DefaultCell,
  DefaultCellWithParser
} from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import { InputProps } from '@/components/atoms/table/InputMaskCell';
import SimpleTable from '@/components/molecules/SimpleTable';
import useAlertConfig from '@/hooks/Alerts/useAlertConfig';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import {
  HTMLInputTypeAttribute,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { FaInfoCircle, FaPen } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import {
  AlertConfigDTO,
  AlertSection,
  AlertVariablesDTO,
  AlertVisibilityTypeValues
} from '../types';
import {
  ALERT_OILWELL_VARIABLES_QUERY_KEY,
  AlertVariablesContext
} from './AlertVariablesTab';
import { OilwellToElevationMethodMap } from '@/hooks/Alerts/OilwellToElevationMethodMap';

export const AlertTables = ({
  oilwell,
  section,
  enabled
}: {
  oilwell: string;
  section: AlertSection;
  enabled: boolean;
}) => {
  const { fetchOilwellAlertVariables } = useAlertConfig();
  const { defaultValues, setDefaultValues } = useContext(AlertVariablesContext);

  let defaultValuesByOilwellAndSection: AlertConfigDTO[] | undefined =
    defaultValues[oilwell]?.[section];

  const ElevationMethodTypes = {
    BCS: [
      'voltage',
      'current',
      'frequency',
      'engine_temperature',
      'intake_pressure',
      'discharge_pressure'
    ],
    BM: ['engine_current', 'ub_cpm'],
    BCP: ['rod_rpm', 'engine_torque', 'rod_torque', 'current']
  };

  if (section === 'elevation') {
    const elevationMethod = OilwellToElevationMethodMap.get(oilwell);
    const elev_types =
      ElevationMethodTypes[
        elevationMethod as keyof typeof ElevationMethodTypes
      ] || [];

    defaultValuesByOilwellAndSection = defaultValuesByOilwellAndSection?.filter(
      variable => elev_types.includes(variable.alert_type)
    );
  }

  const { data: OilwellAlertVariables, isLoading } = useQuery({
    queryKey: [ALERT_OILWELL_VARIABLES_QUERY_KEY, oilwell, section],
    queryFn: () => fetchOilwellAlertVariables(oilwell, section),
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: enabled && !!oilwell && !!section
  });

  useEffect(() => {
    if (OilwellAlertVariables) {
      setDefaultValues({
        ...defaultValues,
        [oilwell]: {
          ...defaultValues[oilwell],
          [section]: OilwellAlertVariables
        }
      });
    }
  }, [OilwellAlertVariables]);

  const [editableCells, setEditableCells] = useState<Record<string, boolean>>(
    {}
  );

  const setCellEditable = (cellKey: string, isEditable: boolean) => {
    setEditableCells(prev => ({
      ...prev,
      [cellKey]: isEditable
    }));
  };

  const isCellEditable = (cellKey: string): boolean => {
    return editableCells[cellKey] || false;
  };

  return (
    <>
      {isLoading && enabled ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {defaultValuesByOilwellAndSection &&
            defaultValuesByOilwellAndSection?.length > 0 &&
            AlertVisibilityTypeValues.map(visibilityType => {
              const filteredData = defaultValuesByOilwellAndSection?.filter(
                variable => variable.visibility_type === visibilityType
              );
              return (
                filteredData.length > 0 && (
                  <SimpleTable
                    key={`${oilwell}.${section}.${visibilityType}`}
                    columns={getColumnsByVisibilityType(
                      visibilityType,
                      `${oilwell}.${section}.${visibilityType}`,
                      isCellEditable,
                      setCellEditable,
                      oilwell,
                      section,
                      '%'
                    )}
                    data={filteredData}
                  />
                )
              );
            })}
        </div>
      )}
    </>
  );
};

const getColumnsByVisibilityType = (
  visibility_type: string,
  saveKey: string,
  isCellEditable: (cellKey: string) => boolean,
  setCellEditable: (cellKey: string, isEditable: boolean) => void,
  oilwell: string,
  section: AlertSection,
  unit?: string
) => {
  switch (visibility_type) {
    case 'percentage_change_alert':
      return getThreeLevelsColumns(
        saveKey,
        isCellEditable,
        setCellEditable,
        oilwell,
        section,
        '%',
        false
      );
    case 'absolute_difference_alert':
      return getThreeLevelsColumns(
        saveKey,
        isCellEditable,
        setCellEditable,
        oilwell,
        section,
        '°C',
        true
      );
    case 'ratio_alert':
      return getTwoLevelsColumns(
        saveKey,
        isCellEditable,
        setCellEditable,
        oilwell,
        section
      );
    default:
      return [];
  }
};

const getThreeLevelsColumns = (
  name: string,
  isCellEditable: (cellKey: string) => boolean,
  setCellEditable: (cellKey: string, isEditable: boolean) => void,
  oilwell: string,
  section: AlertSection,
  unit: string,
  isAbosoluteValue: boolean
) => [
  {
    Header: (
      <div className="relative">
        <Header
          terciary
          text={
            isAbosoluteValue ? 'Variação absoluta' : 'Variação em percentual'
          }
          id="label"
        />
        {isAbosoluteValue && (
          <>
            <FaInfoCircle
              className="absolute left-28 top-2 text-primary w-3.5 h-3.5"
              data-tooltip-id={`variable-tooltip-label-${name}`}
              data-tooltip-target="tooltip-default"
            />
            <ReactTooltip
              id={`variable-tooltip-label-${name}`}
              place="top"
              variant="light"
              style={{ maxWidth: '250px', textAlign: 'center' }}
              content="Alertas em relação a variação absoluta entre o valor atual e o anterior"
            />
          </>
        )}
      </div>
    ),
    accessor: 'label',
    Cell: CustomStyleCell({
      appendClassName:
        'w-full py-1 px-2 bg-[#F5F7FF] text-primary text-xs items-center'
    }),
    minWidth: 120
  },
  {
    Header: (
      <Header terciary text="Verde" id="green" className="text-[#0C8A6C]" />
    ),
    accessor: 'green',
    Cell: EditableCellGreen(oilwell, section),
    minWidth: 120
  },
  {
    Header: (
      <Header terciary text="Vermelho" id="red" className=" text-[#A60000]" />
    ),
    accessor: 'red',
    Cell: EditableCellRed(oilwell, section),
    minWidth: 120
  },
  {
    Header: (
      <Header terciary text="Amarelo" id="yellow" className="text-[#AD953C]" />
    ),
    accessor: 'yellow',
    Cell: ({ row: { original, index } }: any) => {
      const { editedValues } = useContext(AlertVariablesContext);
      const isDirty = !!editedValues[`${name}.${index}.yellow`];
      const yellowValue =
        original.green && original.red
          ? `${original.green} < x ≤ ${original.red}`
          : '-';

      return CustomStyleCell({
        appendClassName: twMerge(
          'py-1 text-xs pl-2 rounded',
          isDirty ? 'bg-persian-blue-100' : ''
        )
      })({
        value: yellowValue
      });
    },
    minWidth: 120
  },
  {
    Header: <Header terciary text="Última Atualização" id="lastUpdate" />,
    accessor: 'last_update',
    Cell: DetailsCell,
    minWidth: 120
  }
];

const getTwoLevelsColumns = (
  name: string,
  isCellEditable: (cellKey: string) => boolean,
  setCellEditable: (cellKey: string, isEditable: boolean) => void,
  oilwell: string,
  section: AlertSection
) => [
  {
    Header: (
      <div className="relative">
        <Header terciary text="Variação absoluta" id="label" />
        <FaInfoCircle
          className="absolute left-28 top-2 text-primary w-3.5 h-3.5"
          data-tooltip-id={`variable-tooltip-label-${name}`}
          data-tooltip-target="tooltip-default"
        />
        <ReactTooltip
          id={`variable-tooltip-label-${name}`}
          place="top"
          variant="light"
          style={{ maxWidth: '250px', textAlign: 'center' }}
          content="Alertas em relação ao limite estabelecido. Valores acima do limite são verdes e abaixo são vermelhos"
        />
      </div>
    ),
    accessor: 'label',
    Cell: ({ value }: any) => {
      return CustomStyleCell({
        appendClassName:
          'w-full py-1 px-2 bg-[#F5F7FF] text-primary text-xs items-center flex gap-2'
      })({
        value: value
      });
    },
    minWidth: 120
  },
  {
    Header: <Header terciary text="Limite" id="limite" />,
    accessor: 'green',
    Cell: EditableCellGreen(oilwell, section),
    minWidth: 120
  },
  {
    Header: <Header terciary text="Última Atualização" id="lastUpdate" />,
    accessor: 'last_update',
    Cell: DetailsCell,
    minWidth: 120
  }
];

const EditableCellGreen =
  (oilwell: string, section: AlertSection) =>
  ({
    value,
    row: { index, original },
    column: { id }
  }: {
    value: any;
    row: { index: number; original: any };
    column: { id: string };
  }) => {
    const { editedValues, setEditedValues } = useContext(AlertVariablesContext);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isEditing, setIsEditing] = useState(false);

    const editedValuesByOilwellAndSection =
      editedValues[oilwell]?.[section as AlertSection];

    const editedAlertTypeIndex = editedValuesByOilwellAndSection?.findIndex(
      (item: AlertConfigDTO) => item.alert_type === original.alert_type
    );

    const editedAlertType =
      editedAlertTypeIndex !== undefined && editedAlertTypeIndex !== -1
        ? editedValuesByOilwellAndSection[editedAlertTypeIndex]
        : undefined;

    const isDirty = !!editedAlertType && editedAlertType?.green !== value;

    const displayValue = isDirty ? editedAlertType.green : value;

    const handleSave = (newValue: string) => {
      if (newValue?.toString() !== value?.toString()) {
        const newEditedValue: AlertConfigDTO = {
          alert_type: original.alert_type,
          label: original.label,
          visibility_type: original.visibility_type,
          red: !!editedAlertType ? editedAlertType.red : original.red, // Você vai querer mergear os valores de red e green com o valor atual
          green: Number(newValue),
          last_update: original.last_update
        };

        if (!!editedAlertType) {
          const updatedSection = editedValuesByOilwellAndSection?.map(
            (item: AlertConfigDTO, index: number) =>
              index === editedAlertTypeIndex ? newEditedValue : item
          );

          setEditedValues({
            ...editedValues,
            [oilwell]: {
              ...editedValues[oilwell],
              [section]: updatedSection
            }
          });
        } else {
          const prevSection = !editedValuesByOilwellAndSection
            ? []
            : editedValuesByOilwellAndSection;

          const newSection = [...prevSection, newEditedValue];

          setEditedValues({
            ...editedValues,
            [oilwell]: {
              ...editedValues[oilwell],
              [section]: newSection
            }
          });
        }
      }
      setIsEditing(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSave(inputRef.current?.value || '');
      } else if (e.key === 'Escape') {
        setIsEditing(false);
      }
    };

    return (
      <div
        className={twMerge(
          'py-1 text-xs pl-2 rounded flex items-center justify-between',
          isDirty ? 'bg-persian-blue-100' : ''
        )}
      >
        {isEditing ? (
          <input
            ref={inputRef}
            type="number"
            className="w-full bg-transparent outline-none"
            defaultValue={displayValue}
            onBlur={e => handleSave(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          <div className="flex items-center justify-between w-full">
            <span>{displayValue}</span>
            <button
              onClick={() => setIsEditing(true)}
              className="hover:text-gray-dark px-2"
            >
              <FaPen className="w-3 h-3" />
            </button>
          </div>
        )}
      </div>
    );
  };

const EditableCellRed =
  (oilwell: string, section: AlertSection) =>
  ({
    value,
    row: { index, original },
    column: { id }
  }: {
    value: any;
    row: { index: number; original: any };
    column: { id: string };
  }) => {
    const { editedValues, setEditedValues } = useContext(AlertVariablesContext);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isEditing, setIsEditing] = useState(false);

    const editedValuesByOilwellAndSection =
      editedValues[oilwell]?.[section as AlertSection];

    const editedAlertTypeIndex = editedValuesByOilwellAndSection?.findIndex(
      (item: AlertConfigDTO) => item.alert_type === original.alert_type
    );

    const editedAlertType =
      editedAlertTypeIndex !== undefined && editedAlertTypeIndex !== -1
        ? editedValuesByOilwellAndSection[editedAlertTypeIndex]
        : undefined;

    const isDirty = !!editedAlertType && editedAlertType?.red !== value;

    const displayValue = isDirty ? editedAlertType.red : value;

    const handleSave = (newValue: string) => {
      if (newValue?.toString() !== value?.toString()) {
        const newEditedValue: AlertConfigDTO = {
          alert_type: original.alert_type,
          label: original.label,
          visibility_type: original.visibility_type,
          red: Number(newValue), // Você vai querer mergear os valores de red e green com o valor atual
          green: !!editedAlertType ? editedAlertType.green : original.green,
          last_update: original.last_update
        };

        if (!!editedAlertType) {
          const updatedSection = editedValuesByOilwellAndSection?.map(
            (item: AlertConfigDTO, index: number) =>
              index === editedAlertTypeIndex ? newEditedValue : item
          );

          setEditedValues({
            ...editedValues,
            [oilwell]: {
              ...editedValues[oilwell],
              [section]: updatedSection
            }
          });
        } else {
          const prevSection = !editedValuesByOilwellAndSection
            ? []
            : editedValuesByOilwellAndSection;

          const newSection = [...prevSection, newEditedValue];

          setEditedValues({
            ...editedValues,
            [oilwell]: {
              ...editedValues[oilwell],
              [section]: newSection
            }
          });
        }

        setIsEditing(false);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSave(inputRef.current?.value || '');
      } else if (e.key === 'Escape') {
        setIsEditing(false);
      }
    };

    return (
      <div
        className={twMerge(
          'py-1 text-xs pl-2 rounded flex items-center justify-between',
          isDirty ? 'bg-persian-blue-100' : ''
        )}
      >
        {isEditing ? (
          <input
            ref={inputRef}
            type="number"
            className="w-full bg-transparent outline-none"
            defaultValue={displayValue}
            onBlur={e => handleSave(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          <div className="flex items-center justify-between w-full">
            <span>{displayValue}</span>
            <button
              onClick={() => setIsEditing(true)}
              className="hover:text-gray-dark px-2"
            >
              <FaPen className="w-3 h-3" />
            </button>
          </div>
        )}
      </div>
    );
  };

const DetailsCell = ({ value, row, classname }: any) => {
  const [showModal, setShowModal] = useState(false);

  const isEmpty = Object.keys(value || {}).length === 0;

  const details = value?.details || [];
  const new_value = details[0]?.new_value;
  const old_value = details[0]?.old_value;

  return !isEmpty ? (
    <div className="flex items-center gap-2">
      <span>
        {DefaultCellWithParser(x => format(x?.date?.toString(), 'dd/MM/yyyy'))({
          value,
          row,
          className: `pl-2 ${classname}`
        })}
      </span>

      <button
        onClick={() => setShowModal(true)}
        className="hover:text-gray-dark"
      >
        <FaInfoCircle className="text-primary" />
      </button>

      {showModal && (
        <Modal isOpen={showModal} setIsOpen={setShowModal}>
          <div className="p-6">
            <Label
              className="text-primary"
              name="Detalhes de edição"
              id="details"
              noBorder
            />
            <div className="border-b border-primary mb-4"></div>

            <div className="flex flex-wrap gap-2 min-w-[650px]">
              <div>
                <p className="flex items-center gap-2 before:content-['•'] before:mr-2 whitespace-nowrap text-sm basis-[calc(50%-0.25rem)]">
                  Executor da edição:{' '}
                  <span className="font-bold">{value?.user}</span>
                </p>
                <p className="flex items-center gap-2 before:content-['•'] before:mr-2 whitespace-nowrap text-sm basis-[calc(50%-0.25rem)]">
                  Data de edição:{' '}
                  <span className="font-bold">
                    {format(value?.date?.toString(), 'dd/MM/yyyy HH:mm')}
                  </span>
                </p>
              </div>

              <div>
                <p className="flex items-center gap-2 before:content-['•'] before:mr-2 whitespace-nowrap text-sm basis-[calc(50%-0.25rem)]">
                  Valor novo: <span className="font-bold">{new_value}</span>
                </p>

                <p className="flex items-center gap-2 before:content-['•'] before:mr-2 whitespace-nowrap text-sm basis-[calc(50%-0.25rem)]">
                  Valor antigo:{' '}
                  <span className="font-bold">
                    {old_value ? old_value : '-'}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  ) : null;
};
