import { Activity } from './Enums/Activity.enum';
import {
  BastoesOperation,
  RondaOperation,
  SonologOperation
} from './Enums/Operation.enum';

export const headerOptions = [
  {
    label: 'Ronda',
    value: Activity.RONDA,
    operations: [
      {
        label: 'Coleta de amostras no cavalote do poço',
        value: RondaOperation.COLETA_AMOSTRAS_CAVALOTE
      },
      {
        label: 'Medição de vazão de injeção',
        value: RondaOperation.MEDICAO_VAZAO_INJECAO
      },
      { label: 'Coleta de pressões', value: RondaOperation.COLETA_PRESSOES },
      {
        label: 'Coleta de temperatura',
        value: RondaOperation.COLETA_TEMPERATURA
      },
      {
        label: 'Quebra da válvula bean',
        value: RondaOperation.QUEBRA_VALVULA_BEAN
      },
      {
        label: 'Alteração de abertura da válvula bean',
        value: RondaOperation.ALTERACAO_ABERTURA_VALVULA_BEAN
      },
      {
        label: 'Confirmar abertura atual da válvula bean in loco',
        value: RondaOperation.CONFIRMAR_ABERTURA_VALVULA_BEAN
      },
      {
        label: 'Avaliar integridade da válvula bean',
        value: RondaOperation.AVALIAR_INTEGRIDADE_VALVULA_BEAN
      },
      {
        label: 'Coleta de parâmetros da bomba',
        value: RondaOperation.COLETA_PARAMETROS_BOMBA
      },
      {
        label: 'Coleta de log do VSD',
        value: RondaOperation.COLETA_LOG_VSD
      },
      {
        label: 'Instalação/troca de manômetro (PI)',
        value: RondaOperation.INSTALACAO_MANOMETRO
      },
      { label: 'Teste DHSV', value: RondaOperation.TESTE_DHSV },
      { label: 'Teste ANC', value: RondaOperation.TESTE_ANC },
      {
        label: 'Teste de surgência',
        value: RondaOperation.TESTE_SURGENCIA
      },
      {
        label: 'Atualização de prontuário',
        value: RondaOperation.ATUALIZACAO_PRONTUARIO
      },
      {
        label: 'Isolamento positivo',
        value: RondaOperation.ISOLAMENTO_POSITIVO
      },
      {
        label: 'Teste de estanqueidade de linha',
        value: RondaOperation.TESTE_ESTANQUEIDADE_LINHA
      },
      {
        label: 'Coleta de dados PDG - estocagem',
        value: RondaOperation.COLETA_DADOS_PDG
      },
      {
        label: 'Monitoramento DHSV',
        value: RondaOperation.MONITORAMENTO_DHSV
      },
      {
        label: 'Instalação de placa identificadora do poço',
        value: RondaOperation.INSTALACAO_PLACA_IDENTIFICADORA
      },
      {
        label: 'Otimização de bombas',
        value: RondaOperation.OTIMIZACAO_BOMBAS
      },
      {
        label: 'Alinhamento e balanceio da UB',
        value: RondaOperation.ALINHAMENTO_BALANCEIO_UB
      },
      {
        label: 'Alteração de curso da UB',
        value: RondaOperation.ALTERACAO_CURSO_UB
      },
      {
        label: 'Parametrização do VSD',
        value: RondaOperation.PARAMETRIZACAO_VSD
      }
    ]
  },
  {
    label: 'Bastões',
    value: Activity.BASTOES,
    operations: [
      {
        label: 'Teste de implementação de bastões',
        value: BastoesOperation.TESTE_IMPLEMENTACAO_BASTOES
      },
      {
        label: 'Encerrar rotina de bastões',
        value: BastoesOperation.ENCERRAR_CURSO_BASTAO
      },
      {
        label: 'Mudança da cor/tipo do bastão',
        value: BastoesOperation.MUDANCA_COR_BASTAO
      },
      {
        label: 'Mudança de frequência do bastão',
        value: BastoesOperation.MUDANCA_FREQUENCIA_BASTAO
      }
    ]
  },
  {
    label: 'Sonolog',
    value: Activity.SONOLOG,
    operations: [{ label: 'Sonolog', value: SonologOperation.SONOLOG }]
  }
] as const;
