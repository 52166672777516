import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const requiredError = 'Este campo é obrigatório';

const schema = z.object({
  current_course: z.coerce
    .number({
      required_error: requiredError,
      invalid_type_error: 'Deve ser um número'
    })
    .min(1, { message: 'O valor mínimo é 1' }),
  requested_course: z.coerce
    .number({
      required_error: requiredError,
      invalid_type_error: 'Deve ser um número'
    })
    .min(1, { message: 'O valor mínimo é 1' })
});

export type CreateAlteracaoCursoUBFormData = z.infer<typeof schema>;

const useCreateAlteracaoCursoUBForm = () => {
  const methods = useForm<CreateAlteracaoCursoUBFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    if (data) {
      setValue('current_course', data?.current_course);
      setValue('requested_course', data?.requested_course);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateAlteracaoCursoUBForm;
