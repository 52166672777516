import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { optionSchema } from '../../../optionSchema';

const schema = z.object({
  pressures_to_collect: z
    .array(optionSchema(true))
    .min(1, { message: 'Este campo é obrigatório' })
    .default([])
});

export type CreateColetaPressoesFormData = z.infer<typeof schema>;

const useCreateColetaPressoesForm = () => {
  const methods = useForm<CreateColetaPressoesFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    console.log('load saved data');
    if (data) {
      console.log('data received');
      setValue('pressures_to_collect', data?.pressures_to_collect);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateColetaPressoesForm;
