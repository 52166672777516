import { RectangleComponent } from '../../Geometries/Rectangle';
import { SchematicReferenceConstants } from '../../SchematicReferenceConstants';

export const ColunaDeProducaoComponent = ({
  depht,
  xPosition,
  schematicReferenceConstants
}: {
  depht: number;
  xPosition: number;
  schematicReferenceConstants: SchematicReferenceConstants;
}) => {
  return (
    <RectangleComponent
      width={6 * schematicReferenceConstants.QUARTEPACKER}
      height={depht}
      referencePosition={{
        x: xPosition,
        y: schematicReferenceConstants.TopoDoPoco
      }}
      color="#FFFFFF"
      lineColor="#00186D"
    />
  );
};
