import kerotestChart from '@/assets/schematic/kerotest.svg';
import valvulaHorizontalChart from '@/assets/schematic/valvula-horizontal.svg';
import { Sprite } from '@pixi/react';
import { ValveElements } from '../interface';
import { flange_or_dhsv_type_to_csv_name } from '../schematicUtils';

const DefaultValvSize = 40;
const DefaultKerotestSize = 37;
const middlePosition = 0;

export const SchematicOilWellCanvaValveElements = (props: {
  centerDistance: number;
  defaultY: number;
  elements: ValveElements | null;
  oposta: boolean;
  textHover: string;
  hoverTooltip?: any;
  hoverTooltipSize: number;
  hoverTextPosition: number;
  setShowTooltip?: any;
}) => {
  const valvulaPosition =
    middlePosition + props.centerDistance * (props.oposta ? -1 : 1);
  const kerotestPosition =
    valvulaPosition +
    DefaultValvSize *
      (props.elements?.has_valvula ? 1 : 0) *
      (props.oposta ? -1 : 1);
  const FlangeDhsvPosition =
    kerotestPosition +
    DefaultKerotestSize *
      (props.elements?.has_kerotest ? 1 : 0) *
      (props.oposta ? -1 : 1);

  return (
    <>
      {props.elements && props.elements.has_valvula && (
        <Sprite
          scale={{ x: 0.67 * (props.oposta ? -1 : 1), y: 0.67 }}
          anchor={{ x: 0, y: 1 }}
          x={valvulaPosition}
          y={props.defaultY}
          image={valvulaHorizontalChart}
          eventMode={'static'}
          mouseover={() => {
            props.hoverTooltip(
              {
                x: props.oposta ? valvulaPosition - 20 : valvulaPosition + 20,
                y: props.defaultY + 20
              },
              {
                x: props.hoverTextPosition * (props.oposta ? -1 : 1),
                y: props.defaultY + 20
              },
              props.textHover,
              { width: props.hoverTooltipSize, height: 80 }
            );
          }}
          mouseout={() => props.setShowTooltip(false)}
        />
      )}

      {props.elements && props.elements.has_kerotest && (
        <Sprite
          scale={{ x: 0.67 * (props.oposta ? -1 : 1), y: 0.68 }}
          anchor={{ x: 0, y: 1 }}
          x={kerotestPosition}
          y={props.defaultY - 10}
          image={kerotestChart}
        />
      )}

      {props.elements &&
        props.elements.has_flange_or_dhsv &&
        Object.keys(flange_or_dhsv_type_to_csv_name).includes(
          props.elements.flange_or_dhsv_type
        ) && (
          <Sprite
            scale={{ x: 0.67 * (props.oposta ? -1 : 1), y: 0.67 }}
            anchor={{ x: 0, y: 1 }}
            x={FlangeDhsvPosition}
            y={props.defaultY}
            image={
              flange_or_dhsv_type_to_csv_name[
                props.elements.flange_or_dhsv_type
              ]
            }
          />
        )}
    </>
  );
};
