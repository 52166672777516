import { ControlledSelect } from '@/pages/FormsPage/ControlledSelect/ControlledSelect';
import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { BastaoType } from './BastaoType.enum';
import useCreateMudancaCorBastaoForm, {
  CreateMudancaCorBastaoFormData
} from './useCreateMudancaCorBastaoForm';

interface MudancaCorBastaoFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const MudancaCorBastaoForm = forwardRef<
  FormRef<CreateMudancaCorBastaoFormData>,
  MudancaCorBastaoFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { schema, methods, useLoadSavedData } = useCreateMudancaCorBastaoForm();

  const {
    register,
    formState: { errors },
    watch,
    trigger,
    control,
    setValue
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('mudanca cor bastao Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  // TODO request to get the current type
  const isLoading = false;
  useEffect(() => {
    setValue('current_type', BastaoType.AZUL_CELESTE);
  }, []);

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Input
          label="Tipo de Bastão"
          {...register('current_type')}
          errorMessage={errors.current_type?.message}
          required
          disabled
          isLoading={isLoading}
        />

        <ControlledSelect
          label="Novo tipo de Bastão"
          name="suggested_type"
          control={control}
          options={Object.values(BastaoType)
            .filter(type => type !== watch('current_type'))
            .map(type => ({
              label: type,
              value: type
            }))}
          errorMessage={errors.suggested_type?.message}
          required
        />

        <Input
          label="Motivo"
          {...register('reason')}
          errorMessage={errors.reason?.message}
          required
        />
      </div>
    </FormWrapper>
  );
});

export default MudancaCorBastaoForm;
