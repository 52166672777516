import { ptBR } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface DatePickerProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  isLoading?: boolean;
}

export const ControlledDatePicker = <T extends FieldValues>({
  name,
  control,
  label,
  required = false,
  placeholder = 'DD/MM/AAAA',
  error,
  disabled,
  className,
  minDate,
  maxDate,
  isLoading = false
}: DatePickerProps<T>) => {
  return (
    <div className="flex flex-col gap-1 w-full relative">
      {label && (
        <label className="font-medium text-sm text-primary">
          {label}
          {required && <span className="text-red ml-1">*</span>}
        </label>
      )}
      <div className="relative">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, ...field } }) => {
            const dateValue = value ? new Date(value) : null;
            return (
              <DatePicker
                {...field}
                selected={dateValue}
                onChange={(date: Date | null) => {
                  onChange(date);
                }}
                disabled={disabled || isLoading}
                placeholderText={placeholder}
                className={`w-full h-[40px] px-3 rounded-md border ${
                  error ? 'border-red' : 'border-[#D8D8D8]'
                } focus:outline-none ${
                  error
                    ? 'focus:border-red focus:ring-1 focus:ring-red'
                    : 'focus:border-primary focus:ring-1 focus:ring-primary'
                } ${className}`}
                dateFormat="dd/MM/yyyy"
                locale={ptBR}
                minDate={minDate}
                maxDate={maxDate}
                showPopperArrow={false}
                autoComplete="off"
                calendarClassName="shadow-lg border-0"
                wrapperClassName="w-full"
                popperClassName="!z-[9999]"
                popperContainer={({ children }) => (
                  <div className="relative z-[9999]">{children}</div>
                )}
              />
            );
          }}
        />
      </div>
      {error && <p className="mt-1 text-sm text-red">{error}</p>}
    </div>
  );
};
