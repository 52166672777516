import { Text, TextStyle, TextStyleFontStyle } from 'pixi.js';

interface CustomPixiTextProps {
  text: string;
  fontFamily: string;
  fontStyle: TextStyleFontStyle;
  fontSize: number;
  textColor: number;
  wordWrapWidth: number;
}

const getStyle = (props: CustomPixiTextProps) => {
  return new TextStyle({
    fontFamily: props.fontFamily,
    fontStyle: props.fontStyle,
    fontSize: props.fontSize,
    fill: [props.textColor],
    wordWrap: true,
    wordWrapWidth: props.wordWrapWidth
  });
};

export const GetCustomPixiText = (props: CustomPixiTextProps) => {
  return new Text(props.text, getStyle(props));
};
