interface FormWrapperProps {
  children: React.ReactNode;
  title: string;
  className?: string;
}

function FormWrapper({ children, title, className }: FormWrapperProps) {
  return (
    <section>
      <h3 className="font-bold text-left text-md text-primary">{title}</h3>
      <div className="h-[1px] bg-primary mt-1.5 mb-4"></div>
      <div className={`${className}`}>{children}</div>
    </section>
  );
}

export default FormWrapper;
