import { getAllOilwells } from '../../../services/oilwells.services';
import Button from '../../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Table from '../../../components/organisms/Table';
import { HiOutlinePlusSm } from 'react-icons/hi';
import PageNavigation from '../../../components/molecules/PageNavigation';
import { useState } from 'react';
import Spinner from '../../../components/atoms/Spinner';
import oilwellsColumns from '../../../constants/tableColumns/mdm/oilwells';
import SearchInput from '../../../components/molecules/SearchInput';

const OilWells = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const perPage = 15;

  const handleGetOilwells = async () => {
    try {
      const response = await getAllOilwells(
        perPage * (currentPage - 1),
        perPage,
        search
      );
      return response;
    } catch (error) {
      toast.error('Erro ao buscar poços');
    }
  };

  const { data, isLoading, isFetching } = useQuery(
    ['oilwells-adm', currentPage, search],
    handleGetOilwells,
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7 // 7 minutos
    }
  );

  const handleNavigateToCreateOilWell = () => {
    navigate('/oilwells/create');
  };

  return (
    <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
      <Button
        title="Novo Poço"
        type="button"
        className="absolute right-14 -top-[56px]"
        icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
        onClick={handleNavigateToCreateOilWell}
      />

      <section className="flex justify-between items-center">
        <h1 className="text-gray font-bold text-lg flex">
          Poços
          {isLoading || (isFetching && <Spinner className="ml-2" size={16} />)}
        </h1>

        <SearchInput
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            setCurrentPage(1);
          }}
        />
      </section>
      {!data ? (
        <div className="flex flex-col items-center justify-center">
          <Spinner className="mt-4" size={32} />
        </div>
      ) : (
        <section className="flex flex-col gap-2">
          <div className="overflow-auto">
            <Table columns={oilwellsColumns} data={data.data || []} />
          </div>

          <PageNavigation
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            className="mt-3"
            totalPages={Math.ceil(data.total / perPage)}
            perPage={perPage}
          />
        </section>
      )}
    </div>
  );
};

export default OilWells;
