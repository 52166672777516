import { Container, Graphics, Sprite, Stage, Text } from '@pixi/react';
import { useCallback } from 'react';
import ScaleUtils from '../ScaleUtils';

type Rectangle = {
  width: number;
  height: number;
  referencePosition: { x: number; y: number };
  color: string;
  lineColor?: string;
};

export const RectangleComponent: React.FC<Rectangle> = ({
  width,
  height,
  referencePosition,
  color,
  lineColor
}) => {
  const widthPerTwo = width / 2;

  const vertex1 = {
    x: referencePosition.x - widthPerTwo,
    y: referencePosition.y
  };
  const vertex2 = {
    x: referencePosition.x + widthPerTwo,
    y: referencePosition.y
  };
  const vertex3 = {
    x: referencePosition.x + widthPerTwo,
    y: referencePosition.y + height
  };

  const draw = useCallback(
    (g: any) => {
      g.clear();
      g.beginFill(color);
      g.lineStyle(2, lineColor, 1);
      g.drawRect(
        vertex1.x,
        vertex1.y,
        vertex2.x - vertex1.x,
        vertex3.y - vertex1.y
      );
      g.endFill();
    },
    [width, height, referencePosition]
  );

  return <Graphics draw={draw} />;
};

export const RescaledRectangleComponent: React.FC<
  Rectangle & { scaleUtils: ScaleUtils }
> = ({ width, height, referencePosition, color, lineColor, scaleUtils }) => {
  const rescaledY = scaleUtils.yTranslationByScale(referencePosition.y);
  const rescaledHeight = scaleUtils.rescaleHeight(height);

  return (
    <RectangleComponent
      width={width}
      height={rescaledHeight}
      referencePosition={{ x: referencePosition.x, y: rescaledY }}
      color={color}
      lineColor={lineColor}
    />
  );
};
