import { twMerge } from 'tailwind-merge';

export const Stacked = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col justify-between gap-2">{children}</div>;
};

export type verticalAlignment = 'top' | 'center' | 'bottom';

export const Spread = ({
  alignVertical,
  children
}: {
  alignVertical?: verticalAlignment;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={twMerge(
        'flex justify-between gap-x-1',
        alignVertical ? getAlignVertical(alignVertical) : ''
      )}
    >
      {children}
    </div>
  );
};

export const Adjacent = ({
  alignVertical = 'center',
  children
}: {
  alignVertical?: verticalAlignment;
  children: React.ReactNode;
}) => {
  return (
    <div className={twMerge('flex gap-x-2', getAlignVertical(alignVertical))}>
      {children}
    </div>
  );
};

export const getAlignVertical = (alignVertical?: verticalAlignment) => {
  if (alignVertical === 'top') {
    return 'items-start';
  } else if (alignVertical === 'center') {
    return 'items-center';
  } else {
    return 'items-end';
  }
};
