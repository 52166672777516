import { optionSchema } from '@/pages/FormsPage/optionSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  line_to_be_tested: optionSchema(true),
  priority: optionSchema(true)
});

export type CreateTesteEstanqueidadeFormData = z.infer<typeof schema>;

const useCreateTesteEstanqueidadeForm = () => {
  const methods = useForm<CreateTesteEstanqueidadeFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    console.log('load saved data');
    if (data) {
      console.log('data received');
      setValue('line_to_be_tested', data?.line_to_be_tested);
      setValue('priority', data?.priority);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateTesteEstanqueidadeForm;
