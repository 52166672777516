import {
  ModificationReferences,
  SchematicInterface
} from '@/pages/WellHandover/interface';
import { ModificationGroup } from '@/pages/WellHandover/surface/modalApprove';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import api from './apiMdm';

export type WellHandoverResponse = {
  id: number;
  oilWellId: number;
  jsonData: SchematicInterface;
  createdAt: string;
  updatedAt: string;
  version: number;
  approved: boolean;
  approvedBy: string | null;
  approvedByName: string | null;
  approvedDate: string | null;
  oilWell: {
    oilField: {
      name: string;
    };
  } | null;
  safeEnvelope: {};
};

type WellHandoverVersionResponse = {
  id: number;
  approvedBy: string;
  approvedDate: string;
};

export type WellHandoverVersion = {
  id: number;
  approved: boolean;
  title: string;
  requestDate?: string;
};

export type WellHandoverVersionList = {
  [id: string]: WellHandoverVersion;
};

type CorrectionRequest = {
  id: number;
  wellSchematicId: number;
  requestDate: string;
  correction: string;
};

export const newVersionName = 'Nova Versão';

export const getWellHandoverOilwells = async (search?: string) => {
  const oilwells = await api.get(`well-schematic/oilwells`, {
    params: { search }
  });
  return oilwells?.data?.map((it: any) => ({
    value: it.name,
    label: it.name
  }));
};

export const fetchWellHandoverOilwell = async (oilwell: string) => {
  try {
    const { data } = await api.get(
      `well-schematic/request_well_update/${oilwell}`
    );
    toast.success('Esquemático do poço buscado com sucesso');
    return data;
  } catch (error) {
    toast.error('Erro ao buscar dados do poço');
    throw error;
  }
};

export const getOilwellVersionsAsync = async (
  oilwell: string,
  hasPermissionToApprove: boolean
) => {
  const response = await api.get<WellHandoverVersionResponse[]>(
    `well-schematic/list/${oilwell}`
  );

  const versions: WellHandoverVersionList = {};
  response.data.forEach(version => {
    const versionTitle = format(version.approvedDate, 'dd/MM/yyyy HH:mm');
    versions[versionTitle] = {
      id: version.id,
      approved: true,
      title: versionTitle,
      requestDate: version.approvedDate
    };
  });

  const forApproval = await getSchematicOilwellForApproval(
    oilwell,
    hasPermissionToApprove
  );
  if (!forApproval) {
    return versions;
  }

  versions[newVersionName] = {
    id: forApproval.id,
    approved: forApproval.approved,
    title: newVersionName
  };

  return versions;
};

export const getSchematicOilwell = async (
  oilwell: string,
  versionId: number,
  approved: boolean,
  hasPermissionToApprove: boolean
) => {
  if (versionId) {
    if (!approved) {
      return await getSchematicOilwellForApproval(
        oilwell,
        hasPermissionToApprove
      );
    }

    const schematicResponse = await api.get<WellHandoverResponse | null>(
      `well-schematic/show/${oilwell}/${versionId}`
    );

    return schematicResponse.data;
  }

  if (hasPermissionToApprove) {
    const forApproval = await getSchematicOilwellForApproval(
      oilwell,
      hasPermissionToApprove
    );
    if (forApproval) {
      return forApproval;
    }
  }

  const schematicResponse = await api.get<WellHandoverResponse | null>(
    `well-schematic/show/${oilwell}`
  );

  return schematicResponse.data;
};

export const getSchematicOilwellForApproval = async (
  oilwell: string,
  hasPermissionToApprove: boolean
) => {
  try {
    if (!hasPermissionToApprove) {
      return null;
    }

    return (
      await api.get<WellHandoverResponse>(
        `well-schematic/for_approval/${oilwell}`
      )
    ).data;
  } catch (error) {
    toast.error('Erro ao buscar esquemático para aprovação');
    return null;
  }
};

export const approveSchematicOilwell = async (
  versionId: number,
  hasPermissionToApprove: boolean
) => {
  try {
    if (!hasPermissionToApprove) {
      return;
    }
    const response = await api.patch(`well-schematic/approve/${versionId}`);
    toast.success('Esquemático aprovado com sucesso');
    return response.data;
  } catch (error) {
    toast.error(
      'Erro ao aprovar esquemático - error: ' + (error as Error).message
    );
    throw error;
  }
};

export const getCorrectionRequests = async (
  oilwellName: string,
  versionId: number
) => {
  try {
    const response = await api.get<CorrectionRequest[]>(
      `well-schematic/correction-request/${oilwellName}/${versionId}`
    );
    return response.data;
  } catch (error) {
    toast.error('Erro ao carregar solicitações de correção');
    throw error;
  }
};

export const createCorrectionRequest = async (
  oilwellName: string,
  versionId: number,
  requestDate: Date,
  correction: string
) => {
  try {
    const createCorrectionRequestDto = {
      oilWellName: oilwellName,
      wellSchematicId: versionId,
      requestDate: requestDate.toISOString(),
      correction: correction
    };
    await api.post(
      `well-schematic/correction-request`,
      createCorrectionRequestDto
    );
    toast.success('Solicitação de correção criada com sucesso');
  } catch (error) {
    toast.error('Erro ao criar solicitação de correção');
    throw error;
  }
};

export const getModifications = async (
  oilwellName: string,
  versionId: number
) => {
  try {
    const response = await api.get<ModificationReferences>(
      `well-schematic/diff/${oilwellName}/${versionId}`
    );
    return response.data;
  } catch (error) {
    toast.error('Erro ao carregar modificações');
    throw error;
  }
};

export const postEnvelopeData = async (formData: FormData) => {
  try {
    const response = await api.post(`well-schematic/safe-envelope`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    toast.success('Envelope de segurança salvo com sucesso');
    return response.data;
  } catch (error) {
    toast.error('Erro ao salvar envelope de segurança');
    throw error;
  }
};

export const downloadAttachment = async (id: number) => {
  try {
    const response = await api.get(`well-schematic/download-attachment/${id}`,
      {
        responseType: 'blob',
      }
    );
    toast.success('Anexo baixado com sucesso');
    return response.data;
  } catch (error) {
    toast.error('Erro ao baixar anexo');
    throw error;
  }
};

export const getEnvelopeData = async (wellSchematicId: number) => {
  try {
    const response = await api.get(
      `well-schematic/safe-envelope/${wellSchematicId}`
    );
    return response.data;
  } catch (error) {
    toast.error('Erro ao buscar envelope de segurança');
    throw error;
  }
};
