import { Graphics } from '@pixi/react';
import React from 'react';
import { ParteInferior } from '../interface';
import { subsurfaceElementsKeyMapping } from '../schematicUtils';
import ScaleUtils from '../ScaleUtils';

const SchematicOilWellRevestimentoRasgado: React.FC<any> = (props: {
  parteInferior: ParteInferior;
  wellDiameter: number;
  scaleUtils: ScaleUtils;
}) => {
  const revestimentoRasgado = (
    g: any,
    topo: number,
    base: number,
    oposto: boolean = false
  ) => {
    g.clear();
    g.lineStyle(2, 0x0000000, 1);
    for (let i = topo; i < base; i = i + 10) {
      g.moveTo((props.wellDiameter / 2) * (oposto ? -1 : 1), i);
      g.lineTo((props.wellDiameter / 2) * (oposto ? -1 : 1), i + 5);
    }
  };

  return (
    <>
      {props.parteInferior.condicoes_mecanicas
        .filter(
          x =>
            subsurfaceElementsKeyMapping[x.tipo.toLowerCase()] ===
            'revestimento-rasgado'
        )
        .map((line: any, index: number) => {
          return (
            <React.Fragment key={`revestimento-rasgado-${index}`}>
              <Graphics
                draw={g =>
                  revestimentoRasgado(
                    g,
                    props.scaleUtils.rescaleHeight(line.intervalo_superior) ??
                      0,
                    props.scaleUtils.rescaleHeight(line.intervalo_inferior) ??
                      0,
                    true
                  )
                }
              />
              <Graphics
                draw={g =>
                  revestimentoRasgado(
                    g,
                    props.scaleUtils.rescaleHeight(line.intervalo_superior) ??
                      0,
                    props.scaleUtils.rescaleHeight(line.intervalo_inferior) ?? 0
                  )
                }
              />
            </React.Fragment>
          );
        })}
    </>
  );
};

export default SchematicOilWellRevestimentoRasgado;
