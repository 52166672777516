import { Graphics, Text } from '@pixi/react';
import { TextStyle } from 'pixi.js';
import React, { useCallback, useEffect } from 'react';
import { RevestimentsAndDiameters } from './Components/Revestiment';

const SchematicOilWellRuler: React.FC<any> = (props: {
  revestimentsAndDiameters: RevestimentsAndDiameters;
  stageWidth: number;
  scaleByLastSubsurfaceElement: number;
  maxDepthFaseDePerfuracao: number;
}) => {
  const lineColor = '#BED4EF';
  const titleColor = 0x193cb9;
  const radius = 10;
  const leftRule = -props.stageWidth / 2 - 10;
  const middleRule = -props.stageWidth / 2 + 20;
  const rightRule = -props.stageWidth / 2 + 50;
  const cellSizeInPixels = 15;

  const [ruleScale, setRuleScale] = React.useState<number[]>([]);

  const lastRevestimentDepth =
    props.revestimentsAndDiameters[props.revestimentsAndDiameters.length - 1]
      .revestiments[
      props.revestimentsAndDiameters[props.revestimentsAndDiameters.length - 1]
        .revestiments.length - 1
    ].profundidade;

  const maxDepthToScale =
    Math.max(props.maxDepthFaseDePerfuracao, Number(lastRevestimentDepth)) *
    props.scaleByLastSubsurfaceElement;

  const handleRuleScale = () => {
    let ruleScale = [];
    for (let i = 0; i <= Number(maxDepthToScale); i += 20) {
      ruleScale.push(i);
    }

    setRuleScale(ruleScale);
  };

  const outline = useCallback(
    (g: any) => {
      g.clear();
      g.lineStyle(2, lineColor, 1);
      g.drawRoundedRect(
        leftRule, //x
        -30, //y
        60, //width
        Math.max(
          ...ruleScale.map(
            scale => scale + 50 //50 is the last element + top margin (-30)
          )
        ), //height
        radius //radius
      );

      g.endFill();
    },
    [ruleScale]
  );

  const range = useCallback(
    (g: any, y: any) => {
      g.clear();
      g.lineStyle(2, lineColor, 1);
      g.moveTo(leftRule, y);
      g.lineTo(rightRule, y);
    },
    [ruleScale]
  );

  const rangeText = (depth: number, index: number) => {
    return (
      <>
        <Text
          scale={1}
          anchor={{ x: 0.55, y: 1 }}
          x={middleRule}
          y={depth + cellSizeInPixels}
          text={getDepthText(
            depth,
            props.scaleByLastSubsurfaceElement,
            cellSizeInPixels
          )}
          style={
            new TextStyle({
              fontFamily: 'Graphie',
              fontStyle: 'normal',
              fontSize: 10,
              fill: [0x000000],
              wordWrap: true,
              wordWrapWidth: 290
            })
          }
        />
      </>
    );
  };

  useEffect(() => {
    handleRuleScale();
  }, [props.revestimentsAndDiameters, props.stageWidth]);

  return (
    <>
      <Graphics draw={outline} />

      <Text
        scale={1}
        anchor={1}
        x={rightRule - 5}
        y={-10}
        text={'Prof. (m)'}
        style={
          new TextStyle({
            fontFamily: 'Graphie',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 12,
            fill: [titleColor],
            wordWrap: true,
            wordWrapWidth: 440
          })
        }
      />

      {ruleScale.map((depth: number, index: number) => (
        <React.Fragment key={`rule-scale-${index}`}>
          <Graphics draw={g => range(g, depth)} />
          {rangeText(depth, index)}
        </React.Fragment>
      ))}
    </>
  );
};

const getDepthText = (
  depth: number,
  scaleByLastSubsurfaceElement: number,
  cellSizeInPixels: number
) => {
  const depthInMeters =
    (depth + cellSizeInPixels) / scaleByLastSubsurfaceElement;
  const dephtInMeters = Math.floor(depthInMeters / 5) * 5;

  return dephtInMeters.toString();
};

export default SchematicOilWellRuler;
