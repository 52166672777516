import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateInstalacaoPlacaIdentificadoraForm, {
  CreateInstalacaoPlacaIdentificadoraFormData
} from './useCreateInstalacaoPlacaIdentificadoraForm';

interface InstalacaoPlacaIdentificadoraFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const InstalacaoPlacaIdentificadoraForm = forwardRef<
  FormRef<CreateInstalacaoPlacaIdentificadoraFormData>,
  InstalacaoPlacaIdentificadoraFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { methods } = useCreateInstalacaoPlacaIdentificadoraForm();

  const {
    register,
    formState: { errors },
    watch,
    trigger
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('instalacao placa identificadora Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="flex gap-4">
        <Input
          label="Motivo"
          {...register('reason')}
          errorMessage={errors.reason?.message}
          disabled={!isEditable}
          required
        />
      </div>
    </FormWrapper>
  );
});

export default InstalacaoPlacaIdentificadoraForm;
