import Table from '@/components/organisms/Table';
import { envelopeProps } from './EnvelopeDataRegister';
import { safeEnvelopeMdmViewOnly } from './safe-envelope-mdm';
import { useEffect, useMemo, useState } from 'react';
import { Label } from '@/components/atoms/Label';
import { downloadAttachment } from '@/services/wellhandover.services';
import { HiCloudDownload } from 'react-icons/hi';

export const EnvelopeDataVisualization: React.FC<{
  envelopeData: envelopeProps;
  setEnvelopeData: (data: envelopeProps) => void;
}> = ({ envelopeData }) => {
  const [tableData, setTableData] = useState<envelopeProps>(envelopeData);
  const [downloading, setDownloading] = useState<string | null>(null);

  const data = useMemo(
    () => [
      { label: 'Anular A', maasp: tableData?.anularA },
      { label: 'Anular B', maasp: tableData?.anularB },
      { label: 'Anular C', maasp: tableData?.anularC },
      { label: 'Coluna', maasp: tableData?.coluna }
    ],
    [tableData]
  );

  const handleDownload = async (attachment: any) => {
    try {
      if (!attachment.id) return;
      setDownloading(attachment.id);

      const response = await downloadAttachment(attachment.id);

      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: attachment.mimeType
        })
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.name);

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download error:', error);
    } finally {
      setDownloading(null);
    }
  };

  useEffect(() => {
    setTableData(envelopeData);
  }, [envelopeData]);

  return (
    <>
      <Table
        columns={safeEnvelopeMdmViewOnly}
        data={data}
        headerClassName="bg-primary text-white text-md font-normal h-10"
        bodyClassName="h-16"
        setData={setTableData}
      />

      <div>
        <Label
          id="Upload de documentos"
          className="text-primary pt-3 text-sm font-normal"
          name="Upload de documento(s)"
          noBorder
        />
        <div className="flex flex-col gap-2 mt-3">
          {tableData?.attachments?.map(attachment => (
            <button
              onClick={() => handleDownload(attachment)}
              key={attachment.id}
              className="flex hover:bg-persian-blue-100 items-center text-sm bg-persian-blue-50 text-persian-blue-800 px-3 py-2 justify-between bg-gray-100 rounded-md"
            >
              <div>
                {attachment.id === downloading
                  ? 'Downloading...'
                  : attachment.name}
              </div>
              <HiCloudDownload className="text-lg" />
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
