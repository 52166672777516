import { useRef, useState, useEffect } from 'react';
import { HiCloudUpload, HiOutlineTrash } from 'react-icons/hi';
import { Attachment } from './EnvelopeDataRegister';
interface FileUploadProps {
  onFilesChange: (files: File[]) => void;
  initialFiles: Array<File | Attachment>;
  limit?: number;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onFilesChange,
  initialFiles,
  limit = 5
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<Array<File | Attachment>>(initialFiles);

  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFileArray = Array.from(event.target.files);

      const existingFiles = files.filter(
        existingFile =>
          !newFileArray.some(newFile => newFile.name === existingFile.name)
      );

      const combinedFiles = [...existingFiles, ...newFileArray].slice(0, limit);

      setFiles(combinedFiles);
      onFilesChange(combinedFiles as File[]);
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    onFilesChange(newFiles as File[]);
  };

  return (
    <div>
      <input
        id="upload"
        name="upload"
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileChange}
        multiple
      />

      <button
        type="button"
        onClick={handleClick}
        className="w-full h-28 border-2 border-primary border-dashed flex flex-col items-center justify-center rounded-md text-primary hover:bg-gray-100 transition"
      >
        <HiCloudUpload className="text-3xl" />
        <span className="text-sm text-black">
          Clique ou arraste arquivos aqui
        </span>
      </button>

      {files.length > 0 && (
        <div className="mt-3">
          <p className="text-sm font-normal mb-2">Arquivos selecionados:</p>
          <ul className="space-y-1">
            {files.map((file, index) => (
              <li
                key={index}
                className="flex items-center bg-persian-blue-50 text-persian-blue-800 px-3 py-2 justify-between bg-gray-100 rounded-md"
              >
                <span className="text-sm text-gray-700">{file.name}</span>
                <button
                  onClick={() => handleRemoveFile(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <HiOutlineTrash className="w-4 h-4" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
