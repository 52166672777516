import { STATUS_MAP } from '@/constants/status';
import { formatDate } from './utils';

export const generalInfo = (oilwellData: any) => [
  { label: 'Nome do Poço', value: oilwellData?.name || '-' },
  { label: 'Campo', value: oilwellData?.oilField?.name || '-' },
  {
    label: 'Status Atual do Poço',
    value: STATUS_MAP[oilwellData?.statusId] || '-'
  },
  { label: 'Nome ANP', value: oilwellData?.nameANP || '-' }
];

export const technicalInfo = (oilwellData: any) => [
  {
    label: 'Vida Útil do Poço',
    value: oilwellData?.operationalLife
      ? formatDate(oilwellData?.operationalLife)
      : '-'
  },
  {
    label: 'Saúde do Poço',
    value: oilwellData?.oilWellHealth || '-'
  },
  {
    label: 'Bacia Sedimentar',
    value: oilwellData?.sedimentaryBasin || '-'
  },
  {
    label: 'Surgência',
    value: oilwellData?.surgencia || '-'
  }
];

export const medidasInfo = (oilwellData: any) => [
  {
    label: 'Profundidade Medida (MD):',
    value: oilwellData?.averageDepth || '-'
  },
  {
    label: 'Profundidade Vertical (TVD):',
    value: oilwellData?.verticalDepth || '-'
  },
  {
    label: 'Latitude (GD SIRGAS 2000):',
    value: oilwellData?.latitude || '-'
  },
  {
    label: 'Longitude (GD SIRGAS 2000):',
    value: oilwellData?.longitude || '-'
  }
];

export const ambienteInfo = (oilwellData: any) => [
  {
    label: 'Ambiente:',
    value: oilwellData?.environment || '-'
  },
  {
    label: 'Última Verificaçaõ de Surgência:',
    value: oilwellData?.dataSurgencia || '-'
  },
  {
    label: 'Intervalo de Monitoramento:',
    value:
      oilwellData?.schematic?.jsonData?.conjunto_solidario_de_barreiras
        ?.intervalo_de_monitoramento || '-'
  },
  {
    label: 'Intervalo de Inspeção:',
    value:
      oilwellData?.schematic?.jsonData?.conjunto_solidario_de_barreiras
        ?.intervalo_de_inspecao || '-'
  }
];
