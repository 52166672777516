import EmptyMessage from '@/components/atoms/EmptyMessage';
import { Label } from '@/components/atoms/Label';
import MenuOptions from '@/components/atoms/MenuOptions';
import Modal from '@/components/atoms/Modal';
import Spinner from '@/components/atoms/Spinner';
import Tabs from '@/components/atoms/Tab';
import { DefaultCell } from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import { TableGrid } from '@/components/atoms/TableGrid';
import { getIssuesRedmineByIds } from '@/services/utils.services';
import {
  createCorrectionRequest,
  getCorrectionRequests,
  getModifications
} from '@/services/wellhandover.services';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useState } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { Column } from 'react-table';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { Modification } from '../interface';

interface ModalApproveProps {
  oilwellName: string;
  versionId: number;
  approved: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleApprove: () => Promise<void>;
}

export type ModificationGroup = {
  groupName: string;
  items: { name: string; oldValue: string; newValue: string }[];
};

const WELLHANDOVER_HISTORY_CACHE_KEY = 'wellhandover-history';
const WELLHANDOVER_MODIFICATIONS_CACHE_KEY = 'wellhandover-modifications';

const RealizarAprovacao = ({
  oilwellName,
  versionId,
  approved,
  handleApprove
}: {
  oilwellName: string;
  versionId: number;
  approved: boolean;
  handleApprove: () => Promise<void>;
}) => {
  const queryClient = useQueryClient();
  const [correction, setCorrection] = useState('');
  const [requestDate, setRequestDate] = useState(new Date());

  const handleCorrection = () => {
    createCorrectionRequest(oilwellName, versionId, requestDate, correction);
    queryClient.invalidateQueries([
      WELLHANDOVER_HISTORY_CACHE_KEY,
      oilwellName,
      versionId
    ]);
    setCorrection('');
    setRequestDate(new Date());
  };
  return (
    <div className="mx-4 px-4 mb-4 pb-4">
      <div className="space-y-4">
        <div className="w-full flex gap-4">
          <div>
            <label className="block text-sm font-medium text-gray">
              Data de solicitação
            </label>
            <input
              className="border text-sm border-gray rounded-md p-2 bg-gray-100"
              type="date"
              value={requestDate.toISOString().split('T')[0]}
              onChange={e => setRequestDate(new Date(e.target.value))}
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-medium text-gray">
              Solicitação de correção
            </label>
            <textarea
              className="w-full border border-gray text-sm rounded-md p-2 bg-gray-100"
              rows={1}
              value={correction}
              onChange={e => setCorrection(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <button
            className={twMerge(
              'border border-primary text-primary px-6 py-2 rounded-full',
              approved ? 'opacity-50 cursor-not-allowed' : ' hover:bg-gray-50'
            )}
            disabled={approved}
            onClick={handleCorrection}
          >
            Corrigir
          </button>
          <button
            className={twMerge(
              'bg-primary text-white px-6 py-2 rounded-full ',
              approved
                ? 'opacity-50 cursor-not-allowed'
                : ' hover:bg-primary-dark'
            )}
            disabled={approved}
            onClick={handleApprove}
          >
            Aprovar
          </button>
        </div>
      </div>
    </div>
  );
};

const HistoricoAprovacao = ({
  oilwellName,
  versionId,
  approved
}: {
  oilwellName: string;
  versionId: number;
  approved: boolean;
}) => {
  const { data: historyData, isLoading: isLoadingHistory } = useQuery(
    [WELLHANDOVER_HISTORY_CACHE_KEY, oilwellName, versionId],
    () => getCorrectionRequests(oilwellName, versionId),
    {
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60
    }
  );

  return (
    <div className="mx-4 px-4 mb-4 pb-4">
      <div className="space-y-4 overflow-y-auto max-h-[400px]">
        {isLoadingHistory ? (
          <Spinner />
        ) : historyData && historyData.length > 0 ? (
          historyData.map(history => (
            <div key={history.id}>
              <div className="w-full flex gap-2">
                <div>
                  <label className="block text-sm font-medium mb-2 text-gray">
                    Data de solicitação
                  </label>
                  <input
                    className="w-full border border-gray text-sm rounded-md p-2 bg-gray-50"
                    value={format(new Date(history.requestDate), 'dd/MM/yyyy')}
                    disabled
                  />
                </div>
                <div className="w-full">
                  <label className="block text-sm font-medium mb-2 text-gray">
                    Status da solicitação
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray text-sm rounded-md p-2 bg-gray-50"
                    value={approved ? 'Aprovado' : 'Aprovação Pendente'}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium mb-2 text-gray">
                  Solicitação da correção
                </label>
                <textarea
                  className="w-full border border-gray rounded-md p-2 bg-gray-50 resize-none"
                  value={history.correction}
                  disabled
                  rows={1}
                />
              </div>
              <div className="border-t border-gray/20 my-4"></div>
            </div>
          ))
        ) : (
          <EmptyMessage
            message="Não há histórico de requisições de correção para este poço"
            className="h-16"
          />
        )}
      </div>
    </div>
  );
};

const ModificacaoVersao = ({
  oilwellName,
  versionId
}: {
  oilwellName: string;
  versionId: number;
}) => {
  const { data: modifications = {}, isLoading: isLoadingModificationGroups } =
    useQuery(
      [WELLHANDOVER_MODIFICATIONS_CACHE_KEY, oilwellName, versionId],
      () => getModifications(oilwellName, versionId),
      {
        cacheTime: 1000 * 60,
        staleTime: 1000 * 60
      }
    );

  const issueIds = [
    ...new Set(Object.values(modifications).flatMap(value => value.issues))
  ];

  const { data: issues, isLoading: isLoadingIssues } = useQuery(
    ['issues-modifications-modal-wellhandover', issueIds],
    async () => {
      const response = await getIssuesRedmineByIds(issueIds, [248], 248);
      return response.data.map(issue => issue.issue);
    },
    {
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60
    }
  );

  return (
    <div className="mx-4 px-4 mb-4 pb-4 overflow-y-auto max-h-[400px]">
      {isLoadingModificationGroups || isLoadingIssues ? (
        <Spinner />
      ) : modifications && Object.keys(modifications).length > 0 ? (
        Object.entries(modifications).map(([key, value]) => (
          <div key={key}>
            {value.changes.length > 0 ? (
              <>
                <div className="flex gap-2 items-center">
                  <Label
                    className="text-primary font-bold"
                    name={fieldTranslations[key]}
                    id={`${key}-label`}
                  />

                  <div
                    data-tooltip-id={`tooltip-modification-${key}`}
                    data-tooltip-target="tooltip-default"
                  >
                    <FaCircleInfo className="w-4 h-4 text-primary cursor-help" />
                  </div>
                  <Tooltip
                    id={`tooltip-modification-${key}`}
                    place="top"
                    variant="dark"
                    style={{ zIndex: 99 }}
                    render={() => (
                      <div className="flex flex-col">
                        <span className="text-[#CA0000]">Valores antigos</span>
                        <span className="text-[#008F18]">Valores novos</span>
                      </div>
                    )}
                  />

                  <MenuOptions
                    nameId={`${key}-issues-menu`}
                    menuClassName="translate-x-[calc(25%+4px)] translate-y-[3%]"
                    links={value.issues.map(issue => ({
                      label: `${issues?.find(redmineIssue => redmineIssue.id === issue)?.subject}`,
                      url: `${import.meta.env.VITE_REDMINE_URL}/issues/${issue}`
                    }))}
                  />
                </div>

                <div className="mb-4">
                  <TableGrid
                    data={value.changes}
                    cols={generateColumns(key, value.changes)}
                  />
                </div>
              </>
            ) : null}
          </div>
        ))
      ) : (
        <EmptyMessage
          message="Não há modificações de versão para este poço"
          className="h-16"
        />
      )}
    </div>
  );
};

const ModalApprove: React.FC<ModalApproveProps> = ({
  oilwellName,
  versionId,
  approved,
  isOpen,
  setIsOpen,
  handleApprove
}) => {
  const tabs = [
    {
      id: 0,
      label: 'Realizar\u00A0aprovação',
      content: (
        <RealizarAprovacao
          oilwellName={oilwellName}
          versionId={versionId}
          approved={approved}
          handleApprove={handleApprove}
        />
      )
    },
    {
      id: 1,
      label: 'Histórico\u00A0de\u00A0Aprovação',
      content: (
        <HistoricoAprovacao
          oilwellName={oilwellName}
          versionId={versionId}
          approved={approved}
        />
      )
    },
    {
      id: 2,
      label: 'Modificação\u00A0de\u00A0versão',
      content: (
        <ModificacaoVersao oilwellName={oilwellName} versionId={versionId} />
      )
    }
  ];

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Detalhes de aprovação"
      titleClassName="text-primary font-bold text-xl"
      className="w-auto max-h-[80vh] overflow-hidden mx-4"
    >
      <div className="flex">
        <Tabs tabs={tabs} />
      </div>
    </Modal>
  );
};

const generateColumns = (
  section: string,
  data: { [key: string]: string | Modification | undefined }[]
): Column[] => {
  if (!data?.length) return [];

  const _keys = data.flatMap(object => {
    if (!object) return [];

    const entries = Object.entries(object).filter(
      ([value]) => value && value !== null && value !== undefined
    );
    return entries.map(([key]) => key).filter(key => key !== 'label');
  });

  const keys = [...new Set(_keys)];

  return [
    {
      Header: (
        <Header
          text={`Item`}
          id={`${section}-item-label`}
          className="py-2 px-0 w-auto"
        />
      ),
      accessor: 'label',
      Cell: DefaultCell
    },
    ...keys.map(key => ({
      Header: (
        <Header
          text={fieldTranslations[key]}
          id={`${section}-${key}`}
          className="py-2 px-0 w-auto whitespace-nowrap"
        />
      ),
      accessor: key,
      Cell: ({ value }: any) => {
        return <ModificationCell value={value} />;
      }
    }))
  ];
};

const ModificationCell = ({ value }: { value: Modification }) => {
  if (!value?.oldValue) {
    return (
      <div className="text-xs font-graphie text-[#008F18]">
        {value?.newValue}
      </div>
    );
  }

  return (
    <div>
      <span className="text-xs font-graphie text-[#CA0000]">
        {value.oldValue}
      </span>
      {' / '}
      <span className="text-xs font-graphie text-[#008F18]">
        {value.newValue}
      </span>
    </div>
  );
};

const fieldTranslations: Record<string, string> = {
  equipamento_de_superficie: 'Equipamento de Superfície',
  valvulas: 'Valvulas',
  equipamento_de_subsuperficie: 'Equipamento de Subsuperfície',
  hastes: 'Hastes',
  condicoes_mecanicas: 'Condições Mecânicas',
  obstrucoes: 'Obstruções',
  peixes: 'Peixes',
  revestimento: 'Revestimento',
  cimentacoes: 'Cimentações',
  restricoes: 'Restrições',
  brocas: 'Brocas',
  coluna_de_producao: 'Coluna de Produção',
  quantidade: 'Quantidade',
  diametro_externo: 'Diâmetro Externo',
  diametro_interno: 'Diâmetro Interno',
  fabricante: 'Fabricante',
  profundidade: 'Profundidade',
  has_valvula: 'Possui Válvula',
  has_kerotest: 'Possui Kerotest',
  has_flange_or_dhsv: 'Possui Flange/DHSV',
  flange_or_dhsv_type: 'Tipo de Flange/DHSV',
  modelo: 'Modelo',
  pressao: 'Pressão',
  diametro: 'Diâmetro',
  intervalo_superior: 'Intervalo Superior',
  intervalo_inferior: 'Intervalo Inferior',
  zona: 'Zona',
  topo: 'Topo',
  base: 'Base',
  grau: 'Grau',
  peso: 'Peso'
};

export default ModalApprove;
