export type AlertVariablesDTO = Record<string, AlertVariableDTO>;

export type AlertVariableDTO = {
  throughput: AlertConfigDTO[];
  pressure: AlertConfigDTO[];
  elevation: AlertConfigDTO[];
};

export const AlertVisibilityTypeValues = [
  'percentage_change_alert',
  'absolute_difference_alert',
  'ratio_alert'
] as const;

export type AlertConfigDTO = {
  alert_type: string;
  label: string;
  visibility_type: (typeof AlertVisibilityTypeValues)[number];
  red: number;
  green: number;
  last_update?: LastUpdate;
};

export type LastUpdate = {
  date: Date;
  user: string;
  details: UpdateDetail[];
};

export type UpdateDetail = {
  criticality: number;
  old_value: number;
  new_value: number;
};

export type AlertConfigChangeDTO = {
  oilwell: string;
  alert_type: string;
  criticality: number;
  old_value?: number;
  new_value: number;
};

export type UserSettingsDTO = {
  email: string;
  active?: boolean;
  receive_troughput_email?: boolean;
  receive_pressure_email?: boolean;
  receive_elevation_email?: boolean;
  receive_no_pressure_measure_email?: boolean;
};

export const AlertSectionValues = [
  'throughput',
  'pressure',
  'elevation'
] as const;

export type AlertSection = (typeof AlertSectionValues)[number];

export const alertSectionLabelResource: Record<AlertSection, string> = {
  throughput: 'Vazão',
  pressure: 'Pressão',
  elevation: 'Elevação'
};

export const criticalityLabelResource: Record<number, string> = {
  2: 'red',
  3: 'green'
};
