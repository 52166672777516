import EmptyMessage from '@/components/atoms/EmptyMessage';
import { PageWrapper } from '@/components/atoms/PageWrapper';
import Spinner from '@/components/atoms/Spinner';
import PageNavigation from '@/components/molecules/PageNavigation';
import SearchInput from '@/components/molecules/SearchInput';
import SideFilter from '@/components/molecules/SideFilter';
import Table from '@/components/organisms/Table';
import { oilwellsListFilters } from '@/constants/filters/OilWellsListTable';
import oilwellsColumnsList from '@/constants/tableColumns/mdm/oilwells-list';
import {
  getAllOilwellsList,
  getWellSchematicsTotals
} from '@/services/oilwells.services';
import { oilwellsListFiltersAtom } from '@/state/oilwells-list.filters.atom';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

interface BadgeAlertProps {
  value: number;
  status: string;
  onClick: () => void;
  isActive: boolean;
}

const BadgeAlert = ({ value, status, onClick, isActive }: BadgeAlertProps) => (
  <button
    className={`flex flex-row items-center gap-2 py-2 px-4 rounded-full ${
      isActive ? 'bg-[#D7DFFF]/40' : ''
    }`}
    onClick={onClick}
  >
    <span
      className={`px-2 py-1 text-xs font-semibold rounded-md w-auto h-6 flex flex-row justify-center items-center ${
        status === 'Pendentes'
          ? 'bg-[#e6432a]/20 text-[#e6432a]'
          : status === 'Realizados'
            ? 'bg-[#D7DFFF] text-[#193CB9]'
            : status === 'Sem registro'
              ? 'bg-[#cacaca89] text-[#4e4e4e]'
              : 'bg-gray-200 text-gray-800'
      } ${isActive ? 'bg-[#D7DFFF] text-[#193CB9]' : ''}`}
    >
      {value}
    </span>
    <p className="text-xs">{status}</p>
  </button>
);

const OilWellsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(15);
  const [filters, setFilters] = useRecoilState(oilwellsListFiltersAtom);
  const [filteredData, setFilteredData] = useState(filters);

  const { data: totals } = useQuery(
    ['well-schematics-totals', search, filters],
    () =>
      getWellSchematicsTotals(search, {
        ...filters,
        statusWellHandover: []
      }),
    {
      keepPreviousData: true,
      staleTime: 5000
    }
  );

  const { data: totalData, isLoading } = useQuery(
    ['oilwells-total', search, filteredData, currentPage, perPage],
    () =>
      getAllOilwellsList(
        (currentPage - 1) * perPage,
        perPage,
        search,
        filteredData
      )
  );

  const filteredWells = totalData?.data || [];
  const totalWells = totalData?.totalCount || 0;

  useEffect(() => {
    setPerPage(Math.min(totalWells, 15));
  }, [totalWells]);

  const handleFilter = (isClearing = false) => {
    setCurrentPage(1);
    const newFilters = isClearing
      ? {
          field: [],
          nameANP: [],
          anpStatus: [],
          healthStatus: [],
          statusWellHandover: []
        }
      : filters;
    setFilteredData(newFilters);
    setFilters(newFilters);
  };

  const handleBadgeClick = (status: string) => {
    setFilteredData(prevFilters => {
      const newStatusWellHandover = prevFilters.statusWellHandover.includes(
        status
      )
        ? prevFilters.statusWellHandover.filter(s => s !== status)
        : [...prevFilters.statusWellHandover, status];

      const newFilters = {
        ...prevFilters,
        statusWellHandover: newStatusWellHandover
      };
      setFilters(newFilters);
      setCurrentPage(1);
      return newFilters;
    });
  };

  return (
    <PageWrapper className="p-0">
      <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
        <section className="flex justify-between items-center">
          <div className="flex flex-row gap-8 items-center justify-center">
            <h1 className="text-primary font-bold text-lg flex">
              Situação atual dos poços
            </h1>
            <div className="flex flex-row gap-1">
              <BadgeAlert
                value={totals?.pendente ?? 0}
                status="Pendentes"
                onClick={() => handleBadgeClick('Pendente')}
                isActive={filteredData.statusWellHandover.includes('Pendente')}
              />
              <BadgeAlert
                value={totals?.realizado ?? 0}
                status="Realizados"
                onClick={() => handleBadgeClick('Realizado')}
                isActive={filteredData.statusWellHandover.includes('Realizado')}
              />
              <BadgeAlert
                value={totals?.semRegistro ?? 0}
                status="Sem registro"
                onClick={() => handleBadgeClick('Sem registro')}
                isActive={filteredData.statusWellHandover.includes(
                  'Sem registro'
                )}
              />
            </div>
          </div>
          <SearchInput
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              setCurrentPage(1);
            }}
          />
        </section>

        {isLoading ? (
          <Spinner className="mt-4" />
        ) : (
          <>
            {filteredWells.length > 0 ? (
              <section className="flex flex-col gap-2">
                <div className="overflow-auto">
                  <Table columns={oilwellsColumnsList} data={filteredWells} />
                </div>
                <PageNavigation
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  className="mt-3"
                  totalPages={Math.ceil(totalWells / perPage)}
                  perPage={perPage}
                  totalItems={totalWells}
                />
              </section>
            ) : (
              <div className="flex flex-col items-center justify-center">
                {totalWells === 0 ? (
                  <EmptyMessage message="Nenhum poço encontrado" />
                ) : (
                  <Spinner className="mt-4" size={32} />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <SideFilter
        atom={oilwellsListFiltersAtom}
        filters={oilwellsListFilters}
        applyChanges={handleFilter}
      />
    </PageWrapper>
  );
};

export default OilWellsList;
