import { Sprite } from '@pixi/react';
import React from 'react';
import { valvulaVertical } from '../schematicUtils';

export const SchematicOilWellVerticalValve: React.FC<any> = (props: {
  elements: any;
  topHeight: number;
  hoverTooltip: any;
  setShowTooltip: any;
  hoverText: string;
  hasTwoColumns: boolean;
}) => {
  return (
    <>
      {props.elements
        .filter((x: any) => x.curta != null || x.longa != null)
        .map((line: any, index: number) => (
          <React.Fragment key={`vertical-valve-elements-${index}`}>
            {props.hasTwoColumns && line.longa && line.curta ? (
              <React.Fragment key={`vertical-valve-longa-curta-${index}`}>
                <Sprite
                  scale={1}
                  anchor={{ x: 1, y: 1 }}
                  x={50}
                  y={-props.topHeight + valvulaVertical.height * (index + 1)}
                  image={valvulaVertical.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: 25,
                        y:
                          -props.topHeight +
                          valvulaVertical.height * (index + 1)
                      },
                      {
                        x: 50,
                        y:
                          -props.topHeight +
                          valvulaVertical.height * (index + 1)
                      },
                      props.hoverText,
                      { width: 180, height: 80 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />

                <Sprite
                  scale={1}
                  anchor={{ x: 1, y: 1 }}
                  x={0}
                  y={-props.topHeight + valvulaVertical.height * (index + 1)}
                  image={valvulaVertical.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: -25,
                        y:
                          -props.topHeight +
                          valvulaVertical.height * (index + 1)
                      },
                      {
                        x: 25,
                        y:
                          -props.topHeight +
                          valvulaVertical.height * (index + 1)
                      },
                      props.hoverText,
                      { width: 180, height: 80 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
              </React.Fragment>
            ) : (
              <>
                {props.hasTwoColumns && !line.longa && (
                  <>
                    <Sprite
                      scale={1}
                      anchor={{ x: 1, y: 1 }}
                      x={0}
                      y={
                        -props.topHeight + valvulaVertical.height * (index + 1)
                      }
                      image={valvulaVertical.image}
                      eventMode={'static'}
                      mouseover={() => {
                        props.hoverTooltip(
                          {
                            x: -25,
                            y:
                              -props.topHeight +
                              valvulaVertical.height * (index + 1)
                          },
                          {
                            x: 30,
                            y:
                              -props.topHeight +
                              valvulaVertical.height * (index + 1)
                          },
                          props.hoverText,
                          { width: 180, height: 80 }
                        );
                      }}
                      mouseout={() => props.setShowTooltip(false)}
                    />
                  </>
                )}

                {props.hasTwoColumns && !line.curta && (
                  <>
                    <Sprite
                      scale={1}
                      anchor={{ x: 1, y: 1 }}
                      x={50}
                      y={
                        -props.topHeight + valvulaVertical.height * (index + 1)
                      }
                      image={valvulaVertical.image}
                      eventMode={'static'}
                      mouseover={() => {
                        props.hoverTooltip(
                          {
                            x: 25,
                            y:
                              -props.topHeight +
                              valvulaVertical.height * (index + 1)
                          },
                          {
                            x: 50,
                            y:
                              -props.topHeight +
                              valvulaVertical.height * (index + 1)
                          },
                          props.hoverText,
                          { width: 180, height: 80 }
                        );
                      }}
                      mouseout={() => props.setShowTooltip(false)}
                    />
                  </>
                )}

                {!props.hasTwoColumns && (
                  <Sprite
                    scale={1}
                    anchor={{ x: 1, y: 1 }}
                    x={25}
                    y={-props.topHeight + valvulaVertical.height * (index + 1)}
                    image={valvulaVertical.image}
                    eventMode={'static'}
                    mouseover={() => {
                      props.hoverTooltip(
                        {
                          x: 0,
                          y:
                            -props.topHeight +
                            valvulaVertical.height * (index + 1)
                        },
                        {
                          x: 0,
                          y:
                            -props.topHeight +
                            valvulaVertical.height * (index + 1)
                        },
                        props.hoverText,
                        { width: 180, height: 80 }
                      );
                    }}
                    mouseout={() => props.setShowTooltip(false)}
                  />
                )}
              </>
            )}
          </React.Fragment>
        ))}
    </>
  );
};
