import Checkbox from '@/components/atoms/Checkbox';
import { ControlledSelect } from '@/pages/FormsPage/ControlledSelect/ControlledSelect';
import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateGeneralInformationForm, {
  CreateGeneralInformationFormData
} from './useCreateGeneralInformationForm';

interface GeneralInformationForm1Props {
  isMandatory: boolean;
  isEditable?: boolean;
  isOilwellFieldMulti?: boolean;
}

const GeneralInformationForm = forwardRef<
  FormRef<CreateGeneralInformationFormData>,
  GeneralInformationForm1Props
>((props, ref) => {
  const { isMandatory, isEditable = true, isOilwellFieldMulti = false } = props;

  const { schema, methods, useLoadSavedData } = useCreateGeneralInformationForm(
    { isOilwellFieldMulti }
  );

  const {
    watch,
    trigger,
    control,
    setValue,
    formState: { errors },
    register
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('General Information Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  const isLoading = false;

  // TODO requisição para buscar os dados do poço
  useEffect(() => {
    setValue('oil_rank', '80º');
    setValue('gas_rank', '12º');
    setValue('committee_restriction', 'Sim');
    setValue('elevation_method', 'PAL');
    setValue('field', 'Anambé');
    setValue('anp_code', '7-ANB-14D-AL');
    setValue('fluid_type', 'Óleo');
    setValue('oilwell_type', 'Produtor');
  }, []);

  return (
    <FormWrapper title="Informações Gerais" className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <ControlledSelect
            name="location"
            label="Local (poço/estação/satélite)"
            required={true}
            control={control}
            options={[
              { label: 'ORGM-0010', value: 'orgm-0010' },
              { label: 'ORGM-0005', value: 'orgm-0005' },
              { label: 'Estação 3', value: 'estacao-3' },
              { label: 'Satélite', value: 'satelite' },
              { label: 'Estação 4', value: 'estacao-4' }
            ]}
            isMulti={isOilwellFieldMulti}
            errorMessage={errors.location?.message}
            disabled={!isEditable}
            isLoading={isLoading}
          />
          <div className="flex gap-4">
            <Input
              label="Ranking óleo"
              {...register('oil_rank')}
              disabled
              errorMessage={errors.oil_rank?.message}
              isLoading={isLoading}
            />
            <Input
              label="Ranking gás"
              {...register('gas_rank')}
              disabled
              errorMessage={errors.gas_rank?.message}
              isLoading={isLoading}
            />
            <Input
              label="Restrição do Comitê"
              {...register('committee_restriction')}
              disabled
              errorMessage={errors.committee_restriction?.message}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <Input
            label="Método de elevação"
            {...register('elevation_method')}
            disabled
            errorMessage={errors.elevation_method?.message}
            isLoading={isLoading}
          />
          <Input
            label="Campo"
            {...register('field')}
            disabled
            errorMessage={errors.field?.message}
            isLoading={isLoading}
          />
          <Input
            label="Código ANP"
            {...register('anp_code')}
            disabled
            errorMessage={errors.anp_code?.message}
            isLoading={isLoading}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Input
            label="Tipo de fluido"
            {...register('fluid_type')}
            disabled
            errorMessage={errors.fluid_type?.message}
            isLoading={isLoading}
          />
          <Input
            label="Tipo de poço"
            {...register('oilwell_type')}
            disabled
            errorMessage={errors.oilwell_type?.message}
            isLoading={isLoading}
          />
        </div>
        <Checkbox
          label="Atividade crítica"
          tooltip="Consideram-se críticas as atividades que envolvem perda de produção acentuada nos TOP 30 produtores ou que possuem risco de segurança operacional atrelado."
          {...register('critical_activity')}
          disabled={!isEditable}
        />
      </div>
    </FormWrapper>
  );
});

export default GeneralInformationForm;
