import GraphieBold from '@/assets/fonts/ttf/Graphie-Bold.ttf';
import GraphieRegular from '@/assets/fonts/ttf/Graphie-Regular.ttf';
import GraphieSemiBold from '@/assets/fonts/ttf/Graphie-SemiBold.ttf';
import { WellHandoverResponse } from '@/services/wellhandover.services';
import { formatDateTimeISO } from '@/utils/utils';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { createTw } from 'react-pdf-tailwind';
import {
  subsurfaceLongColumn,
  subsurfaceShortColumn,
  surfaceEquipmentsList
} from '../schematicUtils';
import { HeaderPrintPDF } from './HeaderPrintPDF';
import { PrintedFooter } from './PrintedFooter';
import TablePrintPDF from './TablePrintPDF';

interface PrintedReportProps {
  schematic: WellHandoverResponse;
  schematicCanvaImage: any;
  oilwellId: string;
  headerData: any;
  hasTwoColumns: boolean;
}

Font.register({ family: 'Graphie', src: GraphieRegular });
Font.register({ family: 'Graphie', src: GraphieSemiBold });
Font.register({ family: 'Graphie', src: GraphieBold });

const tw = createTw({
  theme: {
    extend: {
      colors: {
        custom: 'cornflowerblue'
      }
    }
  }
});

export const PrintedReport: React.FC<PrintedReportProps> = ({
  schematic,
  schematicCanvaImage,
  oilwellId,
  headerData,
  hasTwoColumns
}) => {
  const parteSuperiorJson = schematic.jsonData.parte_superior || null;
  const parteInferiorJson = schematic.jsonData.parte_inferior || null;
  const packerFluid = schematic.jsonData.strutural_data.packer_fluid || null;

  const commonHeaderStyle = `flex flex-1 pl-2 pt-2 font-bold text-[9px] bg-[#193cb9] border-[#193cb9] text-[#FFFFFF]`;
  const commonBodyStyle = `flex flex-1 pl-2 pt-2 text-[8px]`;
  const restricaoObstrucaoPackerFluidHeaderStyle = `flex flex-1 pl-2 pt-2 text-[9px] bg-[#193cb9] text-[#FFFFFF] rounded-l rounded-r rounded-b-none border-slate-300 min-w-[50px]`;

  const surfaceEquipmentsTableData = {
    header: [
      {
        equipamento: {
          value: 'Equipamentos',
          style: tw(
            `${commonHeaderStyle} rounded-l rounded-r-none rounded-b-none`
          )
        },
        descricao: {
          value: 'Descrição',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r rounded-b-none`
          )
        }
      }
    ],
    body: surfaceEquipmentsList(parteSuperiorJson)
      .map((equip: any) => ({
        equipamento: { value: equip.equipamento, style: tw(commonBodyStyle) },
        descricao: { value: equip.descricao, style: tw(commonBodyStyle) }
      }))
      .sort((a: any, b: any) =>
        a.equipamento.value.localeCompare(b.equipamento.value)
      )
  };

  const subsurfaceLongColumnEquipmentsTableData = {
    header: [
      {
        qtd: {
          value: 'Quantidade',
          style: tw(
            `${commonHeaderStyle} rounded-l rounded-r-none rounded-b-none min-w-[25px]`
          )
        },
        descricao: {
          value: 'Descrição',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none min-w-[200px]`
          )
        },
        od: {
          value: 'OD (in)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none`
          )
        },
        id: {
          value: 'ID (in)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none`
          )
        },
        fabricante: {
          value: 'Fabricante',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none min-w-[50px]`
          )
        },
        profundidade: {
          value: 'Prof.(m)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r rounded-b-none`
          )
        }
      }
    ],
    body: subsurfaceLongColumn(parteInferiorJson, hasTwoColumns).map(
      (equip: any) => ({
        qtd: {
          value: equip.quantidade,
          style: tw(`${commonBodyStyle} min-w-[25px]`)
        },
        descricao: {
          value: equip.descricao,
          style: tw(`${commonBodyStyle} min-w-[200px]`)
        },
        od: { value: equip.diametro_externo, style: tw(commonBodyStyle) },
        id: { value: equip.diametro_interno, style: tw(commonBodyStyle) },
        fabricante: {
          value: equip.fabricante,
          style: tw(`${commonBodyStyle} min-w-[50px]`)
        },
        profundidade: { value: equip.profundidade, style: tw(commonBodyStyle) }
      })
    )
  };

  const subsurfaceShortColumnEquipmentsTableData = {
    header: [
      {
        qtd: {
          value: 'Quantidade',
          style: tw(
            `${commonHeaderStyle} rounded-l rounded-r-none rounded-b-none min-w-[50px]`
          )
        },
        descricao: {
          value: 'Descrição',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none min-w-[200px]`
          )
        },
        od: {
          value: 'OD (in)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none`
          )
        },
        id: {
          value: 'ID (in)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none`
          )
        },
        fabricante: {
          value: 'Fabricante',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none min-w-[50px]`
          )
        },
        profundidade: {
          value: 'Prof.(m)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r rounded-b-none`
          )
        }
      }
    ],
    body: subsurfaceShortColumn(parteInferiorJson, hasTwoColumns).map(
      (equip: any) => ({
        qtd: {
          value: equip.quantidade,
          style: tw(`${commonBodyStyle} min-w-[50px]`)
        },
        descricao: {
          value: equip.descricao,
          style: tw(`${commonBodyStyle} min-w-[200px]`)
        },
        od: { value: equip.diametro_externo, style: tw(commonBodyStyle) },
        id: { value: equip.diametro_interno, style: tw(commonBodyStyle) },
        fabricante: {
          value: equip.fabricante,
          style: tw(`${commonBodyStyle} min-w-[50px]`)
        },
        profundidade: { value: equip.profundidade, style: tw(commonBodyStyle) }
      })
    )
  };

  const peixesTableData = {
    header: [
      {
        descricao: {
          value: 'Descrição do Peixe',
          style: tw(
            `${commonHeaderStyle} rounded-l rounded-r-none rounded-b-none min-w-[180px]`
          )
        },
        topo: {
          value: 'Topo (m)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none`
          )
        },
        base: {
          value: 'Base (m)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none`
          )
        },
        diametro_externo: {
          value: 'OD (Pol)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r-none rounded-b-none`
          )
        },
        diametro_interno: {
          value: 'ID (Pol)',
          style: tw(
            `${commonHeaderStyle} rounded-l-none rounded-r rounded-b-none`
          )
        }
      }
    ],
    body:
      parteInferiorJson.peixes.length > 0
        ? parteInferiorJson.peixes.map((peixe: any) => ({
            descricao: {
              value: peixe.descricao,
              style: tw(`${commonBodyStyle} min-w-[180px]`)
            },
            topo: { value: peixe.topo, style: tw(commonBodyStyle) },
            base: { value: peixe.base, style: tw(commonBodyStyle) },
            diametro_externo: {
              value: peixe.diametro_externo,
              style: tw(commonBodyStyle)
            },
            diametro_interno: {
              value: peixe.diametro_interno,
              style: tw(commonBodyStyle)
            }
          }))
        : [
            {
              descricao: {
                value: 'Não há registro de peixe na coluna',
                style: tw(`${commonBodyStyle} min-w-[180px]`)
              }
            }
          ]
  };

  const restricoesData = {
    header: [
      {
        restricoes: {
          value: 'Restrições',
          style: tw(restricaoObstrucaoPackerFluidHeaderStyle)
        }
      }
    ],
    body:
      parteInferiorJson.restricoes.length > 0
        ? parteInferiorJson.restricoes.map((restricao: any) => ({
            restricoes: {
              value: restricao.descricao,
              style: tw(`${commonBodyStyle} min-w-[50px]`)
            }
          }))
        : [
            {
              restricoes: {
                value: 'Não há registro de restrição da coluna',
                style: tw(`${commonBodyStyle} min-w-[50px]`)
              }
            }
          ]
  };

  const obstrucoesData = {
    header: [
      {
        obstrucoes: {
          value: 'Obstruções',
          style: tw(restricaoObstrucaoPackerFluidHeaderStyle)
        }
      }
    ],
    body:
      parteInferiorJson.obstrucoes.length > 0
        ? parteInferiorJson.obstrucoes.map((obstrucao: any) => ({
            obstrucoes: {
              value: obstrucao.descricao,
              style: tw(`${commonBodyStyle} min-w-[50px]`)
            }
          }))
        : [
            {
              obstrucoes: {
                value: 'Não há registro de obstrução da coluna',
                style: tw(`${commonBodyStyle} min-w-[50px]`)
              }
            }
          ]
  };

  const packerFluidData = {
    header: [
      {
        packer_fluid: {
          value: 'Packer Fluid',
          style: tw(restricaoObstrucaoPackerFluidHeaderStyle)
        }
      }
    ],
    body: [
      {
        packer_fluid: {
          value: packerFluid ? `${packerFluid} lb/gal` : 'Não aplicável',
          style: tw(`${commonBodyStyle} min-w-[50px]`)
        }
      }
    ]
  };

  return (
    <Document style={{ fontFamily: 'Graphie', backgroundColor: '#FFFFFF' }}>
      <Page size="A4">
        <View style={tw('flex flex-col')}>
          <View style={tw('flex mx-10 mt-5')} fixed>
            <HeaderPrintPDF
              title={`Esquemático ${oilwellId ?? ''} ${schematic.approvedDate ? `- ${formatDateTimeISO(schematic.approvedDate)}` : ''}`}
              data={headerData}
              tailwindStyle={tw}
            />
          </View>

          <View
            style={tw('flex flex-row items-center justify-center max-h-[80%]')}
          >
            {schematicCanvaImage && <Image src={schematicCanvaImage} />}
          </View>
        </View>

        <PrintedFooter
          styles={tw('absolute text-xs left-0 right-20 text-right')}
        />
      </Page>

      <Page size="A4" style={tw('flex flex-col max-h-[80%]')}>
        <View style={tw('flex mx-10 mt-5 mb-3')} fixed>
          <HeaderPrintPDF
            title={`Esquemático ${oilwellId ?? ''} ${schematic.approvedDate ? `- ${formatDateTimeISO(schematic.approvedDate)}` : ''}`}
            data={headerData}
            tailwindStyle={tw}
          />
        </View>

        <View style={tw('flex flex-row mx-10')}>
          <View style={tw('flex-1 max-w-[100%]')}>
            <Text style={tw('text-sm font-bold text-[#193cb9]')}>
              Equipamentos de Superfície
            </Text>

            <TablePrintPDF
              data={surfaceEquipmentsTableData}
              tailwindStyle={tw}
            />
          </View>
        </View>

        <View style={tw('flex flex-row mx-10 mt-3')}>
          <View style={tw('flex-1 max-w-[100%] max-h-[90%]')}>
            {subsurfaceLongColumnEquipmentsTableData.body.length > 0 && (
              <>
                {subsurfaceShortColumnEquipmentsTableData.body.length > 0 ? (
                  <Text style={tw('text-sm font-bold text-[#193cb9]')}>
                    Equipamentos de Subsuperfície - Coluna Longa
                  </Text>
                ) : (
                  <Text style={tw('text-sm font-bold text-[#193cb9]')}>
                    Equipamentos de Subsuperfície
                  </Text>
                )}
                <TablePrintPDF
                  data={subsurfaceLongColumnEquipmentsTableData}
                  tailwindStyle={tw}
                />
              </>
            )}

            {subsurfaceShortColumnEquipmentsTableData.body.length > 0 && (
              <>
                <Text style={tw('text-sm font-bold text-[#193cb9] mt-3')}>
                  Equipamentos de Subsuperfície - Coluna Curta
                </Text>
                <TablePrintPDF
                  data={subsurfaceShortColumnEquipmentsTableData}
                  tailwindStyle={tw}
                />
              </>
            )}

            {peixesTableData.body.length > 0 && (
              <View style={tw('mt-3')} break>
                <Text style={tw('text-sm font-bold text-[#193cb9]')}>
                  Peixes
                </Text>
                <TablePrintPDF data={peixesTableData} tailwindStyle={tw} />
              </View>
            )}

            <View style={tw('flex flex-row')} break>
              <View style={tw('flex-1 max-w-[40%]')}>
                <TablePrintPDF data={restricoesData} tailwindStyle={tw} />
              </View>
              <View style={tw('flex-1 ml-2.5 max-w-[40%]')}>
                <TablePrintPDF data={obstrucoesData} tailwindStyle={tw} />
              </View>
              <View style={tw('flex-1 ml-2.5 max-w-[20%]')}>
                <TablePrintPDF data={packerFluidData} tailwindStyle={tw} />
              </View>
            </View>
          </View>
        </View>

        <PrintedFooter
          styles={tw('absolute text-xs left-0 right-20 text-right')}
        />
      </Page>
    </Document>
  );
};
