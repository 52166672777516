import conectorDireitaChart from '@/assets/schematic/conector-camada-direita.svg';
import conectorEsquerdaChart from '@/assets/schematic/conector-camada-esquerda.svg';
import conectorMeioChart from '@/assets/schematic/conector-camada-meio.svg';
import { Sprite } from '@pixi/react';

export const SchematicValvesConector: React.FC<{
  size: number;
  y: number;
  center?: number;
  height?: number;
}> = ({ size, y, center = 0, height }) => {
  const positionLeft = center - size / 2;
  const positionRight = center + size / 2;

  return (
    <>
      <Sprite
        scale={1}
        anchor={{ x: 0, y: 1 }}
        x={positionLeft}
        y={y}
        height={height}
        image={conectorEsquerdaChart}
      />
      <Sprite
        scale={1}
        anchor={{ x: 0.5, y: 1 }}
        x={center}
        y={y}
        width={size - 16}
        height={height}
        image={conectorMeioChart}
      />
      <Sprite
        scale={1}
        anchor={{ x: 1, y: 1 }}
        x={positionRight}
        y={y}
        height={height}
        image={conectorDireitaChart}
      />
    </>
  );
};
