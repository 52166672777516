import arvoreNatalSvg from '@/assets/schematic/arvore-de-natal.svg';
import blankingPlugSvg from '@/assets/schematic/blanking-plug.svg';
import bprSvg from '@/assets/schematic/bpr.svg';
import bpvSvg from '@/assets/schematic/bpv.svg';
import cabecoteBcpSvg from '@/assets/schematic/cabecote-bcp.svg';
import colchaoDeAreiaSvg from '@/assets/schematic/colchao-de-areia.svg';
import conexaoEntreColunasSvg from '@/assets/schematic/conexao-entre-colunas.svg';
import conexaoValvulaSvg from '@/assets/schematic/conexao-valvula.svg';
import dhsvSvg from '@/assets/schematic/dhsv.svg';
import flangeCegoSvg from '@/assets/schematic/flange-cego.svg';
import furoRevestimentoDireitoSvg from '@/assets/schematic/furo-no-revestimento-direito.svg';
import furoRevestimentoEsquerdoSvg from '@/assets/schematic/furo-no-revestimento-esquerdo.svg';
import intervaloAbertoDireitoSvg from '@/assets/schematic/intervalo-aberto-direito.svg';
import intervaloAbertoEsquerdoSvg from '@/assets/schematic/intervalo-aberto-esquerdo.svg';
import intervaloIsoladoDireitoSvg from '@/assets/schematic/intervalo-isolado-direito.svg';
import intervaloIsoladoEsquerdoSvg from '@/assets/schematic/intervalo-isolado-esquerdo.svg';
import intervaloSqueezadoDireitoSvg from '@/assets/schematic/intervalo-squeezado-direito.svg';
import intervaloSqueezadoEsquerdoSvg from '@/assets/schematic/intervalo-squeezado-esquerdo.svg';
import mandrilComVglSvg from '@/assets/schematic/mandril-com-vgl.svg';
import mandrilSemVglSvg from '@/assets/schematic/mandril-sem-vgl.svg';
import manometroDhsvSvg from '@/assets/schematic/manometro-dhsv.svg';
import nippleSvg from '@/assets/schematic/nipple.svg';
import packerSvg from '@/assets/schematic/packer.svg';
import peixeSvg from '@/assets/schematic/peixe.svg';
import restricaoObstrucaoSvg from '@/assets/schematic/restricao-obstrucao.svg';
import slidingSleeveAbertaSvg from '@/assets/schematic/sliding-sleeve-aberta.svg';
import slidingSleeveFechadaSvg from '@/assets/schematic/sliding-sleeve-fechada.svg';
import standingValveSvg from '@/assets/schematic/standing-valve.svg';
import tampaoDbSvg from '@/assets/schematic/tampao-db.svg';
import tampaoDeCimentoSvg from '@/assets/schematic/tampao-de-cimento.svg';
import unidadeDeBombeioSvg from '@/assets/schematic/unidade-de-bombeio.svg';

import {
  default as valvulaHorizontalSvg,
  default as valvulaVerticalSvg
} from '@/assets/schematic/valvula.svg';
import type {
  EquipamentoDeSuperficie,
  ParteInferior,
  ParteSuperior,
  Revestimentos
} from './interface';
import { GetCustomPixiText } from './PixiText';
import ScaleUtils from './ScaleUtils';
import { SchematicReferenceConstants } from './SchematicReferenceConstants';
import { RevestimentsAndDiameters } from './subsurface/Components/Revestiment';
import {
  KOPRightText,
  MechanicalConditionLeftTexts,
  SubsurfaceRightTexts
} from './subsurface/SchematicOilWellMechanicalConditions';
import { recalculateTextHeights, TextItem } from './TextsUtils';

export const PixiBackgroundMargin = 10;

export const cabecoteBcp = {
  width: 52,
  height: 132,
  image: cabecoteBcpSvg,
  anchor: 0.7
};

export const unidadeDeBombeio = {
  width: 100,
  height: 98,
  image: unidadeDeBombeioSvg,
  anchor: 0.98
};

export const arvoreNatal = {
  width: 46,
  height: 56,
  image: arvoreNatalSvg,
  anchor: 0.5
};

export const valvulaVertical = {
  width: 48,
  height: 60,
  image: valvulaVerticalSvg
};

export const valvulaHorizontal = {
  width: 60,
  height: 48,
  image: valvulaHorizontalSvg
};

export const mandrilSemVgl = {
  width: 8,
  height: 25,
  image: mandrilSemVglSvg,
  representationType: ['B']
};

export const mandrilComVgl = {
  width: 8,
  height: 25,
  image: mandrilComVglSvg,
  representationType: ['B']
};

export const packer = {
  width: 24,
  height: 6,
  image: packerSvg,
  representationType: ['B']
};

export const dhsv = {
  width: 36,
  height: 36,
  image: dhsvSvg,
  representationType: ['I']
};

export const slidingSleeveAberta = {
  width: 37,
  height: 24,
  image: slidingSleeveAbertaSvg,
  representationType: ['I']
};

export const slidingSleeveFechada = {
  width: 37,
  height: 24,
  image: slidingSleeveFechadaSvg,
  representationType: ['I']
};

export const standingValve = {
  width: 18,
  height: 25,
  image: standingValveSvg,
  representationType: ['I']
};

export const bpv = {
  width: 37,
  height: 41,
  image: bpvSvg,
  representationType: ['I']
};

export const tampaoDb = {
  width: 18,
  height: 31,
  image: tampaoDbSvg,
  representationType: ['I']
};

export const blankingPlug = {
  width: 27,
  height: 39,
  image: blankingPlugSvg,
  representationType: ['I']
};

export const conexaoEntreColunas = {
  width: 12,
  height: 25,
  image: conexaoEntreColunasSvg
};

export const conexaoValvula = {
  width: 20,
  height: 10,
  image: conexaoValvulaSvg
};

export const intervaloAbertoEsquerdo = {
  width: 56,
  height: 18,
  image: intervaloAbertoEsquerdoSvg,
  representationType: ['E']
};

export const intervaloAbertoDireito = {
  width: 56,
  height: 18,
  image: intervaloAbertoDireitoSvg,
  representationType: ['E']
};

export const intervaloIsoladoEsquerdo = {
  width: 56,
  height: 18,
  image: intervaloIsoladoEsquerdoSvg,
  representationType: ['E']
};

export const intervaloIsoladoDireito = {
  width: 56,
  height: 18,
  image: intervaloIsoladoDireitoSvg,
  representationType: ['E']
};

export const tampaoDeCimento = {
  width: 126,
  height: 13,
  image: tampaoDeCimentoSvg,
  representationType: ['B', 'I', 'E']
};

export const bpr = {
  width: 126,
  height: 13,
  image: bprSvg,
  representationType: ['B', 'I', 'E']
};

export const intervaloSqueezadoEsquerdo = {
  width: 56,
  height: 18,
  image: intervaloSqueezadoEsquerdoSvg,
  representationType: ['E']
};

export const intervaloSqueezadoDireito = {
  width: 56,
  height: 18,
  image: intervaloSqueezadoDireitoSvg,
  representationType: ['E']
};

export const colchaoDeAreia = {
  width: 135,
  height: 37,
  image: colchaoDeAreiaSvg,
  representationType: ['B', 'I', 'E']
};

export const nipple = {
  width: 36,
  height: 20,
  image: nippleSvg,
  representationType: ['I']
};

export const restricaoObstrucao = {
  width: 19,
  height: 17,
  image: restricaoObstrucaoSvg,
  representationType: ['B', 'I']
};

export const peixe = {
  width: 23,
  height: 13,
  image: peixeSvg,
  representationType: ['B', 'I']
};

export const furoRevestimentoEsquerdo = {
  width: 56,
  height: 18,
  image: furoRevestimentoEsquerdoSvg,
  representationType: ['E']
};

export const furoRevestimentoDireito = {
  width: 56,
  height: 18,
  image: furoRevestimentoDireitoSvg,
  representationType: ['E']
};

export const topSchematicElements: any = {
  'arvore-de-natal': arvoreNatal,
  'cabecote-bcp': cabecoteBcp,
  'unidade-de-bombeio': unidadeDeBombeio
};

export const surfaceElementsKeyMapping: any = {
  'Árvore de Natal': 'arvore-de-natal',
  'Cabeçote BCP': 'cabecote-bcp',
  'Unidade de Bombeio': 'unidade-de-bombeio'
};

export const getTopSchematicElement = (elementName: string) => {
  return topSchematicElements[normalizeTypeKey(elementName)];
};

export const filterTopSchematicElements = (
  elements: EquipamentoDeSuperficie[]
) => {
  const keys = Object.keys(topSchematicElements);
  return elements.filter(element =>
    keys.includes(surfaceElementsKeyMapping[element.tipo])
  );
};

export const normalizeTypeKey = (elementName: string): string => {
  return elementName
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-');
};

export const subsurfaceSchematicElements: any = {
  'mandril-sem-vgl': [mandrilSemVgl],
  'mandril-com-vgl': [mandrilComVgl],
  packer: [packer],
  dhsv: [dhsv],
  'sliding-sleeve-aberta': [slidingSleeveAberta],
  'sliding-sleeve-fechada': [slidingSleeveFechada],
  'standing-valve': [standingValve],
  bpv: [bpv],
  'tampao-db': [tampaoDb],
  'blanking-plug': [blankingPlug],
  nipple: [nipple],
  'intervalo-aberto': [intervaloAbertoEsquerdo, intervaloAbertoDireito],
  'intervalo-isolado': [intervaloIsoladoEsquerdo, intervaloIsoladoDireito],
  'intervalo-squeezado': [
    intervaloSqueezadoEsquerdo,
    intervaloSqueezadoDireito
  ],
  recimentacao: [intervaloSqueezadoEsquerdo, intervaloSqueezadoDireito],
  bpr: [bpr],
  'tampao-de-cimento': [tampaoDeCimento],
  'colchao-de-areia': [colchaoDeAreia],
  obstrucao: [restricaoObstrucao],
  restricao: [restricaoObstrucao],
  peixe: [peixe],
  bpp: [bpr],
  'furo-no-revestimento': [furoRevestimentoEsquerdo, furoRevestimentoDireito],
};

export const flange_or_dhsv_type_to_csv_name: any = {
  flange: flangeCegoSvg,
  dhsv: manometroDhsvSvg
};

export type AnularesKeysTypes = keyof ParteSuperior['anulares'];

export const subsurfaceElementsKeyMapping: any = {
  dhsv: 'dhsv',
  'mandril - vgl cega': 'mandril-com-vgl',
  'mandril - vgl de pressão': 'mandril-com-vgl',
  'mandril - vgl orifício': 'mandril-com-vgl',
  'mandril de gás lift': 'mandril-sem-vgl',
  packer: 'packer',
  'sliding sleeve - aberta': 'sliding-sleeve-aberta',
  'sliding sleeve - fechada': 'sliding-sleeve-fechada',
  'standing valve': 'standing-valve',
  bpv: 'bpv',
  'tampão db': 'tampao-db',
  'blanking plug': 'blanking-plug',
  nipple: 'nipple',
  'intervalo aberto': 'intervalo-aberto',
  'intervalo isolado': 'intervalo-isolado',
  'intervalo squeezado': 'intervalo-squeezado',
  'furo no revestimento': 'furo-no-revestimento',
  recimentação: 'recimentacao',
  'revestimento rasgado': 'revestimento-rasgado',
  bpr: 'bpr',
  'tampão de cimento': 'tampao-de-cimento',
  'colchão de areia': 'colchao-de-areia',
  restricoes: 'restricao',
  obstrucoes: 'obstrucao',
  peixes: 'peixe',
  bpp: 'bpp'
};

// criar elementors virtuais a partir dos elementos reais ajustando o topo de acordo com o 'height'
export interface VirtualElement {
  id: number;
  // E: elemento externo a coluna de produção,
  // I1: elemento interno a coluna de produção longa,
  // I2: elemento interno a coluna de produção curta,
  // B: elemento externo entre as colunas de produção e os revestimentos,
  // X: ignorado
  representationType: Array<string>;
  tipo: string;
  height: number;
  topo: number;
  base: number;
  originalElement: any;
}

// export interface RepresentationType {<'E' | 'I1' | 'I2' | 'B'>: number | null};

// inicializa o hash que mapeia posições
export const createPositionMapper = (length: number) => {
  const positionMapper: {
    [key: number]: {
      I1: null | any;
      I2: null | any;
      E: null | any;
      B: null | any;
      X: null | any;
    };
  } = {};
  for (let i = 0; i <= length; i++) {
    positionMapper[i] = { I1: null, I2: null, E: null, B: null, X: null };
  }
  return positionMapper;
};

export const createVirtualElements = (
  equipmentList: any,
  scaleMultiplier: number,
  hasTwoColumns: boolean
): VirtualElement[] => {
  const equipmentsToBeRendered = (element: any, key: string) => {
    let condition = true;

    if (element.coluna_de_producao && !hasTwoColumns) {
      condition =
        element.coluna_de_producao.toLowerCase() === 'longa' ||
        element.coluna_de_producao.toLowerCase() === 'única';
    }

    if (!element.tipo) {
      element.tipo = key;
      element.profundidade = element.topo;
    }

    return condition && subsurfaceElementsKeyMapping[element.tipo.toLowerCase()]
      ? element
      : null;
  };

  const defineRepresentationType = (
    originalRepresentationType: Array<string>,
    element: any
  ) => {
    const representationTypeMap = originalRepresentationType.map(
      (type: string) => {
        if (type === 'I') {
          if (!element.coluna_de_producao) {
            return ['I1', 'I2'];
          }

          return element.coluna_de_producao.toLowerCase() === 'longa' ||
            element.coluna_de_producao.toLowerCase() === 'única'
            ? 'I1'
            : 'I2';
        }

        return type;
      }
    );

    return representationTypeMap.flat();
  };

  const mapEquipmentsWithGraphicRepresentation = (
    equipmentList: any,
    key: string
  ) => {
    return equipmentList.filter((element: any) =>
      equipmentsToBeRendered(element, key)
    );
  };

  const topoElemento = (element: any, scaleMultiplier: number) => {
    if (
      element.intervalo_superior === 0 ||
      element.intervalo_superior === null
    ) {
      return element.profundidade * scaleMultiplier;
    }

    return element.intervalo_superior * scaleMultiplier;
  };

  const diferencaEntreTopoEBaseIntervalo = (
    firstHeight: number,
    secondHeight: number,
    tipo: string,
    scaleMultiplier: number
  ) => {
    const svgElement =
      subsurfaceSchematicElements[
        subsurfaceElementsKeyMapping[tipo.toLowerCase()]
      ][0];
    const difference = Math.abs(secondHeight - firstHeight);

    return svgElement && difference < svgElement.height
      ? svgElement.height * scaleMultiplier
      : difference * scaleMultiplier;
  };

  const defineHeight = (
    element: any,
    scaleMultiplier: number,
    svgHeight: number
  ) => {
    const tipo = subsurfaceElementsKeyMapping[element.tipo.toLowerCase()];
    let newHeight = 0;
    switch (tipo) {
      case 'tampao-de-cimento':
        newHeight = diferencaEntreTopoEBaseIntervalo(
          element.intervalo_superior,
          element.intervalo_inferior,
          element.tipo,
          scaleMultiplier
        );
        break;
      default:
        newHeight = svgHeight;
        break;
    }
    return newHeight;
  };

  const defineTopo = (element: any, scaleMultiplier: number) => {
    const tipo = subsurfaceElementsKeyMapping[element.tipo.toLowerCase()];
    let newTopo = 0;
    switch (tipo) {
      case 'intervalo-aberto':
      case 'intervalo-isolado':
      case 'intervalo-squeezado':
      case 'tampao-de-cimento':
      case 'recimentacao':
      case 'furo-no-revestimento':
        newTopo = topoElemento(element, scaleMultiplier);
        break;
      default:
        newTopo =
          (element.topo ? element.topo : element.profundidade) *
          scaleMultiplier;
        break;
    }
    return newTopo;
  };

  const jsonObjectKeysToIterate = [
    'equipamento_de_subsuperficie',
    'condicoes_mecanicas',
    'obstrucoes',
    'restricoes',
    'peixes'
  ];
  let equipmentsWithGraphicRepresentation = Array<VirtualElement>();
  jsonObjectKeysToIterate.forEach((key: any) => {
    equipmentsWithGraphicRepresentation =
      equipmentsWithGraphicRepresentation.concat(
        mapEquipmentsWithGraphicRepresentation(equipmentList[key], key)
      );
  });

  return equipmentsWithGraphicRepresentation.map(
    (element: any, index: number) => {
      const svgElements =
        subsurfaceSchematicElements[
          subsurfaceElementsKeyMapping[element.tipo.toLowerCase()]
        ];
      let svgRepresentationType = ['X'];
      let svgHeight = 0;

      if (svgElements) {
        svgElements.forEach((svgElement: any) => {
          svgHeight = svgElement.height;
          svgRepresentationType = defineRepresentationType(
            svgElement.representationType,
            element
          );
        });
      }

      const newElementHeight = Math.floor(
        defineHeight(element, scaleMultiplier, svgHeight)
      );
      const newElementTopo = Math.floor(defineTopo(element, scaleMultiplier));
      return {
        id: index,
        representationType: svgRepresentationType,
        tipo: subsurfaceElementsKeyMapping[element.tipo.toLowerCase()],
        height: newElementHeight,
        topo: newElementTopo,
        originalElement: element,
        base: 0
      };
    }
  );
};

// iterar nas chaves do posMapper, verificando tanto em I quanto em E se tem algo a ser representado que não esteja no 'renderedElements'
// e caso tenha, adicione o msm na lista de 'renderedElements' e chame a função 'representElement' passando o elemento
export const renderMappedElements = (
  virtualElements: any,
  positionMapper: any
) => {
  const renderedElements = new Set();
  const representElements: Array<VirtualElement> = [];
  // E: elemento externo a coluna de produção,
  // I1: elemento interno a coluna de produção longa,
  // I2: elemento interno a coluna de produção curta,
  // B: elemento externo entre as colunas de produção e os revestimentos,
  // X: ignorado
  const mapOptions = ['E', 'I1', 'I2', 'B', 'X'];

  for (const key in positionMapper) {
    mapOptions.forEach(type => {
      if (
        positionMapper[key][type] !== null &&
        !renderedElements.has(positionMapper[key][type])
      ) {
        renderedElements.add(positionMapper[key][type]);
        representElements.push({
          ...virtualElements[positionMapper[key][type]],
          topo: Number(key)
        });
      }
    });
  }

  return representElements;
};

export const getLastElementScaleMultiplier = (
  subsurfaceEquipments: VirtualElement[],
  maxDepthRevestiment: number
) => {
  const lastSubsurfaceEquipment = subsurfaceEquipments.sort(
    (a: any, b: any) => a.base - b.base
  )?.[subsurfaceEquipments.length - 1];

  return lastSubsurfaceEquipment?.base / maxDepthRevestiment;
};

const groupByExternalDiameter = (revestimentos: Revestimentos) => {
  const groups = new Map<number, any[]>();

  revestimentos.forEach((revestiment: any) => {
    const externalDiameter = revestiment.diametro_externo;

    if (!groups.has(externalDiameter)) {
      groups.set(externalDiameter, []);
    }

    groups.get(externalDiameter)?.push(revestiment);
  });

  const groupsArray = Array.from(groups.entries())
    .sort(([diamA], [diamB]) => diamA - diamB)
    .map(([_, revestiments]) => {
      return revestiments.sort(
        (a: any, b: any) => a.profundidade - b.profundidade
      );
    });

  return groupsArray;
};

export const getRevestimentsAndDiameters = (
  revestimentos: Revestimentos,
  schematicReferenceConstants: SchematicReferenceConstants
): RevestimentsAndDiameters => {
  const groups = groupByExternalDiameter(revestimentos);

  return groups
    .map((revestiments, index) => ({
      width:
        schematicReferenceConstants.wellDiameter +
        2 * schematicReferenceConstants.OneRevestiment * (index + 1),
      revestiments
    }))
    .reverse();
};

export const getRevestimentObjects = (
  revestimentos: Revestimentos,
  schematicReferenceConstants: SchematicReferenceConstants
) => {
  const revestimetsAndDiameters = getRevestimentsAndDiameters(
    revestimentos,
    schematicReferenceConstants
  );

  const revestimentsArray = revestimetsAndDiameters.map(
    ({ width, revestiments }) => {
      const maxDepht = Math.max(
        ...revestiments.map((x: any) => x.profundidade)
      );

      return {
        width: width,
        depht: maxDepht,
        cimentacoes: revestiments.flatMap((x: any) =>
          x.cimentacoes.map((cimentacao: any) => {
            return {
              width: width,
              topo: cimentacao.topo,
              base: cimentacao.base
            };
          })
        ),
        sapata: {
          width: width,
          base: maxDepht
        }
      };
    }
  );

  const totalMaxDepht = Math.max(...revestimentsArray.map((x: any) => x.depht));

  return {
    revestiments: revestimentsArray,
    totalMaxDepht
  };
};

const getLeftTextsWithCollisions = (
  parteInferior: ParteInferior,
  schematicReferenceConstants: SchematicReferenceConstants,
  scaleUtils: ScaleUtils
): TextItem[] => {
  let texts = [];

  const revestimetsAndDiameters = getRevestimentsAndDiameters(
    parteInferior.revestimento,
    schematicReferenceConstants
  );

  const lastRevestimentAndDiameter =
    revestimetsAndDiameters[revestimetsAndDiameters.length - 1];
  const lastRevestiment =
    lastRevestimentAndDiameter.revestiments[
      lastRevestimentAndDiameter.revestiments.length - 1
    ];

  const colarFlutuante = parteInferior.condicoes_mecanicas.find(
    (x: any) => normalizeTypeKey(x.tipo) === 'colar-flutuante'
  );

  let sapataGuiaText = `Sapata Guia Topo: ${lastRevestiment.topo} m Base: ${lastRevestiment.profundidade} m`;

  if (colarFlutuante) {
    sapataGuiaText = `Sapata Guia Topo: ${colarFlutuante.profundidade} m Base: ${lastRevestiment.profundidade} m`;
  }

  const faseDePerfuracaoTexts = parteInferior.brocas.map((broca: any) => {
    const text = `Fase de perfuração ${broca.diametro ?? 'N/A'} @ ${broca.profundidade ?? 'N/A'} m`;

    const pixiText = GetCustomPixiText({
      text: text,
      fontFamily: 'Graphie',
      fontStyle: 'normal',
      fontSize: 11,
      textColor: 0x000000,
      wordWrapWidth: 295
    });

    return new TextItem(
      pixiText,
      scaleUtils.rescaleHeight(broca.profundidade),
      PixiBackgroundMargin
    );
  });

  texts.push(...faseDePerfuracaoTexts);

  const revestimentsAndDiamatersTexts: TextItem[] =
    revestimetsAndDiameters.flatMap(
      ({ width, revestiments }, index: number) => {
        return revestiments.map((revestiment: any) => {
          const revestimentText = `Revestimento ${revestiment.diametro_externo_txt} Grau: ${revestiment.grau}\nPeso: ${revestiment.peso} lb/ft\n@Topo: ${revestiment.topo} m Base: ${revestiment.profundidade} m`;

          const cimentacoesText = revestiment.cimentacoes
            .map(
              (cimentacao: any) =>
                `Cimentação Rev. ${revestiment.diametro_externo_txt}\nTopo: ${cimentacao.topo} m Base: ${cimentacao.base} m`
            )
            .join('\n');

          const finalText = cimentacoesText
            ? `${revestimentText}\n\n${cimentacoesText}`
            : revestimentText;

          const text = GetCustomPixiText({
            text: finalText,
            fontFamily: 'Graphie',
            fontStyle: 'normal',
            fontSize: 11,
            textColor: 0x000000,
            wordWrapWidth: 295
          });
          return new TextItem(
            text,
            scaleUtils.rescaleHeight(revestiment.profundidade),
            PixiBackgroundMargin
          );
        });
      }
    );

  texts.push(...revestimentsAndDiamatersTexts);
  texts.push(...MechanicalConditionLeftTexts(parteInferior, scaleUtils));

  if (sapataGuiaText) {
    const sapataText = GetCustomPixiText({
      text: sapataGuiaText,
      fontFamily: 'Graphie',
      fontStyle: 'normal',
      fontSize: 11,
      textColor: 0xc1981b,
      wordWrapWidth: 440
    });

    texts.push(
      new TextItem(
        sapataText,
        scaleUtils.rescaleHeight(lastRevestiment.profundidade + 1), // + 1 pra garantir que ele esta sempre na ultima posição
        PixiBackgroundMargin
      )
    );
  }

  return texts;
};

export const getLeftTexts = (
  parteInferior: ParteInferior,
  schematicReferenceConstants: SchematicReferenceConstants,
  scaleUtils: ScaleUtils,
  distanceBetweenLeftTexts: number
) => {
  const texts = getLeftTextsWithCollisions(
    parteInferior,
    schematicReferenceConstants,
    scaleUtils
  );

  return recalculateTextHeights(texts, distanceBetweenLeftTexts);
};

export const getRightTexts = (
  subsurfaceEquipments: VirtualElement[],
  distanceBetweenLeftTexts: number,
  kop: number | null,
  scaleByLastElement: number
) => {
  const texts = SubsurfaceRightTexts(subsurfaceEquipments);

  if (kop) {
    const kopText = KOPRightText(kop, scaleByLastElement);
    texts.push(kopText);
  }

  return recalculateTextHeights(texts, distanceBetweenLeftTexts);
};

export interface DepthsColunasDeProducao {
  depthLonga: number;
  depthCurta: number;
}

export const surfaceEquipmentsList = (parteSuperiorJson: any) => {
  const surfaceEquipments: any[] = [];

  Object.keys(parteSuperiorJson.anulares).forEach(key => {
    const anular =
      parteSuperiorJson.anulares[
        key as keyof typeof parteSuperiorJson.anulares
      ];

    ['normal', 'oposta'].forEach(position => {
      const valvula = anular[position as keyof typeof anular];
      if (valvula && valvula.valvula_info && key === 'A') {
        surfaceEquipments.push({
          equipamento: `Válvula de Acesso Anular ${key} ${position === 'oposta' ? 'Oposta' : ''} | ${valvula.valvula_info.fabricante}`,
          descricao: `${valvula.valvula_info.modelo} | ${valvula.valvula_info.pressao} | ${valvula.valvula_info.diametro}`,
          ...valvula.valvula_info
        });
      }
    });
  });

  parteSuperiorJson.equipamento_de_superficie.forEach(
    (equipamento: any) => {
      surfaceEquipments.push({
        equipamento: `${equipamento.tipo} | ${equipamento.fabricante}`,
        descricao: `${equipamento.descricao} ${equipamento.condicao ? `| ${equipamento.condicao}` : ''}`
      });
    }
  );

  return surfaceEquipments;
}

export const subsurfaceLongColumn = (parteInferiorJson: any, hasTwoColumns: boolean) => { 
  if (hasTwoColumns) {
    return parteInferiorJson.equipamento_de_subsuperficie
      .filter(
        (x: any) =>
          x.coluna_de_producao.toLowerCase() == 'longa' ||
          x.coluna_de_producao.toLowerCase() == 'única'
      )
      .map((equipamento: any) => ({
        ...equipamento,
        descricao: `${equipamento.tipo} - ${equipamento.descricao}`
      }));
  }

  return parteInferiorJson.equipamento_de_subsuperficie;
}

export const subsurfaceShortColumn = (parteInferiorJson: any, hasTwoColumns: boolean) => { 
  if (hasTwoColumns) {
    return parteInferiorJson.equipamento_de_subsuperficie.filter(
      (x: any) => x.coluna_de_producao == 'curta'
    );
  }

  return [];
}