import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { postTanks } from '../../../services/oilwells.services';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { mapperOptions, mapperOptionsTextConstants } from '../../../utils/mdm';
import { useQuery } from '@tanstack/react-query';
import { getAllFacilities } from '@/services/apiMdm';

type CreateTank = {
  name: string;
  type: string;
  oilFieldId: string;
  classification: string;
  volume: number;
  code: string;
  serialNumber: string;
  measurementType: string;
  measurementStatus: boolean;
  facilityId: number;
};

const CreateTank: React.FC = () => {
  const { fields, constants } = useMdmUtils({
    fields: true,
    constants: true
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateTank>();

  const { data: facilities } = useQuery({
    queryKey: ['facilities'],
    queryFn: () => getAllFacilities(0, 1000, '')
  });

  const onSubmit = async (data: CreateTank) => {
    try {
      await postTanks({
        ...data,
        oilField: { connect: { id: Number(data.oilFieldId) } },
        volume: Number(data.volume) || 0,
        measurementStatus:
          (data?.measurementStatus?.toString() ?? 'true') === 'true'
      });
      await queryClient.invalidateQueries(['measuring-tank-adm']);

      toast.success('Tanque de medição criado com sucesso');
    } catch (e) {
      toast.error('Erro ao criar tanque de medição');
    }
  };

  return (
    <MdmLayout title={'Criar Tanque de Medição'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          required
          placeholder="Nome"
          {...register('name', { required: true })}
        />

        <Input
          label="Código"
          required
          placeholder="Nome"
          {...register('code', { required: true })}
        />

        <Input
          label="Numero de Série"
          placeholder="NS"
          {...register('serialNumber')}
        />

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Campo"
            placeholder="Campo"
            control={control}
            name={'oilFieldId'}
            required
            options={fields?.map((field: any) => ({
              value: field.id,
              label: field.name
            }))}
          />

          <Input
            label="Volume Total (m³)"
            required
            placeholder="Volume Total (m³)"
            {...register('volume', { required: true })}
          />
        </div>

        <SelectInput
          label="Instalação"
          placeholder="Instalação"
          control={control}
          name={'facilityId'}
          required
          options={
            facilities?.items?.map((facility: any) => ({
              value: facility.id,
              label: facility.name
            })) ?? []
          }
        />

        <SelectInput
          label="Tipo de Medição"
          placeholder="Tipo de Medição"
          control={control}
          name={'measurementType'}
          options={mapperOptionsTextConstants(constants?.TankMeasurementType)}
        />

        <SelectInput
          label="Classificação"
          placeholder="Classificação"
          control={control}
          name={'classification'}
          required
          options={mapperOptions(constants.MeasuringTankClassification)}
        />

        <SelectInput
          label="Status de Medição"
          placeholder="Status de Medição"
          control={control}
          name={'measurementStatus'}
          options={[
            { value: 'true', label: 'Ativo' },
            { value: 'false', label: 'Inativo' }
          ]}
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Salvar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateTank;
