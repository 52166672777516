import { Text, View } from '@react-pdf/renderer';
import React from 'react';

interface TablePrintPDFProps {
  data: any;
  tailwindStyle: any;
}

const TablePrintPDF: React.FC<TablePrintPDFProps> = props => {
  const tableRowHeaderStyleDefault = props.tailwindStyle(`flex flex-row text-[9px] font-bold text-left`);
  const tableCellStyleDefault = props.tailwindStyle(`flex flex-1 p-2 text-[9px]`);
  const tableRowStyleEvenDefault = props.tailwindStyle(`flex flex-row text-[9px] border-l border-r border-b border-slate-200 bg-[#FFFFFF]`);
  const tableRowStyleOddDefault = props.tailwindStyle(`flex flex-row text-[9px] border-l border-r border-b border-slate-200 bg-[#EDF0FA]`);
  const tableContainerStyle = props.tailwindStyle(`w-full text-base font-normal rounded mt-2`);

  return (
    <>
      <View style={tableContainerStyle}>
        {props.data.header.map((row: any, index: number) => (
          <View key={index} style={tableRowHeaderStyleDefault}>
            {Object.entries(row).map(([key, cell]: [string, any], cellIndex: number) => (
              <Text key={cellIndex} style={props.data.header[index][key].style || tableCellStyleDefault}>
                {cell.value ? cell.value : '-'}
              </Text>
            ))}
          </View>
        ))}
        {props.data.body.map((row: any, index: number) => (
          <View key={index} style={index % 2 === 0 ? tableRowStyleEvenDefault : tableRowStyleOddDefault} wrap={false}>
            {Object.entries(row).map(([key, cell]: [string, any], cellIndex: number) => (
              <Text key={cellIndex} style={props.data.body[index][key].style || tableCellStyleDefault}>
                {cell.value ? cell.value : '-'}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </>
  );
};

export default TablePrintPDF;
