import { optionSchema } from '@/pages/FormsPage/optionSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { OpeningType } from './OpeningType.enum';

const schema = z
  .object({
    type: optionSchema(true),
    current_opening: z.string().min(1, { message: 'Este campo é obrigatório' }),
    suggested_opening: z
      .string()
      .min(1, { message: 'Este campo é obrigatório' }),
    opening_type: optionSchema(true),
    operational_sequence_details: z.string(),
    reason: z.string().min(1, { message: 'Este campo é obrigatório' }),
    attachments: z.array(z.instanceof(File)).optional()
  })
  .superRefine((data, ctx) => {
    if (
      data.opening_type.value === OpeningType.RAMPA &&
      !data.operational_sequence_details
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['operational_sequence_details'],
        message: 'Este campo é obrigatório quando o Tipo de abertura é Rampa'
      });
    }
  });

export type CreateAlteracaoBeanFormData = z.infer<typeof schema>;

const useCreateAlteracaoBeanForm = () => {
  const methods = useForm<CreateAlteracaoBeanFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    console.log('load saved data');
    if (data) {
      console.log('data received');
      setValue('current_opening', data?.current_opening);
      setValue('suggested_opening', data?.suggested_opening);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateAlteracaoBeanForm;
