export class SchematicReferenceConstants {
  constructor(
    readonly _QUARTEPACKER: number,
    readonly _TopoDoPoco: number,
    readonly _OneRevestiment: number,
    readonly _wellDiameter: number,
    readonly _middlePosition: number
  ) {}

  get QUARTEPACKER(): number {
    return this._QUARTEPACKER;
  }

  get TopoDoPoco(): number {
    return this._TopoDoPoco;
  }

  get OneRevestiment(): number {
    return this._OneRevestiment;
  }

  get wellDiameter(): number {
    return this._wellDiameter;
  }

  get middlePosition(): number {
    return this._middlePosition;
  }
}

export class SchematicReferenceConstantsBuilder {
  private _QUARTEPACKER: number | null = null;
  private _TopoDoPoco: number | null = null;
  private _OneRevestiment: number | null = null;
  private _wellDiameter: number | null = null;
  private _middlePosition: number | null = null;

  set QUARTEPACKER(value: number) {
    this._QUARTEPACKER = value;
  }

  set TopoDoPoco(value: number) {
    this._TopoDoPoco = value;
  }

  set OneRevestiment(value: number) {
    this._OneRevestiment = value;
  }

  set wellDiameter(value: number) {
    this._wellDiameter = value;
  }

  set middlePosition(value: number) {
    this._middlePosition = value;
  }

  unsafeTryBuild(): SchematicReferenceConstants {
    if (this._QUARTEPACKER === null) {
      throw new Error('O campo QUARTEPACKER é obrigatório');
    }
    if (this._TopoDoPoco === null) {
      throw new Error('O campo TopoDoPoco é obrigatório');
    }
    if (this._OneRevestiment === null) {
      throw new Error('O campo OneRevestiment é obrigatório');
    }
    if (this._wellDiameter === null) {
      throw new Error('O campo wellDiameter é obrigatório');
    }
    if (this._middlePosition === null) {
      throw new Error('O campo middlePosition é obrigatório');
    }

    return new SchematicReferenceConstants(
      this._QUARTEPACKER,
      this._TopoDoPoco,
      this._OneRevestiment,
      this._wellDiameter,
      this._middlePosition
    );
  }
}
