import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const Card = ({
  color,
  children
}: {
  color: string;
  children: ReactNode;
}) => {
  return (
    <div className={twMerge('rounded-lg px-4 py-3 w-full flex-1', color)}>
      {children}
    </div>
  );
};
