import { RFP, RFPSchema } from '@/constants/redmineSchemas/rfp';
import { INTERVENTION_CUSTOM_FIELDS } from '@/pages/InterventionReport';
import { SPT_LIST } from '@/pages/SPT/InterventionPanel';
import { PiopFilters } from '@/state/piop.report.filters';
import { CustomField, RedmineIssuesResponse } from '@/types/redmine';
import { addHours, format } from 'date-fns';
import { integrityFormStatus, ptStatus } from '../constants/enums/status';
import {
  bdoTsTrackers,
  integrityFormTracker
} from '../constants/enums/trackers';
import { InterventionPanelFilterAtomType } from '../state/interventionPanel.filter.atom';
import api from './apiPortal';
import { getIssuesRedmine, getIssuesRedmine2 } from './utils.services';
const path = '/oilwells';

export type SPTData = {
  custom_fields: CustomField[];
  issue: {
    id: number;
    subject: string;
    status_id: number;
    created_on: string;
    updated_on: string;
  };
};

export type SPT = {
  data: {
    parent: SPTData;
    oldest: SPTData;
    concluded: SPTData;
    sitop: SPTData;
    ope: number;
    stb: number;
    dtm: number;
    tnf: number;
  };
};

export interface ExtendedRedmineIssuesResponse extends RedmineIssuesResponse {
  relations?: any;
  meta: any;
}

export const getAllBDOs = async (
  timeRange = '',
  tracker_id = '',
  status_id = ''
) => {
  let data: any = {
    issues: []
  };
  const query = {
    limit: 100,
    status_id: status_id || '29|30|31|32|35|33|34|35|36|37|38',
    tracker_id: tracker_id || '8|11|12|13|14|15|16|17|18|19|20|21',
    offset: 0,
    cf_248: timeRange || undefined
  };

  const data1 = getIssuesRedmine({
    project_id: 3,
    ...query
  });

  const data0 = getIssuesRedmine({
    project_id: 3,
    ...query,
    offset: 100
  });

  const data2 = getIssuesRedmine({
    project_id: 4,
    ...query
  });

  const data5 = getIssuesRedmine({
    project_id: 4,
    ...query,
    offset: 100
  });

  const data3 = getIssuesRedmine({
    project_id: 5,
    ...query
  });

  const data4 = getIssuesRedmine({
    ...query,
    project_id: 6
  });

  await Promise.all([data0, data1, data2, data3, data4, data5]).then(values => {
    values.forEach((value: any) => {
      data = {
        ...value.data,
        issues: [...data.issues, ...value.data.issues]
      };
    });
  });

  return data;
};

export const getBdoPts = async (
  timeRange = '',
  tracker_id = '',
  status_id = ''
) => {
  const { data } = await getIssuesRedmine({
    limit: 480,
    status_id: status_id || '29|30|32|38|105',
    tracker_id: tracker_id || '44|41|43',
    offset: 0,
    cf_248: timeRange || undefined,
    project_id: 12
  });
  return data;
};

interface integrityFormProps {
  timeRange?: string;
  tracker_id?: string;
  status_id?: string;
  station?: string[];
  oilwell?: string;
  page?: number;
  perPage?: number;
}

export const getAllIntegrityForms = async ({
  timeRange,
  tracker_id,
  status_id,
  station,
  oilwell,
  page,
  perPage
}: integrityFormProps) => {
  let data: any = {
    issues: []
  };
  const query = {
    limit: 1,
    status_id:
      status_id ||
      [
        integrityFormStatus['Preenchimento do Formulário'],
        integrityFormStatus['Revisão do Formulário'],
        integrityFormStatus['Correção do Formulário'],
        integrityFormStatus['Formulário Conc. Validado'],
        integrityFormStatus['Formulário Conc. Invalidado'],
        integrityFormStatus['Formulário Cancelado'],
        integrityFormStatus['Formulário Programado']
      ].join('|'),
    cf_2176: station && station.length > 0 ? station.join('|') : undefined,
    tracker_id:
      tracker_id ||
      [
        integrityFormTracker['Teste de DHSV'],
        integrityFormTracker['Teste de Surgência'],
        integrityFormTracker['Teste de Árvore de Natal'],
        integrityFormTracker['Prontuário de Poço']
      ].join('|'),
    offset: page ? (page - 1) * (perPage || 100) : 0,
    start_date: timeRange,
    cf_1975: oilwell
  };

  const totalCount = (
    await getIssuesRedmine({
      ...query,
      project_id: 9
    })
  ).data.total_count;

  (
    await Promise.all(
      Array.from(
        { length: totalCount < 1000 ? Math.ceil(totalCount / 100) : 10 },
        (_, idx) => idx * 100
      ).map(it =>
        getIssuesRedmine({
          ...query,
          limit: 100,
          offset: it,
          project_id: 9
        })
      )
    )
  ).forEach(it => {
    data = {
      ...it.data,
      issues: [...data.issues, ...it.data.issues]
    };
  });

  return data;
};

export const getLastBDO = async (
  date?: string | null,
  tracker: string = 'Pilar'
) => {
  const trackerMapper: Record<string, number> = {
    Pilar: 13,
    Furado: 15,
    'Tucano Sul': bdoTsTrackers['BDO Tucano - Supervisório']
  };

  const res = await getIssuesRedmine({
    project_id: tracker === 'Tucano Sul' ? 12 : 4,
    status_id: tracker === 'Tucano Sul' ? '*' : '29|30|31|32|35|33|34|36|37|38',
    tracker_id: trackerMapper[tracker], // 13 pir, 15 fu
    limit: 1,
    offset: 0,
    cf_248: date
  });

  return res.data;
};

export const getLastBDOEP = async (date?: string | null, tracker = 'Pilar') => {
  const res = await getIssuesRedmine({
    project_id: 3,
    status_id: '29|30|31|32|35|33|34|36|37|38',
    tracker_id: tracker === 'Pilar' ? 8 : 11,
    limit: 1,
    offset: 0,
    cf_248: date || undefined
  });

  return res.data;
};

export const getLastBDOTS = async (date?: string | null) => {
  const res = await Promise.all(
    [
      bdoTsTrackers['BDO Tucano - Supervisório'],
      bdoTsTrackers['BDO Tucano - Campo'],
      bdoTsTrackers['BDO Tucano - Manutenção']
    ].map(async tracker => [
      await getIssuesRedmine({
        project_id: 12,
        status_id: '*',
        tracker_id: tracker,
        limit: 5,
        offset: 0,
        cf_248: date
      })
    ])
  );

  return res.map((it: any) => it?.[0]?.data?.issues);
};

export const getLastXmasTree = async () => {
  const res = await getIssuesRedmine({
    project_id: 9,
    status_id: '*',
    tracker_id: integrityFormTracker['Teste de Árvore de Natal'],
    limit: 1,
    offset: 0,
    include: 'attachments'
  });

  return res.data;
};

export const getPiopOrder = async (
  page: number,
  pageSize: number,
  filters: PiopFilters
) => {
  const { data } = await api.get(`piop/orders`, {
    params: {
      page,
      pageSize,
      localInstalacao: filters.localInstalacao,
      estacao: filters.estacao,
      equipamentos: filters.equipamentos,
      sortBy: 'concl_desejada',
      sortOrder: 'asc',
      ordem: filters.ordem,
      nota: filters.nota,
      startDate: filters.timeRange?.startDate
        ? filters.timeRange?.startDate
        : undefined,
      endDate: filters.timeRange?.endDate
        ? filters.timeRange?.endDate
        : undefined,
      dataPriorizadaInicial: filters.timeRangeDataPriorizada?.startDate
        ? filters.timeRangeDataPriorizada?.startDate
        : undefined,
      dataPriorizadaFinal: filters.timeRangeDataPriorizada?.endDate
        ? filters.timeRangeDataPriorizada?.endDate
        : undefined,
      dataCriacaoInicial: filters.timeRangeDataCriacao?.startDate
        ? filters.timeRangeDataCriacao?.startDate
        : undefined,
      dataCriacaoFinal: filters.timeRangeDataCriacao?.endDate
        ? filters.timeRangeDataCriacao?.endDate
        : undefined,
      statusOrdem: filters.statusOrdem,
      descricaoBreve: filters.descricaoBreve,
      cenarioDePriorizacao: filters.cenarioDePriorizacao,
      tipoDeAtividade: filters.tipoDeAtividade
    }
  });

  return data;
};

export const getPiopOrdersXlsx = async (filters: PiopFilters) => {
  const { data } = await api.get(`piop/export`, {
    params: {
      sortBy: 'concl_desejada',
      sortOrder: 'asc',
      localInstalacao: filters.localInstalacao,
      estacao: filters.estacao,
      equipamentos: filters.equipamentos,
      ordem: filters.ordem,
      nota: filters.nota,
      startDate: filters.timeRange?.startDate
        ? filters.timeRange?.startDate
        : undefined,
      endDate: filters.timeRange?.endDate
        ? filters.timeRange?.endDate
        : undefined,
      dataPriorizadaInicial: filters.timeRangeDataPriorizada?.startDate
        ? filters.timeRangeDataPriorizada?.startDate
        : undefined,
      dataPriorizadaFinal: filters.timeRangeDataPriorizada?.endDate
        ? filters.timeRangeDataPriorizada?.endDate
        : undefined,
      dataCriacaoInicial: filters.timeRangeDataCriacao?.startDate
        ? filters.timeRangeDataCriacao?.startDate
        : undefined,
      dataCriacaoFinal: filters.timeRangeDataCriacao?.endDate
        ? filters.timeRangeDataCriacao?.endDate
        : undefined,
      statusOrdem: filters.statusOrdem,
      descricaoBreve: filters.descricaoBreve,
      cenarioDePriorizacao: filters.cenarioDePriorizacao,
      tipoDeAtividade: filters.tipoDeAtividade
    },
    withCredentials: true,
    responseType: 'blob'
  });

  // Create a Blob URL for the file data
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      endings: 'native'
    })
  );

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'piopOrders.xlsx');

  // Append the link to the body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up the temporary URL and link
  URL.revokeObjectURL(url);
  document.body.removeChild(link);

  return data;
};

export const updatePiopPriority = async (priorities: any) => {
  const { data } = await api.post(`piop/orders`, priorities);
  return data;
};

export const updatePiopSuggestedPrioritizedDates = async (priorities: any) => {
  const { data } = await api.post(
    `piop/orders/update-suggested-prioritized-dates-by-list`,
    priorities
  );
  return data;
};

export const getPiop = async (
  limit: number,
  offset: number,
  filters: {
    station: string[];
    discipline: string[];
    status: string[];
    priority: string[];
    timeRange: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
    scheduledDate: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
    onlyWithDep?: boolean;
    operation?: string;
    order?: string;
  },
  date?: Date,
  status?: string | number | null
) => {
  const { data } = await getIssuesRedmine({
    project_id: 11,
    limit,
    offset,
    cf_2332: date ? `>=${format(date, 'yyyy-MM-dd')}` : undefined,
    cf_2333:
      filters.timeRange.startDate && filters.timeRange.endDate
        ? `><${format(
            addHours(filters.timeRange.startDate, 3),
            'yyyy-MM-dd'
          )}|>=${format(addHours(filters.timeRange.endDate, 3), 'yyyy-MM-dd')}`
        : undefined,
    cf_2320: filters.order ? `~${filters.order}` : undefined,
    cf_2329: filters.operation ? filters.operation : undefined,
    cf_2497:
      filters.scheduledDate.startDate && filters.scheduledDate.endDate
        ? `><${format(
            addHours(filters.scheduledDate.startDate, 3),
            'yyyy-MM-dd'
          )}|${format(
            addHours(filters.scheduledDate.endDate, 3),
            'yyyy-MM-dd'
          )}`
        : undefined,
    sort: 'subject:asc',
    cf_2344:
      filters?.priority?.length > 0 ? filters.priority.join('|') : undefined,
    cf_2338:
      filters?.station?.length > 0 ? filters.station.join('|') : undefined,
    cf_2339:
      filters?.discipline?.length > 0
        ? filters.discipline.join('|')
        : undefined,

    cf_2354: filters.onlyWithDep ? '*' : undefined,
    status_id:
      status || (filters?.status?.length > 0 ? filters.status.join('|') : '*')
  });

  return data;
};

export const getPiopRelations = async (
  limit: number,
  menu: number,
  offset: number,
  filters: {
    station: string[];
    discipline: string[];
    status: string[];
    priority: string[];
    equipment: string[];
    timeRange: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
    scheduledDate: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
    onlyWithDep?: boolean;
    operation?: string;
    order?: string;
  },
  date?: Date,
  status?: string | number | null
) => {
  const { data } = await getIssuesRedmine2<ExtendedRedmineIssuesResponse>({
    cf_ids: [2497, 2341, 2324, 2336, 2339, 2320, 2331, 2338, 2343, 2423, 2510],
    project_id: 11,
    limit,
    filters: [
      {
        cf_id: 2332,
        value: date ? `${format(date, 'yyyy-MM-dd')}` : undefined,
        operator: date ? '>=' : undefined
      },
      {
        cf_id: 2333,
        value: filters.timeRange.startDate
          ? format(addHours(filters.timeRange.startDate, 3), 'yyyy-MM-dd')
          : undefined,
        operator: filters.timeRange.startDate ? '>=' : undefined
      },
      {
        cf_id: 2333,
        value: filters.timeRange.endDate
          ? format(addHours(filters.timeRange.endDate, 3), 'yyyy-MM-dd')
          : undefined,
        operator: filters.timeRange.endDate ? '<=' : undefined
      },
      { cf_id: 2320, value: filters.order ? `${filters.order}` : undefined },
      { cf_id: 2329, value: filters.operation ? filters.operation : undefined },
      {
        cf_id: menu == 3 ? 2332 : 2497,
        value: filters.scheduledDate.startDate
          ? format(addHours(filters.scheduledDate.startDate, 3), 'yyyy-MM-dd')
          : undefined,
        operator: filters.scheduledDate.startDate ? '>=' : undefined
      },
      {
        cf_id: menu == 3 ? 2332 : 2497,
        value: filters.scheduledDate.endDate
          ? format(addHours(filters.scheduledDate.endDate, 3), 'yyyy-MM-dd')
          : undefined,
        operator: filters.scheduledDate.endDate ? '<=' : undefined
      },
      {
        cf_id: 2344,
        value:
          filters?.priority?.length > 0 ? filters.priority.join('|') : undefined
      },
      {
        cf_id: 2338,
        value:
          filters?.station?.length > 0 ? filters.station.join('|') : undefined
      },
      {
        cf_id: 2339,
        value:
          filters?.discipline?.length > 0
            ? filters.discipline.join('|')
            : undefined
      },
      {
        cf_id: 2336,
        value:
          filters?.equipment?.length > 0
            ? filters.equipment.join('|')
            : undefined
      }
    ].filter(filter => filter['value'] != undefined),
    offset,
    sort: 'subject:asc',
    status_id: status,
    // PT status
    status_ids:
      filters?.status?.length > 0
        ? filters.status.map(status => ptStatus[status])
        : undefined
  });

  return data;
};

export const getDrillingBdos = async (
  limit: number,
  page: number,
  oilwell: string,
  status_id?: number | string,
  tracker_id?: number
) => {
  const { data } = await getIssuesRedmine({
    project_id: 13,
    limit,
    page,
    cf_2848: oilwell,
    status_id,
    tracker_id
  });

  return data;
};

export const getDrillingBdo = async (
  date: string,
  drill: string,
  well: string
) => {
  return (
    await getIssuesRedmine({
      project_id: 13,
      tracker_id: 47,
      cf_2847: drill,
      cf_2848: well,
      cf_248: date,
      status_id: '34|156'
    })
  ).data;
};

export const getRDP = async (
  date: string,
  drill: string,
  well: string,
  shift: string
) => {
  return (
    await getIssuesRedmine({
      project_id: 13,
      cf_2847: drill,
      cf_2848: well,
      cf_248: date,
      tracker_id: 54,
      status_id: 34,
      cf_3392: shift
    })
  ).data;
};

export const getOilwellDrillingLookup = async () => {
  return (
    await api.get(path, {
      params: {
        route: 'grid_drilling',
        oilwell: 'none',
        per_page: 0
      }
    })
  ).data;
};

export const getMetaDataSpt = async () => {
  return (
    await api.get(path, {
      params: {
        route: 'spt_register_info'
      }
    })
  ).data;
};

export const getOilwellDrilling = async (oilwell: string) => {
  return (
    await api.get(path, {
      params: {
        route: 'oilwell_drilling',
        oilwell,
        per_page: 120
      }
    })
  ).data;
};

export const getBDPIssues = async (
  oilwell: string,
  probe: string,
  date: string
) => {
  return (
    await api.get<{
      chart_data: {
        [key: string]: {
          unproductive_cia: number;
          unproductive_origem: number;
          unproductive_others: number;
          unproductive_probe: number;
          unproductive_weather: number;
          unproductive_well: number;
          productive: number;
        };
      };
      last_7_days_data: {
        last_7_days_issues: any;
        last_7_days_sum: {
          unproductive_cia: number;
          unproductive_origem: number;
          unproductive_others: number;
          unproductive_probe: number;
          unproductive_weather: number;
          unproductive_well: number;
          productive: number;
        };
      };
      last_bdp_data: {
        last_bdp_issue: any;
        last_bdp_sum: {
          unproductive_cia: number;
          unproductive_origem: number;
          unproductive_others: number;
          unproductive_probe: number;
          unproductive_weather: number;
          unproductive_well: number;
          productive: number;
        };
      };
      well_data: {
        well_issues: any;
        well_sum: {
          unproductive_cia: number;
          unproductive_origem: number;
          unproductive_others: number;
          unproductive_probe: number;
          unproductive_weather: number;
          unproductive_well: number;
          productive: number;
        };
      };
      probe_data: {
        probe_issues: any;
        probe_sum: {
          unproductive_cia: number;
          unproductive_origem: number;
          unproductive_others: number;
          unproductive_probe: number;
          unproductive_weather: number;
          unproductive_well: number;
          productive: number;
        };
      };
      total_data: {
        all_issues: any;
        all_issues_sum: {
          unproductive_cia: number;
          unproductive_origem: number;
          unproductive_others: number;
          unproductive_probe: number;
          unproductive_weather: number;
          unproductive_well: number;
          productive: number;
        };
      };
    }>(path, {
      params: {
        route: 'oilwell_drilling/bdp',
        oilwell,
        probe,
        date
      }
    })
  ).data;
};

export const getAllPiop = async (
  filters: {
    station: string[];
    discipline: string[];
    status: string[];
    priority: string[];
    equipment: string[];
    onlyWithDep?: boolean;
    scheduledDate: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
    operation?: string;
    order?: string;
    timeRange: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
  },
  totalCount: number,
  date?: Date,
  status?: string | number | null
) => {
  const res = await Promise.all(
    Array.from(
      { length: Math.ceil(totalCount / 100) },
      (_, idx) => idx * 100
    ).map(it => getPiop(100, it, filters, date, status))
  );

  const data = res?.[0] || { issues: [] };

  data.issues = res.map(it => it?.issues).flat(1);
  return data;
};

export const getAllPiopRelations = async (
  menu: number,
  filters: {
    station: string[];
    discipline: string[];
    status: string[];
    priority: string[];
    equipment: string[];
    onlyWithDep?: boolean;
    scheduledDate: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
    operation?: string;
    order?: string;
    timeRange: {
      startDate?: Date | string;
      endDate?: Date | string;
    };
  },
  totalCount: number,
  date?: Date,
  status?: string | number | null
) => {
  const res = await Promise.all(
    Array.from(
      { length: Math.ceil(totalCount / 100) },
      (_, idx) => idx + 1
    ).map(it => getPiopRelations(100, menu, it, filters, date, status))
  );

  const data = res?.[0] || { issues: [] };

  data.issues = res.map(it => it?.issues).flat(1);
  return data;
};

export const getPdf = async (id?: string | null): Promise<string> => {
  const res = await api.get<ArrayBuffer>(`${path}/pdf/${id}`, {
    responseType: 'arraybuffer'
  });

  const blob = new Blob([res.data], { type: 'pdf' });
  const urlCreator = window.URL || window.webkitURL;
  const pdfUrl = urlCreator.createObjectURL(blob);

  return pdfUrl;
};

export const getAllPdfs = async (ids: string[]): Promise<string[]> => {
  const res = await Promise.all(ids.map(id => getPdf(id)));

  return res;
};

export const getImage = async (id?: string | null): Promise<string> => {
  const res = await api.get<ArrayBuffer>(`${path}/image/${id}`, {
    responseType: 'arraybuffer'
  });

  const blob = new Blob([res.data], { type: 'image/jpeg' });
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);

  return imageUrl;
};

export const getAllImages = async (ids: string[]): Promise<string[]> => {
  const res = await Promise.all(ids.map(id => getImage(id)));

  return res;
};

export const getAllLastBDOEP = async (date?: string | null) => {
  const res = await Promise.all(
    [8, 11].map(it =>
      getIssuesRedmine({
        project_id: 3,
        status_id: '29|30|31|32|35|33|34|36|37|38',
        tracker_id: it,
        limit: 1,
        offset: 0,
        cf_248: date ? date : undefined
      })
    )
  );

  return res;
};

export const getAllSlickLine = async (date?: string) =>
  (
    await getIssuesRedmine({
      project_id: 14,
      cf_2984: date,
      tracker_id: 52,
      status_id: '*'
    })
  ).data;

export const getFlowRateOilwells = async (
  oilwell: string[],
  date?: string | null
) => {
  const res = await api.get(path, {
    params: {
      route: 'oilwell_flowrate',
      oilwell,
      data_do_teste: date
    }
  });

  return res.data;
};

export const getPiopInfo = async () => {
  const res = await api.get(path, {
    params: {
      route: 'piop_info'
    }
  });

  return res.data;
};

export const getRefreshMaterialized = async () => {
  const res = await api.get(path, {
    params: {
      route: 'refresh_materialized_time'
    }
  });

  return res.data;
};

export const getLastBDOEc = async (date?: string | null, tracker = 'Pilar') => {
  const { data } = await getIssuesRedmine({
    project_id: 5,
    status_id: '29|30|31|32|35|33|34|36|37|38',
    tracker_id: tracker == 'Pilar' ? 19 : 18,
    limit: 1,
    offset: 0,
    cf_248: date ? date : undefined
  });

  return data;
};

export const getLastBDMF = async (tracker: number, date?: string | null) => {
  const res = await getIssuesRedmine({
    project_id: 7,
    status_id: '7|29|39|40|41',
    tracker_id: tracker,
    limit: 1,
    offset: 0,
    cf_248: date ? date : undefined
  });

  return res.data;
};

export const getAllLastBDMF = async (
  date: string | null,
  trackers: number[]
) => {
  const res = await Promise.all(trackers.map(it => getLastBDMF(it, date)));

  return res;
};

export const getBdmfAllocationPilar = async (
  date: string | null,
  tracker: string,
  oilwell?: string,
  page?: number,
  per_page?: number
) => {
  const { data } = await api.get(`oilwells/bdmf_daily_allocation_${tracker}`, {
    params: {
      date,
      oilwell,
      page,
      per_page
    }
  });
  return data;
};

export const getLastBDOUpgnArea = async (
  date?: string | null,
  tracker = 'Pilar'
) => {
  const res = await getIssuesRedmine({
    project_id: 6,
    tracker_id: 20,
    status_id: '29|30|31|32|35|33|34|36|37|38',
    limit: 1,
    offset: 0,
    cf_248: date ? date : undefined
  });

  return res.data;
};

export const getLastBDOUpgnSup = async (
  date?: string | null,
  tracker = 'Pilar'
) => {
  const res = await getIssuesRedmine({
    project_id: 6,
    tracker_id: 16,
    status_id: '29|30|31|32|35|33|34|36|37|38',
    limit: 1,
    offset: 0,
    cf_248: date ? date : undefined
  });

  return res.data;
};

export const getBdoChartData = async (
  tracker = 'Pilar',
  date?: string | null
) => {
  const res = await api.get(
    `${path}/well-production?date=${date}&tracker=${tracker}`
  );

  return res.data;
};

export const getAllBdoWellProductionData = async (date?: string | null) => {
  const res = await Promise.all(
    ['Pilar', 'Furado'].map(it =>
      api.get(`${path}/well-production?date=${date}&tracker=${it}`)
    )
  );

  return res.map(it => it.data);
};

export const getAllWellProduction = async (date?: string | null) => {
  const res = await api.get(`${path}?route=well_production&date=${date}`);

  return res.data;
};

export const getPtsProductionData = async (date?: string | null) => {
  const res = await api.get(`${path}/report_matrix_pts?date=${date}`);

  return res.data;
};

export const getStorageOilwells = async (date?: string | null) => {
  const { data } = await api.get(`oilwells/storage`, {
    params: { current_date: date }
  });

  return data;
};

export const getFinalizedTests = async (date?: string | null) => {
  const { data } = await api.get(`oilwells/finalized-tests`, {
    params: { data_referencia: date }
  });

  return data;
};

export const getWellsDisregaded = async (date?: string | null) => {
  const { data: pilar } = await api.get(`oilwells/wells-disregaded`, {
    params: { date: date, tracker: 'Pilar' }
  });

  const { data: furado } = await api.get(`oilwells/wells-disregaded`, {
    params: { date: date, tracker: 'Furado' }
  });

  return {
    pilar,
    furado
  };
};

export const getParuBel = async (date?: string | null) => {
  const { data } = await api.get(`oilwells/paru_bel_wells_variation`, {
    params: { sample_datetime: date }
  });

  return data;
};

export const getInValidationTests = async (date?: string | null) => {
  const { data } = await api.get(`oilwells/get_testes_em_validacao`, {
    params: { data_referencia: date }
  });

  return data;
};

export const getPressureAndInjection = async (
  oilwell: string,
  grid = 'Injection',
  timeRange?: {
    start?: string | null;
    end?: string | null;
  }
) => {
  const res = await api.get(
    `${path}?route=grids_data&oilwell=${oilwell}&per_page=200&grid=${grid}${
      timeRange ? `&start=${timeRange.start}&end=${timeRange.end}` : ''
    }`
  );

  return res.data;
};

export const getLastBDOequip = async (
  date?: string | null,
  tracker = 'Pilar'
) => {
  const res = await getIssuesRedmine({
    project_id: 4,
    status_id: '29|30|31|32|35|33|34|36|37|38',
    tracker_id: tracker == 'Pilar' ? 12 : 14, // 12 pir, 14 fu
    //TODO
    limit: 1,
    offset: 0,
    include: 'journals',
    cf_248: date ? date : undefined
  });

  return res.data;
};

export const getPiTags = async (date: string) => {
  const res = await api.get(`oilwells/pi-tags`, {
    params: {
      sample_datetime: date
    }
  });

  return res.data;
};

export const getBdoIntervention = async (id: string, startDate: string) => {
  const { data } = await api.get(`oilwells`, {
    params: {
      route: `intervention/${id}/bdo`,
      start_date: startDate,
      cf_ids: INTERVENTION_CUSTOM_FIELDS
    }
  });

  return data;
};

export const getInterventions = async (
  probe: string,
  filters: InterventionPanelFilterAtomType,
  offset = 0
) => {
  const perPage = 13;
  const startDate = filters.startDate
    ? format(filters.startDate, 'yyyy-MM-dd')
    : null;
  const { data } = await api.get(`oilwells`, {
    params: {
      route: 'intervention/panel',
      page: offset,
      per_page: perPage,
      probe,
      oilwells: filters.oilwell,
      intervention_statuses: filters.interventionStatus,
      start_date: startDate
    }
  });

  return {
    cardsData: data?.data || [],
    totalCount: data?.meta?.count || 0
  };
};

export const getSPTFinalInterventionData = async (id: string) => {
  const { data } = await api.get(`oilwells`, {
    params: {
      route: `intervention/${id}/rfi`,
      cf_ids: INTERVENTION_CUSTOM_FIELDS
    }
  });

  return data as SPT;
};

export const getSPTDailyInterventionData = async (
  date: string,
  cf_ids: number[]
) => {
  const emptySPTDATA = {
    custom_fields: [],
    issue: {
      id: 0,
      subject: '',
      status_id: 0,
      created_on: '',
      updated_on: ''
    }
  };

  const emptyData = {
    parent: emptySPTDATA,
    bdo: emptySPTDATA,
    sitop: emptySPTDATA,
    oldest_bdo: emptySPTDATA,
    concluded_bdo: emptySPTDATA,
    oldest_sitop: emptySPTDATA,
    reasons_to_reject: '',
    mocs: '',
    objective_elapsed_days: null
  };

  return await Promise.all(
    SPT_LIST.map(async probe => {
      try {
        const parsedStartDate = format(addHours(date, 3), 'yyyy-MM-dd');
        const { data } = await api.get(`oilwells`, {
          params: {
            route: `intervention/rdi`,
            probe,
            date: parsedStartDate,
            cf_ids
          }
        });

        const interventionData = data?.data || emptyData;

        return {
          probe,
          ...(interventionData as {
            parent: SPTData;
            bdo: SPTData;
            sitop: SPTData;
            oldest_bdo: SPTData;
            concluded_bdo: SPTData;
            oldest_sitop: SPTData;
            reasons_to_reject: string | null;
            mocs: string | null;
            objective_elapsed_days: number | null;
          })
        };
      } catch (e) {
        console.error(e);
        return undefined;
      }
    })
  );
};

export const getRFPData = async (oilwell: string) => {
  const { data } = await api.get(`oilwells`, {
    params: {
      route: `bdo_drilling/rfp`,
      oilwell,
      cf_ids: RFPSchema.getAllCfIds()
    }
  });

  return data as RFP;
};
