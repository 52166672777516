import { TextareaCell } from '@/components/atoms/table/TextareaCell';
import { DefaultCell } from '../../../components/atoms/table/DefaultCell';
import { Header } from '../../../components/atoms/table/Header';

const schematicValvesCols = [
  {
    Header: () => (
      <Header
        secondary
        text={'Válvulas'}
        id="valvulas"
        className={`rounded-r-none rounded-b-none justify-start`}
      />
    ),
    accessor: 'valvula',
    width: '20%',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Fabricante'}
        id="fabricante"
        className={`rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'fabricante',
    width: '10%',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Especificação Técnica'}
        id="especificacaoTecnica"
        className={`rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'especificacaoTecnica',
    width: '10%',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Finalidade'}
        id="finalidade"
        className={`rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'finalidade',
    width: '10%',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Modo de Ativação'}
        id="modoDeAtivacao"
        className={`rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'modoDeAtivacao',
    width: '10%',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Status'}
        id="status"
        className={`rounded-b-none rounded-l-none rounded-r-none justify-start`}
      />
    ),
    accessor: 'status',
    width: '10%',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        secondary
        text={'Observação'}
        id="observacao"
        className={`rounded-b-none rounded-l-none justify-start`}
      />
    ),
    accessor: 'observacao',
    Cell: ({ row: { id, original } }: any) => {
      return (
        <TextareaCell
          name={`data.${id}.observacao`}
          keyName={`data.${original.id}.observacao`}
          value={original.observacoes ? original.observacoes : ''}
          rows={4}
        />
      );
    }
  }
];

export default schematicValvesCols;
