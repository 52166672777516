import { useCallback } from 'react';
import ScaleUtils from '../../ScaleUtils';
import { Graphics } from '@pixi/react';
import { SchematicReferenceConstants } from '../../SchematicReferenceConstants';

export const SapataComponent = ({
  width,
  base,
  scaleUtils,
  schematicReferenceConstants
}: {
  width: number;
  base: number;
  scaleUtils: ScaleUtils;
  schematicReferenceConstants: SchematicReferenceConstants;
}) => {
  const draw = useCallback(
    (g: any) => {
      g.clear();
      g.beginFill('#798B9F');

      const realBase = scaleUtils.yTranslationByScale(base);
      const altura = width / 2;
      const offsetY = Math.max(
        realBase - altura,
        schematicReferenceConstants.TopoDoPoco
      );

      g.moveTo(-width / 2, realBase); // Base esquerda
      g.lineTo(width / 2, realBase); // Base direita
      g.lineTo(schematicReferenceConstants.middlePosition, offsetY); // Topo do triângulo
      g.lineTo(-width / 2, realBase); // Volta para base esquerda
      g.endFill();
    },
    [width, base, scaleUtils, schematicReferenceConstants]
  );

  return <Graphics draw={draw} />;
};
