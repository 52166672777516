import { DefaultCell } from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import { Column } from 'react-table';

const schematicOilWellPeixe: Column[] = [
  {
    Header: () => (
      <Header
        text={`Descrição do Peixe`}
        id="schematicOilWellDescricaoPeixe"
        className={`text-dark uppercase px-3 justify-start border-b-[1px] border-[#E4E8FB] text-nowrap`}
      />
    ),
    accessor: 'descricao',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        text={'Topo (m)'}
        id="schematicOilWellTopo"
        className={`text-dark px-3 justify-start border-b-[1px] border-[#E4E8FB] text-nowrap`}
      />
    ),
    accessor: 'topo',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        text={'Base (m)'}
        id="schematicOilWellBase"
        className={`text-dark px-3 justify-start border-b-[1px] border-[#E4E8FB]`}
      />
    ),
    accessor: 'base',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        text={'OD (Pol)'}
        id="schematicOilWellOdPeixe"
        className={`text-dark px-3 justify-start border-b-[1px] border-[#E4E8FB] text-nowrap`}
      />
    ),
    accessor: 'diametro_externo_txt',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header
        text={'ID (Pol)'}
        id="schematicOilWellIdPeixe"
        className={`text-dark px-3 justify-start border-b-[1px] border-[#E4E8FB]`}
      />
    ),
    accessor: 'diametro_interno',
    Cell: DefaultCell
  }
];

export default schematicOilWellPeixe;
