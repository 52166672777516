import Button from '@/components/atoms/Button';
import { Label } from '@/components/atoms/Label';
import Table from '@/components/organisms/Table';
import { postEnvelopeData } from '@/services/wellhandover.services';
import { useEffect, useMemo, useState } from 'react';
import { FileUpload } from './FileUpload';
import { safeEnvelopeMdm } from './safe-envelope-mdm';

export interface envelopeProps {
  id: number;
  anularA: number;
  anularB: number;
  anularC: number;
  coluna: number;
  signature: string;
  wellSchematicId: number;
  attachments: Attachment[];
}

export interface Attachment {
  id?: string;
  name: string;
  fileKey: string;
  fileSize: number;
  mimeType: string;
}

export const EnvelopeDataRegister: React.FC<{
  envelopeData: envelopeProps;
  wellSchematicId: number;
  setEnvelopeData: (data: envelopeProps) => void;
}> = ({ envelopeData, wellSchematicId, setEnvelopeData }) => {
  const [tableData, setTableData] = useState<envelopeProps>(envelopeData);
  const [uploadedFiles, setUploadedFiles] = useState<Array<File | Attachment>>(
    envelopeData?.attachments || []
  );
  const [uploadingFiles, setUploadingFiles] = useState<number>(0);
  const [uploadError, setUploadError] = useState<string>('');

  useEffect(() => {
    setTableData(envelopeData);
  }, [envelopeData]);

  const data = useMemo(
    () => [
      { label: 'Anular A', maasp: tableData?.anularA },
      { label: 'Anular B', maasp: tableData?.anularB },
      { label: 'Anular C', maasp: tableData?.anularC },
      { label: 'Coluna', maasp: tableData?.coluna }
    ],
    [tableData]
  );

  const validateForm = () => {
    if (uploadedFiles.length === 0) {
      setUploadError('Por favor, anexe pelo menos um arquivo para continuar.');
      return false;
    }

    if (
      !tableData.anularA ||
      !tableData.anularB ||
      !tableData.anularC ||
      !tableData.coluna
    ) {
      setUploadError(
        'Por favor, verifique se os campos estão preenchidos corretamente para continuar.'
      );
      return false;
    }

    if (
      tableData.anularA <= 0 ||
      tableData.anularB <= 0 ||
      tableData.anularC <= 0 ||
      tableData.coluna <= 0
    ) {
      setUploadError('Por favor, insira valores positivos.');
      return false;
    }

    return true;
  };

  const registerEnvelope = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();

    const dataToSend = {
      anularA: tableData.anularA,
      anularB: tableData.anularB,
      anularC: tableData.anularC,
      coluna: tableData.coluna,
      wellSchematicId: wellSchematicId
    };

    formData.append('data', JSON.stringify(dataToSend));

    uploadedFiles.forEach(file => {
      if (file instanceof File) {
        formData.append('files[]', file);
      } else {
        formData.append('alreadyUploadedFiles[]', JSON.stringify(file));
      }
    });

    try {
      setUploadingFiles(1);
      const response = await postEnvelopeData(formData);
      setUploadedFiles(response?.attachments);
      setEnvelopeData(response);
      setUploadError('');
      console.log('Success:', response);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setUploadingFiles(0);
    }
  };

  const handleFilesChange = (files: Array<File | Attachment>) => {
    setUploadedFiles(files);
    setTableData(prev => ({
      ...prev,
      attachments: files.map(file => {
        if (!('id' in file)) {
          return {
            name: file.name,
            fileKey: file.name,
            fileSize: (file as File).size,
            mimeType: (file as File).type
          };
        }
        return file;
      })
    }));
    setUploadError('');
  };

  return (
    <div>
      <div>
        <Label
          id="Inserir Pressões"
          className="text-gray-dark pt-3 text-xs font-normal"
          name="Inserir Pressões *"
          noBorder
        />

        <Table
          columns={safeEnvelopeMdm}
          data={data}
          headerClassName="bg-primary text-white text-md font-normal h-10"
          bodyClassName="h-16"
          setData={newData => {
            setTableData(newData);
          }}
        />
        <div className="text-xs mt-3 text-gray-lighter">
          <label className="text-gray-dark font-medium">
            Assinatura atual:{' '}
          </label>
          <span className="">{tableData?.signature || '-'}</span>
        </div>

        <div>
          <Label
            id="Upload de documentos"
            className="text-gray mb-2 pt-3 text-xs font-normal"
            name="Upload de documento(s)"
            noBorder
          />
          <FileUpload
            onFilesChange={handleFilesChange}
            initialFiles={uploadedFiles as File[]}
            limit={5}
          />
        </div>
      </div>

      {uploadError && <p className="text-gray text-sm mt-5">{uploadError}</p>}

      <div className="flex justify-end space-x-3 mt-5">
        <Button
          title={uploadingFiles > 0 ? 'Enviando...' : 'Salvar'}
          onClick={registerEnvelope}
          className="mt-4 bg-primary text-white rounded-full w-40 py-2"
        />
      </div>
    </div>
  );
};
