import { ControlledSelect } from '@/pages/FormsPage/ControlledSelect/ControlledSelect';
import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { OpeningType } from './OpeningType.enum';
import useCreateAlteracaoBeanForm, {
  CreateAlteracaoBeanFormData
} from './useCreateAlteracaoBeanForm';

interface AlteracaoBeanFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const AlteracaoBeanForm = forwardRef<
  FormRef<CreateAlteracaoBeanFormData>,
  AlteracaoBeanFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { schema, methods, useLoadSavedData } = useCreateAlteracaoBeanForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue,
    control
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('alteracao bean Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  // TODO requisição para buscar os dados do poço
  const isLoading = false;
  useEffect(() => {
    setValue('current_opening', '83/76');
  }, []);

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <ControlledSelect
              name="type"
              options={[
                { label: 'Bean de injeção', value: 'injection_bean' },
                { label: 'Bean de produção', value: 'production_bean' }
              ]}
              label="Tipo de Bean"
              control={control}
              required
              errorMessage={errors.type?.message}
            />
          </div>
          <div className="flex gap-4">
            <Input
              label="Abertura atual"
              {...register('current_opening')}
              disabled
              errorMessage={errors.current_opening?.message}
              isLoading={isLoading}
              mask="99/99"
            />
            <Input
              label="Abertura sugerida"
              {...register('suggested_opening')}
              errorMessage={errors.suggested_opening?.message}
              disabled={!isEditable}
              mask="99/99"
            />
          </div>
        </div>
        <ControlledSelect
          name="opening_type"
          options={[
            { label: 'Rampa', value: OpeningType.RAMPA },
            { label: 'Direto', value: OpeningType.DIRETO }
          ]}
          label="Tipo de abertura"
          control={control}
          required
          errorMessage={errors.opening_type?.message}
        />

        {watch('opening_type')?.value === OpeningType.RAMPA && (
          <Input
            label="Detalhamento da sequência operacional"
            tooltip="Se em rampa, detalhe o período em cada abertura"
            {...register('operational_sequence_details')}
            errorMessage={errors.operational_sequence_details?.message}
            disabled={!isEditable}
            required
            variant="textarea"
            rows={3}
          />
        )}
        <Input
          label="Motivo"
          {...register('reason')}
          errorMessage={errors.reason?.message}
          disabled={!isEditable}
          required
        />
        {/* // TODO: ANEXOS */}
      </div>
    </FormWrapper>
  );
});

export default AlteracaoBeanForm;
