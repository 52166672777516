import { queryClient } from '@/App';
import { DefaultCell } from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import { deleteEquipment, getAllEquipments } from '@/services/apiMdm';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { CreateActionsCell } from '../Components/Atoms/CreateActionsCell';
import MDMBase from '../Components/Molecules/MDMBase';

const equipmentColumns = (
  handleNavigateToUpdateElement: (original: any) => void,
  handleDeleteElement: (original: any) => void
): Column[] => [
  {
    Header: () => <Header text={'ID do Equipamento'} id="code" />,
    accessor: 'code',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Número de Série'} id="serialNumber" />,
    accessor: 'serialNumber',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Tipo'} id="type" />,
    accessor: 'type',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Sistema de Medição'} id="measurementSystem" />,
    accessor: 'measurementSystem.name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Status'} id="active" />,
    accessor: 'active',
    Cell: ({ value, row, className }: any) => {
      return (
        <DefaultCell
          value={value ? 'Ativo' : 'Inativo'}
          row={row}
          className={className}
        />
      );
    }
  },
  {
    Header: () => <Header text={'Ações'} id="actions" />,
    accessor: 'actions',
    Cell: CreateActionsCell(handleNavigateToUpdateElement, handleDeleteElement),
    width: '5%'
  }
];

const Equipment = () => {
  const ITEMS_PER_PAGE = 15;
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [original, setOriginal] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const modalDeleteHandler = (original: any) => {
    setOriginal(original);
    setModalDeleteIsOpen(old => !old);
  };

  const handleDeleteElement = async (original: any) => {
    try {
      await deleteEquipment(original.id);
      await queryClient.invalidateQueries({
        queryKey: ['equipments']
      });
      await queryClient.invalidateQueries({
        queryKey: ['update-equipment', original.id]
      });
      toast.success('Equipamento excluído com sucesso');
    } catch (error) {
      console.log(error);
      toast.error('Erro ao excluir equipamento');
    }
  };

  const handleNavigateToUpdateElement = (original: any) => {
    navigate(`/equipments/update/${original.id}`);
  };

  const handleGetEquipments = async () => {
    try {
      const response = await getAllEquipments(
        (page - 1) * ITEMS_PER_PAGE,
        ITEMS_PER_PAGE,
        search
      );
      return response;
    } catch (error) {
      console.log(error);
      toast.error('Erro ao buscar equipamentos');
    }
  };

  const {
    data = { items: [], meta: { total: 0, skip: 0, take: ITEMS_PER_PAGE } },
    isLoading
  } = useQuery(['equipments', search, page], handleGetEquipments, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7 // 7 minutos
  });

  const totalPages = Math.ceil((data?.meta?.total || 0) / ITEMS_PER_PAGE);

  return (
    <MDMBase
      title="Equipamentos de Medição"
      addButtonTitle="Novo Equipamento"
      deleteModalTitle="Deletar Equipamento"
      deleteModalDescription={`Tem certeza que deseja deletar o equipamento ${original?.name}?`}
      columns={equipmentColumns(
        handleNavigateToUpdateElement,
        modalDeleteHandler
      )}
      isLoading={isLoading}
      data={data}
      totalPages={totalPages}
      ITEMS_PER_PAGE={ITEMS_PER_PAGE}
      addButtonHandler={() => navigate('/equipments/create')}
      page={page}
      setPage={setPage}
      search={search}
      setSearch={setSearch}
      modalDeleteIsOpen={modalDeleteIsOpen}
      setModalDeleteIsOpen={setModalDeleteIsOpen}
      handleDeleteElement={handleDeleteElement}
      original={original}
    />
  );
};

export default Equipment;
