import { forwardRef } from 'react';
import Select, { Props as SelectProps } from 'react-select';

type SelectOptions = {
  value: string;
  label: string;
  order?: number;
};

type Props = SelectProps & {
  options: SelectOptions[];
  ref?: any;
  className?: string;
  label?: string;
  isMulti?: boolean;
  required?: boolean;
};

const SelectInputUncontrolled: React.FC<Props> = forwardRef<any, Props>(
  ({ label, options, isMulti = false, required = false, ...props }, ref) => {
    return (
      <div className="flex flex-col gap-1 w-full">
        {label && (
          <label className="font-medium text-sm text-primary">
            {label}
            {required && <span className="text-red ml-1">*</span>}
          </label>
        )}
        <Select
          {...props}
          ref={ref}
          className={`w-full h-8 rounded-md ${props.className}`}
          options={options}
          isMulti={isMulti}
          styles={{
            control: (provided, state) => ({
              ...provided,
              background: '#fff',
              borderColor: '#D8D8D8',
              minHeight: '40px',
              height: '40px',
              borderRadius: '6px'
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              height: '40px',
              padding: '0 6px'
            }),
            input: (provided, state) => ({
              ...provided,
              margin: '0px'
            }),
            indicatorSeparator: state => ({
              display: 'none'
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '40px'
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: '#a5a5a5'
            }),
            option: (provided, state) => ({
              ...provided,
              background: state.isSelected ? '#193CB960' : '#fff',
              padding: '0.5rem 1rem',
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
              fontWeight: '400',
              color: state.isSelected ? '#fff' : '#000',
              cursor: 'pointer',
              '&:hover': {
                background: state.isSelected ? '#193CB999' : '#F3F4F6'
              }
            }),
            menu: provided => ({
              ...provided,
              zIndex: 9999,
              overflow: 'visible',
              position: 'relative'
            }),
            menuList: provided => ({
              ...provided,
              position: 'relative',
              zIndex: 9999
            }),
            menuPortal: provided => ({
              ...provided,
              zIndex: 9999
            })
          }}
          menuPortalTarget={document.body}
          menuPosition="fixed"
        />
      </div>
    );
  }
);

export default SelectInputUncontrolled;
