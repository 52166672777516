import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface TabItem {
  id: number;
  label: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: TabItem[];
}

const Tabs = ({ tabs }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="w-full flex flex-col">
      <div className="flex border-b border-gray-lighter">
        {tabs.map(tab => (
          <button
            key={tab.id}
            className={twMerge(
              'px-8 py-2 text-sm font-medium',
              activeTab === tab.id
                ? 'text-primary border-b-2 border-primary'
                : 'text-gray-dark hover:text-gray-700'
            )}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-4">{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
