import Checkbox from '@/components/atoms/Checkbox';
import { ControlledDatePicker } from '@/pages/FormsPage/ControlledDatePicker/ControlledDatePicker';
import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateFrequencyInformationForm, {
  CreateFrequencyInformationFormData
} from './useCreateFrequencyInformationForm';

interface FrequencyInformationFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const FrequencyInformationForm = forwardRef<
  FormRef<CreateFrequencyInformationFormData>,
  FrequencyInformationFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { methods } = useCreateFrequencyInformationForm();

  const {
    register,
    formState: { errors },
    watch,
    trigger,
    control
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('Frequency Information Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Informações de Frequência">
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <Input
            label="Frequência (d)"
            type="number"
            {...register('frequency')}
            disabled={!isEditable}
            errorMessage={errors.frequency?.message}
            required
          />
          <Input
            label="Quantidade por dia"
            type="number"
            {...register('quantity_per_day')}
            disabled={!isEditable}
            errorMessage={errors.quantity_per_day?.message}
            required
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Checkbox
            label="Frequência Temporária"
            {...register('temporary_frequency')}
            isError={!!errors.temporary_frequency}
            disabled={!isEditable}
            className="py-5"
          />
          <div className="grid grid-cols-2 gap-4">
            <ControlledDatePicker
              name="start_date"
              control={control}
              label="Data inicial"
              required
              error={errors.start_date?.message}
              disabled={!isEditable}
            />
            {watch('temporary_frequency') && (
              <ControlledDatePicker
                name="end_date"
                control={control}
                label="Data final"
                required
                error={errors.end_date?.message}
                disabled={!isEditable}
                minDate={watch('start_date')}
              />
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
});

export default FrequencyInformationForm;
