import { queryClient } from '@/App';
import { DefaultCell } from '@/components/atoms/table/DefaultCell';
import { Header } from '@/components/atoms/table/Header';
import { deleteFacility, getAllFacilities } from '@/services/apiMdm';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { CreateActionsCell } from '../Components/Atoms/CreateActionsCell';
import MDMBase from '../Components/Molecules/MDMBase';

const facilityColumns = (
  handleNavigateToUpdateElement: (original: any) => void,
  handleDeleteElement: (original: any) => void
): Column[] => [
  {
    Header: () => <Header text={'Código'} id="code" />,
    accessor: 'code',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Nome'} id="name" />,
    accessor: 'name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Campo'} id="oilField" />,
    accessor: 'oilFields',
    Cell: ({ value, row, className }: any) => {
      return (
        <DefaultCell
          value={value.map((item: any) => item.name).join(', ')}
          row={row}
          className={className}
        />
      );
    }
  },
  {
    Header: () => <Header text={'Polo'} id="hub" />,
    accessor: 'hub',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Bacia'} id="basin" />,
    accessor: 'basin',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Estado'} id="state" />,
    accessor: 'state',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Status'} id="active" />,
    accessor: 'active',
    Cell: ({ value, row, className }: any) => {
      return (
        <DefaultCell
          value={value ? 'Ativo' : 'Inativo'}
          row={row}
          className={className}
        />
      );
    }
  },
  {
    Header: () => <Header text={'Ações'} id="actions" />,
    accessor: 'actions',
    Cell: CreateActionsCell(handleNavigateToUpdateElement, handleDeleteElement),
    width: '5%'
  }
];

const Facility = () => {
  const ITEMS_PER_PAGE = 15;
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [original, setOriginal] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const modalDeleteHandler = (original: any) => {
    setOriginal(original);
    setModalDeleteIsOpen(old => !old);
  };

  const handleGetFacilities = async () => {
    try {
      const response = await getAllFacilities(
        (page - 1) * ITEMS_PER_PAGE,
        ITEMS_PER_PAGE,
        search
      );
      return response;
    } catch (error) {
      toast.error('Erro ao buscar instalações');
    }
  };

  const {
    data = { items: [], meta: { total: 0, skip: 0, take: ITEMS_PER_PAGE } },
    isLoading
  } = useQuery(['facilities', search, page], handleGetFacilities, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 3, // 3 minutos
    cacheTime: 1000 * 60 * 7 // 7 minutos
  });

  const handleDeleteElement = async (original: any) => {
    try {
      await deleteFacility(original.id);
      await queryClient.invalidateQueries({
        queryKey: ['facilities']
      });
      toast.success('Instalação excluída com sucesso');
    } catch (error) {
      console.log(error);
      toast.error('Erro ao excluir instalação');
    }
  };

  const handleNavigateToUpdateElement = (original: any) => {
    navigate(`/facilities/update/${original.id}`);
  };

  const totalPages = Math.ceil((data?.meta?.total || 0) / ITEMS_PER_PAGE);

  return (
    <MDMBase
      title="Instalações"
      addButtonTitle="Nova Instalação"
      deleteModalTitle="Deletar Instalação"
      deleteModalDescription={`Tem certeza que deseja deletar a instalação ${original?.name}?`}
      columns={facilityColumns(
        handleNavigateToUpdateElement,
        modalDeleteHandler
      )}
      isLoading={isLoading}
      data={data}
      totalPages={totalPages}
      ITEMS_PER_PAGE={ITEMS_PER_PAGE}
      addButtonHandler={() => navigate('/facilities/create')}
      page={page}
      setPage={setPage}
      search={search}
      setSearch={setSearch}
      modalDeleteIsOpen={modalDeleteIsOpen}
      setModalDeleteIsOpen={setModalDeleteIsOpen}
      handleDeleteElement={handleDeleteElement}
      original={original}
    />
  );
};

export default Facility;
