import {
  getOilwellField,
  getOilwellNameAnp,
  getOilwellStatus
} from '@/services/oilwells.services';

const selectMapper = (it: any) => ({ label: it, value: it });

const getOilFieldAsync = async (search: string) => {
  const fields = await getOilwellField();

  return fields
    .filter((it: any) => (search ? it.label.includes(search) : true))
    .map((it: any) => ({
      value: it.name,
      label: it.name
    }));
};

const wellStatusValues = [
  'Arrasado',
  'Produzindo',
  'Abandonado temporariamente sem monitoramento',
  'Abandonado temporariamente com monitoramento',
  'Em intervenção',
  'Fechado',
  'Operando para captação de água'
].map(selectMapper);

const healthStatusValues = ['Saudável', 'Monitoramento', 'Crítico'].map(
  selectMapper
);

const getNomeAnpAsync = async () => {
  const data = await getOilwellNameAnp();
  const anpValues = data.data.map((it: any) => ({
    value: it.nameANP
  }));
  const anpValuesUnique = [...new Set(anpValues.map((it: any) => it.value))];
  return anpValuesUnique.map(selectMapper);
};

export const oilwellsListFilters = [
  { key: 'field', name: 'Campo', values: [], asyncFn: getOilFieldAsync },
  {
    key: 'nameANP',
    name: 'Nome ANP',
    values: [],
    asyncFn: getNomeAnpAsync
  },
  { key: 'anpStatus', name: 'Status ANP', values: wellStatusValues },
  { key: 'healthStatus', name: 'Saúde do Poço', values: healthStatusValues }
];
