import { STATUS_MAP } from '@/constants/status';
import { formatDateTimeISO } from '@/utils/utils';
import { BsCheckLg } from 'react-icons/bs';

export interface LifecycleStep {
  label: string;
  initialDate: string;
  finalDate: string;
  author?: string;
  newData?: {
    statusId: string;
  };
  oldData?: {
    statusId: string;
  };
  createdAt?: string;
  applicationDate?: string;
  changes?: {
    [key: string]: {
      old: string;
      new: string;
    };
  };
}

export interface LifecycleStepsProps {
  currentStep: number;
  history: LifecycleStep[];
}

export const LifecycleSteps: React.FC<LifecycleStepsProps> = ({
  currentStep,
  history
}) => {
  const circleSize = 10;

  // Criar array de status sem duplicatas
  const statusSteps = history.reduce(
    (steps, item) => {
      // Se é o primeiro item, adiciona o status antigo
      if (steps.length === 0 && item.oldData?.statusId) {
        steps.push({
          status: item.oldData.statusId,
          date: formatDateTimeISO(item.createdAt || ''),
          author: item.author,
          changes: item.changes,
          finalDate: formatDateTimeISO(item.applicationDate || '')
        });
      }

      // Adiciona o novo status
      steps.push({
        status: item.newData?.statusId,
        date: formatDateTimeISO(item.createdAt || ''),
        author: item.author,
        changes: item.changes,
        finalDate: formatDateTimeISO(item.applicationDate || '')
      });

      return steps;
    },
    [] as Array<{
      status: string | undefined;
      date: string;
      author: string | undefined;
      changes: (typeof history)[0]['changes'];
      finalDate: string;
    }>
  );

  return (
    <div className="relative overflow-x-auto">
      <div className="relative w-max pb-4">
        <div
          className="absolute h-1 rounded-full mt-[37px] bg-gray-lighter/40"
          style={{ width: '100%' }}
        >
          <div
            className="h-full bg-persian-blue-600 transition-all duration-500"
            style={{
              width: `${(currentStep / (statusSteps.length - 1)) * 100}%`
            }}
          />
        </div>

        <div className="relative flex justify-between w-max px-5 py-5">
          {statusSteps.map((step, index) => (
            <div key={index} className="flex flex-col mx-5 items-center group">
              <div
                className={`w-${circleSize} h-${circleSize} flex justify-center items-center rounded-full transition-all duration-300 ${
                  index <= currentStep ? 'bg-[#edeeff]' : 'bg-white'
                }`}
              >
                {index <= currentStep && (
                  <BsCheckLg className="w-5 h-5 text-primary" />
                )}
              </div>

              <div className="mt-4 flex flex-col items-center">
                <span
                  className={`text-xs w-48 text-center font-medium ${
                    index <= currentStep ? 'text-primary' : 'text-gray-lighter'
                  }`}
                >
                  {STATUS_MAP[step.status as keyof typeof STATUS_MAP] ||
                    'Status Desconhecido'}
                </span>
                <div className="text-xs text-center text-gray-lighter flex flex-col justify-center items-center mt-2">
                  <p>Data: {step.date}</p>
                  <p>Data de Aplicação: {step.finalDate}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
