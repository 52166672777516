import { FooterCard } from './FooterCard';

export const DoubleFooterCards = ({
  title1,
  content1,
  title2,
  content2
}: {
  title1: string;
  content1: string;
  title2: string;
  content2: string;
}) => {
  return (
    <div className="flex gap-2">
      <FooterCard title={title1} content={content1} />
      <FooterCard title={title2} content={content2} />
    </div>
  );
};
