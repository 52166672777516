import Spinner from '@/components/atoms/Spinner';
import {
  createOrUpdateEquipment,
  EquipmentItem,
  getOneEquipment
} from '@/services/apiMdm';
import {
  getAllGasMeters,
  getAllMeasuringTank
} from '@/services/oilwells.services';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryClient } from '../../../App';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/Input';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '@/hooks/Common/MdmUtils';
import { mapperOptionsTextConstants } from '@/utils/mdm';

type EquipmentItemForm = {
  id: number;
  code: string;
  serialNumber: string;
  type: string;
  measurementTankId?: string;
  gasMeterId?: string;
  active?: string;
};

const CreateEquipment = () => {
  const navigate = useNavigate();
  const onSubmit = async (
    data: EquipmentItemForm & {
      measurementSystem: { id: number; type: string } | undefined;
    }
  ) => {
    try {
      const measurementTankId = +(data?.measurementTankId ?? 0);
      const gasMeterId = +(data?.gasMeterId ?? 0);
      const active = data?.active?.toString() === 'true';
      await createOrUpdateEquipment({
        ...data,
        measurementTankId,
        gasMeterId,
        active
      });

      await queryClient.invalidateQueries({
        queryKey: ['equipments']
      });
      navigate('/equipments');
      toast.success('Equipamento criado com sucesso');
    } catch (e) {
      toast.error('Erro ao criar equipamento');
    }
  };

  return (
    <MdmLayout title={'Criar Equipamento'}>
      <EquipmentForm onSubmit={onSubmit} />
    </MdmLayout>
  );
};

const UpdateEquipment = () => {
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data: equipment, isLoading } = useQuery({
    queryKey: ['update-equipment', id],
    queryFn: () => getOneEquipment(+id)
  });

  const measurementSystem = equipment?.measuringTank
    ? { id: equipment.measuringTank.id, type: 'tank' }
    : { id: equipment?.gasMeter?.id, type: 'gasMeter' };

  const defaultValues = {
    id: equipment?.id ?? 0,
    code: equipment?.code ?? '',
    serialNumber: equipment?.serialNumber ?? '',
    type: equipment?.type ?? '',
    measurementTankId: equipment?.measurementTankId ?? undefined,
    gasMeterId: equipment?.gasMeterId ?? undefined,
    measurementSystem: measurementSystem ?? undefined,
    active: equipment?.active ?? true
  };

  const onSubmit = async (
    data: EquipmentItemForm & {
      measurementSystem: { id: number; type: string } | undefined;
    }
  ) => {
    try {
      const measurementTankId = +(data?.measurementTankId ?? 0);
      const gasMeterId = +(data?.gasMeterId ?? 0);
      const active = data?.active?.toString() === 'true';
      await createOrUpdateEquipment({
        ...data,
        measurementTankId,
        gasMeterId,
        active
      });

      await queryClient.invalidateQueries({
        queryKey: ['equipments']
      });
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'update-equipment'
      });

      toast.success('Equipamento atualizado com sucesso');
      navigate('/equipments');
    } catch (e) {
      toast.error('Erro ao atualizar equipamento');
    }
  };

  return (
    <MdmLayout title={'Atualizar Equipamento'}>
      {isLoading ? (
        <Spinner />
      ) : (
        <EquipmentForm onSubmit={onSubmit} defaultValues={defaultValues} />
      )}
    </MdmLayout>
  );
};

type EquipmentFormProps = {
  onSubmit: (
    data: EquipmentItemForm & {
      measurementSystem: { id: number; type: string } | undefined;
    }
  ) => void;
  defaultValues?: EquipmentItem & {
    measurementSystem: { id: number; type: string } | undefined;
  };
};

const EquipmentForm: React.FC<EquipmentFormProps> = ({
  onSubmit,
  defaultValues
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      id: defaultValues?.id ?? 0,
      code: defaultValues?.code ?? '',
      serialNumber: defaultValues?.serialNumber ?? '',
      type: defaultValues?.type ?? '',
      measurementTankId: defaultValues?.measurementTankId?.toString(),
      gasMeterId: defaultValues?.gasMeterId?.toString(),
      measurementSystem: defaultValues?.measurementSystem ?? {
        id: 0,
        type: ''
      },
      active: defaultValues?.active?.toString() ?? 'true'
    }
  });

  const { constants } = useMdmUtils({
    constants: true
  });

  const { data: measurementTanks = [] } = useQuery({
    queryKey: ['measurement-tanks-equipment-cadastre'],
    queryFn: async () => {
      const response = await getAllMeasuringTank();
      return response;
    }
  });
  const { data: gasMeters = [] } = useQuery({
    queryKey: ['gas-meters-equipment-cadastre'],
    queryFn: async () => {
      const response = await getAllGasMeters();
      return response;
    }
  });

  const measurementSystemOptions = measurementTanks
    ?.map((measurementSystem: any) => ({
      value: { id: measurementSystem.id, type: 'tank' },
      label: measurementSystem.name
    }))
    .concat(
      gasMeters?.map((measurementSystem: any) => ({
        value: { id: measurementSystem.id, type: 'gasMeter' },
        label: measurementSystem.name
      }))
    );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-2 items-center justify-center w-3/4"
    >
      <Input
        label="ID do Equipamento"
        required
        placeholder="ID do Equipamento"
        {...register('code', { required: true })}
      />

      <Input
        label="Número de Série"
        required
        placeholder="Número de Série"
        {...register('serialNumber', { required: true })}
      />

      <SelectInput
        label="Tipo"
        placeholder="Selecione o tipo"
        control={control}
        name="type"
        required
        options={mapperOptionsTextConstants(constants?.EquipmentType)}
      />

      <SelectInput
        label="Sistema de Medição"
        placeholder="Selecione o sistema de medição"
        control={control}
        onChange={() => {
          const value = watch('measurementSystem');
          if (value) {
            const selectedSystem = measurementSystemOptions.find(
              (option: any) => option.value === value
            );

            if (selectedSystem) {
              if (selectedSystem.value.type === 'tank') {
                setValue('measurementTankId', selectedSystem.value.id);
                setValue('gasMeterId', undefined);
              } else if (selectedSystem.value.type === 'gasMeter') {
                setValue('gasMeterId', selectedSystem.value.id);
                setValue('measurementTankId', undefined);
              }
            }
          }
        }}
        name={'measurementSystem'}
        required
        options={measurementSystemOptions}
      />

      <SelectInput
        label="Status"
        placeholder="Status"
        control={control}
        name={'active'}
        options={[
          { value: 'true', label: 'Ativo' },
          { value: 'false', label: 'Inativo' }
        ]}
      />

      <Button
        className="px-24 h-12 mt-4"
        type="submit"
        title="Salvar"
        disabled={!isValid && !errors}
      />
    </form>
  );
};

export { CreateEquipment, UpdateEquipment };
