import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const requiredError = 'Este campo é obrigatório';

const schema = z
  .object({
    frequency: z.coerce
      .number({
        required_error: requiredError,
        invalid_type_error: 'Deve ser um número'
      })
      .min(1, { message: 'O valor mínimo é 1' }),
    quantity_per_day: z.coerce
      .number({
        required_error: requiredError,
        invalid_type_error: 'Deve ser um número'
      })
      .min(1, { message: 'O valor mínimo é 1' }),
    temporary_frequency: z.boolean(),
    start_date: z.date({ required_error: requiredError }),
    end_date: z.date().optional()
  })
  .superRefine((data, ctx) => {
    if (data.temporary_frequency) {
      if (!data.end_date) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['end_date'],
          message: 'Data final é obrigatória quando frequência é temporária'
        });
      }
    }
  });

export type CreateFrequencyInformationFormData = z.infer<typeof schema>;

const useCreateFrequencyInformationForm = () => {
  const methods = useForm<CreateFrequencyInformationFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      quantity_per_day: 1,
      temporary_frequency: false
    }
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    if (data) {
      setValue('frequency', data?.frequency);
      setValue('quantity_per_day', data?.quantity_per_day);
      setValue('temporary_frequency', data?.temporary_frequency);
      setValue('start_date', data?.start_date);
      setValue('end_date', data?.end_date);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateFrequencyInformationForm;
