import { Card } from '@/components/atoms/Card';
import { Adjacent, Spread, Stacked } from '@/components/atoms/Composite';
import { PageWrapper } from '@/components/atoms/PageWrapper';
import { PrintStyle } from '@/components/atoms/PrintStyle';
import SelectInput from '@/components/atoms/Select';
import Spinner from '@/components/atoms/Spinner';
import { TableGrid } from '@/components/atoms/TableGrid';
import { TextBold, Textsm } from '@/components/atoms/Texts';
import { WhiteCard } from '@/components/atoms/WhiteCard';
import DatepickerSearchParams from '@/components/molecules/DatepickerSearchParams';
import { DoubleFooterCards } from '@/components/molecules/DoubleFooterCards';
import SideFilter from '@/components/molecules/SideFilter';
import { StackedPrimary } from '@/components/molecules/StackedPrimary';
import { StackedText } from '@/components/molecules/StackedText';
import { StackedWhite } from '@/components/molecules/StackedWhite';
import { TextLongComponent } from '@/components/molecules/TextLongComponent';
import { XML039Schema } from '@/constants/redmineSchemas/xml039';
import { useParams } from '@/hooks/Common/useParams';
import {
  getAllGasMeters,
  getAllMeasuringTank
} from '@/services/oilwells.services';
import { getGridSchemas, getIssuesRedmine2 } from '@/services/utils.services';
import { DummyAtom } from '@/state/dummy.atom';
import { DefaultRedmineIssueResponse } from '@/types/redmine';
import { mapperOptions } from '@/utils/mdm';
import { tablefyGridSchema } from '@/utils/tablesUtils';
import { extractYear, formatDate } from '@/utils/utils';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

export const Xml039Report = () => {
  const { params } = useParams();
  const dataRelatorio = format(
    params.get('dataRelatorio') ?? new Date(),
    'yyyy-MM-dd'
  );
  const pontoDeMedicao = params.getAll('pontoDeMedicao');
  const tipoDeNotificacao = params.getAll('tipoDeNotificacao');

  const projectId = 16;
  const cfIds = XML039Schema.getAllCfIds();
  const limit = 1;
  const page = 1;
  const filters = [
    {
      cf_id: XML039Schema.XML039CfIds.Data_do_Relatorio,
      value: dataRelatorio
    },
    {
      cf_id: XML039Schema.XML039CfIds.Tag_do_Ponto_de_Medicao,
      value: pontoDeMedicao?.join('|')
    },
    {
      cf_id: XML039Schema.XML039CfIds.Tipo_de_Notificacao,
      value: tipoDeNotificacao?.join('|')
    }
  ];

  const { data = DefaultRedmineIssueResponse(), isLoading } = useQuery({
    queryKey: [
      'xml039-report-data',
      dataRelatorio,
      pontoDeMedicao,
      tipoDeNotificacao
    ],
    queryFn: async () =>
      (
        await getIssuesRedmine2({
          project_id: projectId,
          cf_ids: cfIds,
          limit,
          offset: page,
          filters
        })
      ).data,
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true
  });

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const issueId = data.issues[0]?.issue?.id;

  const getCFValue = (cfId: XML039Schema.XML039CfIds) => {
    return XML039Schema.getCFValue(data, cfId);
  };

  const getCFGrid = (cfId: XML039Schema.XML039CfIds) => {
    return XML039Schema.getCFGrid(data, cfId);
  };

  return (
    <PageWrapper>
      <div
        ref={componentRef}
        className="w-full h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full"
      >
        <div className="flex flex-col w-full flex-wrap gap-2 rounded-lg px-4 py-3 bg-background">
          <PrintStyle />

          <div className="text-2xl print:text-lg print:pl-3 print:p-0 text-primary font-bold border-b border-primary print:border-none print:rounded-lg print:items-center pb-4 mb-2 w-full flex justify-between print:bg-[#EDF0FA]">
            <div className="flex print:flex-col print:gap-0 gap-2 print:items-start items-center">
              <span className="print:text-slate-800">
                NFSM - Notificação de Falha do Sistema de Medição
              </span>
              <span className="print:hidden text-slate-600">-</span>
              <span className="text-slate-600 text-lg print:text-2xl print:flex print:text-primary">
                {formatDate(
                  getCFValue(XML039Schema.XML039CfIds.Data_do_Relatorio)
                )}
              </span>
            </div>

            <Adjacent>
              <button
                onClick={() => {
                  window.open(
                    `${import.meta.env.VITE_REDMINE_URL}` +
                      (issueId ? `/issues/${issueId}` : ''),
                    '_blank'
                  );
                }}
                className="text-xs px-4 h-6 rounded-full border
                  no-print disabled:cursor-not-allowed disabled:text-gray"
                disabled={isLoading}
              >
                Acessar Boletim
              </button>

              <button
                onClick={handlePrint}
                className="text-xs px-4 h-6 rounded-full border
                  no-print disabled:cursor-not-allowed disabled:text-gray"
                disabled={isLoading}
              >
                Imprimir
              </button>
            </Adjacent>
            <img
              src="/origem.png"
              alt="Logo Origem"
              className="w-[203px] only-print resize-none max-w-none -ml-5"
            />
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <Spinner />
            </div>
          ) : (
            <Xml039ReportContent
              getCFValue={getCFValue}
              getCFGrid={getCFGrid}
            />
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

function Xml039ReportContent({
  getCFValue,
  getCFGrid
}: {
  readonly getCFValue: (cfId: XML039Schema.XML039CfIds) => string;
  readonly getCFGrid: (cfId: XML039Schema.XML039CfIds) => any;
}) {
  const { setParam, params } = useParams();
  const dataRelatorio = format(
    params.get('dataRelatorio') ?? new Date(),
    'yyyy-MM-dd'
  );
  const pontoDeMedicao = params.getAll('pontoDeMedicao');
  const tipoDeNotificacao = params.getAll('tipoDeNotificacao');

  const handleFilter = (isClearing?: boolean) => {
    if (isClearing) {
      setParam(new URLSearchParams());
    }
  };

  const { data: gridSchemas = { data: {} }, isLoading: isLoadingGridSchemas } =
    useQuery({
      queryFn: async () =>
        await getGridSchemas([
          XML039Schema.XML039CfIds.Grid_Correcao_de_Volume,
          XML039Schema.XML039CfIds.Grid_Correcao_de_BSW,
          XML039Schema.XML039CfIds.Grid_Correcao_de_Calibracao
        ]),
      queryKey: ['xml039_grids_schemas']
    });

  const { data: measurementTanks = [] } = useQuery({
    queryKey: ['measurement-tanks'],
    queryFn: async () => {
      const response = await getAllMeasuringTank();
      return response;
    }
  });
  const { data: gasMeters = [] } = useQuery({
    queryKey: ['gas-meters'],
    queryFn: async () => {
      const response = await getAllGasMeters();
      return response;
    }
  });

  const measurementSystemOptions = measurementTanks
    ?.map((measurementSystem: any) => ({
      value: measurementSystem.name,
      label: measurementSystem.name
    }))
    .concat(
      gasMeters?.map((measurementSystem: any) => ({
        value: measurementSystem.name,
        label: measurementSystem.name
      }))
    );

  return (
    <Stacked>
      <div className="grid grid-cols-8 gap-2 h-min-[100px]">
        <div className="h-full col-span-6">
          <Card color="bg-primary">
            <Spread>
              {[
                {
                  label: 'Operador',
                  value: 'Origem Energia Alagoas S.A.'
                },
                {
                  label: 'Data\xA0do\xA0Relatório',
                  value: formatDate(
                    getCFValue(XML039Schema.XML039CfIds.Data_do_Relatorio)
                  )
                },
                {
                  label: 'Ano',
                  value: extractYear(
                    getCFValue(XML039Schema.XML039CfIds.Data_do_Relatorio)
                  )
                },
                {
                  label: 'Código',
                  value: getCFValue(XML039Schema.XML039CfIds.Codigo)
                },
                {
                  label: 'Campo',
                  value: getCFValue(XML039Schema.XML039CfIds.Campo)
                }
              ].map(item => (
                <StackedPrimary
                  key={item.label}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Spread>
          </Card>
        </div>
        <div className="col-span-2">
          <Card color="bg-primary">
            <StackedPrimary label="FO-COR-GER-CPROD-026" value="Revisão: 1" />
          </Card>
        </div>
      </div>

      <div className="grid grid-cols-8 gap-2">
        <div className="col-span-6">
          <WhiteCard>
            <Spread>
              {[
                {
                  label: 'Tipo de Notificação:',
                  value: getCFValue(
                    XML039Schema.XML039CfIds.Tipo_de_Notificacao
                  )
                },
                {
                  label: 'Notificação Relacionada:',
                  value: getCFValue(
                    XML039Schema.XML039CfIds.Notificacao_Relacionada
                  )
                },
                {
                  label: 'Instalação:',
                  value: getCFValue(XML039Schema.XML039CfIds.Instalacao)
                },
                {
                  label: 'Código da Instalação:',
                  value: getCFValue(
                    XML039Schema.XML039CfIds.Codigo_da_Instalacao
                  )
                }
              ].map(item => (
                <StackedWhite
                  key={item.label}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Spread>
          </WhiteCard>
        </div>
        <div className="col-span-2">
          <WhiteCard>
            <Stacked>
              <Textsm color="text-primary">Localização</Textsm>
              <Spread>
                <Adjacent alignVertical="center">
                  <TextBold color="text-primary">Bacia:</TextBold>
                  <Textsm color="text-primary">
                    {getCFValue(XML039Schema.XML039CfIds.Bacia)}
                  </Textsm>
                </Adjacent>
                <Adjacent alignVertical="center">
                  <TextBold color="text-primary">Estado:</TextBold>
                  <Textsm color="text-primary">
                    {getCFValue(XML039Schema.XML039CfIds.Estado)}
                  </Textsm>
                </Adjacent>
              </Spread>
            </Stacked>
          </WhiteCard>
        </div>
      </div>

      <WhiteCard>
        <Stacked>
          <div className="flex justify-between gap-4 border-b border-slate-200">
            {[
              {
                label: 'Tag do ponto de medição',
                value: getCFValue(
                  XML039Schema.XML039CfIds.Tag_do_Ponto_de_Medicao
                )
              },
              {
                label: 'Tipo de medição',
                value: getCFValue(XML039Schema.XML039CfIds.Tipo_de_Medicao)
              },
              {
                label: 'Equipamento',
                value: getCFValue(XML039Schema.XML039CfIds.Equipamento)
              },
              {
                label: 'Número de série',
                value: getCFValue(XML039Schema.XML039CfIds.Numero_de_Serie)
              },
              {
                label: 'Tipo de fluido',
                value: getCFValue(XML039Schema.XML039CfIds.Tipo_de_Fluido)
              }
            ].map(item => (
              <StackedText
                key={item.label}
                label={item.label}
                value={item.value}
              />
            ))}
          </div>
          <div className="flex justify-between pt-4">
            {[
              {
                label: 'Data de ocorrência',
                value: formatDate(
                  getCFValue(XML039Schema.XML039CfIds.Data_de_Ocorrencia)
                )
              },
              {
                label: 'Data de retorno à normalidade',
                value: formatDate(
                  getCFValue(
                    XML039Schema.XML039CfIds.Data_de_Retorno_a_Normalidade
                  )
                )
              },
              {
                label: 'Data de detecção',
                value: formatDate(
                  getCFValue(XML039Schema.XML039CfIds.Data_de_Deteccao)
                )
              },
              {
                label: 'Previsão de Retorno à Normalidade (dias)',
                value: getCFValue(
                  XML039Schema.XML039CfIds.Previsao_de_retorno_a_normalidade
                )
              }
            ].map(item => (
              <StackedText
                key={item.label}
                label={item.label}
                value={item.value}
              />
            ))}
          </div>
        </Stacked>
      </WhiteCard>

      <DoubleFooterCards
        title1="Responsável pela detecção"
        content1={getCFValue(
          XML039Schema.XML039CfIds.Responsavel_pela_Deteccao
        )}
        title2="Tipo de Falha"
        content2={getCFValue(XML039Schema.XML039CfIds.Tipo_de_Falha)}
      />

      {[
        {
          title: 'Descrição do evento',
          content: getCFValue(XML039Schema.XML039CfIds.Descricao_do_Evento)
        },
        {
          title: 'Ações tomadas',
          content: getCFValue(XML039Schema.XML039CfIds.Acoes_Tomadas)
        },
        {
          title: 'Metodologia de estimativa',
          content: getCFValue(
            XML039Schema.XML039CfIds.Metodologia_de_Estimativa
          )
        }
      ].map(item => (
        <TextLongComponent
          key={item.title}
          title={item.title}
          content={item.content}
        />
      ))}

      {isLoadingGridSchemas ? (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      ) : (
        [
          {
            title: 'Correção de Volume',
            data: getCFGrid(XML039Schema.XML039CfIds.Grid_Correcao_de_Volume),
            columns: tablefyGridSchema(
              gridSchemas,
              XML039Schema.XML039CfIds.Grid_Correcao_de_Volume,
              'text-white'
            )
          },
          {
            title: 'Correção de BSW',
            data: getCFGrid(XML039Schema.XML039CfIds.Grid_Correcao_de_BSW),
            columns: tablefyGridSchema(
              gridSchemas,
              XML039Schema.XML039CfIds.Grid_Correcao_de_BSW,
              'text-white'
            )
          },
          {
            title: 'Correção de Calibração',
            data: getCFGrid(
              XML039Schema.XML039CfIds.Grid_Correcao_de_Calibracao
            ),
            columns: tablefyGridSchema(
              gridSchemas,
              XML039Schema.XML039CfIds.Grid_Correcao_de_Calibracao,
              'text-white'
            )
          }
        ].map(item => (
          <div key={item.title} className="m-2">
            <Stacked>
              <TextBold color="text-primary">{item.title}</TextBold>
              <TableGrid
                headerClassName="bg-primary"
                key={item.title}
                data={item.data}
                cols={item.columns}
              />
            </Stacked>
          </div>
        ))
      )}

      <DoubleFooterCards
        title1="Elaborador"
        content1={getCFValue(XML039Schema.XML039CfIds.Elaborador)}
        title2="Aprovador"
        content2={getCFValue(XML039Schema.XML039CfIds.Aprovador)}
      />

      <SideFilter atom={DummyAtom} applyChanges={handleFilter} filters={[]}>
        <DatepickerSearchParams
          name="Data do Relatório"
          value={dataRelatorio}
          keyValue="dataRelatorio"
        />

        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">
            Tag do Ponto de Medição
          </h3>
          <SelectInput
            options={measurementSystemOptions}
            onChange={selectedOptions => {
              params.delete('pontoDeMedicao');
              selectedOptions.forEach(option => {
                params.append('pontoDeMedicao', option.value);
              });
              setParam(params);
            }}
            value={pontoDeMedicao?.map(option => ({
              value: option,
              label: option
            }))}
          />
        </div>

        <div className="flex flex-col gap-1">
          <h3 className="text-sm font-bold text-white">Tipo de Notificação</h3>
          <SelectInput
            options={mapperOptions([
              'Inicial',
              'Intermediária',
              'Final',
              'Retificação'
            ])}
            onChange={selectedOptions => {
              params.delete('tipoDeNotificacao');
              selectedOptions.forEach(option => {
                params.append('tipoDeNotificacao', option.value);
              });
              setParam(params);
            }}
            value={tipoDeNotificacao?.map(option => ({
              value: option,
              label: option
            }))}
          />
        </div>
      </SideFilter>
    </Stacked>
  );
}
