import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { postGasMeter } from '../../../services/oilwells.services';

import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { queryClient } from '../../../App';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { mapperOptionsTextConstants } from '@/utils/mdm';
import { useQuery } from '@tanstack/react-query';
import { getAllFacilities } from '@/services/apiMdm';
import { useNavigate } from 'react-router-dom';

type CreateGasMeterForm = {
  name: string;
  type: string;
  oilFieldId: string;
  serialNumber: string;
  facilityId: number;
  measurementType: string;
  classification: string;
  measurementStatus: boolean;
};

const CreateGasMeter: React.FC = () => {
  const { fields, constants } = useMdmUtils({
    fields: true,
    constants: true
  });

  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateGasMeterForm>();

  const { data: facilities } = useQuery({
    queryKey: ['facilities'],
    queryFn: () => getAllFacilities(0, 1000, '')
  });

  const onSubmit = async (data: CreateGasMeterForm) => {
    try {
      await postGasMeter({
        ...data,
        oilField: { connect: { id: Number(data.oilFieldId) } },
        measurementStatus:
          (data?.measurementStatus?.toString() ?? 'true') === 'true'
      });
      await queryClient.invalidateQueries(['gas-meters-adm']);

      toast.success('Medidor de gás criado com sucesso');

      navigate('/gas-meter');
    } catch (e) {
      toast.error('Erro ao criar medidor de gás');
    }
  };

  return (
    <MdmLayout title={'Criar Medidor de Gás'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          required
          placeholder="Nome"
          {...register('name', { required: true })}
        />

        <Input
          label="Numero de Série"
          placeholder="NS"
          {...register('serialNumber')}
        />

        <SelectInput
          label="Tipo"
          placeholder="Tipo"
          control={control}
          name={'type'}
          options={
            constants?.GasMeterType?.map((type: string) => ({
              // label should format SEPARADOR_GRAVITACIONAL to "Separador Gravitacional"
              value: type,
              label: type
                .split('_')
                .map(
                  (word: string) => word.charAt(0) + word.slice(1).toLowerCase()
                )
                .join(' ')
            })) ?? []
          }
        />

        <SelectInput
          label="Campo"
          placeholder="Campo"
          control={control}
          name={'oilFieldId'}
          required
          options={fields?.map((field: any) => ({
            value: field.id,
            label: field.name
          }))}
        />

        <SelectInput
          label="Instalação"
          placeholder="Instalação"
          control={control}
          name={'facilityId'}
          required
          options={
            facilities?.items?.map((facility: any) => ({
              value: facility.id,
              label: facility.name
            })) ?? []
          }
        />

        <SelectInput
          label="Tipo de Medição"
          placeholder="Tipo de Medição"
          control={control}
          name={'measurementType'}
          options={mapperOptionsTextConstants(constants?.GasMeasurementType)}
        />

        <SelectInput
          label="Classificação"
          placeholder="Classificação"
          control={control}
          name={'classification'}
          options={mapperOptionsTextConstants(
            constants?.MeasurementSystemClassification
          )}
        />

        <SelectInput
          label="Status de Medição"
          placeholder="Status de Medição"
          control={control}
          name={'measurementStatus'}
          options={[
            { value: 'true', label: 'Ativo' },
            { value: 'false', label: 'Inativo' }
          ]}
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Salvar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateGasMeter;
