import { Label } from '@/components/atoms/Label';
import Modal from '@/components/atoms/Modal';
import { FaXmark } from 'react-icons/fa6';
import { SafeEnvelopeTabs } from './SafeEnvelopeTabs';

interface SafeEnvelopeProps {
  wellSchematicId: number;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const SafeEnvelopeModal: React.FC<SafeEnvelopeProps> = ({
  wellSchematicId,
  isOpen,
  onClose,
  onConfirm
}) => {
  return (
    <Modal
      className="max-w-none w-1/2 relative h-[80vh] overflow-y-auto"
      isOpen={isOpen}
      setIsOpen={onClose}
    >
      <div className="px-8 py-4">
        <div className="flex justify-between">
          <Label
            id="Cadastro do Envelope Seguro de Operações"
            className="text-primary pt-3"
            name="Cadastro do Envelope Seguro de Operações"
            noBorder
          />

          <button onClick={onClose} className="text-2xl text-gray-dark">
            <FaXmark />
          </button>
        </div>

        <div className="mt-5">
          <SafeEnvelopeTabs wellSchematicId={wellSchematicId} />
        </div>
      </div>
    </Modal>
  );
};
