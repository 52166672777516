import { getNestedValue } from '@/utils/table';
import { useEffect, useState } from 'react';
import { TbEdit } from 'react-icons/tb';
import { Column } from 'react-table';
import { twMerge } from 'tailwind-merge';
import ModalConfirmation from '../molecules/ConfirmationModal';
import Table from '../organisms/Table';
import Button from './Button';
import EmptyMessage from './EmptyMessage';
import { numberFormatter2 } from '@/utils/utils';
import Spinner from './Spinner';

interface TableGridProps {
  id?: string;
  gridId?: number;
  title?: string | undefined;
  cols: Column[];
  data: any;
  children?: React.ReactNode;
  titleClassName?: string;
  tableContainerClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  editable?: boolean;
  resetForm?: () => void;
  skipConfirmation?: boolean;
  editableState?: boolean;
  setEditableState?: (e: boolean) => void;
  hasBorder?: boolean;
  containerClassName?: string;
  aggregatedColumns?: {
    groups?: { id: string; className: string }[];
    columns: { id: string; group?: string; className: string }[];
  };
  isFetching?: boolean;
}

export const TableGrid = ({
  id,
  gridId,
  title,
  cols,
  data,
  children,
  titleClassName,
  tableContainerClassName,
  headerClassName,
  bodyClassName,
  editable,
  editableState,
  setEditableState,
  resetForm,
  isFetching,
  hasBorder,
  containerClassName,
  skipConfirmation,
  aggregatedColumns
}: TableGridProps) => {
  const [modalCancelIsOpen, setModalCancelIsOpen] = useState(false);

  const handleCancel = () => {
    resetForm && resetForm();
    setEditableState && setEditableState(false);
  };

  const handleToggleEdit = () => {
    if (!editableState) return setEditableState && setEditableState(true);

    if (skipConfirmation) return handleCancel();

    setModalCancelIsOpen(true);
  };

  const shouldDisplayData = (gridId: number | undefined): boolean => {
    const gridIds = [2849, 3943];
    return !gridIds.includes(gridId ?? -1);
  };

  return (
    <div
      className={twMerge(
        'flex flex-col w-full break-inside-avoid',
        containerClassName
      )}
      id={id}
    >
      {title ? (
        <span
          className={twMerge(
            'font-bold text-gray mb-4 text-lg pt-2 border-t border-[#ddd] flex',
            titleClassName
          )}
        >
          <div className="flex gap-1 items-center">
            {title}

            {isFetching ? <Spinner size={18} className="w-fit" /> : null}
          </div>

          {editable && (
            <button
              className="ml-2 bg-slate-100 hover:bg-slate-200 transition-colors rounded flex items-center justify-center w-6 h-6 no-print"
              onClick={handleToggleEdit}
              type="button"
            >
              <TbEdit size={16} color="#666" />
            </button>
          )}
        </span>
      ) : null}

      {children}

      <div
        className={twMerge(
          'w-full overflow-y-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table',
          tableContainerClassName
        )}
      >
        {shouldDisplayData(gridId) ? (
          data && data.length > 0 ? (
            <>
              <Table
                columns={cols}
                data={data}
                editable={editableState}
                hasBorder={hasBorder}
                headerClassName={twMerge(
                  'sticky top-0 bg-white z-10',
                  headerClassName
                )}
                bodyClassName={bodyClassName}
              />

              {aggregatedColumns && (
                <>
                  <div className="flex justify-end mt-0.5 gap-0.5 mr-[1px]">
                    {aggregatedColumns.columns.map((column, i) => {
                      return AggregateColumns(
                        data,
                        [column.id],
                        column.id,
                        column.className
                      );
                    })}
                  </div>

                  {aggregatedColumns.groups &&
                    aggregatedColumns.groups.length !== 0 && (
                      <div className="flex justify-end mt-0.5 gap-0.5">
                        {aggregatedColumns.groups.map((group, i) => {
                          const columnsId = aggregatedColumns.columns
                            .filter(column => column.group === group.id)
                            .map(column => column.id);

                          return AggregateColumns(
                            data,
                            columnsId,
                            group.id,
                            group.className
                          );
                        })}
                      </div>
                    )}
                </>
              )}
            </>
          ) : (
            <EmptyMessage
              message="Sem dados disponíveis."
              className="min-h-[2rem] w-min"
            />
          )
        ) : (
          <EmptyMessage
            message="Os dados estão disponíveis para consulta através do banco de dados."
            className="min-h-[2rem] w-min"
          />
        )}
      </div>

      {editableState && (
        <div className="flex justify-end w-full mt-4 gap-2 pb-3 no-print">
          <Button
            title="Cancelar"
            onClick={() => setModalCancelIsOpen(true)}
            type="button"
            className="bg-transparent hover:bg-primary/10"
            classNameSpan="text-body-dark font-medium underline"
          />

          <Button
            className="px-4"
            type="submit"
            title="Salvar"
            // onClick={() => setEditableState(false)}
          />
        </div>
      )}

      {modalCancelIsOpen && (
        <ModalConfirmation
          confirmAction={handleCancel}
          title="Cancelar edição"
          description="Tem certeza que deseja cancelar a edição? Essa é uma ação permanente."
          confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
          isOpen={true}
          setIsOpen={() => setModalCancelIsOpen(!modalCancelIsOpen)}
        />
      )}
    </div>
  );
};

const getSizeOfElementById = (id: string) => {
  const element = document.getElementById(id);
  return `${element?.offsetWidth}px` || 'fit-content';
};

const AggregateColumns = (
  dataGrid: any[],
  ids: (string | number)[],
  refId: string,
  className: string
) => {
  const [width, setWidth] = useState('fit-content');
  useEffect(() => {
    const width = getSizeOfElementById(refId);
    setWidth(width);
  }, []);

  useEffect(() => {
    const handleZoom = () => {
      const width = getSizeOfElementById(refId);
      setWidth(width);
    };

    window.addEventListener('resize', handleZoom);

    return () => {
      window.removeEventListener('resize', handleZoom);
    };
  }, [refId]);

  return (
    <div className={className} style={{ width }} key={`${refId}-aggregated`}>
      {numberFormatter2().format(
        dataGrid.reduce((acc: number, it: any) => {
          const rowValue = ids.reduce(
            (rowAcc: number, id) =>
              rowAcc + (+getNestedValue(it, id.toString()) || 0),
            0
          );
          return acc + rowValue;
        }, 0)
      )}
    </div>
  );
};
