import { ControlledDatePicker } from '@/pages/FormsPage/ControlledDatePicker/ControlledDatePicker';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateSingleDateForm, {
  CreateSingleDateFormData
} from './useCreateSingleDateForm';

interface SingleDateFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
  hasAnpDateField?: boolean;
}

const SingleDateForm = forwardRef<
  FormRef<CreateSingleDateFormData>,
  SingleDateFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true, hasAnpDateField = false } = props;

  const { methods } = useCreateSingleDateForm({
    hasAnpDateField
  });

  const {
    formState: { errors },
    watch,
    trigger,
    control
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('Single Date Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Informação - Data da atividade">
      <ControlledDatePicker
        name="activity_date"
        control={control}
        label="Data da atividade"
        required={true}
        error={errors.activity_date?.message}
        disabled={!isEditable}
      />

      {hasAnpDateField && (
        <ControlledDatePicker
          name="anp_date"
          control={control}
          label="Data de vencimento ANP"
          required={true}
          error={errors.anp_date?.message}
          disabled={!isEditable}
        />
      )}
    </FormWrapper>
  );
});

export default SingleDateForm;
