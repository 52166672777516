import { useRetrieveCustomFields } from '@/hooks/Common/useRedmineTablenator';
import { grid2array } from '@/utils/customFields';
import { containsKey, getNestedValue } from '@/utils/table';
import { tablefyWithCustomFields } from '@/utils/tablesUtils';
import { twMerge } from 'tailwind-merge';
import {
  convertToNameNValue,
  convertToSummaryBigCards,
  formatItem,
  StructureType
} from '../../constants/view/types/structure.type';
import { convertGridToJson } from '../../utils/bdos';
import EmptyMessage from '../atoms/EmptyMessage';
import { Label } from '../atoms/Label';
import { LinkField } from '../atoms/LinkField';
import { TableGrid } from '../atoms/TableGrid';
import TextComponent from '../atoms/TextComponent';
import { TextField } from '../atoms/TextField';
import FooterComponent from './Footer';
import RawTable from './RawTable';
import { ReportHeader } from './ReportHeader';
import { SummaryBigCards } from './SummaryBigCards';

interface SwitchComponentFormProps {
  structure: StructureType;
  data: any;
  noGridBorder?: boolean;
  isCol?: boolean;
  blueLabel?: boolean;
}

export const SwitchComponentForm = ({
  structure,
  data,
  noGridBorder,
  isCol = true,
  blueLabel = false
}: SwitchComponentFormProps) => {
  const automaticGridIds = structure
    .filter(item => item.type === 'grid' && !item.columns)
    .map(it => it.id);

  const redmineGridIds = structure
    .filter(item => item.type === 'grid' && !item.columns && item.redmineGridId)
    .map(it => (it.type === 'grid' ? it.redmineGridId : undefined))
    .filter((id): id is number => id !== undefined && id !== null);

  const isDataHash = !Array.isArray(data);
  const { gridCustomFields, gridSchemas } = useRetrieveCustomFields(
    automaticGridIds,
    redmineGridIds,
    data
  );
  return (
    <div className="flex flex-wrap gap-4 gap-y-3 overflow-hidden w-full print-element-scroller">
      {structure?.map((item, index: number) => {
        const dataValue = isDataHash
          ? getNestedValue(data, item.id.toString())
          : data?.find((it: any) => it.id == item.id)?.value;
        const dataHasCF = isDataHash
          ? containsKey(data, item.id.toString())
          : !!data?.find((it: any) => it.id == item.id)?.id;
        const condition = item.condition
          ? (data?.[item.condition] && !item.reverseCondition) ||
            (!data?.[item.condition] && item.reverseCondition)
            ? ''
            : 'hidden'
          : '';

        switch (item.type) {
          case 'label':
            return (
              <Label
                name={item.name}
                id={item.id}
                key={`${item.id}-${index}`}
                secondary={item.size == 'h2'}
                noBorder={item.noBorder}
                className={twMerge(condition, blueLabel ? 'text-primary' : '')}
              />
            );
          case 'grid':
            const gridValue = grid2array(dataValue);
            const gridCols =
              item.columns ||
              (gridCustomFields && dataValue
                ? tablefyWithCustomFields(
                    item.redmineGridId
                      ? (gridSchemas?.[item.redmineGridId]
                          ?.sort(x => x.position)
                          .map(it => it.grid_column_id) ?? [])
                      : Object.keys(gridValue?.[0] || {}),
                    gridCustomFields,
                    item.headerClassName
                  )
                : []);

            return dataHasCF ? (
              <TableGrid
                gridId={item.redmineGridId}
                title={item.name}
                cols={gridCols}
                key={`${item.id}-${index}`}
                data={gridValue}
                containerClassName={twMerge(condition, item.containerClassName)}
                titleClassName={noGridBorder ? 'border-none' : ''}
                tableContainerClassName={item.tableContainerClassName}
                headerClassName={item.headerClassName}
                bodyClassName={item.bodyClassName}
                hasBorder={!(item.noBorder ?? true)}
                aggregatedColumns={item.aggregatedColumns}
              />
            ) : (
              <div
                className={twMerge(
                  'w-full overflow-y-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table',
                  item.tableContainerClassName
                )}
              >
                <EmptyMessage
                  message="Dados não encontrados."
                  className="min-h-[2rem] w-min"
                />
              </div>
            );
          case 'link':
            return dataValue ? (
              <LinkField
                to={`${import.meta.env.VITE_REDMINE_URL}/attachments/${dataValue}`}
                name={item.name}
                value={dataValue}
                key={`${item.id}-${index}`}
                isCol
              />
            ) : null;
          case 'text':
            return (
              <div className="w-full">
                <TextComponent value={dataValue} key={item.id} />
              </div>
            );
          case 'report-header':
            return (
              <ReportHeader
                nestedValues={convertToNameNValue(item.structure, data)}
                className={item.className}
                key={item.id}
              />
            );
          case 'footer':
            return (
              <FooterComponent
                nestedValues={convertToNameNValue(item.structure, data)}
                className={item.className}
                key={item.id}
              />
            );
          case 'summary-cards':
            return (
              <div className="flex w-full">
                <SummaryBigCards
                  wrapperClassName={twMerge('w-full', item.className)}
                  data={
                    item.structure
                      ? convertToSummaryBigCards(item.structure, data)
                      : dataValue || []
                  }
                  key={`${item.id}-${index}`}
                />
              </div>
            );
          case 'raw-table':
            return (
              <RawTable
                columns={item.columns}
                data={Object.values(
                  convertGridToJson(dataValue ?? '{}') ?? {}
                ).filter((obj: any) => {
                  return !Object.values(obj).every(value => value === '');
                })}
                tableClassName={item.tableClassName}
                headerClassName={item.headerClassName}
                bodyClassName={item.bodyClassName}
                rowClassName={item.rowClassName}
                key={`${item.id}-${index}`}
              />
            );
          default:
            return dataHasCF ? (
              <TextField
                name={item.name || ''}
                key={item.id + (item.name || '')}
                isFullWidth={(item as any).fullWidth}
                isCol={isCol}
                className={condition}
                value={formatItem(item, dataValue)}
              />
            ) : null;
        }
      })}
    </div>
  );
};
