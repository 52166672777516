import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';
import FloatingPortal from './FloatingPortal';

interface Props {
  nameId: string;
  links: { label: string; value: string }[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  buttonClassName?: string;
  buttonText?: string;
  menuClassName?: string;
  iconClassName?: string;
  linkClassName?: string;
  hoverText?: string;
}

const DropdownButton: React.FC<Props> = ({
  nameId,
  links,
  activeTab,
  setActiveTab,
  buttonClassName,
  buttonText,
  menuClassName,
  iconClassName,
  linkClassName,
  hoverText
}) => {
  const [isMenuOptionsVisible, setMenuOptionsVisible] = useState(false);
  const [isMenuInfoVisible, setMenuInfoVisible] = useState(false);
  const toggleTooltip = () => {
    setMenuOptionsVisible(!isMenuOptionsVisible);
  };

  const handleMouseOver = () => {
    setMenuInfoVisible(true);
  };

  const handleMouseOut = () => {
    setMenuInfoVisible(false);
  };

  const closeMenu = () => {
    setMenuOptionsVisible(false);
  };

  const getPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      return {
        top: rect.top + 45,
        left: rect.left
      };
    }

    return { top: 0, left: 0 };
  };

  const getPositionInfo = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      return {
        top: rect.top + 60,
        left: rect.left - 50
      };
    }

    return { top: 0, left: 0 };
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let handler = ({ target }: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(target as Node)) {
        closeMenu();
      }
    };

    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [buttonRef]);

  useEffect(() => {
    getPosition();
    window.addEventListener('scroll', closeMenu, true);

    return () => {
      window.removeEventListener('scroll', closeMenu, true);
    };
  }, [buttonRef]);

  return (
    <>
      <button
        ref={buttonRef}
        className={twMerge(
          buttonClassName,
          `flex items-center justify-center gap-2
          rounded-3xl
          disabled:bg-[#bddaff] disabled:hover:bg-[#bddaff]
          disabled:color-[#1648df]
          disabled:cursor-not-allowed`
        )}
        onClick={toggleTooltip}
        // onMouseOver={handleMouseOver}
        // onMouseOut={handleMouseOut}
      >
        <span>{buttonText}</span>
        <FaChevronDown
          className={`${iconClassName} ml-auto transition-all ${
            isMenuOptionsVisible && 'rotate-180'
          }`}
        />
      </button>

      <FloatingPortal>
        <div
          style={{ ...getPosition(), zIndex: 300 }}
          className={twMerge(
            `
            fixed
            bg-white
            flex flex-col 
            p-2 
            before:content-['']
            rounded-2xl
            z-300
            transition-opacity
            shadow-black
            w-[200px]
            ${
              isMenuOptionsVisible
                ? 'opacity-100 visible'
                : 'opacity-0 invisible'
            }
      `,
            menuClassName
          )}
        >
          {links.map(it => (
            <button
              key={`${nameId}-${it.label}`}
              onClick={() => setActiveTab(it.value)}
              className={`
                ${linkClassName}
                ${activeTab === it.value ? 'bg-btn-light text-primary-dark' : 'text-primary'}`}
            >
              <span className="text-base text-primary group-hover:text-[#292929] transition-all">
                {it.label}
              </span>
            </button>
          ))}
        </div>
      </FloatingPortal>

      <FloatingPortal>
        <div
          style={{ ...getPositionInfo(), zIndex: 300 }}
          className={twMerge(
            `
            fixed
            bg-white
            flex flex-col
            p-2 
            shadow-light
            before:content-[''] before:absolute before:-top-2  before:-right-1 before:rotate-45 
            rounded-lg
            z-50
            transition-opacity
            text-center
            w-[280px]
            ${
              isMenuInfoVisible && !isMenuOptionsVisible
                ? 'opacity-100 visible'
                : 'opacity-0 invisible'
            }
      `,
            menuClassName
          )}
        >
          {hoverText && (
            <span className="text-sm transition-all">{hoverText}</span>
          )}
        </div>
      </FloatingPortal>
    </>
  );
};

export default DropdownButton;
