import { optionSchema } from '@/pages/FormsPage/optionSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  current_type: z.string(),
  suggested_type: optionSchema(true),
  reason: z.string().min(1, { message: 'Este campo é obrigatório' })
});

export type CreateMudancaCorBastaoFormData = z.infer<typeof schema>;

const useCreateMudancaCorBastaoForm = () => {
  const methods = useForm<CreateMudancaCorBastaoFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    if (data) {
      setValue('suggested_type', data?.suggested_type);
      setValue('current_type', data?.current_type);
      setValue('reason', data?.reason);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateMudancaCorBastaoForm;
