import { applyDefaultProps, PixiComponent } from '@pixi/react';
import { Container, Graphics } from 'pixi.js';

interface PixiBackgroundProps {
  item: Container;
  position: { x: number; y: number };
  color: string;
  padding: number;
}

export default PixiComponent('PixiBackground', {
  create: (props: PixiBackgroundProps) => {
    const item = props.item;
    item.position.set(0, 0);

    const roundedRectangle = RoundedRectangleComponent({
      width: item.width,
      height: item.height,
      referencePosition: { x: 0, y: 0 },
      padding: props.padding,
      color: props.color
    });

    const container = new Container();
    container.position.set(props.position.x, props.position.y);
    container.addChild(roundedRectangle);
    container.addChild(item);

    return container;
  },
  applyProps: (
    instance: Container<Container>,
    oldProps: PixiBackgroundProps,
    newProps: PixiBackgroundProps
  ) => {
    const { item: oldItem, ...oldP } = oldProps;
    const { item: newItem, ...newP } = newProps;

    // apply rest props to PIXI.Text
    applyDefaultProps(instance, oldP, newP);

    // set new count
    instance.position.set(newProps.position.x, newProps.position.y);
    instance.children[0].width =
      instance.children[1].width + 2 * newProps.padding;
    instance.children[0].height =
      instance.children[1].height + 2 * newProps.padding;
  },
  didMount: (instance, parent) => {
    // apply custom logic on mount
  },
  willUnmount: (instance, parent) => {
    // clean up before removal
  },
  config: {
    // destroy instance on unmount?
    // default true
    destroy: true,

    /// destroy its children on unmount?
    // default true
    destroyChildren: true
  }
});

export const RoundedRectangleComponent = ({
  width,
  height,
  referencePosition,
  padding,
  color
}: {
  width: number;
  height: number;
  referencePosition: { x: number; y: number };
  padding: number;
  color: string;
}) => {
  const graphics = new Graphics();
  const radius = 10;

  graphics.clear();
  graphics.beginFill(color);

  // Desenha retângulo com bordas arredondadas
  graphics.drawRoundedRect(
    referencePosition.x - padding,
    referencePosition.y - padding,
    width + 2 * padding,
    height + 2 * padding,
    radius
  );

  graphics.endFill();

  return graphics;
};
