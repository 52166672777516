import { z } from 'zod';

export const optionSchema = (required?: boolean) =>
  z.object(
    {
      label: z.string(),
      value: z.string()
    },
    {
      required_error: required ? 'Este campo é obrigatório' : undefined
    }
  );
