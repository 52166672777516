import Input from '@/components/atoms/Input';
import { Header } from '../../../components/atoms/table/Header';
import { envelopeProps } from './EnvelopeDataRegister';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs leading-3 py-2">
      {value || '-'}
    </span>
  );
};

export const safeEnvelopeMdm = [
  {
    Header: () => <Header text={''} id="pressure" className="text-white" />,
    accessor: 'label',
    Cell: (value: any) => {
      return (
        <span className="text-primary font-medium text-sm leading-3 py-2">
          {value.value || '-'}
        </span>
      );
    }
  },
  {
    Header: () => (
      <Header text={'MAASP (kgf/cm²)'} id="maasp" className="text-white" />
    ),
    accessor: 'maasp',
    Cell: ({ value, row, data, setData }: any) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const label = row.original.label;
        const fieldMap: { [key: string]: keyof envelopeProps } = {
          'Anular A': 'anularA',
          'Anular B': 'anularB',
          'Anular C': 'anularC',
          Coluna: 'coluna'
        };

        if (setData && fieldMap[label]) {
          setData((prev: envelopeProps) => ({
            ...prev,
            [fieldMap[label]]: Number(newValue) || 0
          }));
        }
      };

      return (
        <Input
          value={value || null}
          onChange={handleChange}
          type="number"
          step={0.1}
          min={0.1}
          className="text-sm leading-3 py-2 w-40"
        />
      );
    }
  },
  {
    Header: () => (
      <Header text={'MAWOP (kgf/cm²)'} id="mawop" className="text-white" />
    ),
    accessor: 'mawop',
    Cell: ({ row }: any) => {
      const maasp = row.original.maasp || 0;
      const mawop = (maasp * 0.8).toFixed(2);
      return (
        <span className="text-sm leading-3 py-2 w-40 text-gray-500">
          {mawop}
        </span>
      );
    }
  }
];

export const safeEnvelopeMdmViewOnly = [
  {
    Header: () => <Header text={''} id="pressure" className="text-white" />,
    accessor: 'label',
    Cell: (value: any) => {
      return (
        <span className="text-primary font-medium text-sm leading-3 py-2">
          {value.value || '-'}
        </span>
      );
    }
  },
  {
    Header: () => (
      <Header text={'MAASP (kgf/cm²)'} id="maasp" className="text-white" />
    ),
    accessor: 'maasp',
    Cell: ({ value, row, data, setData }: any) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const label = row.original.label;

        const fieldMap: { [key: string]: keyof envelopeProps } = {
          'Anular A': 'anularA',
          'Anular B': 'anularB',
          'Anular C': 'anularC',
          Coluna: 'coluna'
        };

        if (setData && fieldMap[label]) {
          setData((prev: envelopeProps) => ({
            ...prev,
            [fieldMap[label]]: Number(newValue) || 0
          }));
        }
      };

      return (
        <span className="text-sm leading-3 py-2 text-center w-40 text-gray-500">
          {value || '-'}
        </span>
      );
    }
  },
  {
    Header: () => (
      <Header text={'MAWOP (kgf/cm²)'} id="mawop" className="text-white" />
    ),
    accessor: 'mawop',
    Cell: ({ row }: any) => {
      const maasp = row.original.maasp;
      let mawop;
      maasp ? (mawop = (maasp * 0.8).toFixed(2)) : 0;
      return (
        <span className="text-sm leading-3 py-2 w-40 text-gray-500">
          {mawop || '-'}
        </span>
      );
    }
  }
];
