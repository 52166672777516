import { RescaledRectangleComponent } from '../../Geometries/Rectangle';
import ScaleUtils from '../../ScaleUtils';
import { SchematicReferenceConstants } from '../../SchematicReferenceConstants';

export type RevestimentsAndDiameters = {
  width: number;
  revestiments: any[];
}[];

export type RevestimentGroup = {
  width: number;
  depht: number;
  cimentacoes: {
    width: number;
    topo: number;
    base: number;
  }[];
  sapata: {
    width: number;
    base: number;
  };
};

export const RevestimentComponent = ({
  width,
  depht,
  scaleUtils,
  schematicReferenceConstants
}: {
  width: number;
  depht: number;
  scaleUtils: ScaleUtils;
  schematicReferenceConstants: SchematicReferenceConstants;
}) => {
  const referencePosition = {
    x: schematicReferenceConstants.middlePosition,
    y: schematicReferenceConstants.TopoDoPoco
  };
  const color = '#FFFFFF';
  const lineColor = '#83a3c9';

  return (
    <RescaledRectangleComponent
      width={width}
      height={depht}
      referencePosition={referencePosition}
      color={color}
      lineColor={lineColor}
      scaleUtils={scaleUtils}
    />
  );
};
