import { Sprite } from '@pixi/react';
import React from 'react';
import { packer, VirtualElement } from '../schematicUtils';

interface Props {
  subsurfaceEquipments: VirtualElement[];
  hasTwoColumns: boolean;
  quartePacker: number;
  hoverTooltip: any;
  setShowTooltip: any;
}

const SchematicOilWellPacker: React.FC<Props> = (props: {
  subsurfaceEquipments: VirtualElement[];
  hasTwoColumns: boolean;
  quartePacker: number;
  hoverTooltip: any;
  setShowTooltip: any;
}) => {
  const xPositionLongaPacker = props.hasTwoColumns
    ? 10 * props.quartePacker
    : 5 * props.quartePacker;

  const xPositionCurtaPacker = props.hasTwoColumns
    ? -10 * props.quartePacker
    : -5 * props.quartePacker;

  const xPositionMeioEsquerdaPacker = -4 * props.quartePacker;
  const xPositionCentralPacker = -6 * props.quartePacker;
  const xPositionMeioDireitaPacker = 0 * props.quartePacker;

  const topMiddleAnchor = { x: 0.5, y: 0 };

  const packerEquipmentHoverText = (tipo: string, profundidade: number) => {
    return `${tipo} - ${profundidade} m`;
  };

  return (
    <>
      {props.subsurfaceEquipments.map((line: VirtualElement, index: number) => {
        return (
          <React.Fragment key={`subsurface-equipment-${index}`}>
            {line.tipo === 'packer' && (
              <>
                <Sprite
                  scale={1}
                  anchor={topMiddleAnchor}
                  x={xPositionLongaPacker}
                  y={line.topo}
                  image={packer.image}
                  width={packer.width}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: xPositionLongaPacker,
                        y: line.topo + 35
                      },
                      {
                        x: xPositionLongaPacker,
                        y: line.topo + 35
                      },
                      packerEquipmentHoverText(
                        line.originalElement.tipo,
                        line.originalElement.profundidade
                      ),
                      { width: 150, height: 70 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
                {props.hasTwoColumns && (
                  <>
                    <Sprite
                      scale={1}
                      anchor={topMiddleAnchor}
                      x={xPositionMeioEsquerdaPacker}
                      y={line.topo}
                      image={packer.image}
                      width={packer.width}
                    />
                    <Sprite
                      scale={1}
                      anchor={topMiddleAnchor}
                      x={xPositionCentralPacker}
                      y={line.topo}
                      image={packer.image}
                      width={packer.width}
                    />
                    <Sprite
                      scale={1}
                      anchor={topMiddleAnchor}
                      x={xPositionMeioDireitaPacker}
                      y={line.topo}
                      image={packer.image}
                      width={packer.width}
                    />
                  </>
                )}
                <Sprite
                  scale={1}
                  anchor={topMiddleAnchor}
                  x={xPositionCurtaPacker}
                  y={line.topo}
                  image={packer.image}
                  width={packer.width}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: xPositionCurtaPacker,
                        y: line.topo + 35
                      },
                      {
                        x: xPositionCurtaPacker,
                        y: line.topo + 35
                      },
                      packerEquipmentHoverText(
                        line.originalElement.tipo,
                        line.originalElement.profundidade
                      ),
                      { width: 150, height: 70 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SchematicOilWellPacker;
