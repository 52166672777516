import { Sprite } from '@pixi/react';
import React from 'react';
import { GetCustomPixiText } from '../PixiText';
import ScaleUtils from '../ScaleUtils';
import { SchematicReferenceConstants } from '../SchematicReferenceConstants';
import { TextItem } from '../TextsUtils';
import { ParteInferior, Revestimentos } from '../interface';
import {
  bpr,
  colchaoDeAreia,
  furoRevestimentoDireito,
  furoRevestimentoEsquerdo,
  getRevestimentObjects,
  intervaloAbertoDireito,
  intervaloAbertoEsquerdo,
  intervaloIsoladoDireito,
  intervaloIsoladoEsquerdo,
  intervaloSqueezadoDireito,
  intervaloSqueezadoEsquerdo,
  normalizeTypeKey,
  PixiBackgroundMargin,
  subsurfaceElementsKeyMapping,
  tampaoDeCimento,
  VirtualElement
} from '../schematicUtils';
import { RevestimentsAndDiameters } from './Components/Revestiment';

const SchematicOilWellMechanicalConditions: React.FC<any> = (props: {
  subsurfaceEquipments: VirtualElement[];
  hasTwoColumns: boolean;
  wellDiameter: number;
  quartePacker: number;
  drawMarginTop: number;
  distanceBetweenLeftTexts: number;
  distanceBetweenRightTexts: number;
  scaleUtils: ScaleUtils;
  revestimentsAndDiameters: RevestimentsAndDiameters;
  scaleByLastElement: number;
  hoverTooltip: any;
  setShowTooltip: any;
  subsurfaceRevestiments: Revestimentos;
  schematicReferenceConstants: SchematicReferenceConstants;
}) => {
  const xPositionTampaoDeCimento = 0;

  const xPositionBpr = 0;

  const xPositionColchaoDeAreia = 0;

  const topMiddleAnchor = { x: 0.5, y: 0 };
  const leftAnchor = { x: 0, y: 0 };
  const defaultAnchor = { x: 1, y: 0 };

  const defineIntervaloXPosition = (elementY: number) => {
    const { revestiments } = getRevestimentObjects(
      props.subsurfaceRevestiments,
      props.schematicReferenceConstants
    );

    const currentRevestiment = revestiments.find((x: any) => {
      return props.scaleUtils.rescaleHeight(x.depht) > elementY;
    });

    if (!currentRevestiment) {
      return 0;
    }

    return currentRevestiment?.width / 2 || 0;
  };

  return (
    <>
      {props.subsurfaceEquipments.map((line, index: number) => {
        return (
          <React.Fragment key={`condicoes-mecanicas-${index}`}>
            {line.tipo === 'intervalo-aberto' && (
              <>
                <Sprite
                  scale={1}
                  anchor={defaultAnchor}
                  x={-defineIntervaloXPosition(line.topo)}
                  y={line.topo}
                  image={intervaloAbertoEsquerdo.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: -defineIntervaloXPosition(line.topo),
                        y: line.topo + 55
                      },
                      {
                        x: -defineIntervaloXPosition(line.topo),
                        y: line.topo + 60
                      },
                      getIntervalosTooltipText(line),
                      { width: 300, height: 100 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
                <Sprite
                  scale={1}
                  anchor={leftAnchor}
                  x={defineIntervaloXPosition(line.topo)}
                  y={line.topo}
                  image={intervaloAbertoDireito.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: defineIntervaloXPosition(line.topo),
                        y: line.topo + 55
                      },
                      {
                        x: defineIntervaloXPosition(line.topo),
                        y: line.topo + 60
                      },
                      getIntervalosTooltipText(line),
                      { width: 300, height: 100 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
              </>
            )}

            {line.tipo === 'intervalo-isolado' && (
              <>
                <Sprite
                  scale={1}
                  anchor={defaultAnchor}
                  x={-defineIntervaloXPosition(line.topo)}
                  y={line.topo}
                  image={intervaloIsoladoEsquerdo.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: -defineIntervaloXPosition(line.topo),
                        y: line.topo + 55
                      },
                      {
                        x: -defineIntervaloXPosition(line.topo),
                        y: line.topo + 60
                      },
                      getIntervalosTooltipText(line),
                      { width: 300, height: 100 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
                <Sprite
                  scale={1}
                  anchor={leftAnchor}
                  x={defineIntervaloXPosition(line.topo)}
                  y={line.topo}
                  image={intervaloIsoladoDireito.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: defineIntervaloXPosition(line.topo),
                        y: line.topo + 55
                      },
                      {
                        x: defineIntervaloXPosition(line.topo),
                        y: line.topo + 60
                      },
                      getIntervalosTooltipText(line),
                      { width: 300, height: 100 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
              </>
            )}

            {(line.tipo === 'intervalo-squeezado' ||
              line.tipo === 'recimentacao') && (
              <>
                <Sprite
                  scale={1}
                  anchor={defaultAnchor}
                  x={-defineIntervaloXPosition(line.topo)}
                  y={line.topo}
                  image={intervaloSqueezadoEsquerdo.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: -defineIntervaloXPosition(line.topo),
                        y: line.topo + 55
                      },
                      {
                        x: -defineIntervaloXPosition(line.topo),
                        y: line.topo + 60
                      },
                      getIntervalosTooltipText(line),
                      { width: 300, height: 100 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />

                <Sprite
                  scale={1}
                  anchor={leftAnchor}
                  x={defineIntervaloXPosition(line.topo)}
                  y={line.topo}
                  image={intervaloSqueezadoDireito.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: defineIntervaloXPosition(line.topo),
                        y: line.topo + 55
                      },
                      {
                        x: defineIntervaloXPosition(line.topo),
                        y: line.topo + 60
                      },
                      getIntervalosTooltipText(line),
                      { width: 300, height: 100 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
              </>
            )}

            {line.tipo === 'tampao-de-cimento' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={xPositionTampaoDeCimento}
                y={line.topo}
                image={tampaoDeCimento.image}
                width={props.wellDiameter}
                height={line.height}
              />
            )}

            {(line.tipo === 'bpr' || line.tipo === 'bpp') && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={xPositionBpr}
                y={line.topo}
                image={bpr.image}
                width={props.wellDiameter}
              />
            )}

            {line.tipo === 'colchao-de-areia' && (
              <Sprite
                scale={1}
                anchor={topMiddleAnchor}
                x={xPositionColchaoDeAreia}
                y={line.topo}
                image={colchaoDeAreia.image}
                width={props.wellDiameter}
              />
            )}

            {line.tipo === 'furo-no-revestimento' && (
              <>
                <Sprite
                  scale={1}
                  anchor={defaultAnchor}
                  x={-defineIntervaloXPosition(line.topo)}
                  y={line.topo}
                  image={furoRevestimentoEsquerdo.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: -defineIntervaloXPosition(line.topo),
                        y: line.topo + 55
                      },
                      {
                        x: -defineIntervaloXPosition(line.topo),
                        y: line.topo + 60
                      },
                      getIntervalosTooltipText(line),
                      { width: 300, height: 100 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
                <Sprite
                  scale={1}
                  anchor={leftAnchor}
                  x={defineIntervaloXPosition(line.topo)}
                  y={line.topo}
                  image={furoRevestimentoDireito.image}
                  eventMode={'static'}
                  mouseover={() => {
                    props.hoverTooltip(
                      {
                        x: defineIntervaloXPosition(line.topo),
                        y: line.topo + 55
                      },
                      {
                        x: defineIntervaloXPosition(line.topo),
                        y: line.topo + 60
                      },
                      getIntervalosTooltipText(line),
                      { width: 300, height: 100 }
                    );
                  }}
                  mouseout={() => props.setShowTooltip(false)}
                />
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const groupTextsByTypeAndProximity = (
  texts: any[],
  proximityThreshold: number
) => {
  const groupedTexts: any[] = [];
  let currentGroup: any[] = [];

  texts.forEach((text, index) => {
    if (currentGroup.length === 0) {
      currentGroup.push(text);
    } else {
      const lastTextInGroup = currentGroup[currentGroup.length - 1];
      if (Math.abs(lastTextInGroup.topo - text.topo) <= proximityThreshold) {
        currentGroup.push(text);
      } else {
        groupedTexts.push(currentGroup);
        currentGroup = [text];
      }
    }

    if (index === texts.length - 1) {
      groupedTexts.push(currentGroup);
    }
  });

  return groupedTexts.sort((a, b) => a[0].topo - b[0].topo);
};

const filterTextsIntervalos = (subsurfaceEquipments: VirtualElement[]) => {
  let textsIntervaloAberto: any[] = [];
  let textsIntervaloIsolado: any[] = [];
  let textsIntervaloSqueezado: any[] = [];

  subsurfaceEquipments
    .filter(
      x =>
        x.tipo === 'intervalo-aberto' ||
        x.tipo === 'intervalo-isolado' ||
        x.tipo === 'intervalo-squeezado'
    )
    .map((line: any) => {
      switch (line.tipo) {
        case 'intervalo-aberto':
          textsIntervaloAberto.push({
            text: getMechanicalConditionsRightText(line),
            topo: line.topo
          });
          break;
        case 'intervalo-isolado':
          textsIntervaloIsolado.push({
            text: getMechanicalConditionsRightText(line),
            topo: line.topo
          });
          break;
        case 'intervalo-squeezado':
          textsIntervaloSqueezado.push({
            text: getMechanicalConditionsRightText(line),
            topo: line.topo
          });
          break;
      }
    });

  return {
    textsIntervaloAberto: textsIntervaloAberto,
    textsIntervaloIsolado: textsIntervaloIsolado,
    textsIntervaloSqueezado: textsIntervaloSqueezado
  };
};

export const SubsurfaceRightTexts = (
  subsurfaceEquipments: VirtualElement[]
) => {
  let pixiTextsIntervaloAberto: any[] = [];
  let pixiTextsIntervaloIsolado: any[] = [];
  let pixiTextsIntervaloSqueezado: any[] = [];

  const textsIntervalos = filterTextsIntervalos(subsurfaceEquipments);

  const groupedTextsIntervaloAberto = groupTextsByTypeAndProximity(
    textsIntervalos.textsIntervaloAberto,
    30
  );
  const groupedTextsIntervaloIsolado = groupTextsByTypeAndProximity(
    textsIntervalos.textsIntervaloIsolado,
    30
  );
  const groupedTextsIntervaloSqueezado = groupTextsByTypeAndProximity(
    textsIntervalos.textsIntervaloSqueezado,
    30
  );

  groupedTextsIntervaloAberto.forEach(group => {
    let pixiText = GetCustomPixiText({
      text: group
        .flat()
        .map((x: any) => x.text)
        .join('\n'),
      fontFamily: 'Graphie',
      fontStyle: 'normal',
      fontSize: 11,
      textColor: 0x000000,
      wordWrapWidth: 400
    });

    pixiTextsIntervaloAberto.push(
      new TextItem(pixiText, group[0].topo, PixiBackgroundMargin)
    );
  });

  groupedTextsIntervaloIsolado.forEach(group => {
    let pixiText = GetCustomPixiText({
      text: group
        .flat()
        .map((x: any) => x.text)
        .join('\n'),
      fontFamily: 'Graphie',
      fontStyle: 'normal',
      fontSize: 11,
      textColor: 0x000000,
      wordWrapWidth: 400
    });

    pixiTextsIntervaloIsolado.push(
      new TextItem(pixiText, group[0].topo, PixiBackgroundMargin)
    );
  });

  groupedTextsIntervaloSqueezado.forEach(group => {
    let pixiText = GetCustomPixiText({
      text: group
        .flat()
        .map((x: any) => x.text)
        .join('\n'),
      fontFamily: 'Graphie',
      fontStyle: 'normal',
      fontSize: 11,
      textColor: 0x000000,
      wordWrapWidth: 400
    });

    pixiTextsIntervaloSqueezado.push(
      new TextItem(pixiText, group[0].topo, PixiBackgroundMargin)
    );
  });

  const mergePixiTexts = pixiTextsIntervaloAberto
    .concat(pixiTextsIntervaloIsolado)
    .concat(pixiTextsIntervaloSqueezado);

  return mergePixiTexts;
};

export const KOPRightText = (kop: number, scaleMultiplier: number) => {
  const text = GetCustomPixiText({
    text: `KOP @ ${kop}`,
    fontFamily: 'Graphie',
    fontStyle: 'normal',
    fontSize: 12,
    textColor: 0x000000,
    wordWrapWidth: 300
  });

  return new TextItem(
    text,
    Math.ceil(kop * scaleMultiplier),
    PixiBackgroundMargin
  );
};

export const MechanicalConditionLeftTexts = (
  parteInferior: ParteInferior,
  scaleUtils: ScaleUtils
) => {
  const textColorDefault = 0x000000;
  const textColorFuroRevestimento = 0xff0000;

  return parteInferior.condicoes_mecanicas
    .filter(
      x =>
        subsurfaceElementsKeyMapping[x.tipo.toLowerCase()] !==
          'intervalo-aberto' &&
        subsurfaceElementsKeyMapping[x.tipo.toLowerCase()] !==
          'intervalo-isolado' &&
        subsurfaceElementsKeyMapping[x.tipo.toLowerCase()] !==
          'intervalo-squeezado' &&
        normalizeTypeKey(x.tipo) !== 'sapata-guia'
    )
    .map((line: any) => {
      const text = GetCustomPixiText({
        text: getMechanicalConditionsLeftText(line),
        fontFamily: 'Graphie',
        fontStyle: 'normal',
        fontSize: 11,
        textColor: (normalizeTypeKey(line.tipo) === 'furo-no-revestimento') ? textColorFuroRevestimento : textColorDefault,
        wordWrapWidth: 230
      });

      const depth =
        line.profundidade && line.profundidade > 0
          ? (line.profundidade as number)
          : line.intervalo_inferior && line.intervalo_inferior > 0
            ? (line.intervalo_inferior as number)
            : line.intervalo_superior && line.intervalo_superior > 0
              ? (line.intervalo_superior as number)
              : 0;

      return new TextItem(
        text,
        scaleUtils.rescaleHeight(depth),
        PixiBackgroundMargin
      );
    });
};

const getIntervalosTooltipText = (line: any) => {
  return `${line.originalElement.tipo} ${line.originalElement.zona ?? 'N/A'}\nTopo: ${line.originalElement.intervalo_superior} m, Base: ${line.originalElement.intervalo_inferior} m`;
};

const getMechanicalConditionsRightText = (line: any) => {
  const textIntervalos = `${line.originalElement.tipo} ${line.originalElement.zona ?? 'N/A'} Topo: ${line.originalElement.intervalo_superior ?? '-'} m, Base: ${line.originalElement.intervalo_inferior ?? '-'} m`;
  const textWithTopoEBase = `${line.originalElement.tipo}: Topo: ${line.originalElement.intervalo_superior ?? '-'} m, Base: ${line.originalElement.intervalo_inferior ?? '-'} m`;
  const textWithProfundidade = `${line.originalElement.tipo}: Profundidade: ${line.originalElement.profundidade} m`;

  switch (line.tipo) {
    case 'intervalo-aberto':
    case 'intervalo-isolado':
    case 'intervalo-squeezado':
      return textIntervalos;
    default:
      return line.profundidade && line.profundidade != 0
        ? textWithProfundidade
        : textWithTopoEBase;
  }
};

const getMechanicalConditionsLeftText = (line: any) => {
  const textIntervalos = `${line.tipo} ${line.zona ?? 'S/N'}\nTopo: ${line.intervalo_superior ?? '-'} m, Base: ${line.intervalo_inferior ?? '-'} m`;
  const textIntervalosComProfundidade = `${line.tipo} ${line.zona ?? 'S/N'}\nTopo: ${line.intervalo_superior ?? '-'} m, Base: ${line.intervalo_inferior ?? '-'} m, Profundidade: ${line.profundidade} m`;
  const textWithTopoEBase = `${line.tipo}: Topo: ${line.intervalo_superior ?? '-'} m\nBase: ${line.intervalo_inferior ?? '-'} m`;
  const textWithProfundidade = `${line.tipo}: Profundidade: ${line.profundidade} m`;

  const tipo = subsurfaceElementsKeyMapping[line.tipo.toLowerCase()];

  switch (tipo) {
    case 'intervalo-aberto':
    case 'intervalo-isolado':
    case 'intervalo-squeezado':
      return textIntervalos;
    case 'tampao-de-cimento':
    case 'furo-no-revestimento':
      return line.intervalo_superior > 0
        ? textIntervalos
        : textIntervalosComProfundidade;
    default:
      return line.profundidade && line.profundidade != 0
        ? textWithProfundidade
        : textWithTopoEBase;
  }
};

export default SchematicOilWellMechanicalConditions;
