import {
  StyleSheet,
  Text
} from '@react-pdf/renderer';


const styles = StyleSheet.create({
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 25,
    left: 0,
    right: 30,
    textAlign: 'right',
  },
});

export const PrintedFooter: React.FC<any> = () => {
  return (
    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
    )} fixed />
  )
}