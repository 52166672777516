import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { FaCircleInfo } from 'react-icons/fa6';
import ReactSelect from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';
interface Option {
  value: string;
  label: string;
  order?: number;
}

interface SelectProps<T extends FieldValues, TOption = Option> {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  required?: boolean;
  options: readonly TOption[];
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  isMulti?: boolean;
  className?: string;
  isLoading?: boolean;
  tooltip?: string;
}

export const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  label,
  required = false,
  options,
  placeholder = 'Selecione...',
  errorMessage,
  disabled,
  isMulti = false,
  className,
  isLoading = false,
  tooltip
}: SelectProps<T>) => {
  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex items-start gap-1">
        {label && (
          <label className="font-medium text-sm text-primary">
            {label}
            {required && <span className="text-red ml-1">*</span>}
          </label>
        )}
        {tooltip && (
          <>
            <div data-tooltip-id={tooltip}>
              <FaCircleInfo className="text-sm text-primary ml-1 mt-1" />
            </div>
            <ReactTooltip
              id={`tooltip-${tooltip}`}
              place="top"
              variant="light"
              style={{
                maxWidth: '300px',
                textAlign: 'left',
                backgroundColor: '#fffff',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.3)'
              }}
              content={tooltip}
              opacity={1}
            />
          </>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ReactSelect<Option, boolean>
            {...field}
            isDisabled={disabled || isLoading}
            isLoading={isLoading}
            options={options}
            isMulti={isMulti}
            className={`w-full rounded-md ${className}`}
            placeholder={placeholder}
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#fff',
                borderColor: errorMessage ? '#FF4646' : '#D8D8D8',
                minHeight: '40px',
                borderRadius: '6px',
                '&:hover': {
                  borderColor: errorMessage ? '#FF4646' : '#D8D8D8'
                },
                boxShadow: state.isFocused
                  ? errorMessage
                    ? '0 0 0 1px #FF4646'
                    : '0 0 0 1px #193CB9'
                  : 'none',
                '&:disabled': {
                  backgroundColor: '#f1f1f1',
                  color: '#777777'
                }
              }),
              valueContainer: provided => ({
                ...provided,
                padding: '0 6px'
              }),
              input: provided => ({
                ...provided,
                margin: '0px'
              }),
              indicatorSeparator: () => ({
                display: 'none'
              }),
              indicatorsContainer: provided => ({
                ...provided
              }),
              placeholder: provided => ({
                ...provided,
                color: '#a5a5a5'
              }),
              option: (provided, state) => ({
                ...provided,
                background: state.isSelected ? '#193CB960' : '#fff',
                padding: '0.5rem 1rem',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                fontWeight: '400',
                color: state.isSelected ? '#fff' : '#000',
                cursor: 'pointer',
                '&:hover': {
                  background: state.isSelected ? '#193CB999' : '#F3F4F6'
                }
              }),
              menu: provided => ({
                ...provided,
                zIndex: 9999,
                overflow: 'visible',
                position: 'relative'
              }),
              menuList: provided => ({
                ...provided,
                position: 'relative',
                zIndex: 9999
              }),
              menuPortal: provided => ({
                ...provided,
                zIndex: 9999
              })
            }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )}
      />
      {errorMessage && <p className="mt-1 text-sm text-red">{errorMessage}</p>}
    </div>
  );
};
