import { Sprite } from '@pixi/react';
import React from 'react';
import { ParteSuperior } from '../interface';
import {
  filterTopSchematicElements,
  getTopSchematicElement
} from '../schematicUtils';

export const SchematicTopElements: React.FC<any> = (props: {
  parteSuperior: ParteSuperior;
  baseHeight: number;
  hasTwoColumns: boolean;
}) => {
  return (
    <>
      {filterTopSchematicElements(
        props.parteSuperior.equipamento_de_superficie
      ).map((element, index: number) => {
        return (
          <React.Fragment key={`equipamento-topo-${index}`}>
            {props.hasTwoColumns ? (
              <>
                <Sprite
                  scale={1}
                  anchor={{
                    x: getTopSchematicElement(element.tipo).anchor,
                    y: 1
                  }}
                  x={25}
                  y={-props.baseHeight}
                  image={getTopSchematicElement(element.tipo).image}
                />
                <Sprite
                  scale={1}
                  anchor={{
                    x: getTopSchematicElement(element.tipo).anchor,
                    y: 1
                  }}
                  x={-25}
                  y={-props.baseHeight}
                  image={getTopSchematicElement(element.tipo).image}
                />
              </>
            ) : (
              <Sprite
                scale={1}
                anchor={{
                  x: getTopSchematicElement(element.tipo).anchor,
                  y: 1
                }}
                x={0}
                y={-props.baseHeight}
                image={getTopSchematicElement(element.tipo).image}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
