import Tabs from '@/components/atoms/Tab';
import { EnvelopeDataRegister, envelopeProps } from './EnvelopeDataRegister';
import { EnvelopeDataVisualization } from './EnvelopeDataVisualization';
import { useEffect, useState } from 'react';
import { getEnvelopeData } from '@/services/wellhandover.services';

export const SafeEnvelopeTabs: React.FC<any> = ({ wellSchematicId }) => {
  const [envelopeData, setEnvelopeData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        const response = await getEnvelopeData(id);
        setEnvelopeData(response);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData(wellSchematicId);
  }, [wellSchematicId]);

  const tabs = [
    {
      id: 0,
      label: 'Visualizar informações',
      content: (
        <EnvelopeDataVisualization
          envelopeData={envelopeData}
          setEnvelopeData={setEnvelopeData}
        />
      )
    },
    {
      id: 1,
      label: 'Cadastro de informações',
      content: (
        <EnvelopeDataRegister
          envelopeData={envelopeData}
          wellSchematicId={wellSchematicId}
          setEnvelopeData={setEnvelopeData}
        />
      )
    }
  ];

  return <Tabs tabs={tabs} />;
};
