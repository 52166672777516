import { optionSchema } from '@/pages/FormsPage/optionSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  type: optionSchema(true),
  level_type: optionSchema(true),
  measurement_location: optionSchema(true),
  anp_deadline: z.date().optional()
});

export type CreateSonologFormData = z.infer<typeof schema>;

const useCreateSonologForm = () => {
  const methods = useForm<CreateSonologFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    console.log('load saved data');
    if (data) {
      console.log('data received');
      setValue('type', data?.type);
      setValue('level_type', data?.level_type);
      setValue('measurement_location', data?.measurement_location);
      setValue('anp_deadline', data?.anp_deadline);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateSonologForm;
