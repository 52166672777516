import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { optionSchema } from '../../../optionSchema';

const getSchema = (isOilwellFieldMulti: boolean) => {
  if (isOilwellFieldMulti) {
    return z.object({
      location: z
        .array(optionSchema(true))
        .min(1, { message: 'Este campo é obrigatório' })
        .default([]),
      oil_rank: z.string(),
      gas_rank: z.string(),
      committee_restriction: z.enum(['Sim', 'Não']),
      elevation_method: z.string(),
      field: z.string(),
      anp_code: z.string(),
      fluid_type: z.string(),
      oilwell_type: z.string(),
      critical_activity: z.boolean()
    });
  }

  return z.object({
    location: optionSchema(true),
    oil_rank: z.string(),
    gas_rank: z.string(),
    committee_restriction: z.enum(['Sim', 'Não']),
    elevation_method: z.string(),
    field: z.string(),
    anp_code: z.string(),
    fluid_type: z.string(),
    oilwell_type: z.string(),
    critical_activity: z.boolean()
  });
};

export type CreateGeneralInformationFormData = {
  location:
    | { label: string; value: string }
    | { label: string; value: string }[];
  oil_rank: string;
  gas_rank: string;
  committee_restriction: 'Sim' | 'Não';
  elevation_method: string;
  field: string;
  anp_code: string;
  fluid_type: string;
  oilwell_type: string;
  critical_activity: boolean;
};

const useCreateGeneralInformationForm = ({
  isOilwellFieldMulti = false
}: {
  isOilwellFieldMulti?: boolean;
}) => {
  const schema = getSchema(isOilwellFieldMulti);

  const methods = useForm({
    resolver: zodResolver(schema as any),
    mode: 'onChange'
  }) as unknown as ReturnType<typeof useForm<CreateGeneralInformationFormData>>;

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    if (data) {
      setValue('location', data?.location);
      setValue('oil_rank', data?.oil_rank);
      setValue('gas_rank', data?.gas_rank);
      setValue('committee_restriction', data?.committee_restriction);
      setValue('elevation_method', data?.elevation_method);
      setValue('field', data?.field);
      setValue('anp_code', data?.anp_code);
      setValue('fluid_type', data?.fluid_type);
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateGeneralInformationForm;
