import { ControlledSelect } from '@/pages/FormsPage/ControlledSelect/ControlledSelect';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { Linha } from './Linha.enum';
import { Priority } from './Priority.enum';
import useCreateTesteEstanqueidadeForm, {
  CreateTesteEstanqueidadeFormData
} from './useCreateTesteEstanqueidadeForm';

interface TesteEstanqueidadeFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const TesteEstanqueidadeForm = forwardRef<
  FormRef<CreateTesteEstanqueidadeFormData>,
  TesteEstanqueidadeFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { methods } = useCreateTesteEstanqueidadeForm();

  const {
    register,
    formState: { errors },
    watch,
    trigger,
    control
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('coleta pressoes Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <ControlledSelect
          label="Linha a ser testada"
          name="line_to_be_tested"
          control={control}
          options={Object.values(Linha).map(type => ({
            label: type,
            value: type
          }))}
          errorMessage={errors.line_to_be_tested?.message}
          required
        />

        <ControlledSelect
          label="Prioridade"
          name="priority"
          control={control}
          options={Object.values(Priority).map(type => ({
            label: type,
            value: type
          }))}
          errorMessage={errors.priority?.message}
          required
        />
      </div>
    </FormWrapper>
  );
});

export default TesteEstanqueidadeForm;
