import Button from '@/components/atoms/Button';
import Spinner from '@/components/atoms/Spinner';
import {
  getOilWellIdbyName,
  getOneOilwellByApplicationDate,
  getSchematicData
} from '@/services/oilwells.services';
import { getIssuesRedmine } from '@/services/utils.services';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoGrid from '../../components/organisms/VerticalTable';
import {
  ambienteInfo,
  generalInfo,
  medidasInfo,
  technicalInfo
} from '../../utils/oilWellDataStructure';
import { LifecycleStep, LifecycleSteps } from './LifeCycleSteps';

interface OilWellProps {
  name: string;
  id: string;
  schematic: any;
}

const OilWellInfo: React.FC = () => {
  const { oilwellname } = useParams();
  const [history, setHistory] = useState<LifecycleStep[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDate] = useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });
  const [oilWellData, setOilWellData] = useState<any>(null);

  const navigate = useNavigate();

  const getCustomFieldValue = (customFields: any, id: number) => {
    const field = customFields.find((field: any) => field.id === id);
    return field ? field.value : null;
  };

  useEffect(() => {
    const fetchOilWellData = async () => {
      try {
        const oilWellTest: OilWellProps = (await getOilWellIdbyName(
          oilwellname ?? ''
        )) || { name: '', id: '0', schematic: {} };
        const schematic = (await getSchematicData(oilwellname ?? '')) || {};

        const res =
          (await getIssuesRedmine({
            project_id: 9,
            tracker_id: 31,
            status_id: '64',
            cf_1975: oilWellTest.name,
            order: 'created_on:desc',
            limit: 1
          })) || {};

        if (oilWellTest) {
          oilWellTest.schematic = schematic;

          const customFields = res.data.issues[0]?.custom_fields;
          if (customFields) {
            Object.assign(oilWellTest, {
              surgencia: getCustomFieldValue(customFields, 2040),
              dataSurgencia: format(
                new Date(getCustomFieldValue(customFields, 2307)),
                'dd/MM/yyyy'
              )
            });
          }
        }

        setOilWellData(oilWellTest);

        const { history } = await getOneOilwellByApplicationDate(
          oilWellTest.id,
          {
            date: currentDate.startDate
          }
        );

        setHistory(
          history.sort(
            (a: any, b: any) =>
              new Date(a.createdAt || '').getTime() -
              new Date(b.createdAt || '').getTime()
          )
        );

        setIsLoading(false);
      } catch {
        toast.error('Erro ao buscar dados do poço');
        setIsLoading(false);
      }
    };

    fetchOilWellData();
  }, [oilwellname, currentDate]);

  return (
    <div className="flex flex-col gap-4 mt-5">
      <div className="flex justify-end w-full flex-row gap-4">
        <Button
          onClick={() => navigate(`/home/well-handover/${oilWellData?.name}`)}
          className={`w-fit h-8 text-xs ${
            oilWellData?.name === undefined
              ? 'bg-gray-lighter hover:bg-gray-lighter cursor-not-allowed'
              : ''
          }`}
          title="Visualizar Well Handover"
        />
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-4 bg-white p-4 rounded-lg">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <h1 className="text-primary font-bold text-lg flex">
                  Dados gerais do poço
                </h1>

                {[generalInfo, technicalInfo, medidasInfo, ambienteInfo].map(
                  (info, key) => (
                    <div key={key}>
                      <InfoGrid items={info(oilWellData)} />
                      {key < 3 && (
                        <div className="border-t border-gray-lighter/40 w-full" />
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 bg-white p-4 pb-10 rounded-lg">
            <h1 className="text-primary font-bold text-lg flex">
              Etapas do ciclo de vida
            </h1>

            <div className="flex flex-col gap-4">
              <LifecycleSteps currentStep={history.length} history={history} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OilWellInfo;
