import Select, {
  GroupBase,
  MultiValue,
  StylesConfig,
  ThemeConfig,
  components
} from 'react-select';
import Async from 'react-select/async';

type SelectOptions = {
  value: string;
  label: string;
  order?: number;
};

type Props = {
  options: SelectOptions[];
  value?: MultiValue<SelectOptions> | undefined;
  onChange: (selectedOptions: MultiValue<SelectOptions>) => void;
  className?: string;
  asyncFn?: () => Promise<any>;
  secondary?: boolean;
};

export const selectTheme = (theme: any) => ({
  ...theme,
  borderRadius: 6,
  spacing: {
    ...theme.spacing,
    controlHeight: 34
  },
  colors: {
    ...theme.colors,
    primary: '#aabbff',
    neutral60: '#E5E5E5'
  }
});

export const selectInputStyles: StylesConfig<
  SelectOptions,
  any,
  GroupBase<SelectOptions>
> = {
  placeholder: provided => ({
    ...provided,
    color: 'white',
    fontWeight: '300',
    fontSize: '16px'
  }),
  valueContainer: base => ({
    ...base,
    height: 'auto'
  }),
  multiValue: base => ({
    ...base
  }),
  control: provided => ({
    ...provided,
    background: '#193CB9',
    borderRadius: '8px'
  }),
  menuList: provided => ({
    ...provided,
    minHeight: '100px',
    fontWeight: 'thin',
    color: 'white'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#193CB9',
    fontSize: '13px',
    fontWeight: state.isSelected ? 'bold' : 'normal'
  }),
  menuPortal: provided => ({
    ...provided,
    fontSize: '13px'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white'
  }),
  input: provided => ({
    ...provided,
    color: 'white',
    letterSpacing: '.2rem'
  })
};

export const selectInputStylesSecondary: StylesConfig<
  SelectOptions,
  true,
  GroupBase<SelectOptions>
> = {
  placeholder: provided => ({
    ...provided,
    color: '#666',
    fontWeight: '300',
    fontSize: '16px'
  }),
  valueContainer: base => ({
    ...base,
    height: '32px'
  }),
  multiValue: base => ({
    ...base
  }),
  control: provided => ({
    ...provided,
    background: 'transparent',
    borderRadius: '8px',
    borderColor: '#dddd'
  }),
  menuList: provided => ({
    ...provided,
    minHeight: '100px',
    fontWeight: 'thin',
    color: 'white'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#4660d1',
    fontSize: '13px',
    fontWeight: state.isSelected ? 'bold' : 'normal'
  }),
  menuPortal: provided => ({
    ...provided,
    fontSize: '13px'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'gray'
  }),
  input: provided => ({
    ...provided,
    color: 'gray',
    letterSpacing: '.2rem'
  })
};

export const ValueContainer = ({ children, ...props }: any) => {
  const { hasValue } = props;
  const [tags, otherChildren] = children;

  return (
    <components.ValueContainer {...props}>
      {!hasValue ? (
        <>{children}</>
      ) : (
        <>
          {tags}
          {otherChildren}
        </>
      )}
    </components.ValueContainer>
  );
};

export const ValueContainerSecondary = ({ children, ...props }: any) => {
  const { hasValue } = props;
  const [tags, otherChildren] = children;

  return (
    <components.ValueContainer {...props}>
      {!hasValue ? (
        <>{children}</>
      ) : (
        <>
          <span className="font-medium text-sm">
            {tags.length} item{tags.length > 1 && 's'}
          </span>
          {otherChildren}
        </>
      )}
    </components.ValueContainer>
  );
};

export const selectProps = {
  theme: selectTheme,
  styles: selectInputStyles,
  components: { ValueContainer }
};

const SelectInput: React.FC<Props> = (props: Props) => {
  const theme: ThemeConfig = (theme: any) => ({
    ...theme,
    borderRadius: 6,
    spacing: {
      ...theme.spacing,
      controlHeight: 34
    },
    colors: {
      ...theme.colors,
      primary: '#aabbff',
      neutral60: '#E5E5E5'
    }
  });

  return props.asyncFn !== undefined ? (
    <Async
      className={`w-full rounded-lg font-medium ${props.className}`}
      isMulti={true}
      options={props.options}
      placeholder="Selecione..."
      onChange={props.onChange}
      theme={theme}
      value={props.value}
      hideSelectedOptions={!props.secondary}
      closeMenuOnSelect={!props.secondary}
      styles={props.secondary ? selectInputStylesSecondary : selectInputStyles}
      components={{
        ValueContainer: props.secondary
          ? ValueContainerSecondary
          : ValueContainer
      }}
      loadOptions={props.asyncFn}
      cacheOptions
      defaultOptions
      menuPosition="fixed"
    />
  ) : (
    <Select
      className={`w-full rounded-lg font-medium ${props.className}`}
      isMulti={true}
      options={props.options}
      placeholder="Selecione..."
      onChange={props.onChange}
      theme={theme}
      hideSelectedOptions={!props.secondary}
      closeMenuOnSelect={!props.secondary}
      value={props.value}
      styles={props.secondary ? selectInputStylesSecondary : selectInputStyles}
      components={{
        ValueContainer: props.secondary
          ? ValueContainerSecondary
          : ValueContainer
      }}
      menuPosition="fixed"
    />
  );
};

export default SelectInput;
