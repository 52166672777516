import { TableGrid } from '@/components/atoms/TableGrid';
import SearchInput from '@/components/molecules/SearchInput';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/atoms/Button';
import Spinner from '../../../components/atoms/Spinner';
import gasMeterColumns from '../../../constants/tableColumns/mdm/gasmeter';
import { getAllGasMeters } from '../../../services/oilwells.services';

const GasMeter = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const handleGetGasMeter = async () => {
    try {
      const response = await getAllGasMeters(search);
      return response;
    } catch (error) {
      toast.error('Erro ao buscar medidores de gás');
    }
  };

  const { data, isLoading } = useQuery(
    ['gas-meters-adm', search],
    handleGetGasMeter,
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 3, // 3 minutos
      cacheTime: 1000 * 60 * 7 // 7 minutos
    }
  );

  const handleNavigateToCreateOilWell = () => {
    navigate('/gas-meter/create');
  };

  return (
    <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
      <Button
        title="Novo Medidor de gás"
        type="button"
        className="absolute right-14 -top-[56px]"
        icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
        onClick={handleNavigateToCreateOilWell}
      />

      <section className="flex justify-between items-center">
        <h1 className="text-gray font-bold text-lg flex">
          Medidor de gás
          {isLoading && <Spinner className="ml-2" size={16} />}
        </h1>

        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </section>

      {!data && (
        <div className="flex flex-col items-center justify-center">
          <Spinner className="mt-4" size={32} />
        </div>
      )}

      {data && (
        <section className="flex flex-col gap-2">
          <TableGrid cols={gasMeterColumns} data={data || []} />
        </section>
      )}
    </div>
  );
};

export default GasMeter;
