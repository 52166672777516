import { Sprite } from '@pixi/react';
import React from 'react';
import { restricaoObstrucao, VirtualElement } from '../schematicUtils';

const SchematicOilWellRestricoesObstrucoes: React.FC<any> = (props: {
  subsurfaceEquipments: VirtualElement[];
  hasTwoColumns: boolean;
  wellDiameter: number;
  quartePacker: number;
  topoDoPoco: number;
}) => {
  const xPositionLonga = props.hasTwoColumns
    ? props.wellDiameter / 3.7
    : props.wellDiameter / 9;
  const xPositionCurta = props.hasTwoColumns
    ? -props.wellDiameter / 7
    : props.wellDiameter / 8;

  const topLeftAnchor = { x: 1, y: 0 };

  return (
    <>
      {props.subsurfaceEquipments
        .filter(x => x.tipo === 'restricao' || x.tipo === 'obstrucao')
        .map((line, index: number) => {
          return (
            <React.Fragment key={`restricao-obstrucao-${index}`}>
              <Sprite
                scale={1}
                anchor={topLeftAnchor}
                x={
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'longa' ||
                  line.originalElement.coluna_de_producao.toLowerCase() ===
                    'única'
                    ? xPositionLonga
                    : xPositionCurta
                }
                y={line.topo}
                image={restricaoObstrucao.image}
              />
            </React.Fragment>
          );
        })}
    </>
  );
};

export default SchematicOilWellRestricoesObstrucoes;
