import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateTesteImplementacaoBastaoForm, {
  CreateTesteImplementacaoBastaoFormData
} from './useCreateTesteImplementacaoBastaoForm';

interface TesteImplementacaoBastaoFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const TesteImplementacaoBastaoForm = forwardRef<
  FormRef<CreateTesteImplementacaoBastaoFormData>,
  TesteImplementacaoBastaoFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { schema, methods, useLoadSavedData } =
    useCreateTesteImplementacaoBastaoForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue,
    control
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('alteracao bean Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="flex gap-4">
        <Input
          label="Cor sugerida"
          {...register('suggested_color')}
          errorMessage={errors.suggested_color?.message}
          required
        />
      </div>
    </FormWrapper>
  );
});

export default TesteImplementacaoBastaoForm;
