import {
  AlertConfigDTO,
  AlertSection,
  AlertVariablesDTO,
  UserSettingsDTO
} from '@/pages/Alerts/SettingsPage/Components/types';
import api from '@/services/apiPortal';
import { getAllOilwells } from '@/services/oilwells.services';
import { useAccount } from '@azure/msal-react';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import { OilwellToElevationMethodMap } from './OilwellToElevationMethodMap';

const OILWELLS_LIMIT = 650;

const useAlertConfig = () => {
  const user = useAccount();

  // pegar o email do usuário logado
  const author_email = user?.username;

  let isOilwellMapInitialized = false;

  const fetchOilwells = async (fieldValues: FieldValues): Promise<string[]> => {
    const { search, ...filters } = fieldValues;
    const { data } = await getAllOilwells(0, OILWELLS_LIMIT, search, filters);
    const oilwells = data?.map((oilwell: any) => oilwell.name);

    // Atualiza o mapa na primeira vez que fetchOilwells for chamado
    if (!isOilwellMapInitialized) {
      data.forEach((oilwell: any) => {
        OilwellToElevationMethodMap.set(oilwell.name, oilwell.elevationMethod);
      });
      isOilwellMapInitialized = true;
    }

    return oilwells;
  };

  const fetchAlertVariables = async (
    oilwells: string[]
  ): Promise<AlertVariablesDTO> => {
    const { data } = await api.get(`/oilwells`, {
      params: {
        route: 'alert_config',
        oilwells: oilwells.join(',')
      }
    });
    return data;
  };

  const fetchOilwellAlertVariables = async (
    oilwell: string,
    section: AlertSection
  ): Promise<AlertConfigDTO[]> => {
    const { data } = await api.get(`/oilwells`, {
      params: {
        route: 'oilwell_config_by_group',
        oilwell: oilwell,
        alert_group_type: section
      }
    });
    return data;
  };

  const submitAlertVariables = async (
    fieldValues: FieldValues
  ): Promise<void> => {
    try {
      const _ = await api.post(`/oilwells/update-alerts-config`, {
        data: fieldValues,
        user_email: author_email
      });
      toast.success('Configurações atualizadas com sucesso');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Erro ao atualizar configurações: ${error.message}`);
      } else {
        toast.error('Erro ao atualizar configurações');
      }
    }
    return;
  };

  const fetchUsers = async (
    name: string,
    page: number,
    perPage: number
  ): Promise<{
    data: UserSettingsDTO[];
    pagy: { count: number; page: number };
  }> => {
    const { data } = await api.get(`/oilwells`, {
      params: {
        route: 'alert_user',
        email: name,
        page,
        per_page: perPage
      }
    });
    const users = data.data?.map((user: any) => {
      return {
        email: user.email,
        receive_troughput_email: user.receive_troughput_email,
        receive_pressure_email: user.receive_pressure_email,
        receive_elevation_email: user.receive_elevation_email,
        receive_no_pressure_measure_email:
          user.receive_no_pressure_measure_email
      };
    });
    return { data: users, pagy: data.pagy };
  };

  const handleAddOrUpdateUsers = async (
    users: UserSettingsDTO[]
  ): Promise<void> => {
    try {
      const _ = await api.post(`/oilwells/alerts/update-alert-user`, {
        data: users,
        user_email: author_email
      });
      toast.success('Configurações atualizadas com sucesso');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Erro ao atualizar configurações: ${error.message}`);
      } else {
        toast.error('Erro ao atualizar configurações');
      }
    }
    return;
  };

  const handleDeletion = async (email: string) => {
    const users: UserSettingsDTO[] = [
      {
        email,
        active: false,
        receive_troughput_email: false,
        receive_pressure_email: false,
        receive_elevation_email: false,
        receive_no_pressure_measure_email: false
      }
    ];

    try {
      const _ = await api.post(`/oilwells/alerts/update-alert-user`, {
        data: users,
        user_email: author_email
      });
      toast.success('Configurações atualizadas com sucesso');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Erro ao atualizar configurações: ${error.message}`);
      } else {
        toast.error('Erro ao atualizar configurações');
      }
    }
    return;
  };

  const hasPermissionToEdit =
    user?.idTokenClaims?.roles?.includes('Portal.Admin') ||
    user?.idTokenClaims?.roles?.includes('Portal.AlertEditor');

  return {
    fetchOilwells,
    fetchAlertVariables,
    fetchOilwellAlertVariables,
    submitAlertVariables,
    fetchUsers,
    handleAddOrUpdateUsers,
    handleDeletion,
    hasPermissionToEdit
  };
};

export default useAlertConfig;
