import Accordion from '@/components/atoms/Accordion';
import Button from '@/components/atoms/Button';
import { PageWrapper } from '@/components/atoms/PageWrapper';
import Spinner from '@/components/atoms/Spinner';
import Table from '@/components/organisms/Table';
import schematicOilWellPeixe from '@/constants/tableColumns/schematic/schematicOilWellPeixe';
import schematicOilWellSubSurfaceCols from '@/constants/tableColumns/schematic/schematicOilWellSubSurfaceCols';
import schematicOilWellSurfaceCols from '@/constants/tableColumns/schematic/schematicOilWellSurfaceCols';
import { WellHandoverResponse } from '@/services/wellhandover.services';
import { formatDate, formatDateYearMonthDay } from '@/utils/utils';
import {
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { useEffect, useRef, useState } from 'react';
import { BiErrorAlt } from 'react-icons/bi';
import { FaFish } from 'react-icons/fa';
import { FaFileExport } from 'react-icons/fa6';
import { GoAlertFill } from 'react-icons/go';
import { SafeEnvelopeModal } from './SafeEnvelope/SafeEnvelope';
import { SchematicOilWellCanva } from './SchematicOilWellCanva';
import { PrintedReport } from './print/PrintedReport';
import { subsurfaceLongColumn, subsurfaceShortColumn, surfaceEquipmentsList } from './schematicUtils';

interface SchematicOilWellProps {
  oilwell: string;
  mockSchematic: WellHandoverResponse;
  schematicUniqueKey: string;
}

export const SchematicOilWell: React.FC<SchematicOilWellProps> = ({
  oilwell: oilwellId,
  mockSchematic,
  schematicUniqueKey
}) => {
  const [containerWidth, setSchematicContainerWidth] = useState(600);
  const [modalOpen, setModalOpen] = useState(false);
  const schematicOilwellCanvaRef = useRef<HTMLDivElement>(null);
  const [schematicCanvaImage, setSchematicCanvaImage] = useState<any>(null);
  const [schematicCanvaImageLoading, setSchematicCanvaImageLoading] =
    useState<boolean>(false);

  const [headerData, setHeaderData] = useState<any>([
    {
      poco: '',
      campo: '',
      letDown: '',
      dataAprovacao: ''
    }
  ]);

  const [equipamentosSuperficie, setEquipamentosSuperficie] = useState<any[]>(
    []
  );
  const [
    equipamentosSubsuperficieLongaList,
    setEquipamentosSubsuperficieLongaList
  ] = useState<any[]>([]);
  const [
    equipamentosSubsuperficieCurtaList,
    setEquipamentosSubsuperficieCurtaList
  ] = useState<any[]>([]);

  const mockRestricoes = mockSchematic.jsonData.parte_inferior.restricoes;
  const mockObstrucoes = mockSchematic.jsonData.parte_inferior.obstrucoes;
  const mockPeixe = mockSchematic.jsonData.parte_inferior.peixes;

  const hasTwoColumns =
    mockSchematic.jsonData.parte_superior.numero_de_colunas_de_producao > 1 &&
    mockSchematic.jsonData.parte_inferior.numero_de_colunas_de_producao > 1;

  useEffect(() => {
    const handleResize = () => resizeSchematicOilwellContainer();
    window.addEventListener('resize', handleResize);
    resizeSchematicOilwellContainer();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setEquipamentosSuperficieListMock();
    setEquipamentosSubsuperficieLongaListMock();
    setEquipamentosSubsuperficieCurtaListMock();
    handleHeaderData();
  }, [mockSchematic]);

  const setEquipamentosSuperficieListMock = () => {
    const equipamentosSuperficieList = surfaceEquipmentsList(mockSchematic.jsonData.parte_superior);

    setEquipamentosSuperficie(
      equipamentosSuperficieList.sort((a, b) =>
        a.equipamento.localeCompare(b.equipamento)
      )
    );
  };

  const setEquipamentosSubsuperficieLongaListMock = () => {
    const equipamentosSubsuperficieLongaList = subsurfaceLongColumn(mockSchematic.jsonData.parte_inferior, hasTwoColumns);
    setEquipamentosSubsuperficieLongaList(equipamentosSubsuperficieLongaList);
  };

  const setEquipamentosSubsuperficieCurtaListMock = () => {
    const equipamentosSubsuperficieCurtaList = subsurfaceShortColumn(mockSchematic.jsonData.parte_inferior, hasTwoColumns);
    setEquipamentosSubsuperficieCurtaList(equipamentosSubsuperficieCurtaList);
  };

  const resizeSchematicOilwellContainer = () => {
    if (schematicOilwellCanvaRef !== null) {
      const containerDiv =
        schematicOilwellCanvaRef.current?.getBoundingClientRect();
      if (containerDiv) {
        setSchematicContainerWidth(containerDiv.width);
      }
    }
  };

  const handleSchematicCanvaImage = (data: any) => {
    setSchematicCanvaImage(data);
  };

  const handleSchematicCanvaImageLoading = (isLoading: boolean) => {
    setSchematicCanvaImageLoading(isLoading);
  };

  const handleHeaderData = () => {
    setHeaderData([
      {
        poco: oilwellId,
        campo: mockSchematic.oilWell?.oilField.name,
        let_down: mockSchematic.jsonData.strutural_data.let_down
          ? mockSchematic.jsonData.strutural_data.let_down
          : '',
        dataAprovacao: mockSchematic.approvedDate
          ? formatDate(mockSchematic.approvedDate)
          : ''
      }
    ]);
  };

  const handleSave = async () => {
    setModalOpen(false);
  };

  const schematicFileName = `Esquemático ${oilwellId ? `- ${oilwellId}` : '' } ${mockSchematic.approvedDate ? ` - ${formatDateYearMonthDay(mockSchematic.approvedDate)}` : ''}.pdf`

  return (
    <PageWrapper>
      <SafeEnvelopeModal
        wellSchematicId={mockSchematic.id}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleSave}
      />

      <div className="flex flex-row w-full justify-between">
        <h1 className="text-primary font-bold text-xl mb-4">
          <span>Esquemático do Poço - {oilwellId ?? ''}</span>
        </h1>

        <div className="flex flex-row space-x-3">
          <Button
            onClick={() => setModalOpen(old => !old)}
            title="Envelope Seguro"
            className={`
                    h-8 w-auto
                    flex justify-center items-center
                    bg-transparent text-primary 
                    text-sm font-bold
                    hover:text-white 
                    border rounded-full
                    disabled:cursor-not-allowed
                    disabled:hover:bg-gray-200
                    disabled:bg-gray-200`}
          />
          {schematicCanvaImageLoading ? (
            <Button
              title="PDF"
              className={`
                    h-8 w-20
                    flex justify-center items-center
                    bg-transparent text-primary 
                    text-sm font-bold
                    hover:text-white 
                    border rounded-full
                    disabled:cursor-not-allowed
                    disabled:hover:bg-gray-200
                    disabled:bg-gray-200`}
              icon={(<Spinner size={16} />)}
              disabled={true}
            />
          ) : (
            <PDFDownloadLink
              document={
                <PrintedReport
                  schematic={mockSchematic}
                  schematicCanvaImage={schematicCanvaImage}
                  oilwellId={oilwellId}
                  headerData={headerData}
                  hasTwoColumns={hasTwoColumns}
                />
              }
              fileName={schematicFileName}
              target="_blank"
            >
              <Button
                title="PDF"
                className={`
                      h-8 w-20
                      flex justify-center items-center
                      bg-transparent text-primary 
                      text-sm font-bold
                      hover:text-white 
                      border rounded-full
                      disabled:cursor-not-allowed
                      disabled:hover:bg-gray-200
                      disabled:bg-gray-200`}
                icon={<FaFileExport size={16} />}
              />
            </PDFDownloadLink>
          )}
        </div>
      </div>

      <div className="grid gap-4 grid-cols-1 md:grid-cols-[minmax(0px,80vw)_minmax(0px,20vw)] xl:grid-cols-[minmax(0px,80vw)_minmax(0px,20vw)]">
        <div
          className="flex flex-col flex-wrap bg-white rounded-[10px] min-h-[660px] page-body-canva"
          ref={schematicOilwellCanvaRef}
        >
          <SchematicOilWellCanva
            parentWidth={containerWidth}
            schematicData={mockSchematic.jsonData}
            handleSchematicCanvaImage={handleSchematicCanvaImage}
            handleSchematicCanvaImageLoading={handleSchematicCanvaImageLoading}
            schematicUniqueKey={schematicUniqueKey}
          />
        </div>

        <div className="flex flex-col rounded-[10px] h-full">
          <div className="bg-white rounded-[10px] pb-2 pt-2 px-4 mb-4">
            <Accordion
              key={`SchematicOilWell1`}
              title={'Equipamentos de Superfície'}
              buttonClassName={`bg-white hover:bg-[#fff] visible px-0 text-left whitespace-normal`}
            >
              <div className="flex max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                <Table
                  columns={schematicOilWellSurfaceCols}
                  headerClassName="sticky top-0 z-10 bg-white p-0 text-nowrap"
                  data={equipamentosSuperficie}
                />
              </div>
            </Accordion>
          </div>

          {equipamentosSubsuperficieLongaList.length > 0 && (
            <div className="bg-white rounded-[10px] pb-2 pt-2 px-4 mb-4">
              <Accordion
                key={`SchematicOilWell2`}
                title={
                  equipamentosSubsuperficieCurtaList.length > 0
                    ? 'Equipamentos de Subsuperfície - Coluna Longa'
                    : 'Equipamentos de Subsuperfície'
                }
                buttonClassName={`bg-white hover:bg-[#fff] visible px-0 text-left whitespace-normal`}
              >
                <div className="flex max-h-[300px] overflow-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                  <Table
                    columns={schematicOilWellSubSurfaceCols}
                    headerClassName="sticky top-0 z-10 bg-white p-0 text-nowrap"
                    data={equipamentosSubsuperficieLongaList}
                  />
                </div>
              </Accordion>
            </div>
          )}

          {equipamentosSubsuperficieCurtaList.length > 0 && (
            <div className="bg-white rounded-[10px] pb-2 pt-2 px-4 mb-4">
              <Accordion
                key={`SchematicOilWell2`}
                title={'Equipamentos de Subsuperfície - Coluna Curta'}
                buttonClassName={`bg-white hover:bg-[#fff] visible px-0 text-left whitespace-normal`}
              >
                <div className="flex max-h-[300px] overflow-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                  <Table
                    columns={schematicOilWellSubSurfaceCols}
                    headerClassName="sticky top-0 z-10 bg-white p-0 text-nowrap"
                    data={equipamentosSubsuperficieCurtaList}
                  />
                </div>
              </Accordion>
            </div>
          )}

          <div className="bg-white rounded-[10px] pb-2 pt-2 px-4 mb-4">
            <h3 className="mb-3 uppercase font-medium text-sm">Restrição</h3>
            <div className="flex flex-col max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
              {mockRestricoes.length == 0 ? (
                <div className="font-bold text-[12px] p-2">
                  <p className="flex text-sm font-medium">
                    <span className="me-2">
                      <GoAlertFill size={24} />
                    </span>
                    <span className="text-[#7B7B7B] mt-1">
                      Não há registro de restrição da coluna
                    </span>
                  </p>
                </div>
              ) : (
                mockRestricoes.map((restricao: any, index: any) => (
                  <div
                    className="flex flex-col font-bold text-[12px] p-2"
                    key={`restricao-container-${index}`}
                  >
                    <ul className="border-gray-200 border-b-[1px] border-[#DFDFDF]">
                      <li
                        className="flex text-sm font-medium mb-2"
                        key={`restricao-${index}`}
                      >
                        <span className="me-2">
                          <GoAlertFill size={24} />
                        </span>
                        <div className="flex flex-col">
                          <span className="text-dark">
                            Restrição {index + 1}
                          </span>
                          <span className="text-[#7B7B7B]">
                            {restricao.descricao}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="bg-white rounded-[10px] pb-2 pt-2 px-4 mb-4">
            <h3 className="mb-3 uppercase font-medium text-sm">Obstrução</h3>
            <div className="flex flex-col max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
              {mockObstrucoes.length == 0 ? (
                <div className="font-bold text-[12px] p-2">
                  <p className="flex text-sm font-medium">
                    <span className="me-2">
                      <GoAlertFill size={24} />
                    </span>
                    <span className="text-[#7B7B7B] mt-1">
                      Não há registro de obstrução da coluna
                    </span>
                  </p>
                </div>
              ) : (
                mockObstrucoes.map((obstrucao: any, index: any) => (
                  <div
                    className="flex flex-col font-bold text-[12px] p-2"
                    key={`restricao-container-${index}`}
                  >
                    <ul className="border-gray-200 border-b-[1px] border-[#DFDFDF]">
                      <li
                        className="flex text-sm font-medium mb-2"
                        key={`restricao-${index}`}
                      >
                        <span className="me-2">
                          <GoAlertFill size={24} />
                        </span>
                        <div className="flex flex-col">
                          <span className="text-dark">
                            Obstrução {index + 1}
                          </span>
                          <span className="text-[#7B7B7B]">
                            {obstrucao.descricao}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="bg-white rounded-[10px] py-4 px-4 mb-4">
            <h3 className="mb-1 uppercase font-medium text-sm">
              Packer Fluid
            </h3>
            {!mockSchematic.jsonData.strutural_data.packer_fluid ? (
              <p className="text-[#7B7B7B] text-sm font-medium">
                <span className="flex flex-row">
                  <BiErrorAlt size={17} color="gray" className="me-1" />
                  Não aplicável
                </span>
              </p>
            ) : (
              <p className="text-[#7B7B7B] text-sm font-medium">
                <span>
                  {mockSchematic.jsonData.strutural_data.packer_fluid} lb/gal
                </span>
              </p>
            )}
          </div>

          <div className="bg-white rounded-2xl py-4 px-4">
            {mockPeixe.length == 0 ? (
              <div>
                <h3 className="mb-1 uppercase font-medium text-sm">Peixes</h3>
                <div className="flex flex-row items-center">
                  <span className="me-2">
                    <FaFish size={24} />
                  </span>
                  <span className="text-[#7B7B7B] text-sm font-medium">
                    Não há histórico de peixe no poço
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex overflow-y-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                <div className="border-[1px] border-[#E4E8FB] rounded last:border-b-0">
                  <div className="flex max-h-[300px] overflow-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
                    <Table
                      headerClassName="px-0 whitespace-nowrap"
                      bodyClassName="border-b-[1px] border-[#E4E8FB]"
                      columns={schematicOilWellPeixe}
                      data={mockPeixe}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SchematicOilWell;
