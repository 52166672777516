import Button from '@/components/atoms/Button';
import Spinner from '@/components/atoms/Spinner';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import PageNavigation from '@/components/molecules/PageNavigation';
import SearchInput from '@/components/molecules/SearchInput';
import Table from '@/components/organisms/Table';
import { Dispatch, SetStateAction } from 'react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { Column } from 'react-table';

interface MDMBaseProps {
  title: string;
  addButtonTitle: string;
  deleteModalTitle: string;
  deleteModalDescription: string;
  columns: Column[];
  isLoading: boolean;
  data: any;
  totalPages: number;
  ITEMS_PER_PAGE: number;
  addButtonHandler: () => void;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  search: string;
  setSearch: (search: string) => void;
  modalDeleteIsOpen: boolean;
  setModalDeleteIsOpen: (modalDeleteIsOpen: boolean) => void;
  handleDeleteElement: (original: any) => void;
  original: any;
}

const MDMBase = ({
  title,
  addButtonTitle,
  deleteModalTitle,
  deleteModalDescription,
  columns,
  isLoading,
  data,
  totalPages,
  ITEMS_PER_PAGE,
  addButtonHandler,
  page,
  setPage,
  search,
  setSearch,
  modalDeleteIsOpen,
  setModalDeleteIsOpen,
  handleDeleteElement,
  original
}: MDMBaseProps) => {
  return (
    <div className="flex flex-col gap-4 py-4 px-6 bg-white rounded-lg mt-4 pb-8 relative">
      <Button
        title={addButtonTitle}
        type="button"
        className="absolute right-14 -top-[56px]"
        icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
        onClick={addButtonHandler}
      />

      <section className="flex justify-between items-center">
        <h1 className="text-gray font-bold text-lg flex">
          {title}
          {isLoading && <Spinner className="ml-2" size={16} />}
        </h1>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </section>

      {!data && (
        <div className="flex flex-col items-center justify-center">
          <Spinner className="mt-4" size={32} />
        </div>
      )}

      {data && (
        <section className="flex flex-col gap-2">
          <Table columns={columns} data={data.items ?? []} />

          <PageNavigation
            currentPage={page}
            setCurrentPage={setPage}
            className="mt-2"
            totalPages={totalPages}
            perPage={ITEMS_PER_PAGE}
            totalItems={data?.meta?.total || 0}
          />

          {modalDeleteIsOpen && (
            <ModalConfirmation
              confirmAction={() => handleDeleteElement(original)}
              title={deleteModalTitle}
              description={deleteModalDescription}
              confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
              isOpen={modalDeleteIsOpen}
              setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
            />
          )}
        </section>
      )}
    </div>
  );
};

export default MDMBase;
