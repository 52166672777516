import tooltipChart from '@/assets/schematic/tooltip.svg';

import {
  AnularesKeysTypes,
  createPositionMapper,
  createVirtualElements,
  DepthsColunasDeProducao,
  getLastElementScaleMultiplier,
  getLeftTexts,
  getRevestimentObjects,
  getRevestimentsAndDiameters,
  getRightTexts,
  normalizeTypeKey,
  packer,
  PixiBackgroundMargin,
  renderMappedElements,
  subsurfaceElementsKeyMapping,
  subsurfaceSchematicElements,
  surfaceElementsKeyMapping,
  topSchematicElements,
  valvulaVertical,
  VirtualElement
} from './schematicUtils';

import { containsKey } from '@/utils/table';
import { Container, Graphics, Sprite, Stage, Text } from '@pixi/react';
import { TextStyle } from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { ParteInferior, SchematicInterface } from './interface';
import PixiBackground from './PixiBackground';
import ScaleUtils from './ScaleUtils';
import { SchematicReferenceConstantsBuilder } from './SchematicReferenceConstants';
import { CimentacaoComponent } from './subsurface/Components/Cimentacao';
import { ColunaDeProducaoComponent } from './subsurface/Components/ColunaDeProducao';
import {
  RevestimentComponent,
  RevestimentGroup
} from './subsurface/Components/Revestiment';
import { SapataComponent } from './subsurface/Components/Sapata';
import SchematicOilWellMechanicalConditions from './subsurface/SchematicOilWellMechanicalConditions';
import SchematicOilWellPacker from './subsurface/SchematicOilWellPacker';
import SchematicOilWellPeixes from './subsurface/SchematicOilWellPeixes';
import SchematicOilWellRestricoesObstrucoes from './subsurface/SchematicOilWellRestricoesObstrucoes';
import SchematicOilWellRevestimentoRasgado from './subsurface/SchematicOilWellRevestimentoRasgado';
import SchematicOilWellRuler from './subsurface/SchematicOilWellRuler';
import SchematicOilWellSubsurfaceEquipment from './subsurface/SchematicOilWellSubsurfaceEquipment';
import { SchematicLateralValvesElements } from './surface/SchematicLateralValvesElements';
import { SchematicOilWellAnularesElements } from './surface/SchematicOilWellAnularesElements';
import { SchematicOilWellVerticalValve } from './surface/SchematicOilWellVerticalValve';
import { SchematicTopElements } from './surface/SchematicTopElements';

// TODO: usar essas variaveis como base no resto das contas e ajustar a
// possivel besteira que tenha feito quando criei elas
const QUARTEPACKER = packer.width / 4;
// const QUARTEPACKER = 7.714285714285714
const OneRevestiment = 20;

// const wellDiameter= 108

// TODO: Reduzir o arquivo para um max de 200 linhas
const middlePosition = 0;
const TopoDoPoco = 0;

const TamanhoRenderizadoParteInferior = 1500;

// Heights
const ConectorHeightBig = 45;
const ConectorHeightMini = 38;
const SpaceBetweenConectors = 3;
const DistanceBetweenValvLayers = SpaceBetweenConectors + ConectorHeightBig;
const distanceBetweenLeftTexts = 10;
const distanceBetweenRightTexts = 5;
const distanceBetweenRightTextsMechanicalConditions = 80;

interface Props {
  parentWidth: number;
  schematicData: SchematicInterface;
  handleSchematicCanvaImage: (image: string) => void;
  handleSchematicCanvaImageLoading: (isLoading: boolean) => void;
  schematicUniqueKey: string;
}

export const SchematicOilWellCanva: React.FC<Props> = (props: {
  parentWidth: number;
  schematicData: SchematicInterface;
  handleSchematicCanvaImage: (image: any) => void;
  handleSchematicCanvaImageLoading: (isLoading: boolean) => void;
  schematicUniqueKey: string;
}) => {
  const [stageWidth, setStageWidth] = useState(props.parentWidth);
  const [pixiContext, setApp] = useState<any>(null);

  // tooltips
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipInfo, setShowTooltipInfo] = useState({
    tooltipPosition: { x: 0, y: 0 },
    textPosition: { x: 0, y: 0 },
    tooltipSize: { width: 0, height: 0 },
    text: ''
  });

  const hoverTooltip = (
    tooltipPosition: { x: number; y: number },
    textPosition: { x: number; y: number },
    text: string,
    tooltipSize?: { width: number; height: number }
  ) => {
    setShowTooltipInfo({
      tooltipPosition,
      textPosition,
      text,
      tooltipSize: tooltipSize ?? { width: 210, height: 80 }
    });
    setShowTooltip(true);
  };

  // Subsuperfície
  const [subsurfaceEquipments, setSubsurfaceEquipments] = useState<
    VirtualElement[]
  >([]);
  const [depthLongaRenderedElements, setDepthLongaRenderedElements] =
    useState(0);
  const [depthCurtaRenderedElements, setDepthCurtaRenderedElements] =
    useState(0);

  const handleSubsurfaceEquipments = () => {
    setSubsurfaceEquipments(
      mapNewDepths(
        props.schematicData.parte_inferior,
        ScaleMultiplier,
        isTwoColumns
      )
    );
  };

  useEffect(() => {
    handleSubsurfaceEquipments();
  }, [props.schematicData]);

  useEffect(() => {
    setStageWidth(props.parentWidth);
  }, [props.parentWidth]);

  useEffect(() => {
    props.handleSchematicCanvaImageLoading(true);
    setTimeout(() => {
      extractImageFromCanvas();
    }, 2000);
  }, [pixiContext, subsurfaceEquipments]);

  // Consts

  const drawMarginTop = 50;
  const oilwellDepht = getMaxDepthFromAllRevestiments();
  const ScaleMultiplier = TamanhoRenderizadoParteInferior / oilwellDepht;
  const ScaleByLastSubsurfaceElement =
    subsurfaceEquipments.length > 0
      ? getLastElementScaleMultiplier(subsurfaceEquipments, oilwellDepht)
      : ScaleMultiplier;

  const oilwellDephtScaleUtils = new ScaleUtils(TopoDoPoco, ScaleMultiplier);
  const lastSubsurfaceElementScaleUtils = new ScaleUtils(
    TopoDoPoco,
    ScaleByLastSubsurfaceElement
  );

  const isTwoColumns =
    props.schematicData.parte_superior.numero_de_colunas_de_producao > 1 &&
    props.schematicData.parte_inferior.numero_de_colunas_de_producao > 1;

  const xPositionLeftTexts = -stageWidth / 2 + 60 + 10; //60 = largura da régua, 10 = espaçamento

  const filterAnulares = () => {
    const anulares = Object.keys(props.schematicData.parte_superior.anulares);
    const lastKey = anulares
      .toReversed()
      .find(
        (x: string) =>
          props.schematicData.parte_superior.anulares[x as AnularesKeysTypes]
            .normal ||
          props.schematicData.parte_superior.anulares[x as AnularesKeysTypes]
            .oposta
      );
    if (!lastKey) return [];

    return anulares.slice(0, anulares.indexOf(lastKey) + 1);
  };

  const anularesCount = filterAnulares().length;

  const topSchematicElementsTipo =
    props.schematicData.parte_superior.equipamento_de_superficie.map(
      (x: any) => x.tipo
    );
  const topSchematicElementsList: any = topSchematicElementsTipo
    .map((x: string) => {
      return topSchematicElements[surfaceElementsKeyMapping[x]];
    })
    .filter((x: any) => x);

  const topSchematicElementsHeight =
    props.schematicData.parte_superior.equipamento_de_superficie &&
    props.schematicData.parte_superior.equipamento_de_superficie.length > 0 &&
    topSchematicElementsList &&
    topSchematicElementsList.length > 0
      ? topSchematicElementsList[0].height
      : 0;
  const valvulasPistoneioHeight =
    valvulaVertical.height *
    props.schematicData.parte_superior.valvulas_pistoneio.filter(
      valvula => containsKey(valvula, 'curta') || containsKey(valvula, 'longa')
    ).length;
  const valvulasLateraisHeight =
    ConectorHeightMini *
    props.schematicData.parte_superior.valvulas_laterais_producao.filter(
      valvula =>
        containsKey(valvula, 'normal') || containsKey(valvula, 'oposta')
    ).length;
  const valvulasMestrasHeight =
    valvulaVertical.height *
    props.schematicData.parte_superior.valvula_mestra.filter(
      valvula => containsKey(valvula, 'curta') || containsKey(valvula, 'longa')
    ).length;
  const anularesHeight =
    (anularesCount - 1) * DistanceBetweenValvLayers + ConectorHeightBig;
  const totalValvesHeight =
    topSchematicElementsHeight +
    valvulasPistoneioHeight +
    valvulasMestrasHeight +
    valvulasLateraisHeight +
    anularesHeight;

  const wellDiameter = isTwoColumns ? 24 * QUARTEPACKER : 14 * QUARTEPACKER; // 112

  const schematicReferenceBuilder = new SchematicReferenceConstantsBuilder();
  schematicReferenceBuilder.QUARTEPACKER = QUARTEPACKER;
  schematicReferenceBuilder.TopoDoPoco = TopoDoPoco;
  schematicReferenceBuilder.OneRevestiment = OneRevestiment;
  schematicReferenceBuilder.wellDiameter = wellDiameter;
  schematicReferenceBuilder.middlePosition = middlePosition;

  const schematicReferenceConstants =
    schematicReferenceBuilder.unsafeTryBuild();

  // Divisão entre superfície e subsuperfície
  const draw = (g: any) => {
    g.clear();
    g.lineStyle(2, 0x000000, 1);
    g.moveTo(-stageWidth / 2 + 55, 0);
    g.lineTo(stageWidth, 0);
  };

  // Revestimentos/Cimentações/Sapata
  function getMaxDepthFromAllRevestiments(): number {
    return Math.max(
      ...props.schematicData.parte_inferior.revestimento.map(
        revestiment => revestiment.profundidade
      )
    );
  }

  const getMaxTextHeight = () => {
    const leftTextHeights = leftTexts.map(
      text => text.height + text.item.height
    );
    const rightTextHeights = rightTexts.map(
      text => text.height + text.item.height
    );
    const maxLeftTextHeight = Math.max(...leftTextHeights);
    const maxRightTextHeight = Math.max(...rightTextHeights);
    return (
      Math.max(maxLeftTextHeight, maxRightTextHeight) + 2 * PixiBackgroundMargin
    );
  };

  const leftTexts = getLeftTexts(
    props.schematicData.parte_inferior,
    schematicReferenceConstants,
    lastSubsurfaceElementScaleUtils,
    distanceBetweenLeftTexts
  );

  const rightTexts = getRightTexts(
    subsurfaceEquipments,
    distanceBetweenRightTexts,
    props.schematicData.strutural_data.kop,
    ScaleByLastSubsurfaceElement
  );

  const maxTextHeight = getMaxTextHeight();

  const xPositionDireitaDefault = isTwoColumns
    ? QUARTEPACKER * 20
    : QUARTEPACKER * 15;

  const xPositionRightTexts = xPositionDireitaDefault + 8 * QUARTEPACKER;

  const getGeometriesFromRevestiments = () => {
    const { revestiments, totalMaxDepht } = getRevestimentObjects(
      props.schematicData.parte_inferior.revestimento,
      schematicReferenceConstants
    );

    const maxDepthFaseDePerfuracao = getGeometriesFundoDoPoco();

    const whiteLine = (g: any) => {
      g.clear();
      g.lineStyle(2, '#FFFFFF', 1);
      g.moveTo(
        -wellDiameter / 2 + 1,
        lastSubsurfaceElementScaleUtils.rescaleHeight(totalMaxDepht)
      );
      g.lineTo(
        wellDiameter / 2 - 1,
        lastSubsurfaceElementScaleUtils.rescaleHeight(totalMaxDepht)
      );
    };

    return (
      <>
        {revestiments.map(
          (revestimentsItem: RevestimentGroup, index: number) => {
            let depth = revestimentsItem.depht;
            if (index === revestiments.length - 1) {
              depth = Math.max(
                maxDepthFaseDePerfuracao,
                revestimentsItem.depht
              );
            }

            return (
              <React.Fragment key={`revestiment-group-${index}`}>
                <RevestimentComponent
                  width={revestimentsItem.width}
                  depht={depth}
                  scaleUtils={lastSubsurfaceElementScaleUtils}
                  schematicReferenceConstants={schematicReferenceConstants}
                />

                {revestimentsItem.cimentacoes.map(
                  (cimentacao, cimentacoesIndex: number) => (
                    <CimentacaoComponent
                      width={cimentacao.width}
                      topo={cimentacao.topo}
                      base={cimentacao.base}
                      scaleUtils={lastSubsurfaceElementScaleUtils}
                      schematicReferenceConstants={schematicReferenceConstants}
                      key={`cimentacoes-${cimentacoesIndex}`}
                    />
                  )
                )}

                <SapataComponent
                  width={revestimentsItem.sapata.width}
                  base={revestimentsItem.sapata.base}
                  scaleUtils={lastSubsurfaceElementScaleUtils}
                  schematicReferenceConstants={schematicReferenceConstants}
                />
              </React.Fragment>
            );
          }
        )}

        {/* Esse componente é o poço, perdão pelo nome não significativo */}
        <RevestimentComponent
          width={wellDiameter}
          depht={totalMaxDepht}
          scaleUtils={lastSubsurfaceElementScaleUtils}
          schematicReferenceConstants={schematicReferenceConstants}
          key={'revestimento-poco'}
        />

        {maxDepthFaseDePerfuracao !== null &&
          totalMaxDepht !== null &&
          maxDepthFaseDePerfuracao > totalMaxDepht && (
            <Graphics draw={g => whiteLine(g)} anchor={1} />
          )}
      </>
    );
  };

  const getGeometriesFundoDoPoco = () => {
    const fundoDoPocoEmCondicoesMecanicas =
      props.schematicData.parte_inferior.condicoes_mecanicas.filter(
        condicaoMecanica =>
          normalizeTypeKey(condicaoMecanica.tipo) === 'fundo-do-poco'
      );

    const faseDePerfuracao = props.schematicData.parte_inferior.brocas;

    const maxDepthFaseDePerfuracao = Math.max(
      ...faseDePerfuracao.map((fase: any) => {
        return fase.profundidade;
      }),
      ...fundoDoPocoEmCondicoesMecanicas.map((condicao: any) => {
        return condicao.profundidade;
      })
    );

    return maxDepthFaseDePerfuracao;
  };

  const getColunasDeProducao = (): DepthsColunasDeProducao => {
    if (
      !Array.isArray(
        props.schematicData.parte_inferior.equipamento_de_subsuperficie
      )
    ) {
      return {
        depthLonga: 0,
        depthCurta: 0
      };
    }

    const minOilwellScaleDepthCurta = Math.max(
      ...props.schematicData.parte_inferior.equipamento_de_subsuperficie
        .filter(
          (equipamento: any) => equipamento.coluna_de_producao === 'curta'
        )
        .map(equipamento => {
          if (!equipamento.profundidade) return 0;
          let elementHeight = subsurfaceSchematicElements[
            subsurfaceElementsKeyMapping[equipamento.tipo.toLowerCase()]
          ]
            ? subsurfaceSchematicElements[
                subsurfaceElementsKeyMapping[equipamento.tipo.toLowerCase()]
              ][0].height
            : 0;
          return oilwellDephtScaleUtils.rescaleHeight(
            equipamento.profundidade + elementHeight
          );
        })
    );

    // const minLastElementScaleDepthCurta = Math.max(
    //   ...props.schematicData.parte_inferior.equipamento_de_subsuperficie
    //     .filter(
    //       (equipamento: any) => equipamento.coluna_de_producao === 'curta'
    //     )
    //     .map(equipamento => {
    //       if (!equipamento.profundidade) return 0;
    //       let elementHeight = subsurfaceSchematicElements[
    //         subsurfaceElementsKeyMapping[equipamento.tipo.toLowerCase()]
    //       ]
    //         ? subsurfaceSchematicElements[
    //             subsurfaceElementsKeyMapping[equipamento.tipo.toLowerCase()]
    //           ][0].height
    //         : 0;
    //       return lastSubsurfaceElementScaleUtils.rescaleHeight(
    //         equipamento.profundidade + elementHeight
    //       );
    //     })
    // );

    const minOilwellScaleDepthLonga = Math.max(
      ...props.schematicData.parte_inferior.equipamento_de_subsuperficie
        .filter(
          (equipamento: any) =>
            equipamento.coluna_de_producao.toLowerCase() === 'longa' ||
            equipamento.coluna_de_producao.toLowerCase() === 'única'
        )
        .map(equipamento => {
          if (!equipamento.profundidade) return 0;
          let elementHeight = subsurfaceSchematicElements[
            subsurfaceElementsKeyMapping[equipamento.tipo.toLowerCase()]
          ]
            ? subsurfaceSchematicElements[
                subsurfaceElementsKeyMapping[equipamento.tipo.toLowerCase()]
              ][0].height
            : 0;
          return oilwellDephtScaleUtils.rescaleHeight(
            equipamento.profundidade + elementHeight
          );
        })
    );

    // const minLastElementScaleDepthLonga = Math.max(
    //   ...props.schematicData.parte_inferior.equipamento_de_subsuperficie
    //     .filter(
    //       (equipamento: any) =>
    //         equipamento.coluna_de_producao.toLowerCase() === 'longa' ||
    //         equipamento.coluna_de_producao.toLowerCase() === 'única'
    //     )
    //     .map(equipamento => {
    //       if (!equipamento.profundidade) return 0;
    //       let elementHeight = subsurfaceSchematicElements[
    //         subsurfaceElementsKeyMapping[equipamento.tipo.toLowerCase()]
    //       ]
    //         ? subsurfaceSchematicElements[
    //             subsurfaceElementsKeyMapping[equipamento.tipo.toLowerCase()]
    //           ][0].height
    //         : 0;
    //       return lastSubsurfaceElementScaleUtils.rescaleHeight(
    //         equipamento.profundidade + elementHeight
    //       );
    //     })
    // );

    const depthCurta = () => {
      if (depthCurtaRenderedElements) {
        return depthCurtaRenderedElements;
      }

      return minOilwellScaleDepthCurta;

      // return Math.max(
      //   minOilwellScaleDepthCurta,
      //   minLastElementScaleDepthCurta,
      // );
    };

    const depthLonga = () => {
      if (depthLongaRenderedElements) {
        return depthLongaRenderedElements;
      }

      return minOilwellScaleDepthLonga;

      // return Math.max(
      //   minOilwellScaleDepthLonga,
      //   minLastElementScaleDepthLonga,
      // )
    };

    return {
      depthLonga: depthLonga(),
      depthCurta: depthCurta()
    };
  };

  const extractImageFromCanvas = () => {
    if (!pixiContext) return;

    const imageThis = pixiContext.renderer.generateTexture(pixiContext.stage, {
      region: pixiContext.screen,
      resolution: 2,
      backgroundColor: '0xffffff',
      backgroundAlpha: 1
    });

    return pixiContext.renderer.extract
      .image(imageThis, 'image/png', 1.0)
      .then((image: HTMLImageElement) => {
        props.handleSchematicCanvaImage(image.src);
        props.handleSchematicCanvaImageLoading(false);
      });
  };

  const updatePositionMapper = (
    virtualElements: VirtualElement[],
    positionMapper: any
  ) => {
    let depthLonga = 0;
    let depthCurta = 0;
    const ignoredElements = [
      'peixe',
      'restricao',
      'obstrucao',
      'bpr',
      'tampao-de-cimento',
      'colchao-de-areia',
      'bpp'
    ];
    const externalElementsConnectedInColumn = [
      'packer',
      'mandril-sem-vgl',
      'mandril-com-vgl'
    ];

    virtualElements.forEach((element: VirtualElement) => {
      const elementCanExist = (element: VirtualElement, top: number) => {
        for (let i = top; i < top + element.height; i++) {
          for (const [key, value] of element.representationType.entries()) {
            if (
              positionMapper[i][value] !== null &&
              positionMapper[i][value] !== undefined
            ) {
              return false;
            }
          }
        }
        return true;
      };

      const findTopper = (element: VirtualElement) => {
        let currentTop = element.topo;

        while (!elementCanExist(element, currentTop)) {
          currentTop += 1;
        }
        return currentTop;
      };

      const renderizadoEmColunaCurta = () => {
        return (
          (!ignoredElements.includes(element.tipo) &&
            element.representationType.includes('I2')) ||
          externalElementsConnectedInColumn.includes(element.tipo)
        );
      };

      const renderizadoEmColunaLonga = () => {
        return (
          (!ignoredElements.includes(element.tipo) &&
            element.representationType.includes('I1')) ||
          externalElementsConnectedInColumn.includes(element.tipo)
        );
      };

      const elementTop = findTopper(element);
      element.base = elementTop + element.height;

      for (let i = elementTop; i < elementTop + element.height; i++) {
        element.representationType.forEach((type: string) => {
          positionMapper[i][type] = element.id;
        });
      }

      if (renderizadoEmColunaCurta()) {
        depthCurta = Math.max(depthCurta, elementTop + element.height);
      }

      if (renderizadoEmColunaLonga()) {
        depthLonga = Math.max(depthLonga, elementTop + element.height);
      }
    });

    setDepthCurtaRenderedElements(depthCurta);
    setDepthLongaRenderedElements(depthLonga);

    return positionMapper;
  };

  const mapNewDepths = (
    equipmentList: ParteInferior,
    scaleMultiplier: number,
    hasTwoColumns: boolean
  ) => {
    let positionMapper = createPositionMapper(5000);
    const virtualElements = createVirtualElements(
      equipmentList,
      scaleMultiplier,
      hasTwoColumns
    );

    positionMapper = updatePositionMapper(virtualElements, positionMapper);
    return renderMappedElements(virtualElements, positionMapper);
  };

  return (
    <Stage
      width={stageWidth ?? 0}
      height={totalValvesHeight + drawMarginTop + maxTextHeight}
      options={{ backgroundAlpha: 0, resizeTo: window }}
      onMount={setApp}
    >
      <Container
        position={[stageWidth / 1.9, totalValvesHeight + drawMarginTop]}
      >
        <SchematicTopElements
          parteSuperior={props.schematicData.parte_superior}
          baseHeight={
            valvulasPistoneioHeight +
            valvulasLateraisHeight +
            valvulasMestrasHeight +
            anularesHeight
          }
          hasTwoColumns={isTwoColumns}
        />

        <SchematicOilWellVerticalValve
          elements={props.schematicData.parte_superior.valvulas_pistoneio}
          topHeight={
            valvulasPistoneioHeight +
            valvulasLateraisHeight +
            valvulasMestrasHeight +
            anularesHeight
          }
          setShowTooltip={setShowTooltip}
          hoverTooltip={hoverTooltip}
          hoverText={'Válvula de Pistoneio'}
          hasTwoColumns={isTwoColumns}
        />

        <SchematicLateralValvesElements
          parteSuperior={props.schematicData.parte_superior}
          conectorHeightMini={ConectorHeightMini}
          topHeight={
            valvulasLateraisHeight + valvulasMestrasHeight + anularesHeight
          }
          wellDiameter={wellDiameter}
          setShowTooltip={setShowTooltip}
          hoverTooltip={hoverTooltip}
        />

        <SchematicOilWellVerticalValve
          elements={props.schematicData.parte_superior.valvula_mestra}
          topHeight={valvulasMestrasHeight + anularesHeight}
          setShowTooltip={setShowTooltip}
          hoverTooltip={hoverTooltip}
          hoverText={'Válvula Mestra'}
          hasTwoColumns={isTwoColumns}
        />

        <SchematicOilWellAnularesElements
          parteSuperior={props.schematicData.parte_superior}
          anulares={filterAnulares()}
          topHeight={anularesHeight}
          setShowTooltip={setShowTooltip}
          hoverTooltip={hoverTooltip}
          wellDiameter={wellDiameter}
          oneRevestiment={OneRevestiment}
          distanceBetweenValvLayers={DistanceBetweenValvLayers}
          conectorHeightBig={ConectorHeightBig}
        />

        {getGeometriesFromRevestiments()}
        <SchematicOilWellPacker
          subsurfaceEquipments={subsurfaceEquipments}
          hasTwoColumns={isTwoColumns}
          quartePacker={QUARTEPACKER}
          hoverTooltip={hoverTooltip}
          setShowTooltip={setShowTooltip}
        />

        {isTwoColumns ? (
          <>
            <ColunaDeProducaoComponent
              depht={getColunasDeProducao().depthCurta}
              xPosition={-5 * QUARTEPACKER}
              schematicReferenceConstants={schematicReferenceConstants}
              key={'coluna-de-producao-curta'}
            />
            ,
            <ColunaDeProducaoComponent
              depht={getColunasDeProducao().depthLonga}
              xPosition={5 * QUARTEPACKER}
              schematicReferenceConstants={schematicReferenceConstants}
              key={'coluna-de-producao-longa'}
            />
          </>
        ) : (
          <ColunaDeProducaoComponent
            depht={getColunasDeProducao().depthLonga}
            xPosition={0}
            schematicReferenceConstants={schematicReferenceConstants}
            key={'coluna-de-producao'}
          />
        )}

        {leftTexts.map(({ height, item }) => {
          return (
            <PixiBackground
              item={item}
              position={{
                x: xPositionLeftTexts,
                y: height
              }}
              color="#F1F3FA"
              padding={10}
              key={`left-text-${props.schematicUniqueKey}-${item.text}-${height}`}
            />
          );
        })}

        {rightTexts.map(({ height, item }) => {
          return (
            <PixiBackground
              item={item}
              position={{ x: xPositionRightTexts, y: height }}
              color="#F1F3FA"
              padding={10}
              key={`right-text-${props.schematicUniqueKey}-${item.text}-${height}`}
            />
          );
        })}

        <SchematicOilWellRuler
          revestimentsAndDiameters={getRevestimentsAndDiameters(
            props.schematicData.parte_inferior.revestimento,
            schematicReferenceConstants
          )}
          stageWidth={stageWidth}
          scaleByLastSubsurfaceElement={ScaleByLastSubsurfaceElement}
          maxDepthFaseDePerfuracao={getGeometriesFundoDoPoco()}
        />

        <SchematicOilWellSubsurfaceEquipment
          subsurfaceEquipments={subsurfaceEquipments}
          hasTwoColumns={isTwoColumns}
          quartePacker={QUARTEPACKER}
          scaleMultiplier={ScaleMultiplier}
          topoDoPoco={TopoDoPoco}
          hoverTooltip={hoverTooltip}
          setShowTooltip={setShowTooltip}
        />

        <SchematicOilWellRevestimentoRasgado
          parteInferior={props.schematicData.parte_inferior}
          wellDiameter={wellDiameter}
          scaleUtils={oilwellDephtScaleUtils}
        />
        
        <SchematicOilWellMechanicalConditions
          subsurfaceEquipments={subsurfaceEquipments}
          hasTwoColumns={isTwoColumns}
          wellDiameter={wellDiameter}
          quartePacker={QUARTEPACKER}
          drawMarginTop={drawMarginTop}
          distanceBetweenLeftTexts={distanceBetweenLeftTexts}
          distanceBetweenRightTexts={
            distanceBetweenRightTextsMechanicalConditions
          }
          scaleUtils={lastSubsurfaceElementScaleUtils}
          revestimentsAndDiameters={getRevestimentsAndDiameters(
            props.schematicData.parte_inferior.revestimento,
            schematicReferenceConstants
          )}
          scaleByLastElement={ScaleByLastSubsurfaceElement}
          hoverTooltip={hoverTooltip}
          setShowTooltip={setShowTooltip}
          schematicReferenceConstants={schematicReferenceConstants}
          subsurfaceRevestiments={
            props.schematicData.parte_inferior.revestimento
          }
        />

        <SchematicOilWellPeixes
          subsurfaceEquipments={subsurfaceEquipments}
          hasTwoColumns={isTwoColumns}
          wellDiameter={wellDiameter}
          scaleMultiplier={ScaleMultiplier}
          topoDoPoco={TopoDoPoco}
          maxDepthsColunaDeProducao={getColunasDeProducao()}
        />

        <SchematicOilWellRestricoesObstrucoes
          subsurfaceEquipments={subsurfaceEquipments}
          hasTwoColumns={isTwoColumns}
          wellDiameter={wellDiameter}
          quartePacker={QUARTEPACKER}
          topoDoPoco={TopoDoPoco}
        />

        <Graphics draw={draw} />

        {showTooltip && showTooltipInfo && (
          <>
            <Sprite
              scale={1}
              anchor={0.5}
              x={showTooltipInfo.tooltipPosition.x}
              y={showTooltipInfo.tooltipPosition.y}
              width={showTooltipInfo.tooltipSize.width}
              height={showTooltipInfo.tooltipSize.height}
              image={tooltipChart}
            />
            <Text
              scale={0.8}
              anchor={{ x: 0.55, y: 1 }}
              x={showTooltipInfo.textPosition.x}
              y={showTooltipInfo.textPosition.y}
              text={showTooltipInfo.text}
              style={
                new TextStyle({
                  fontFamily: 'Graphie',
                  fontStyle: 'normal',
                  fontSize: 14,
                  fill: ['#000000'],
                  wordWrap: true,
                  wordWrapWidth: 440
                })
              }
            />
          </>
        )}
      </Container>
    </Stage>
  );
};
