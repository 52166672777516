import { Stacked } from '../atoms/Composite';
import { Textsm, TextsmBold } from '../atoms/Texts';

export const StackedPrimary = ({
  label,
  value
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <Stacked>
      <Textsm color="text-white/80">{label}</Textsm>
      <TextsmBold color="text-white">{value}</TextsmBold>
    </Stacked>
  );
};
