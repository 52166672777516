import { Sprite } from '@pixi/react';
import React from 'react';
import { ParteSuperior } from '../interface';
import { conexaoEntreColunas } from '../schematicUtils';
import { SchematicOilWellCanvaValveElements } from './SchematicOilWellCanvaValveElements';
import { SchematicValvesConector } from './SchematicValvesConector';

export const SchematicLateralValvesElements: React.FC<any> = (props: {
  parteSuperior: ParteSuperior;
  conectorHeightMini: number;
  topHeight: number;
  wellDiameter: number;
  hoverTooltip: any;
  setShowTooltip: any;
}) => {
  const hasTwoColumns = props.parteSuperior.numero_de_colunas_de_producao > 1;

  return (
    <>
      {props.parteSuperior.valvulas_laterais_producao
        .filter(x => x.normal != null || x.oposta != null)
        .map((line, index: number) => (
          <React.Fragment key={`lateral-valve-elements-${index}`}>
            {hasTwoColumns ? (
              <>
                <Sprite
                  scale={1}
                  anchor={{ x: 0.5, y: 1 }}
                  x={0}
                  y={
                    -props.topHeight +
                    props.conectorHeightMini * (index + 1) -
                    7
                  }
                  image={conexaoEntreColunas.image}
                />

                <SchematicValvesConector
                  size={46}
                  y={-props.topHeight + props.conectorHeightMini * (index + 1)}
                  center={25}
                  height={props.conectorHeightMini}
                />

                <SchematicValvesConector
                  size={46}
                  y={-props.topHeight + props.conectorHeightMini * (index + 1)}
                  center={-25}
                  height={props.conectorHeightMini}
                />
              </>
            ) : (
              <SchematicValvesConector
                size={46}
                y={-props.topHeight + props.conectorHeightMini * (index + 1)}
                center={0}
                height={props.conectorHeightMini}
              />
            )}

            {Object.keys(line).map((type: string, objectIndex: number) => (
              <SchematicOilWellCanvaValveElements
                centerDistance={hasTwoColumns ? 48 : 23}
                defaultY={
                  -props.topHeight +
                  (props.conectorHeightMini * (index + 1) - 2)
                }
                elements={type == 'normal' ? line.normal : line.oposta}
                hoverTooltip={props.hoverTooltip}
                setShowTooltip={props.setShowTooltip}
                oposta={type === 'normal' ? false : true}
                textHover={
                  type === 'normal'
                    ? 'Válvula Lateral'
                    : 'Válvula Lateral Oposta'
                }
                hoverTooltipSize={180}
                hoverTextPosition={
                  type === 'normal'
                    ? (props.wellDiameter / 3) * (index + 1) + 15
                    : (props.wellDiameter / 3) * (index + 1) + 10
                }
                key={`valvula-lateral-${objectIndex}-${type}`}
              />
            ))}
          </React.Fragment>
        ))}
    </>
  );
};
