import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { postOilwell } from '../../../services/oilwells.services';

import { queryClient } from '../../../App';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/Input';
import SelectInput from '../../../components/atoms/SelectInput';
import MdmLayout from '../../../components/templates/MdmLayout';
import {
  injectionTypes,
  targetInstallationCode,
  waterBranch
} from '../../../constants/filters/OilWellsTable';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { mapperOptions } from '../../../utils/mdm';
import { addHours } from 'date-fns';

type CreateOilWellForm = {
  name: string;
  description: string;
  type: string;
  oilFieldId: string;
  statusId: number;
  nameANP: string;
  codeANP: string;
  satelliteId: number;
  fluid: string;
  elevationMethod: string;
  interventionExpense: string;
  latitude: number;
  longitude: number;
  averageDepth: number;
  verticalDepth: number;
  sedimentaryBasin: string;
  environment: string;
  operationalLife: string;
};

const CreateOilWell: React.FC = () => {
  const { status, satellites, fields, constants, conjugateLine, location } =
    useMdmUtils({
      status: true,
      satellites: true,
      fields: true,
      constants: true,
      conjugateLine: true,
      location: true
    });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateOilWellForm>();

  const onSubmit = async (data: CreateOilWellForm) => {
    try {
      const updatedData = Object.keys(data).reduce((acc: any, key: any) => {
        const typedKey = key as keyof CreateOilWellForm;
        const value = data[typedKey];

        if (
          ['latitude', 'longitude', 'averageDepth', 'verticalDepth'].includes(
            typedKey
          )
        ) {
          acc[typedKey] = value === '' ? null : parseFloat(value as string);
        } else if (typedKey === 'operationalLife') {
          acc[typedKey] =
            value === '' ? null : addHours(new Date(value as string), 3);
        } else {
          acc[typedKey] = value === '' ? null : value;
        }

        return acc;
      }, {} as CreateOilWellForm);

      await postOilwell({
        ...updatedData,
        statusId: Number(data.statusId),
        satelliteId: Number(data.satelliteId)
      });
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'oilwells-adm'
      });

      toast.success('Poço criado com sucesso');
    } catch (e) {
      toast.error('Erro ao criar poço');
    }
  };

  return (
    <MdmLayout title={'Criar Poço'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2 items-center justify-center w-3/4"
      >
        <Input
          label="Nome"
          required
          placeholder="Nome"
          {...register('name', { required: true })}
        />

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Tipo"
            placeholder="Tipo"
            control={control}
            required
            name={'type'}
            options={mapperOptions(constants?.OilWellType)}
          />

          <SelectInput
            label="Campo"
            placeholder="Campo"
            control={control}
            name={'oilFieldId'}
            required
            options={fields?.map((field: any) => ({
              value: field.id,
              label: field.name
            }))}
          />
        </div>

        <SelectInput
          label="Status ANP"
          placeholder="Status"
          control={control}
          required
          name={'statusId'}
          options={status?.map((status: any) => ({
            value: status.id,
            label: status.anp
          }))}
        />

        <div className="flex gap-4 w-full">
          <Input
            label="Nome ANP"
            placeholder="Nome ANP"
            required
            {...register('nameANP', { required: true })}
          />

          <Input
            label="Código ANP"
            required
            placeholder="Código ANP"
            {...register('codeANP', { required: true })}
          />
        </div>

        <SelectInput
          label="Satelite"
          placeholder="Satelite"
          control={control}
          name={'satelliteId'}
          options={satellites?.map((satellite: any) => ({
            value: satellite.id,
            label: satellite.name
          }))}
        />

        <Input
          label="Vida útil do poço"
          placeholder="Vida útil do poço"
          type="date"
          {...register('operationalLife', { required: false })}
        />

        <div className="flex gap-4 w-full">
          <Input
            label="Latitude"
            placeholder="Latitude"
            type="number"
            {...register('latitude', { required: false })}
          />

          <Input
            label="Longitude"
            placeholder="Longitude"
            type="number"
            {...register('longitude', { required: false })}
          />
        </div>

        <div className="flex gap-4 w-full">
          <Input
            label="Profundidade MD"
            placeholder="Profundidade MD"
            type="number"
            {...register('averageDepth', { required: false })}
          />

          <Input
            label="Profundidade Vertical"
            placeholder="Profundidade Vertical"
            type="number"
            {...register('verticalDepth', { required: false })}
          />
        </div>

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Bacia Sedimentar"
            placeholder="Bacia Sedimentar"
            control={control}
            name="sedimentaryBasin"
            options={[
              { value: 'Alagoas', label: 'Alagoas' },
              { value: 'Espírito Santo', label: 'Espírito Santo' },
              { value: 'Potiguar', label: 'Potiguar' },
              { value: 'Recôncavo', label: 'Recôncavo' },
              { value: 'Tucano Sul', label: 'Tucano Sul' }
            ]}
          />

          <SelectInput
            label="Ambiente"
            placeholder="Ambiente"
            name="environment"
            control={control}
            options={[
              { value: 'Onshore', label: 'Onshore' },
              { value: 'Offshore', label: 'Offshore' }
            ]}
          />
        </div>

        <SelectInput
          label="Locação"
          placeholder="Locação"
          control={control}
          name={'locationId'}
          options={location?.map((location: any) => ({
            value: location.id,
            label: location.name
          }))}
        />

        <SelectInput
          label="Linha Conjugada"
          placeholder="Linha Conjugada"
          control={control}
          name={'conjugateLineId'}
          options={conjugateLine?.map((line: any) => ({
            value: line.id,
            label: line.name
          }))}
        />

        <SelectInput
          label="Ramal de Água"
          placeholder="Ramal de Água"
          control={control}
          name={'waterBranch'}
          options={waterBranch}
        />

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Tipo de Injeção"
            placeholder="Tipo de Injeção"
            control={control}
            name={'injectionType'}
            options={
              mapperOptions(constants?.InjectionType).length > 0
                ? mapperOptions(constants?.InjectionType)
                : injectionTypes
            }
          />

          <SelectInput
            label="Instalação de Destino"
            placeholder="Instalação de Destino"
            control={control}
            name={'targetInstallationCode'}
            options={targetInstallationCode}
          />
        </div>

        <div className="flex gap-4 w-full">
          <SelectInput
            label="Fluído"
            placeholder="Fluído"
            required
            control={control}
            name={'fluid'}
            options={mapperOptions(constants?.Fluids)}
          />

          <SelectInput
            options={mapperOptions(constants?.ElevationMethods)}
            label="Método de elevação"
            placeholder="Método de elevação"
            control={control}
            name="elevationMethod"
          />
        </div>

        <SelectInput
          options={mapperOptions(constants?.InterventionExpense)}
          label="Despesa de Intervenção"
          placeholder="Despesa de Intervenção"
          control={control}
          name="interventionExpense"
        />

        <Button
          className="px-24 h-12 mt-4"
          type="submit"
          title="Salvar"
          disabled={!isValid && !errors}
        />
      </form>
    </MdmLayout>
  );
};

export default CreateOilWell;
