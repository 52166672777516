import Input from '@/pages/FormsPage/Input/Input';
import { forwardRef, useImperativeHandle } from 'react';
import type { FormRef } from '../../../index';
import FormWrapper from '../../FormWrapper/FormWrapper';
import useCreateIsolamentoPositivoForm, {
  CreateIsolamentoPositivoFormData
} from './useCreateIsolamentoPositivoForm';

interface IsolamentoPositivoFormProps {
  isMandatory: boolean;
  isEditable?: boolean;
}

const IsolamentoPositivoForm = forwardRef<
  FormRef<CreateIsolamentoPositivoFormData>,
  IsolamentoPositivoFormProps
>((props, ref) => {
  const { isMandatory, isEditable = true } = props;

  const { methods } = useCreateIsolamentoPositivoForm();

  const {
    register,
    formState: { errors },
    watch,
    trigger
  } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      if (isValid) {
        return true;
      } else {
        console.log('isolamento positivo Form is invalid');
        return false;
      }
    },
    formValues: {
      ...watch()
    }
  }));

  return (
    <FormWrapper title="Detalhes de nova atividade">
      <div className="flex gap-4">
        <Input
          label="Número da Nota para retirada dos instrumentos"
          {...register('note_number')}
          disabled={!isEditable}
          errorMessage={errors.note_number?.message}
          type="number"
          required
        />
      </div>
    </FormWrapper>
  );
});

export default IsolamentoPositivoForm;
