import { format } from 'date-fns';
import { useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { BsTrash3Fill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryClient } from '../../../App';
import SquareButton from '../../../components/atoms/SquareButton';
import { Header } from '../../../components/atoms/table/Header';
import ModalConfirmation from '../../../components/molecules/ConfirmationModal';
import { useMdmUtils } from '../../../hooks/Common/MdmUtils';
import { deleteTank } from '../../../services/oilwells.services';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap w-full">
      {value || '-'}
    </span>
  );
};

export const TimeCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs whitespace-nowrap">
      {value ? format(new Date(value), 'dd/MM/yyyy') : '-'}
    </span>
  );
};

const gasMeterColumns = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Nome'} id="name" />,
    accessor: 'name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Código'} id="code" />,
    accessor: 'code',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Numero de Série'} id="serialNumber" />,
    accessor: 'serialNumber',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Classificação'} id="classification" />,
    accessor: 'classification',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Volume Total (m³)'} id="volume" />,
    accessor: 'volume',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Campo'} id="campo" />,
    accessor: 'oilFieldId',
    Cell: (value: any) => {
      const { fields } = useMdmUtils({
        fields: true
      });

      return (
        <DefaultCell
          value={
            fields?.find(
              (field: any) => field.id === value.row.original.oilFieldId
            )?.name
          }
        />
      );
    }
  },
  {
    Header: () => <Header text={'Instalação'} id="facility" />,
    accessor: 'facility.name',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Tipo de Medição'} id="measurementType" />,
    accessor: 'measurementType',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Data de atualização'} id="updatedAt" />,
    accessor: 'updatedAt',
    Cell: TimeCell
  },
  {
    Header: () => <Header text={'Status'} id="active" />,
    accessor: 'measurementStatus',
    Cell: ({ value, row, className }: any) => {
      return (
        <DefaultCell
          value={(value ?? true) ? 'Ativo' : 'Inativo'}
          row={row}
          className={className}
        />
      );
    }
  },
  {
    Header: () => <Header text={'Ações'} id="actions" />,
    accessor: 'actions',
    Cell: (props: any) => {
      const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
      const navigate = useNavigate();

      const handleTank = async () => {
        try {
          await deleteTank(props.row.original.id);
          toast.success('Tanque de medição deletado com sucesso');
          queryClient.invalidateQueries(['measuring-tank-adm']);
        } catch {
          toast.error('Erro ao deletar tanque de medição');
        }
      };

      return (
        <>
          <div className="flex gap-2">
            <SquareButton
              className="bg-red hover:bg-red/75"
              onClick={() => setModalDeleteIsOpen(old => !old)}
              icon={<BsTrash3Fill color="white" size={16} />}
            />

            <SquareButton
              onClick={() => navigate(`/tank/${props.row.original.id}`)}
              icon={<BiPencil color="white" size={16} />}
            />
          </div>

          {modalDeleteIsOpen && (
            <ModalConfirmation
              confirmAction={handleTank}
              title="Deletar tanque de medição"
              description="Tem certeza que deseja deletar esse tanque de medição? Essa é uma ação permanente."
              confirmButtonClassName="bg-emerald-600 hover:bg-emerald-400"
              isOpen={true}
              setIsOpen={() => setModalDeleteIsOpen(!modalDeleteIsOpen)}
            />
          )}
        </>
      );
    }
  }
];

export default gasMeterColumns;
