import { Header } from '../../../components/atoms/table/Header';
import { format } from 'date-fns';
import TooltipCell from '@/components/atoms/TooltipCell';
import { RiAlertFill } from 'react-icons/ri';

const DefaultCell = ({ value }: any) => {
  return (
    <span className="text-[#191919] text-xs leading-3 py-2">
      {value || '-'}
    </span>
  );
};

const oilwellsColumnsList = [
  {
    Header: () => <Header text={'Poço'} id="name" />,
    accessor: 'name',
    Cell: ({ value }: any) => {
      return (
        <a
          href={`/home/well-handover/${value}/info`}
          className="text-primary text-xs font-medium"
        >
          {value}
        </a>
      );
    },
    minWidth: 120
  },
  {
    Header: () => <Header text={'Nome ANP'} id="nameANP" />,
    accessor: 'nameANP',
    Cell: DefaultCell,
    minWidth: 120
  },
  {
    Header: () => <Header text={'Campo'} id="oilField" />,
    accessor: 'oilField',
    Cell: ({ value }: any) => {
      return (
        <TooltipCell
          value={value}
          className="inline-block text-xs leading-[10px] truncate min-w-[100px] max-w-[100px] rounded-md"
        />
      );
    },
    maxWidth: 120
  },
  {
    Header: () => <Header text={'Status ANP'} id="statusANP" />,
    accessor: 'statusANP',
    Cell: ({ value }: any) => {
      const getColorClass = (value: string) => {
        switch (value) {
          case 'Arrasado':
            return 'bg-[#cacaca89] text-[#4e4e4e]';
          case 'Produzindo':
            return 'bg-[#10AE88]/25 text-[#0C8A6C]';
          case 'Abandonado temporariamente sem monitoramento':
            return 'bg-[#FFE8D7] text-[#EC7100]';
          case 'Abandonado temporariamente com monitoramento':
            return 'bg-[#A5A900]/20 text-[#A5A900]';
          case 'Em intervenção':
            return 'bg-[#D7DFFF] text-[#193CB9]';
          case 'Fechado':
            return 'bg-[#FFD800]/20 text-[#a39f32]';
          case 'Abandonado permanentemente':
            return 'bg-gray/25 text-gray-dark';
        }
      };

      return (
        <TooltipCell
          value={value}
          className={`text-[10px] inline-block leading-[10px] truncate min-w-[100px] max-w-[100px] rounded-md px-2 py-1 text-center ${getColorClass(value)}`}
        />
      );
    },
    minWidth: 100
  },
  {
    Header: () => (
      <Header text={'Profundidade Vertical'} id="profundidadeVertical" />
    ),
    accessor: 'profundidadeVertical',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header text={'Profundidade Medida'} id="profundidadeMedida" />
    ),
    accessor: 'profundidadeMedida',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Integridade'} id="estadoIntegridade" />,
    accessor: 'estadoIntegridade',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Criticidade'} id="estadoCriticidade" />,
    accessor: 'estadoCriticidade',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Saúde'} id="saudePoco" />,
    accessor: 'saudePoco',
    Cell: ({ value }: any) => {
      const getColorClass = (value: string) => {
        switch (value) {
          case 'Regular':
            return 'bg-[#FFF069]/50 text-[#AD953C]';
          case 'Ruim':
            return 'bg-[#10AE88]/25 text-[#0C8A6C]';
          case 'Péssima':
            return 'bg-[#FF4646]/25 text-[#FF4646]';
          case 'Boa':
            return 'bg-[#10AE88]/25 text-[#0C8A6C]';
          case 'Ótima':
            return 'bg-[#BAFFDE] text-[#00AE70]';
          default:
            return 'bg-gray/25 text-gray-dark';
        }
      };

      return value ? (
        <span
          className={`text-[10px] leading-[10px] truncate w-full whitespace-nowrap overflow-hidden rounded-md px-2 py-1 my-1 text-center inline-block ${getColorClass(value)}`}
        >
          {value}
        </span>
      ) : (
        <DefaultCell value={value} />
      );
    }
  },
  {
    Header: () => <Header text={'Interv. Inspeção'} id="intervaloInspecao" />,
    accessor: 'intervaloInspecao',
    Cell: DefaultCell
  },
  {
    Header: () => (
      <Header text={'Interv. Monitoramento'} id="intervaloMonitoramento" />
    ),
    accessor: 'intervaloMonitoramento',
    Cell: DefaultCell
  },
  {
    Header: () => <Header text={'Well Handover'} id="statusWellHandover" />,
    accessor: 'latestSchematic',
    Cell: ({ row }: any) => {
      const { versions, statusWellHandover, name, approvedDate } = row.original;

      if (statusWellHandover === 'Realizado') {
        return (
          <a
            href={`/home/well-handover/${name}`}
            className="text-primary flex flex-col text-xs font-medium"
          >
            Versão {versions}
            <span className="text-gray-dark text-xs font-normal">
              Aprovação:
              {approvedDate
                ? format(new Date(approvedDate), 'dd/MM/yyyy')
                : 'Sem data'}
            </span>
          </a>
        );
      }

      if (statusWellHandover === 'Pendente') {
        return (
          <a
            href={`/home/well-handover/${name}`}
            className="text-primary flex flex-row items-center text-xs gap-2 font-medium"
          >
            <span className="w-5 h-5 bg-[#e6432a]/20 text-[#e6432a] text-xs rounded-md flex justify-center items-center flex-row">
              <RiAlertFill />
            </span>{' '}
            Versão {versions}
          </a>
        );
      }

      return (
        <span className="text-xs text-gray-lighter italic">Sem registro</span>
      );
    },
    minWidth: 175
  }
];

export default oilwellsColumnsList;
