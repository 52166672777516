import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const requiredError = 'Este campo é obrigatório';

const getSchema = (hasAnpDateField: boolean) => {
  if (hasAnpDateField) {
    return z.object({
      activity_date: z.date({ message: requiredError }),
      anp_date: z.date({ message: requiredError })
    });
  }

  return z.object({
    activity_date: z.date({ message: requiredError })
  });
};

export type CreateSingleDateFormData = {
  activity_date: Date;
  anp_date?: Date;
};

const useCreateSingleDateForm = ({
  hasAnpDateField = false
}: {
  hasAnpDateField?: boolean;
}) => {
  const schema = getSchema(hasAnpDateField);

  const methods = useForm({
    resolver: zodResolver(schema as any),
    mode: 'onChange'
  }) as unknown as ReturnType<typeof useForm<CreateSingleDateFormData>>;

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    if (data) {
      setValue('activity_date', data?.activity_date);
      if (hasAnpDateField) {
        setValue('anp_date', data?.anp_date);
      }
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateSingleDateForm;
