import { optionSchema } from '@/pages/FormsPage/optionSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { OpeningType } from '../AlteracaoBeanForm/OpeningType.enum';

const schema = z
  .object({
    current_frequency: z.coerce
      .number()
      .min(1, { message: 'Este campo é obrigatório' }),
    requested_frequency: z.coerce
      .number()
      .min(1, { message: 'Este campo é obrigatório' }),
    unit_of_measure: optionSchema(true),

    is_impact_on_gross_production: optionSchema(true),
    expected_impact_on_gross_production: z.string(),
    bastao_type_change: optionSchema(true),
    opening_type: optionSchema(true),
    operational_sequence_details: z.string()
  })
  .superRefine((data, ctx) => {
    if (
      data.opening_type.value === OpeningType.RAMPA &&
      !data.operational_sequence_details
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['operational_sequence_details'],
        message: 'Este campo é obrigatório quando o Tipo de abertura é Rampa'
      });
    }

    if (
      data.is_impact_on_gross_production.value === 'yes' &&
      !data.expected_impact_on_gross_production
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['expected_impact_on_gross_production'],
        message:
          'Este campo é obrigatório quando o Impacto na produção bruta é Sim'
      });
    }
  });

export type CreateOtimizacaoBombasFormData = z.infer<typeof schema>;

const useCreateOtimizacaoBombasForm = () => {
  const methods = useForm<CreateOtimizacaoBombasFormData>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { setValue } = methods;

  const useLoadSavedData = (data: any) => {
    console.log('load saved data');
    if (data) {
      console.log('data received');
      setValue('current_frequency', data?.current_frequency);
      setValue('requested_frequency', data?.requested_frequency);
      setValue('unit_of_measure', data?.unit_of_measure);
      setValue(
        'is_impact_on_gross_production',
        data?.is_impact_on_gross_production
      );
      setValue(
        'expected_impact_on_gross_production',
        data?.expected_impact_on_gross_production
      );
      setValue('bastao_type_change', data?.bastao_type_change);
      setValue('opening_type', data?.opening_type);
      setValue(
        'operational_sequence_details',
        data?.operational_sequence_details
      );
    }
  };

  return {
    methods,
    schema,
    useLoadSavedData
  };
};

export default useCreateOtimizacaoBombasForm;
