import Tabs from '@/components/atoms/Tab';
import AlertVariablesTab from './Components/AlerVariables/AlertVariablesTab';
import AlertUserSettings from './Components/Users/UserSettingsTab';

const AlertSettings = () => {
  const tabs = [
    {
      id: 0,
      label: 'Alteração de Variáveis',
      content: <AlertVariablesTab />
    },
    {
      id: 1,
      label: 'Alteração de Destinatários de Alertas',
      content: <AlertUserSettings />
    }
  ];

  return <Tabs tabs={tabs} />;
};

export default AlertSettings;
