export const mapperOptions = (data: any) =>
  data
    ? data.map((item: any) => ({
        value: item,
        label: item
      }))
    : [];

export const mapperOptionsTextConstants = (data: any) =>
  data
    ? data.map((item: any) => ({
        value: item,
        label: item
          .split('_')
          .map((word: string) => word.charAt(0) + word.slice(1).toLowerCase())
          .join(' ')
      }))
    : [];
