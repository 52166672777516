import { atom } from 'recoil';

export interface OilwellsListFilters {
  field: string[];
  nameANP: string[];
  anpStatus: string[];
  healthStatus: string[];
  statusWellHandover: string[];
}

export const oilwellsListFiltersAtom = atom<OilwellsListFilters>({
  key: 'oilwellsListFilters',
  default: {
    field: [],
    nameANP: [],
    anpStatus: [],
    healthStatus: [],
    statusWellHandover: []
  }
});
